import React from "react";
import { MdError } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";
import CustomTooltip from "../tooltip/CustomTooltip";
import CustomIcon from "../customIcon/CustomIcon";
import { Link } from "react-router-dom";
import "./displayAlerts.scss"
import { Button } from "react-bootstrap";
// different status messages used in the website
export const FetchError = (props) => {
  return (
    <div className="center flex-column p-0">
      <MdError size={120} className="darkErrorIcon mb-3" />
      <h3 className="errorHeading dark">Something went wrong.</h3>
      <h4 className="errorDescription dark">
        This content is not available at this moment.
      </h4>
      <h4 className="errorDescription dark">Please try again later.</h4>
    </div>
  );
};

export const FetchErrorLight = (props) => {
  return (
    <div className="center flex-column p-0">
      <MdError size={100} className="text-white mb-3" />
      <h3 className="errorHeading ">Something went wrong.</h3>
      <h4 className="errorDescription ">
        This content is not available at this moment.
      </h4>
      <h4 className="errorDescription ">Please try again later.</h4>
    </div>
  );
};
export const EmptyResponse = (props) => {
  return (
    <div className="center flex-column p-0">
      <MdError size={120} className="darkErrorIcon mb-3" />
      {/* <h3 className="errorHeading dark">Sorry,</h3> */}
      <h4 className="errorDescription dark">It looks like a bit empty here.</h4>
      <h4 className="errorDescription dark">Please try again later.</h4>
    </div>
  );
};
export const EmptyResponseLight = (props) => {
  return (
    <div className="center flex-column p-0">
      <MdError size={100} className="text-white mb-3" />
      {/* <h3 className="errorHeading ">Oops!</h3> */}
      <h4 className="errorDescription ">It looks like a bit empty here.</h4>
      <h4 className="errorDescription ">Please try again later.</h4>
    </div>
  );
};

export const CartEmpty = (props) => {
  return (
    <div className="center flex-column p-0">
      <CustomIcon icon="cart" size={50} className="CartIcon" />
      {/* <h3 className="errorHeading ">Hey Friend,</h3> */}
      <h4 className="errorDescription ">Your cart looks empty.</h4>
      <h4 className="errorDescription ">Why don't you add something ?</h4>
      <Link to="/own-a-fossil" className="linkToPurchase text-white">
     
        <Button
          className="bordered"
          variant="secondary"
          
        >
          view fossils
        </Button>
     
      
      </Link>
    </div>
  );
};
export const NoItemsFound = (props) => {
  return (
    <div className="center flex-column p-0 ">
      <MdError size={90} className="darkErrorIcon mb-1" />

      <h3 className="errorHeading dark">Sorry,</h3>
      <h4 className="errorDescription dark">No results to show.</h4>
      {/* <h4 className="errorDescription dark">
        {" "}
        Please check the spelling or try different query.
      </h4> */}
    </div>
  );
};
export const NoItemsFoundLight = (props) => {
  return (
    <div className="center flex-column p-0">
      <MdError size={90} className="text-white mb-1" />
      <h3 className="errorHeading">Sorry,</h3>
      <h4 className="errorDescription ">No results to show.</h4>
      {/* <h4 className="errorDescription ">
        {" "}
        Please check the spelling or try different query.
      </h4> */}
    </div>
  );
};
export const FormSubmitSuccess = (props) => {
  return (
    <div className="center flex-column contactUsAlertSection">
      <AiFillCheckCircle className="submitSuccessIcon theme2" />
      <h2 className="submitSuccessHead theme2">Thank you!</h2>
      <p className="submitSuccessPara theme2">
        Your submission have been submitted successfully.
      </p>
      {props.msg2 !== "hide" && (
        <p className="submitSuccessPara theme2">
          Our representative will contact you soon.
        </p>
      )}
    </div>
  );
};

export const FormSubmitError = (props) => {
  return (
    <div className="center flex-column  contactUsAlertSection">
      <MdError className="submitErrorIcon theme2" />
      <h2 className="submitErrorHead theme2">Submission Failed!</h2>
      <p className="submitErrorPara text-center theme2">
        Looks like something went wrong. <br />
        Please try later.
      </p>
    </div>
  );
};
export const ShowingSearchResultsDialog = ({ value, count, onClick }) => {
  return (
    <p className="searchResultTextDisplay ">
      {" "}
      Showing {count} result(s) for "
      <b>
        <i>{value}</i>
      </b>{" "}
      "
      <CustomTooltip title="Please click to clear search">
        <a className="clearSearch dark" onClick={onClick}>
          Clear search
        </a>
      </CustomTooltip>
    </p>
  );
};
export const ShowingSearchResultsDialogLight = ({ value, count, onClick }) => {
  return (
    <p className="searchResultTextDisplay light">
      {" "}
      Showing {count} result(s) for "
      <b>
        <i>{value}</i>
      </b>{" "}
      "{" "}
      <CustomTooltip title="Please click to clear search">
        <a className="clearSearch light" onClick={onClick}>
          {" "}
          Clear search
        </a>
      </CustomTooltip>
    </p>
  );
};
