import React from "react";
import { MdNavigateNext } from "react-icons/md";
import ReactImageMagnify from "react-image-magnify";
import CustomTooltip from "../tooltip/CustomTooltip";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { Autoplay, Pagination } from "swiper";
import dummy from "../../../assets/images/dummyBg.png";
const DetailsPageSwiperWithZoom = ({ id, image, images, onClick }) => {
  const handleOnError = (e) => {
    e.target.src = dummy;
  };
  console.log(images?.length);
  return (
    <>
      <div className="tagOther four" onClick={onClick}>
        {images.length > 0 ? `View all photos` : `Click to enlarge`}{" "}
        <MdNavigateNext />
      </div>
      <div id="enlargeImageContainer"></div>

      <Swiper
        pagination={{ clickable: true }}
        autoplay={{
          delay: 7500,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        loop
        modules={[Pagination,Autoplay]}
        className=""
        key={id}
      >
        <SwiperSlide className="cursor-pointer" onClick={onClick}>
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "Fossil",
                isFluidWidth: true,
                onError: (event) => {
                  event.target.src = dummy;
                },
                src: process.env.REACT_APP_BACKEND_URL + "public/" + image,
              },
              largeImage: {
                src: process.env.REACT_APP_BACKEND_URL + "public/" + image,
                width: 1200,
                height: 920,
              },
              onError: (event) => {
                event.target.src = dummy;
              },
              enlargedImagePosition: "top",
              enlargedImageContainerDimensions: {
                width: "100%",
                height: "100%",
              },
              enlargedImagePortalId: "enlargeImageContainer",
              //enlargedImageContainerDimensions:{width: '600px', height: '600px'},
              //enlargedImageContainerClassName:"enlargeContainer",
              //isEnlargedImagePortalEnabledForTouch: true,
            }}
          />
        </SwiperSlide>
        {images.length > 0 &&
          images.slice(0, 4).map((item, index) => {
            if (item.image !== null)
              return (
                <SwiperSlide className="cursor-pointer" 
                  
                  onClick={onClick}
                >
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "Fossil",
                        isFluidWidth: true,
                        onError: (event) => {
                          event.target.src = dummy;
                        },
                        src:
                          process.env.REACT_APP_BACKEND_URL +
                          "public/" +
                          item.image,
                      },
                      largeImage: {
                        src:
                          process.env.REACT_APP_BACKEND_URL +
                          "public/" +
                          item.image,
                        width: 1200,
                        height: 920,
                      },
                      onError: (event) => {
                        event.target.src = dummy;
                      },
                      enlargedImagePosition: "top",
                      enlargedImageContainerDimensions: {
                        width: "100%",
                        height: "100%",
                      },
                      enlargedImagePortalId: "enlargeImageContainer",
                      //enlargedImageContainerDimensions:{width: '600px', height: '600px'},
                      //enlargedImageContainerClassName:"enlargeContainer",
                      //isEnlargedImagePortalEnabledForTouch: true,
                    }}
                  />
                </SwiperSlide>
              );
          })}
      </Swiper>
    </>
  );
};

export default DetailsPageSwiperWithZoom;
