import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const CMSFormTextArea = (props) => {
  const { label, name,theme, required, form, maxLength, ...rest } = props;
  return (
    <div className="form-group position-relative">
      {/* {
                (label !== "") &&
                <>
                    {
                        (required === true)
                            ? <OverlayTrigger
                                key={`tooltip-${name}`}
                                placement='right'
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Required field.
                                    </Tooltip>
                                }
                            ><label htmlFor={name} className={`required ' ${(form.touched[name] && form.errors[name]) ? "isInvalidText" : ""}`}> {label}</label></OverlayTrigger>
                            : <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "isInvalidText" : ""}`}> {label}</label>
                    }
                </>
            } */}

      {label !== "" && (
        <>
          {required === true ? (
            <label
              htmlFor={name}
              className={`required ' ${
                form.touched[name] && form.errors[name] ? "isInvalidText" : ""
              }`}
            >
              {" "}
              {label}
            </label>
          ) : (
            <label
              htmlFor={name}
              className={` notRequired ${
                form.touched[name] && form.errors[name] ? "isInvalidText" : ""
              }`}
            >
              {" "}
              {label}
            </label>
          )}
        </>
      )}
      {/* <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "isInvalidText required" : "required"}`}>{label}</label> */}

      <Field
        as="textarea"
        id={name}
        name={name}
        maxLength={maxLength}
        className={`form-control customInput textArea ${
          form.touched[name] && form.errors[name] ? "is-invalid" : ""
        }`}
        {...rest}
      />
      <div
        className={`maxLength ${theme === "light" ?"cream" :""} ${
          form.touched[name] && form.errors[name] ? "light" : ""
        } end`}
      >
        {maxLength ? form.values[name].length + "/" + maxLength : null}
      </div>
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="errorMessage">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

export default CMSFormTextArea;
