import React from "react";
import { AppliedFilter, AppliedFilterSort } from "../../../../shared/filters/AppliedFilter";

const AppliedPressReleasesFilter = ({screen,setParentScreen,onClick}) => {
  const handleRemoveItem = (array, value, type) => {
    setParentScreen({
      ...screen,
      overlay: true,
    });
    setTimeout(() => {
      const newArr = array.filter((e) => e !== value);

      if (type === "language") {
        setParentScreen({
          ...screen,
          language: newArr,
          overlay: false,
        });
      }
      if (type === "year") {
        setParentScreen({
          ...screen,
          year: newArr,
          overlay: false,
        });
      }
      if (type === "sources") {
        setParentScreen({
          ...screen,
          sources: newArr,
          overlay: false,
        });
      }
      if (type === "relevant") {
        setParentScreen({
          ...screen,
          relevant: "",
          overlay: false,
        });
      }
      localStorage.setItem("pressFiltersApplied", JSON.stringify(""));
      localStorage.setItem("pressScreenValues", JSON.stringify(screen));
    }, 100);
  };
  const handleRemoveItemSort = () => {
    setParentScreen({
      ...screen,
      overlay: true,
    });
    setTimeout(() => {
      setParentScreen({
        ...screen,
        sortedValue: "",
        overlay: false,
      });
    }, 2000);
  };
  return (

      <div className="start filtersTopDisplay">
        Applied filter(s) :
        {/* {screen.sortedValue && (
          <AppliedFilterSort
            value={screen.sortedValue}
            onClick={handleRemoveItemSort}
          />
        )} */}
        {screen.relevant && (
        <AppliedFilter
          value="relevant"
          array={[]}
          type="relevant"
          onClick={handleRemoveItem}
        />
      )}
        {screen.language &&
          screen.language.map((item) => {
            return (
              <AppliedFilter
                value={item}
                onClick={handleRemoveItem}
                array={screen.language}
                type="language"
              />
            );
          })}
        {screen.year &&
          screen.year.map((item) => {
            return (
              <AppliedFilter
                value={item}
                onClick={handleRemoveItem}
                array={screen.year}
                type="year"
              />
            );
          })}
        {screen.sources &&
          screen.sources.map((item) => {
            return (
              <AppliedFilter
                value={item}
                onClick={handleRemoveItem}
                array={screen.sources}
                type="sources"
              />
            );
          })}
      </div>

  );
};

export default AppliedPressReleasesFilter;
