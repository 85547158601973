import React, { useState, useReducer, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import "../../fossils.scss";
import "./collectionDetails.scss";
import BodyLayout from "../../../../layout/body/BodyLayout";
import CustomIcon from "../../../../shared/customIcon/CustomIcon";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
import {
  screenInitialState,
  screenReducer,
} from "../../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../../config/axios";
import { BsDownload } from "react-icons/bs";
import { Loading, OverLayLoading } from "../../../../shared/loading/Loading";
import { FetchError } from "../../../../shared/displayAlerts/DisplayAlerts";
import ExpandedModal from "../../../../shared/subpageImageCircle/expandedModal/ExpandedModal";
import DetailsPageSwiperWithZoom from "../../../../shared/detailsPageSwiperWithZoom/DetailsPageSwiperWithZoom";
import parse from "html-react-parser";
import { VisitButtons } from "../../../../shared/buttons/Buttons";
const CollectionDetails = (props) => {
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const { slug } = useParams();
  const [screen, setScreen] = useState({
    show: false,
    id: slug,
    animate: true,
    data: [],
    fossilLoading: true,
    filterApplied: "",
  });

  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    setScreen({
      ...screen,
      fossilLoading: true,
    });
    const saved = localStorage.getItem("fossilCollectionFilterApplied");
    const initialValue = JSON.parse(saved);
    const activeIdSaved = localStorage.getItem("currentActiveIdmMOT");
    const activeId = JSON.parse(activeIdSaved);
    try {
      const res = await axiosInstance.get(
        `fossil-collections/` +
          (activeId ? activeId : screen.id) +
          (initialValue ? "?" + initialValue : "")
      );
      console.log(res.data);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });

      setScreen({
        ...screen,
        animate: false,
        fossilLoading: false,
        data: res.data.item.images,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      setScreen({
        ...screen,
        animate: false,
      });
    }
  };
  const showModal = () => {
    setScreen({ ...screen, show: true });
  };
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      const activeIdSaved = localStorage.getItem("currentActiveIdMOT");
      const activeId = JSON.parse(activeIdSaved);

      localStorage.setItem("currentActiveIdMOT", JSON.stringify(screen.id));

      loadItem();
    }, 100);

    navigate(`/fossils/memory-of-time-collection/details/${screen.id}`);
  }, [screen.id]);
  return (
    <BodyLayout>
      {detailsState.isFetching === true && !screen.data && <Loading />}
      {screen.fossilLoading === true && detailsState.hasError === false && (
        <OverLayLoading />
      )}
      <div className="fossilsSection position-relative pb-4">
        <div className="yellowBox"></div>

        <Row className="ms-xl-1  pb-4">
          <Col xl={12} className="pt-xl-5 pt-md-3">
            <Row className="uniPadding large innerSection2 pt-3">
              <Col xl={12} lg={12} md={12} className="pt-4 pb-2">
                <div className="headContainer">
                  <div className="backIconTop smallDevice">
                    <CustomTooltip title="Click to go back">
                      <Link
                        to="/fossils/memory-of-time-collection"
                        className="goBack"
                      >
                        <CustomIcon
                          icon="leftArrow"
                          size={40}
                          className="imagePlus "
                          // onClick={() => handleBack()}
                        />
                      </Link>
                    </CustomTooltip>
                  </div>
                  <div className="backIcon">
                    <CustomTooltip title="Click to go back">
                      <Link
                        to="/fossils/memory-of-time-collection"
                        className="goBack"
                      >
                        <CustomIcon
                          icon="leftArrow"
                          size={40}
                          className="imagePlus "
                          // onClick={() => handleBack()}
                        />
                      </Link>
                    </CustomTooltip>
                  </div>
                  <h3 className="headingText mb-0">
                    Memory of Time Collection
                  </h3>
                </div>
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                className="pt-0 pe-xl-5 prevNextIcons"
              >
                <div className="nextPrevIcons me-xl-4">
                  <div className="start me-2 nextContainer ">
                    {detailsState &&
                      detailsState.data &&
                      detailsState.data.pagination &&
                      detailsState.data.pagination.prevId && (
                        <>
                          <CustomTooltip
                            title="Please click to view previous fossil"
                            position="bottom-end"
                          >
                            <div className="hideOnMobile">
                              <CustomIcon
                                onClick={() =>
                                  setScreen({
                                    ...screen,
                                    id: detailsState.data.pagination.prevId,
                                    fossilLoading: true,
                                  })
                                }
                                icon="leftArrow"
                                size={40}
                                className="leftRotate me-xl-4 me-md-0  me-4 hideOnMobile"
                                // onClick={() => handleBack()}
                              />
                            </div>
                            <div className="showOnMobile">
                              <CustomIcon
                                onClick={() =>
                                  setScreen({
                                    ...screen,
                                    id: detailsState.data.pagination.prevId,
                                    fossilLoading: true,
                                  })
                                }
                                icon="leftArrow"
                                size={25}
                                className="leftRotate me-xl-4  me-2 showOnMobile"
                                // onClick={() => handleBack()}
                              />
                            </div>
                          </CustomTooltip>
                          <p className="pe-3 ps-2 mb-0 viewNextFossil hideOnMobile">
                            Previous fossil
                          </p>
                          <p className="pe-3 ps-1 mb-0 viewNextFossil showOnMobile">
                            Previous
                          </p>
                        </>
                      )}
                  </div>

                 
                      <div className="end  nextContainer">
                         {detailsState &&
                    detailsState.data &&
                    detailsState.data.pagination &&
                    detailsState.data.pagination.nextId && (<>
                  
                        <p className="pe-2 mb-0 viewNextFossil hideOnMobile">
                          Next fossil
                        </p>
                        <p className="pe-1 mb-0 viewNextFossil showOnMobile">
                          Next
                        </p>
                        <CustomTooltip
                          title="Please click to view next fossil"
                          position="bottom-end"
                        >
                          <div className="hideOnMobile">
                            <CustomIcon
                              onClick={() =>
                                setScreen({
                                  ...screen,
                                  id: detailsState.data.pagination.nextId,
                                  fossilLoading: true,
                                })
                              }
                              icon="leftArrow"
                              size={40}
                              className="rightRotate me-xl-4 me-md-4"
                              // onClick={() => handleBack()}
                            />
                          </div>
                          <div className="showOnMobile">
                            <CustomIcon
                              onClick={() =>
                                setScreen({
                                  ...screen,
                                  id: detailsState.data.pagination.nextId,
                                  fossilLoading: true,
                                })
                              }
                              icon="leftArrow"
                              size={25}
                              className="rightRotate me-2"
                              // onClick={() => handleBack()}
                            />
                          </div>
                        </CustomTooltip>
                          </>
                             )}
                      </div>
                 
                </div>
              </Col>
            </Row>

            {detailsState && detailsState.hasError && (
              <>
                <Row className="uniPadding large innerSection2 pt-1 ">
                  <Col xl={12} lg={12} md={12} className="pt-4 pe-xl-5">
                    <FetchError />
                  </Col>
                </Row>
              </>
            )}
            {detailsState &&
              !detailsState.hasError &&
              detailsState.data &&
              detailsState.data.item && (
                <>
                  <Row className="uniPadding large innerSection2 pt-1 ">
                    <Col xl={12} lg={12} md={12} className="pt-1 pe-xl-5">
                      <div
                        className={`detailsHolder  ms-lg-2  ${
                          screen.animate ? "animate" : ""
                        } me-xl-4 me-lg-4`}
                      >
                        <div className="tagDisplay d-flex">
                          {detailsState.data.item.newDiscovery === "1" && (
                            <span className={`tag one`}>Newly discovered</span>
                          )}
                          {detailsState.data.item.fossilOfTheMonth === "1" && (
                            <span className={`tag two`}>
                              Fossil of the month
                            </span>
                          )}
                          {detailsState.data.item.excellentPreservation ===
                            "1" && (
                            <span className={`tag three`}>
                              Excellent Preservation
                            </span>
                          )}
                          {detailsState.data.item.museumQuality === "1" && (
                            <span className={`tag seven`}>Museum Quality</span>
                          )}
                          {detailsState.data.item.exceptionalRarity === "1" && (
                            <span className={`tag four`}>
                              Exceptional Rarity
                            </span>
                          )}
                          {detailsState.data.item.collectible === "1" && (
                            <span className={`tag five`}>Collectible</span>
                          )}
                          {detailsState.data.item.newSpecies === "1" && (
                            <span className={`tag six`}>New Species</span>
                          )}
                        </div>

                        <Row className="detailsDisplay">
                          <Col  className="leftSection ">
                            <Row className="nameAndPrice">
                              <Col>
                                <p className="fossilName">
                                  {detailsState.data.item.genus}
                                </p>

                                <hr className="redLine" />
                              </Col>
                            </Row>

                            <Row>
                              <Col xl={4} lg={5} md={6} sm={12} xs={12}>
                                <div className="mt-3 ">
                                  {detailsState.data.item.category && (
                                    <>
                                      <p className="fossilSubHead">Category</p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.category}
                                      </p>
                                    </>
                                  )}

                                  {detailsState.data.item.subCategory && (
                                    <>
                                      <p className="fossilSubHead">Subcategory</p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.subCategory}
                                      </p>
                                    </>
                                  )}
                                  {detailsState.data.item.site && (
                                    <>
                                      <p className="fossilSubHead">Site</p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.site}
                                      </p>
                                    </>
                                  )}
                                  {detailsState.data.item.ageOrOriginDate && (
                                    <>
                                      <p className="fossilSubHead">
                                        Age / Origin date
                                      </p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.ageOrOriginDate}
                                      </p>
                                    </>
                                  )}
                                  {console.log(detailsState.data.item)}
                                  {detailsState.data.item.dimension && (
                                    <>
                                      <p className="fossilSubHead">
                                        Dimension{" "}
                                      </p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.dimension}
                                      </p>
                                    </>
                                  )}
                                       {detailsState.data.item.matrix && (
                                    <>
                                      <p className="fossilSubHead">
                                        Matrix{" "}
                                      </p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.matrix}
                                      </p>
                                    </>
                                  )}
                                </div>
                              </Col>

                              <Col xl={8} lg={7} md={6} sm={12} xs={12}>
                                {detailsState.data.item.description && (
                                  <p className="fossilDetails mt-3">
                                    {parse(detailsState.data.item.description)}
                                  </p>
                                )}
                                {detailsState.data.item.relatedMaterial && (
                                  <>
                                    <a
                                      className="fossilSubHead orange mt-2"
                                      download={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "public/" +
                                        detailsState.data.item.relatedMaterial
                                      }
                                      href={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "public/" +
                                        detailsState.data.item.relatedMaterial
                                      }
                                    >
                                      <CustomTooltip title="Please click to download">
                                        Related Material <BsDownload />
                                      </CustomTooltip>
                                    </a>
                                    {/* <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.relatedMaterial}
                                      </p> */}
                                  </>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12}
                            className="rightSection position-relative ps-xl-0 ps-lg-0"
                            
                          >
                            <DetailsPageSwiperWithZoom
                              images={screen.data}
                              image={detailsState.data.item.image}
                              onClick={showModal}
                              id={detailsState.data.item._id}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <ExpandedModal
                    show={screen.show}
                    onHide={() => setScreen(() => ({ ...screen, show: false }))}
                    array={[
                      detailsState.data.item.image &&
                        detailsState.data.item.image,
                    ]}
                    url={`fossils/gallery/${slug}`}
                  />
                  <Row className="uniPadding large pt-xl-5 pt-lg-5 ">
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      className="pe-xl-5 bottomButton"
                    >
                      <CustomTooltip title="Click to purchase">
                        <Button
                          className="bordered"
                          variant="secondary"
                          target="_blank"
                          href={process.env.REACT_APP_BASE_URL + "own-a-fossil"}
                        >
                          visit shop
                        </Button>
                      </CustomTooltip>
                    </Col>
                  </Row>
                </>
              )}
          </Col>
        </Row>
      </div>
    </BodyLayout>
  );
};

export default CollectionDetails;
