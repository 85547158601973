import React from "react";
import "./loading.scss";
// full page loading
export const Loading = () => {
  return (
    <div className="loading center">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export const OverLayLoading = ({ color }) => {
  return (
    <div
      className={`overlayLoading center ${color === "light" ? "light" : " "}`}
    >
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export const ContainerLoading = () => {
  return (
    <div className="containerLoading center">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export const OnlyLoading = () => {
  return (
    <div className="onlyLoading center">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export const OnlyLoadingLight = () => {
  return (
    <div className="onlyLoadingLight center">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};