import React, { useReducer, useState } from "react";
import { Formik } from "formik";
import { Row, Col } from "react-bootstrap";
import CMSFormInput from "../../../shared/formFields/CMSFormInput";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import { FormSubmitButton } from "../../../shared/buttons/Buttons";
import CMSFormTelephone from "../../../shared/formFields/CMSFormTelephone";
import { whatsappCheckoutSchema } from "../../../../utils/yupValidation";
import {
  updateInitialState,
  updateReducer,
} from "../../../shared/reducers/updateReducer";
import axiosInstance from "../../../../config/axios";
import { OverLayLoading } from "../../../shared/loading/Loading";
const WhatsappForm = ({ setScreen, screen }) => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [error, setError] = useState(false);
  const localEmailValueFetch = localStorage.getItem("BuyerEmail");
  const localEmailValue = JSON.parse(localEmailValueFetch);
  const saved = localStorage.getItem("BuyerId");
  const buyerId = JSON.parse(saved);
  const buyerSaved = localStorage.getItem("BuyerDetails");
  const buyerDetail = JSON.parse(buyerSaved);
  const handleSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axiosInstance.post(`cart/whatsapp-checkout`, data);
      window.scrollTo(0, 0);
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      localStorage.setItem("cartShowForm", JSON.stringify(false));
      setScreen({
        ...screen,
        showForm: false,
        status: "success",
        orderByWhatsapp: true,
        orderId: res.data.orderId,
      });
      localStorage.setItem("cartItemsCount", JSON.stringify(0));
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      localStorage.setItem("cartShowForm", JSON.stringify(true));
      setScreen({
        ...screen,
        showForm: false,
        status: "error",
        orderByWhatsapp: false,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    }
  };

  return (
    <>
      {updateState.isSubmitting === true && <OverLayLoading />}
      <Formik
        initialValues={
          buyerDetail !== null
            ? {
                buyerId: buyerId,
                firstName: buyerDetail.firstName ? buyerDetail.firstName : "",
                lastName: buyerDetail.lastName ? buyerDetail.lastName : "",
                email: buyerDetail.email ? buyerDetail.email : "",
                phone: buyerDetail.phone ? buyerDetail.phone : "",
              }
            : {
                buyerId: buyerId,
                firstName: "",
                lastName: "",
                email: localEmailValue ? localEmailValue : "",
                phone: "",
              }
        }
        validationSchema={whatsappCheckoutSchema}
        onSubmit={(values, { resetForm }) => {
          // handleSubmit(values);
          !error ? handleSubmit(values) : console.log("error");
          console.log(values);
        }}
      >
        {(form) => (
          <>
            <Row className="mx-0">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                <div className=" end">
                  <p className="mandatoryContainer light mb-0">All fields are mandatory</p>
                </div>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                <CMSFormInput
                  name="firstName"
                  placeholder="First Name"
                  form={form}
                  required={true}
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                <CMSFormInput
                  name="lastName"
                  placeholder="Last Name"
                  form={form}
                  required={true}
                />
              </Col>

              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                id="email"
                className="mb-3"
              >
                <CMSFormInput
                  name="email"
                  placeholder="Email"
                  form={form}
                  required={true}
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                id="phone"
                className="mb-3"
              >
                <CMSFormTelephone
                  name="phone"
                  placeholder="Phone"
                  form={form}
                  errorState={error}
                  setErrorState={setError}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="end mt-xl-3 px-4"
              >
                
                  <FormSubmitButton
                    text="Place order"
                    onClick={form.handleSubmit}
                  />
               
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </>
  );
};

export default WhatsappForm;
