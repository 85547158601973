import React, { useReducer, useEffect } from "react";
import DiscoverUsSubPage from "../../../layout/subPage/DiscoverUsSubpage";

import axiosInstance from "../../../../config/axios";
import TextSwiper from "../textSwiper/TextSwiper";
import { Loading } from "../../../shared/loading/Loading";
import { screenInitialState, screenReducer } from "../../../shared/reducers/screenReducer";

const MIM = () => {
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );

  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axiosInstance.get(`page-content/mim`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
    document.title = "MIM Museum";
  }, []);
  return (
    <>
      {detailsState.isFetching === true ? (
        <Loading />
      ) : detailsState.hasError ? (
        <DiscoverUsSubPage error={true} />
      ) : (
        <DiscoverUsSubPage
          heading={detailsState.data.heading}
          leftContent={
            <TextSwiper
              layout="ourHistory"
              slide1={detailsState.data.slide1}
              slide2={detailsState.data.slide2}
              slide3={detailsState.data.slide3}
              slide4={detailsState.data.slide4}
              slide5={detailsState.data.slide5}
            />
          }
          title={
            detailsState.data.bannerCaption
              ? detailsState.data.bannerCaption
              : detailsState.data.heading
          }
          images={detailsState.data.banner}
          buttonText={detailsState.data.buttonText}
          buttonLink={detailsState.data.buttonLink}
          galleryCount={detailsState.data.galleryCount}
          slug="page-gallery/mim"
        />
      )}
    </>
  );
};

export default MIM;
