import React from "react";
import { useState, useEffect, useReducer } from "react";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import WhatsappForm from "../checkOutForms/WhatsappForm";
import GuestForm from "../checkOutForms/GuestForm";
import { MdError } from "react-icons/md";
import { FormSubmitButton } from "../../../shared/buttons/Buttons";
import StickyBox from "react-sticky-box";
import axiosInstance from "../../../../config/axios";
import {
  updateInitialState,
  updateReducer,
} from "../../../shared/reducers/updateReducer";
const CheckoutType = ({ parentState, setParentState }) => {
  const buyerSaved = localStorage.getItem("BuyerDetails");
  const buyerDetails = JSON.parse(buyerSaved);
  const [screen, setScreen] = useState({
    checkoutType: "whatsapp",
    showForm: false,
    status: "",
    orderByWhatsapp: false,
    payOnline: false,
    orderId: null,
  });
  const saveToLocalStorage = (data) => {
    localStorage.setItem("cartShowForm", JSON.stringify(1));
  };
  useEffect(() => {
    if (buyerDetails !== null) {
      setScreen({
        ...screen,
        showForm: false,
      });
    }
    // if (screen.status === "error") {
    //   setTimeout(() => {
    //     setScreen({
    //       ...screen,
    //       status: "",
    //       showForm: true,
    //     });
    //   }, 4000);
    // }

    // if (screen.status === "success") {
    //   setParentState({
    //     ...parentState,
    //     payOnline: true,
    //     orderId: screen.orderId,
    //   });
    //   setScreen({
    //     ...screen,
    //     status: "",
    //     showForm: false,
    //   });
    // }
    if (screen.orderByWhatsapp === true) {
      setTimeout(() => {
        window.open(
          `https://api.whatsapp.com/send?phone=009619540555&text=Hi Memory of time, I would like to go with order number ${screen.orderId}`,
          "_blank"
        );
      }, 10000);
      setParentState({
        ...parentState,
        orderByWhatsapp: true,
        orderId: screen.orderId,
      });
    }
    if (screen.payOnline === true) {
      setParentState({
        ...parentState,
        payOnline: true,
        orderId: screen.orderId,
      });
    }
  }, [screen.status]);
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  useEffect(() => {
    const saved = localStorage.getItem("cartShowForm");
    const showForm = JSON.parse(saved);
    console.log(showForm);
    if (showForm === 1) {
      setScreen({
        ...screen,
        showForm: true,
      });
    }
  }, []);
  useEffect(() => {
    const saved = localStorage.getItem("checkOutType");
    const showForm = JSON.parse(saved);

    if (showForm !== null) {
      setScreen({
        ...screen,
        checkoutType: showForm,
        showForm: true,
      });
    }
    
  }, []);
  const saved = localStorage.getItem("BuyerId");
  const buyerId = JSON.parse(saved);
  const handleSubmit = async () => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    const data =
      buyerDetails !== null
        ? {
            buyerId: buyerId,
            firstName: buyerDetails.firstName ? buyerDetails.firstName : "",
            lastName: buyerDetails.lastName ? buyerDetails.lastName : "",
            email: buyerDetails.email ? buyerDetails.email : "",
            phone: buyerDetails.phone ? buyerDetails.phone : "",
          }
        : null;
    if (data !== null) {
      try {
        const res = await axiosInstance.post(`cart/whatsapp-checkout`, data);
        window.scrollTo(0, 0);
        updateDispatch({
          type: "FORM_SUBMISSION_SUCCESS",
          payload: res.data,
        });
        localStorage.removeItem("cartShowForm");
        setScreen({
          ...screen,
          showForm: false,
          status: "success",
          orderByWhatsapp: true,
          orderId: res.data.orderId,
        });
      } catch (error) {
        const status = error.response ? error.response.status : 500;
        const errorMessage =
          status === 400 ? error.response.data.errorMessage : error.message;
        updateDispatch({
          type: "FORM_SUBMISSION_FAILURE",
          payload: errorMessage,
        });
        localStorage.setItem("cartShowForm", JSON.stringify(1));
        setScreen({
          ...screen,
          showForm: false,
          status: "error",
          orderByWhatsapp: false,
        });
        setTimeout(() => {
          updateDispatch({
            type: "FORM_INITIAL_STATE",
          });
        }, 8000);
      }
    }
  };
  return (
    <div className="checkoutTypeSection  h-100 ms-xl-2">
      {(buyerDetails !== null || screen.showForm === false) &&
      screen.status !== "error" &&
      screen.status !== "success" ? (
        <>
          {screen.showForm === false && (
            <>
              <StickyBox offsetTop={20} offsetBottom={20}>
                <div
                  onClick={() => {
                    setScreen({
                      ...screen,
                      showForm: true,
                    });
                    localStorage.setItem("cartShowForm", JSON.stringify(1));
                  }}
                >
                  <FormSubmitButton text="Place order" onClick={null} />
                </div>
              </StickyBox>
            </>
          )}
        </>
      ) : null}
      
      {screen.showForm === true ? (
        <>
          <div className="topSelection ">
            <div
              onClick={() =>
               { setScreen({
                  ...screen,
                  checkoutType: "whatsapp",
                });localStorage.setItem("checkOutType", JSON.stringify("whatsapp"));}
              }
              className={`whatsapp center checkout ${
                screen.checkoutType === "whatsapp" ? "active" : ""
              } `}
            >
              <p className="checkoutHead mb-0">WhatsApp checkout</p>

              <div className="arrow-down"></div>
            </div>
            <div
              onClick={() => {
                setScreen({
                  ...screen,
                  checkoutType: "online",
                });
                localStorage.setItem("checkOutType", JSON.stringify("online"));
              }}
              className={`online center checkout ${
                screen.checkoutType === "online" ? "active" : ""
              } `}
            >
              <p className="checkoutHead mb-0">Online Payment</p>

              <div className="arrow-down"></div>
            </div>
          </div>
          <div className="formArea">
            {screen.checkoutType === "online" ? (
              <GuestForm screen={screen} setScreen={setScreen} />
            ) : (
              <WhatsappForm screen={screen} setScreen={setScreen} />
            )}
          </div>
        </>
      ) : null}

      {/* {screen.showForm === false &&
        screen.status !== "error" &&
        screen.checkoutType === "online" && (
          <>
            <StickyBox offsetTop={20} offsetBottom={20}>
              <CustomTooltip
                title="Please click to place order"
                position="bottom"
              >
                <Link to="/checkout">
                  <FormSubmitButton text="Place order" onClick={null} />
                </Link>
              </CustomTooltip>
            </StickyBox>
          </>
        )} */}

      {screen.showForm === false &&
        screen.status === "error" &&
        screen.checkoutType === "online" && (
          <>
            <div className="center flex-column  contactUsAlertSection">
              <MdError className="submitErrorIcon theme2" />
              <h2 className="submitErrorHead theme2">
                Submission Failed
              </h2>
              <p className="submitErrorPara text-center theme2  mb-2">
                Looks like something went wrong. <br />
                Please retry payment or change checkout type.
              </p>
              <div className="additionalMsgAfterSubmission">
                <span className="link">
                  <CustomTooltip
                    title="Please click to checkout through whatsapp"
                    position="bottom"
                  >
                    <p className="mb-2" onClick={() => handleSubmit()}>
                      Whatsapp checkout
                    </p>
                  </CustomTooltip>
                </span>
                <span className="link">
                  {" "}
                  <CustomTooltip
                    title="Please click to try again"
                    position="bottom"
                  >
                    <p
                      onClick={() => {
                        setScreen({
                          ...screen,
                          showForm: true,
                        });
                        saveToLocalStorage(true);
                      }}
                    >
                      Retry payment
                    </p>
                  </CustomTooltip>
                </span>
              </div>
            </div>
          </>
        )}
      {screen.showForm === false &&
        screen.status === "error" &&
        screen.checkoutType === "whatsapp" && (
          <>
            <div className="center flex-column  contactUsAlertSection">
              <MdError className="submitErrorIcon theme2" />
              <h2 className="submitErrorHead theme2">
               Submission Failed!
              </h2>
              <p className="submitErrorPara text-center theme2  mb-2">
                Looks like something went wrong. <br />
                Please try again.
              </p>
              <div className="additionalMsgAfterSubmission">
                <span className="link mt-0">
                  {" "}
                  <CustomTooltip
                    title="Please click to try again"
                    position="bottom"
                  >
                    <p
                      onClick={() => {
                        setScreen({
                          ...screen,
                          showForm: true,
                        });
                        saveToLocalStorage(true);
                      }}
                    >
                      Try again
                    </p>
                  </CustomTooltip>
                </span>
              </div>
            </div>
          </>
        )}
    </div>
  );
};

export default CheckoutType;
