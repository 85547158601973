import React, { useEffect ,useState} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { Pagination } from "swiper";
import "./textSwiper.scss";
import "../discoverUs.scss";

import parse from "html-react-parser";
import { Tooltip } from "react-bootstrap";
const TextSwiper = ({ slide1, slide2, slide3, slide4, slide5, layout }) => {
  const params = {
    pagination: {
      el: ".swiper-pagination custom_bullet",
      clickable: true,
      renderBullet: (index, className) => {
        return '<span class="' + className + '">' + (index + 1) + "</span>";
      },
    },
  };
  const [swiperIndex, setSwiperIndex] = useState(0)
  return (
    <>
      {layout === "ourHistory" && (
        <>
          {slide1 && (
            <div className="textDisplayMobile showOnMobile mt-4">
              {parse(slide1)}
            </div>
          )}
          {slide2 && (
            <div className="textDisplayMobile showOnMobile mt-1">
              {parse(slide2)}
            </div>
          )}
          {slide3 && (
            <div className="textDisplayMobile showOnMobile mt-1">
              {parse(slide3)}
            </div>
          )}
          {slide4 && (
            <div className="textDisplayMobile showOnMobile mt-1">
              {parse(slide4)}
            </div>
          )}
        </>
      )}

      <div className="hideOnMobile h-100">
    
        <Swiper
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return `<span class="${className}" title=""></span>`;
            },
          }}
          onActiveIndexChange={(swiperCore) => { setSwiperIndex(swiperCore.activeIndex); }}
       
          autoplay
          loop
          modules={[Pagination]}
          className="textSwiper hideOnMobile"
        >
          {layout === "ourHistory" && (
            <>
              {slide1 && (
                <SwiperSlide className="bg-transparent">
                  <div className="textDisplay"> {parse(slide1)} </div>
                </SwiperSlide>
              )}
              {slide2 && (
                <SwiperSlide className="bg-transparent">
                  <div className="textDisplay"> {parse(slide2)} </div>
                </SwiperSlide>
              )}
              {slide3 && (
                <SwiperSlide className="bg-transparent">
                  <div className="textDisplay"> {parse(slide3)} </div>
                </SwiperSlide>
              )}
              {slide4 && (
                <SwiperSlide className="bg-transparent">
                  <div className="textDisplay"> {parse(slide4)} </div>
                </SwiperSlide>
              )}
              {slide5 && (
                <SwiperSlide className="bg-transparent">
                  <div className="textDisplay"> {parse(slide5)} </div>
                </SwiperSlide>
              )}
            </>
          )}
          {/* {layout === "fossilSites" && (
            <>
              {data.map((item, index) => {
                return (
                  <SwiperSlide key={index} className="bg-transparent">
                    <div key={index} className="fossilSitesDetails">
                      <p className="subHead">{item.title}</p>
                      <p>
                        <span>Period</span> : {item.period}
                      </p>
                      <p>
                        <span>Altitude</span> : {item.altitude}
                      </p>
                      <p>{item.description}</p>
                 
                    </div>
                  </SwiperSlide>
                );
              })}
            </>
          )}
          {layout === "IR" && (
            <>
              {data.map((item, index) => {
                return (
                  <SwiperSlide key={index} className="bg-transparent">
                    <div key={index} className="fossilSitesDetails">
                      <p className="subHead">
                        {item.title},{" "}
                        <span className="location">{item.location}</span>
                      </p>

                      <p>{item.description}</p>
                      <div className="my-5">
                        <BorderButton
                          text="Visit Website"
                          title={`Click to view website of ${item.title}`}
                          link={item.link}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </>
          )} */}
        </Swiper>
      </div>
    </>
  );
};

export default TextSwiper;
