import React, { useState, useEffect, useReducer } from "react";
import { Col, Row } from "react-bootstrap";
import BodyLayout from "../../../layout/body/BodyLayout";
import CustomIcon from "../../../shared/customIcon/CustomIcon";
import { Loading, OverLayLoading } from "../../../shared/loading/Loading";
import SiteCard from "./siteCard/SiteCard";
import ImageCircle from "../../../shared/subpageImageCircle/ImageCircle";
import parse from "html-react-parser";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import {
  screenInitialState,
  screenReducer,
} from "../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../config/axios";
import { FetchErrorLight } from "../../../shared/displayAlerts/DisplayAlerts";

const FossilSites = () => {
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const [screen, setScreen] = useState({
    loading: false,
    details: null,
    detailsItem: null,
    view: "map",
  });

  const handleClick = (item) => {
    setScreen({
      ...screen,
      loading: true,
      details: item._id,
    });
  };
  const handleBack = () => {
    setScreen({
      ...screen,
      details: null,
    });
  };

  const loadPageContent = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axiosInstance.get(`page-content/fossil-sites`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    document.title = "Fossil Sites";
  }, []);
  const loadItem = async () => {
    setScreen({
      ...screen,
      loading: true,
    });
 
    try {
      const res = await axiosInstance.get(`fossil-sites/${screen.details}`);
      setScreen({
        ...screen,
        loading: false,
        status: "fetched",
        detailsItem: res.data,
      });
    } catch (error) {
      setScreen({
        ...screen,
        loading: false,
        status: "fetchError",
        detailsItem: null,
      });
    }
  };
  useEffect(() => {
    loadPageContent();
  }, []);
  useEffect(() => {
    if (screen.details !== null) {
      loadItem();
    }
  }, [screen.details]);

  return (
    <BodyLayout>
      {detailsState.isFetching === true && <Loading />}
      <div
        className={`ourHistorySection fossilSites  w-100 ${
          detailsState.hasError ? "center" : ""
        } `}
      >
        {detailsState.hasError && (
          <>
            <div className="yellowBox"></div>
            <Row className="w-100 mx-auto  p-0">
              <Col>
                <FetchErrorLight />
              </Col>
            </Row>
          </>
        )}
        {detailsState.isFetching === false && !detailsState.hasError && (
          <>
            <div className="yellowBox"></div>
            {screen.loading === true && <OverLayLoading />}
            {screen.details !== null &&
            screen.detailsItem !== null &&
            screen.loading === false ? (
              <Row className="w-100 mx-auto">
                <Col xl={12} className="py-5">
                  <Row className="uniPadding  innerSection py-3">
                    <Col xl={7} className="pt-4  ">
                      <div className="headContainer">
                        <div className="backIcon">
                          <CustomTooltip title="Please click to go back">
                            <CustomIcon
                              icon="leftArrow"
                              size={40}
                              className="imagePlus "
                              onClick={() => handleBack()}
                            />
                          </CustomTooltip>
                        </div>

                        <h3 className="ourHistoryText">
                          {screen.detailsItem.title}
                        </h3>
                        <hr className="yellowLine" />
                      </div>
                      <Row>
                        <Col xl={7} className="pt-0">
                          <div className="fossilSitesText">
                            {parse(screen.detailsItem.description)}
                          </div>
                          <div className="fossilSitesNote">
                            {screen.detailsItem.note && "Note: "+ parse(screen.detailsItem.note)}
                            
                          </div>
                        </Col>
                        <Col xl={4} className="ms-xl-4 pt-0">
                          {screen.detailsItem.period && (
                            <div className="fossilSitesText">
                              <div className="fossilSitesBold">Period</div>
                              {parse(screen.detailsItem.period)}
                            </div>
                          )}
                          {screen.detailsItem.altitude && (
                            <div className="fossilSitesText">
                              <div className="fossilSitesBold">Altitude</div>
                              {parse(screen.detailsItem.altitude)}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Col>

                    <Col
                      xl={5}
                      xs={12}
                      className="pe-3 circleContainer position-relative "
                    >
                      <div className="backIconTop smallDevice">
                        <CustomTooltip title="Please click to go back">
                          <CustomIcon
                            icon="leftArrow"
                            size={40}
                            className="imagePlus "
                            onClick={() => handleBack()}
                          />
                        </CustomTooltip>
                      </div>
                      <ImageCircle
                        images={screen.detailsItem.image}
                        title={
                          screen.detailsItem.bannerCaption
                            ? screen.detailsItem.bannerCaption
                            : screen.detailsItem.title
                        }
                        galleryCount={screen.detailsItem.galleryCount}
                        slug={`fossil-sites/gallery/${screen.detailsItem._id}`}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row className="w-100 mx-auto">
                <Col xl={12} className="py-5 ">
                  <Row className="uniPadding innerSection pt-xl-3 pb-xl-5">
                    <Col xl={4} className="pt-4">
                      <div className="headContainer">
                        <h3 className="ourHistoryText">
                          {detailsState.data.heading}
                        </h3>
                        <hr className="yellowLine" />
                      </div>
                      <div className="fossilSitesText ">
                        {detailsState.data.content &&
                          parse(detailsState.data.content)}
                      </div>
                    </Col>
                    <Col xl={8} xs={12} className="pe-xl-4">
                      <div className="mt-xl-5 pt-xl-4 pe-xl-3">
                        <div className="mt-xl-5 ms-xl-5">
                          <Row className="mb-xl-5 pt-3 ">
                            {detailsState &&
                              detailsState.data &&
                              detailsState.data.sites &&
                              detailsState.data.sites.map((item, index) => {
                                return (
                                  <Col
                                    xl={6}
                                    md={6}
                                    key={index}
                                    className="ps-xl-4 mb-5"
                                  >
                                    <SiteCard
                                      item={item}
                                      onClick={() => handleClick(item)}
                                    />
                                  </Col>
                                );
                              })}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </BodyLayout>
  );
};

export default FossilSites;
