import React from "react";
import { Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./buttons.scss";
import CustomTooltip from "../tooltip/CustomTooltip";

// different buttons used in the website
export const Buttons = (props) => {
  return (
    <Button
      className="borderButton"
      variant="secondary"
      onClick={props.onC
      }
  
    >
      {props.text}
    </Button>
  );
};
export const VisitButtons = (props) => {
  return (
    <Button
      className="bordered"
      variant="secondary"
      onClick={props.onC
      }
  
    >
      {props.text}
    </Button>
  );
};
export const BorderButton = (props) => {
  return (
    <Button
      className="bordered"
      variant="secondary"
      target="_blank"
      href={props.link ? props.link : ""}
      data-title={props.title}
    >
      {props.text}
    </Button>
  );
};
export const BlueButtons = (props) => {
  return (
    <Button
      className="blueButton"
      variant="secondary"
    >
      <NavLink to="/" className="linkText">
        Home
      </NavLink>
    </Button>
  );
};

export const SubmitButton = (props) => {
  return (
    <CustomTooltip title="Please click to submit">
      <Button
        className="submitButton"
        variant="secondary"
        onClick={props.onClick}
      >
        {props.text}
      </Button>
    </CustomTooltip>
  );
};
export const FormSubmitButton = (props) => {
  return (
    <Button
      className={`formSubmitButton ${props.theme === "light" ? "light" : ""} `}
      variant="secondary"
      onClick={props.onClick}
    >
      {props.text}
    </Button>
  );
};
