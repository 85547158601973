import React, { useState } from "react";
import "./mediaListing.scss";
import { FiDownload, FiExternalLink } from "react-icons/fi";
import { Row, Col } from "react-bootstrap";
import dummy from "../../../../../assets/images/dummyBg.png";
import CustomIcon from "../../../../shared/customIcon/CustomIcon";
import ExpandedModal from "../../../../shared/subpageImageCircle/expandedModal/ExpandedModal";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
const ListingComponent = ({ item, onClick, activeItem, initialItem }) => {
  const handleOnError = (e) => {
    e.target.src = dummy;
  };
  const [screen, setScreen] = useState({
    show: false,
  });
  return (
    <>
      <div
        className={`listingComponentHolder mediaGallery ${
          item.picturesCount > 0 || item.videosCount > 0 ? "click" : ""
        } `}
        onClick={() => setScreen(() => ({ ...screen, show: true }))}
      >
        <div className="galleryCard">
          <CustomTooltip title="Click to view more details" position="right">
            <div className="displayImageContainer">

            <img
              src={process.env.REACT_APP_BACKEND_URL + "public/" + item.image}
              className="displayImage"
              onError={handleOnError}
              alt=""
            />
            <div className="displayImage hover"></div>
            </div>
          </CustomTooltip>

          <div className="plusIcon">
            <CustomTooltip title="Click to view more details">
              <CustomIcon icon="plus" size={32} />
            </CustomTooltip>
          </div>

          <div className="detailsSection">
            <hr className="redLine" />
            <div className="bottomSection">
              <p className="itemName mt-3 mb-0 ps-0">{item.title}</p>
              <p className="itemDate">{item.date}</p>
              <p className="itemCount">
                {item.picturesCount + 1} Photo(s){" "}
                {item.videosCount > 0 && "," + item.videosCount + "video(s)"}
              </p>
            </div>
          </div>
        </div>
      </div>

      <ExpandedModal
        show={screen.show}
        onHide={() => setScreen(() => ({ ...screen, show: false }))}
        url={`media-gallery/${item._id}`}
        array={[item]}
      />
    </>
  );
};

export default ListingComponent;
