import React from "react";
import {
  AppliedFilter,
  AppliedFilterSort,
} from "../../../shared/filters/AppliedFilter";

const AppliedFiltersOwnAFossil = ({ screen, setParentScreen }) => {
  const handleRemoveItem = (array, value, type) => {
    setParentScreen({
      ...screen,
      overlay: true,
      filterShow: false,
    });
    setTimeout(() => {
      const newArr = array.filter((e) => e !== value);

      if (type === "category") {
        setParentScreen({
          ...screen,
          category: "",
          overlay: false,
          filterShow: true,
        });
      }
      if (type === "subCategory") {
        setParentScreen({
          ...screen,
          subCategory: newArr,
          overlay: false,
          filterShow: true,
        });
      }
      if (type === "site") {
        setParentScreen({
          ...screen,
          site: newArr,
          overlay: false,
          filterShow: true,
        });
      }
      if (type === "tag") {
        setParentScreen({
          ...screen,
          tag: newArr,
          overlay: false,
          filterShow: true,
        });
      }
      if (type === "price") {
        setParentScreen({
          ...screen,
          price: "",
          filterShow: true,
          overlay: false,
        });
      }
      localStorage.setItem("ownAFossilFiltersApplied", JSON.stringify(""));
      localStorage.setItem("ownAFossilScreenValues", JSON.stringify(screen));
    }, 100);
  };
  // const handleRemoveItemSort = () => {
  //   setParentScreen({
  //     ...screen,
  //     overlay: true,
  //   });
  //   setTimeout(() => {
  //     setParentScreen({
  //       ...screen,
  //       sortedValue: "",
  //       overlay: false,
  //     });
  //     localStorage.setItem("ownAFossilScreenValues", JSON.stringify(screen));
  //   }, 100);
  // };

  return (
    <div className="start filtersTopDisplay">
      Applied filter(s) :
      {/* {screen.sortedValue && (
        <AppliedFilterSort
          value={screen.sortedValue}
          onClick={handleRemoveItemSort}
          price={true}
        />
      )} */}
      {screen.category && (
        <AppliedFilter
          value={screen.category}
          onClick={handleRemoveItem}
          array={[]}
          type="category"
        />
      )}
    
      {screen.price && (
        <AppliedFilter
          value={"price " + screen.price[0] + " - " + screen.price[1]}
          onClick={handleRemoveItem}
          array={screen.price}
          type="price"
        />
      )}
      {screen.subCategory &&
        screen.subCategory.map((item) => {
          return (
            <AppliedFilter
              value={item}
              onClick={handleRemoveItem}
              array={screen.subCategory}
              type="subCategory"
            />
          );
        })}
      {screen.site &&
        screen.site.map((item) => {
          return (
            <AppliedFilter
              value={item}
              onClick={handleRemoveItem}
              array={screen.site}
              type="site"
            />
          );
        })}
      {screen.tag &&
        screen.tag.map((item) => {
          return (
            <AppliedFilter
              value={item}
              onClick={handleRemoveItem}
              array={screen.tag}
              type="tag"
            />
          );
        })}
    </div>
  );
};

export default AppliedFiltersOwnAFossil;
