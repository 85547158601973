import React from "react";
import "./testimonialListing.scss";

// temp
import image from "../../../../assets/images/dummyIcon.png";
import { useRef, useEffect } from "react";
const TestimonialListingItem = ({ item, selectedItem, sortedValue }) => {
  const handleOnError = (e) => {
    e.target.src = image;
  };
  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  useEffect(() => {
    if (selectedItem === item._id) {
      setTimeout(() => {
        handleClick();
      }, 100);
    }
  }, [selectedItem]);

  return (
    <div
      ref={ref}
      className={`TestimonialListingItem ${
        selectedItem === item._id && !sortedValue ? "active" : ""
      }`}
    >
      <div className="imageSection center">
        <div className="imageHolder center">
          <img
            src={process.env.REACT_APP_BACKEND_URL + "public/" + item.image}
            alt="profileImage"
            onError={handleOnError}
            className="profileImage"
          />
        </div>
      </div>
      <div className="message">
        <div className="hover"></div>
        <div className="textSection">
          <p className="name">
            {" "}
            {item.title} {item.firstName} {item.lastName}
          </p>
          <p className="company">
            {item.designation}{" "}
            {item.designation && item.company && "," + item.company}
          </p>
          <p className="messageAdded">"{item.message}"</p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialListingItem;
