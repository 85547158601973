import React, { useState } from "react";
import "./collectionComponent.scss";
import dummy from "../../../../../../assets/images/dummyBg.png";
import CustomIcon from "../../../../../shared/customIcon/CustomIcon";
import { Link } from "react-router-dom";
import CustomTooltip from "../../../../../shared/tooltip/CustomTooltip";

const CollectionComponent = ({ item, onClick }) => {
  const handleOnError = (e) => {
    e.target.src = dummy;
  };

  const className = () => {
    let count = 0;
    if (item.newDiscovery === "1") {
      count += 1;
    }
    if (item.museumQuality === "1") {
      count += 1;
    }
    if (item.fossilOfTheMonth === "1") {
      count += 1;
    }
    if (item.excellentPreservation === "1") {
      count += 1;
    }

    if (item.exceptionalRarity === "1") {
      count += 1;
    }
    if (item.collectible === "1") {
      count += 1;
    }
    if (item.newSpecies === "1") {
      count += 1;
    }
    if (count === 1) {
      return false;
    } else if (count > 1) return true;
  };

  return (
    <>
      <div
        className={`listingComponentHolder memoryOfTimeCollection `}
        onClick={onClick}
      >
        <div className="collectionCard">
          <Link to={`/fossils/memory-of-time-collection/details/${item._id}`}>
            <CustomTooltip title="Click to get more info" position="right">
              <div className="displayImageContainer">
                <img
                  src={
                    process.env.REACT_APP_BACKEND_URL + "public/" + item.image
                  }
                  className="displayImage"
                  onError={handleOnError}
                  alt=""
                />
                 <div className="displayImage hover"></div>
              </div>
             
            </CustomTooltip>
          </Link>

          <div className="plusIcon">
            <CustomTooltip title="Click to get more info" position="bottom">
              {" "}
              <Link
                to={`/fossils/memory-of-time-collection/details/${item._id}`}
              >
                <CustomIcon icon="plus" size={32} />
              </Link>
            </CustomTooltip>
          </div>

          <div className="detailsSection">
            <div className={`tagDisplay ${className()}`}>
              {item.newDiscovery === "1" && (
                <div className={`tag one`}>Newly discovered</div>
              )}
              {item.fossilOfTheMonth === "1" && (
                <div className={`tag two`}>Fossil of the month</div>
              )}
              {item.excellentPreservation === "1" && (
                <div className={`tag three`}>Excellent Preservation</div>
              )}
              {item.exceptionalRarity === "1" && (
                <div className={`tag four`}>Exceptional Rarity</div>
              )}
              {item.collectible === "1" && (
                <div className={`tag five`}>Collectible</div>
              )}
              {item.newSpecies === "1" && (
                <div className={`tag six`}>New Species</div>
              )}
              {item.museumQuality === "1" && (
                <div className={`tag seven`}>Museum Quality</div>
              )}
            </div>

            <hr className="redLine" />
            <div className="bottomSection">
              <p className="itemName mt-3 mb-0 ps-0">{item.genus}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CollectionComponent;
