import React from "react";
import "./linkComponent.scss";
import { FiExternalLink } from "react-icons/fi";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
const LinkComponent = ({ name, link }) => {
  return (
    <div className="linkContainer center">
      <div className="brownContainer center">{name}</div>
      <div className="whiteContainer">
        <a className="icon" href={link} target="_blank" >
          <CustomTooltip title="Click to view website">
          <FiExternalLink />
          </CustomTooltip>
          
        </a>
      </div>
    </div>
  );
};

export default LinkComponent;
