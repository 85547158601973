export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  outputSize = { width: 70, height: 70 },
  outputExtension = "image/jpeg"
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = outputSize.width;
  canvas.height = outputSize.height;

  ctx.imageSmoothingEnabled = false;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    outputSize.width,
    outputSize.height

  );

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      let fileUrl = URL.createObjectURL(blob);

      resolve({ fileUrl, blob });
    }, outputExtension);
  });
}
