import React from "react";
import { Accordion } from "react-bootstrap";
import { Formik } from "formik";
import CMSFormCheckBoxes from "../../../../shared/formFields/CMSFormCheckBoxes";
import CMSFormRadioButtons from "../../../../shared/formFields/CMSFormRadioButtons";
import CMSFormSelect from "../../../../shared/formFields/CMSFormSelect";
import CMSFormRadioButtonsSubmit from "../../../../shared/formFields/CMSFormRadioButtonsSubmit";
const MemoryOfTimeFilters = ({
  screen,
  onSubmit,
  parentState,
  sortArray,
  categories,
  subCategories,
  fossilSites,
  moreFilters,
  onClick,
  search,
  setSearch,
}) => {
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i].value, value: item[i].normalizedValue });
    }

    return array;
  };

  return (
    <Formik
      initialValues={{
        category: screen.category ? screen.category : "",
        subCategory: screen.subCategory ? screen.subCategory : "",
        site: screen.site ? screen.site : "",
        tag: screen.tag ? screen.tag : "",
        sort: screen.sortedValue ? screen.sortedValue : "",
      }}
      onSubmit={async (values) => {
        onSubmit(values);
        console.log(values);
      }}
      onReset={async (values) => {
        parentState({
          ...screen,
          searchedValue: "",
          sortedValue: "",
          category: "",
          subCategory: "",
          site: "",
          tag: "",
        });
        setSearch("");
        //onClick();
        localStorage.setItem("motFiltersApplied", JSON.stringify(""));
        localStorage.setItem("motScreenValues", JSON.stringify(screen));
      }}
    >
      {(form) => (
        <>
          <Accordion defaultActiveKey={["0", "3"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Choose a Category</Accordion.Header>
              <Accordion.Body>
                <div className="filterHead type">
                  <CMSFormRadioButtonsSubmit
                    name="category"
                    form={form}
                    label=""
                    onChange={(e) => {
                      form.handleChange(e);
                      form.values.subCategory = "";
                      form.handleSubmit(e);
                    }}
                    options={handleFormatArray(categories)}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {console.log(subCategories)}
            {subCategories.length > 0 ? (
              <Accordion.Item eventKey="1">
                <Accordion.Header>Choose a subcategory</Accordion.Header>
                <Accordion.Body>
                  <div className="filterHead type">
                    <CMSFormCheckBoxes
                      name="subCategory"
                      form={form}
                      label=""
                      options={handleFormatArray(subCategories)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ) : null}

            <Accordion.Item eventKey="4">
              <Accordion.Header>Choose a Site </Accordion.Header>
              <Accordion.Body>
                <div className="filterHead type">
                  <CMSFormCheckBoxes
                    name="site"
                    form={form}
                    label=""
                    options={handleFormatArray(fossilSites)}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>More Filters</Accordion.Header>
              <Accordion.Body>
                <div className="filterHead sort">
                  <CMSFormCheckBoxes
                    name="tag"
                    form={form}
                    label=""
                    options={handleFormatArray(moreFilters)}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="showOnMobile">
              <Accordion.Header>Sort by </Accordion.Header>
              <Accordion.Body>
                <div className="filterHead sort">
                  <CMSFormRadioButtons
                    name="sort"
                    form={form}
                    label=""
                    options={sortArray}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="filterHeadBottom sort">
            <a
              href=""
              className="filterApply first"
              onClick={form.handleSubmit}
            >
              apply
            </a>
          </div>
          {screen.category ||
          screen.searchedValue ||
          screen.subCategory ||
          screen.site ||
          screen.tag ||
          screen.sortedValue ? (
            <div className="filterHeadBottom last sort">
              <a href="" className="filterApply" onClick={form.handleReset}>
                reset all filters
              </a>
            </div>
          ) : null}
        </>
      )}
    </Formik>
  );
};

export default MemoryOfTimeFilters;
