import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import AppRoutes from "./config/appRoutes";
import ScrollToTop from "./components/shared/scrollToTop/ScrollToTop";
import { useEffect } from "react";
//import { initNetCommerceCheckout } from "./config/netCommerceConfig";

function App() {
  return (
    <>
      <ScrollToTop />
      <AppRoutes />
    </>
  );
}

export default App;
