import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const CMSFormInput = (props) => {
  const {
    name,
    label,
    form,
    required,
    autoFocus,
    placeholder,
    helpMessage,
    ...rest
  } = props;

  return (
    <div className="form-group ">
      {/* Created Useable Component */}
      {/* {
                (label !== "") &&
                <>
                    {
                        (required === true)
                            ? <OverlayTrigger
                                key={`tooltip-${name}`}
                                placement='right'
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Required field.
                                    </Tooltip>
                                }
                            ><label htmlFor={name} className={`required ' ${(form.touched[name] && form.errors[name]) ? "isInvalidText" : ""}`}> {label}</label></OverlayTrigger>
                            : <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "isInvalidText" : ""}`}> {label}</label>
                    }
                </>
            } */}
      {label !== "" && (
        <>
          {required === true ? (
            <label
              htmlFor={name}
              className={`required ' ${
                form.touched[name] && form.errors[name] ? "isInvalidText" : ""
              }`}
            >
              {" "}
              {label}
            </label>
          ) : (
            <label
              htmlFor={name}
              className={` notRequired ${
                form.touched[name] && form.errors[name] ? "isInvalidText " : " "
              }`}
            >
              {" "}
              {label}
            </label>
          )}
        </>
      )}
      {/* Created Useable Component */}
      {helpMessage && helpMessage.message && (
        <OverlayTrigger
          trigger="hover"
          key={`info-popup-${name}`}
          placement="right"
          overlay={
            <Popover id={`popover-positioned-${name}`}>
              {helpMessage.heading && (
                <Popover.Header as="h3">{helpMessage.heading}</Popover.Header>
              )}

              <Popover.Body>{helpMessage.message}</Popover.Body>
            </Popover>
          }
        >
          <i className="fa fa-info-circle ml-2 infoIcon"></i>
        </OverlayTrigger>
      )}

      <Field
        name={name}
        autoFocus={autoFocus}
        placeholder={placeholder}
        className={`form-control customInput ${
          form.touched[name] && form.errors[name] ? "is-invalid" : " "
        }`}
        {...rest}
      />
      {required === true && <div className="required"></div>}

      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="errorMessage">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

export default CMSFormInput;
