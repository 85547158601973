import * as yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

export const validateImageType = (value) => {
  if (value) {
    let type = value.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    return SUPPORTED_FORMATS.includes(type);
  }
};

export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email")
    .required("Please enter your email"),
});
export const searchSchema = yup.object().shape({
  search: yup.string(),
  // .required("Please enter something"),
});
export const addTestimonialSchema = yup.object().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  email: yup.string().email().required("Please enter your email"),
  // name: yup.string().required("Please enter your  name"),
  designation: yup.string(),
  company: yup.string(),
  message: yup
    .string()
    .required("Please enter a message")
    .max(300, "maximum 300 characters allowed"),
  // recaptcha: yup.string().required("Captcha is required"),
  image: yup
    .mixed()
    .nullable()
    .test(
      "type",
      "Please upload an image of format png,jgp or jpeg",
      function (value) {
        if (value == "undefined" || value) {
          return (
            value &&
            (value.type === "image/jpg" ||
              value.type === "image/jpeg" ||
              value.type === "image/png")
          );
        } else {
          return true;
        }
      }
    )
    .test("size", "File exceeded size of 2mb", function (value) {
      if (value == "undefined" || value) {
        console.log(value.size);
        return value && value.size <= 1024 * 1024;
      } else {
        return true;
      }
    }),
  // image: yup
  //   .mixed()
  //   .test(
  //     "fileSize",
  //     "File exceeded size of 2mb",
  //     (value) => value.size <= 1024 * 1024
  //   )
  //   .test(
  //     "fileType",
  //     "Please upload an image of format png,jgp or jpeg",
  //     (value) => SUPPORTED_FORMATS.includes(value.type)
  //   ),
});
export const unSubscribeSchema = yup.object().shape({
  email: yup.string().email().required("Please enter your email"),
});
export const whatsappCheckoutSchema = yup.object().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  email: yup.string().email().required("Please enter your email"),
  phone: yup
    .string()
    .required("Please enter your phone number")
    .min(10, "Please enter complete number"),
});
export const onlineCheckoutSchema = yup.object().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  email: yup.string().email().required("Please enter your email"),
  country: yup.string().required("Please select your country"),
  phone: yup
    .string()
    .required("Please enter your phone number")
    .min(10, "Please enter complete number"),
});
export const contactUsSchema = yup.object().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  email: yup.string().email().required("Please enter your email"),
  country: yup.string().required("Please select your country"),
  phone: yup
    .string()
    .required("Please enter your phone number")
    .min(10, "Please enter complete number"),
  message: yup
    .string()
    .required("Please enter a message")
    .max(300, "maximum 300 characters allowed"),
  // recaptcha: yup.string().required("Captcha is required"),
});
export const shippingAddressSchema = yup.object().shape({
  firstName: yup.string().required("Please enter your first name"),
  lastName: yup.string().required("Please enter your last name"),
  email: yup.string().email().required("Please enter your email"),
  country: yup.string().required("Please select your country"),
  city: yup.string().required("Please enter your city"),
  state: yup.string(),
  postCode: yup.string().required("Please enter your postcode"),
  phone: yup
    .string()
    .required("Please enter your phone number")
    .min(10, "Please enter complete number"),
  addressLine1: yup
    .string()
    .required("Please enter your address")
    .max(300, "maximum 300 characters allowed"),
  addressLine2: yup
    .string()

    .max(300, "maximum 300 characters allowed"),
});
