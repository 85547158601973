import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const ModalFilter = (props) => {
  return (
    <Modal
      fullscreen={true}
      show={props.show}
      size="lg"
      aria-labelledby="testimonial form"
      centered
      className=""
    >
      <Modal.Body className="filterModal">{props.children}</Modal.Body>
    </Modal>
  );
};

export default ModalFilter;
