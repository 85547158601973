import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
function CMSFormCheckBoxes(props) {
  const { label, name, options, form, required, ...rest } = props;

  return (
    <div>
      {label !== "" && (
        <>
          {required === true ? (
            <OverlayTrigger
              key={`tooltip-${name}`}
              placement="right"
              overlay={<Tooltip id={`tooltip-right`}>Required field.</Tooltip>}
            >
              <label
                htmlFor={name}
                className={`required ' ${
                  form.touched[name] && form.errors[name] ? "isInvalidText" : ""
                }`}
              >
                {" "}
                {label}
              </label>
            </OverlayTrigger>
          ) : (
            <label
              htmlFor={name}
              className={` ${
                form.touched[name] && form.errors[name]
                  ? "isInvalidText label"
                  : " label"
              }`}
            >
              {" "}
              {label}
            </label>
          )}
        </>
      )}
      {/* {label !== "" && (
        <label
          htmlFor={name}
          className={` ${
            form.touched[name] && form.errors[name]
              ? "isInvalidText required"
              : "required"
          }`}
        >
          {" "}
          {label}
        </label>
      )} */}
       <div role="group" aria-labelledby="my-radio-group" className={` ${
          form.touched[name] && form.errors[name]
            ? "is-invalid  "
            : " "
        }`}>
            <div className="checkBoxContainer">
        {options.map((option) => {
          return (
       

        
            <label
              className={` ${
                form.touched[name] && form.errors[name]
                  ? "isInvalidText checkboxText mb-2 radio" 
                  : "checkboxText radio"
              }`}
            >
              <Field
                name={name}
                type="checkbox"
                value={option.value}
              />

             <span className="ms-2"> {option.label}</span>
            </label>
       
          );
        })}
         </div>
      </div>
      {/* <div
        className={` ${
          form.touched[name] && form.errors[name] ? "is-invalid" : ""
        }`}
      >
        {options.map((option) => {
          return (
            <div key={option.label} className="checkBoxContainer ">
              <Field
                name={name}
                type="checkbox"
                id={option.value}
                className="checkbox"
                value={option.value}
                defaultChecked={form.values[name].includes(option.value)}
              />
              <label
                htmlFor={option.value}
                className={` ${
                  form.touched[name] && form.errors[name]
                    ? "isInvalidText checkboxText ms-2"
                    : "ms-2 checkboxText"
                }`}
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div> */}
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="error invalid-feedback">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
}

export default CMSFormCheckBoxes;
