import React, { useState, useEffect } from "react";
import Banner from "../home/homePagePartials/banner/Banner";
import NewsSection from "../home/homePagePartials/newsSection/NewsSection";
import ProductSwiper from "../home/homePagePartials/productSwiper/ProductSwiper";
import Testimonial from "../home/homePagePartials/testimonialSection/Testimonial";
import { Loading } from "../../shared/loading/Loading";
import BodyLayout from "../../layout/body/BodyLayout";
import Footer from "../../layout/footer/Footer";

function HomePage() {
  console.log("Home Page")
  const [screen, setScreen] = useState({
    loading: true,
  });
  useEffect(() => {
    document.title = "Memory of Time";
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setScreen({ ...screen, loading: false });
    }, 2000);
    localStorage.removeItem("newsFiltersApplied");
    localStorage.removeItem("newsScreenValues");
    localStorage.removeItem("motFiltersApplied");
    localStorage.removeItem("motScreenValues");
    localStorage.removeItem("ownAFossilFiltersApplied");
    localStorage.removeItem("ownAFossilScreenValues");
    localStorage.removeItem("mediaFiltersApplied");
    localStorage.removeItem("mediaScreenValues");
    localStorage.removeItem("pressFiltersApplied");
    localStorage.removeItem("pressScreenValues");
    localStorage.removeItem("publicationFiltersApplied");
    localStorage.removeItem("publicationScreenValues");
    localStorage.removeItem("currentActiveIdNews");
    localStorage.removeItem("currentActiveIdTestimonial");
  }, []);

  return (
    <>
      <BodyLayout footer="hide">
        {screen.loading && <Loading />}
        <Banner />
        <NewsSection />
        <div className="position-relative">
          <ProductSwiper />
          <Testimonial />
          <Footer />
        </div>
      </BodyLayout>
    </>
  );
}

export default HomePage;
