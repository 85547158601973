import React, { useState, useEffect } from "react";
import BodyLayout from "../../layout/body/BodyLayout";
import { Loading, OverLayLoading } from "../../shared/loading/Loading";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../config/axios";
import "./payOnline.scss";
import { initNetCommerceCheckout } from "../../../config/netCommerceConfig";
import { Button, Col, Row } from "react-bootstrap";
import { BorderButton } from "../../shared/buttons/Buttons";
import CustomTooltip from "../../shared/tooltip/CustomTooltip";
// import {
//     screenInitialState,
//     screenReducer,
// } from "../../shared/reducers/screenReducer";

const PayOnline = () => {
  const { transactionId } = useParams();
  useEffect(() => {
    document.title = "Payment";
  }, []);
  //console.log("transactionId", transactionId)
  let buyerId = localStorage.getItem("BuyerId");
  buyerId = JSON.parse(buyerId);
  // console.log("buyerId", buyerId)
  console.log(window.innerHeight, "height");
  const [screen, setScreen] = useState({
    status: "fetching", //fetching,processing
    errorType: null, //apiFailed,transactionValidationFailed
  });

  const validateTransaction = async () => {
    try {
      const res = await axiosInstance.post(`cart/validate-online-payment`, {
        buyerId,
        transactionId,
      });

      if (res.status === 200 && res.data.status === "validated") {
        //console.log("window.Checkout", window.Checkout);
        if (window.Checkout && typeof window.Checkout === "object") {
          window.Checkout.configure({
            session: {
              id: res.data.sessionId,
            },
          });

          setScreen({
            ...screen,
            status: "fetched",
            invoice: res.data.invoice,
            errorType:null,
          });
        } else {
          console.log("onlinePaymentSetupFailed")
          setScreen({
            ...screen,
            status: "error",
            errorType: "onlinePaymentSetupFailed",
          });
        }
      } else {
        setScreen({
          ...screen,
          status: "error",
          errorType: "transactionValidationFailed",
        });
      }
    } catch (error) {
      setScreen({
        ...screen,
        status: "error",
        errorType: "apiFailed",
      });
    }
  };

  useEffect(() => {
    if (transactionId) {
      setTimeout(() => {
        initNetCommerceCheckout(transactionId);
        validateTransaction();
      }, 2000);
    }
  }, []);
  useEffect(() => {
    if (screen.errorType === "onlinePaymentSetupFailed" ) {
      console.log("render",screen.errorType)
      setScreen({
        ...screen,
        status: "fetching",
      });
      validateTransaction();
    }
  }, [screen])
  

  return (
    <BodyLayout>
      {screen.status === "fetching" && <Loading />}
      {screen.status === "processing" && <OverLayLoading />}
      {screen.status === "error" ? (
        <div className="payOnlineSectionSmall">
          <div className="yellowBox"></div>
          <Row className="ms-xl-1">
            <Col xl={12} className="py-xl-5 py-md-5 py-lg-5 py-1">
              <Row className="uniPadding innerSection py-xl-5 py-lg-3 py-md-3 pb-5">
                <Col xl={12} lg={12} md={12} className="pt-4">
                  <div className="headContainer">
                    <h3 className="payOnlineText">
                      {" "}
                      {screen.status === "error" ? (
                        <>
                          {screen.errorType === "onlinePaymentSetupFailed"
                            ? "Setup Failed"
                            : "Error"}
                        </>
                      ) : (
                        "Pay Online"
                      )}
                    </h3>
                    <hr className="yellowLine" />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="payOnlineContent">
                    {screen.status === "error" && (
                      <>
                        {screen.errorType === "apiFailed" && <p>Something went wrong, Try again later.</p>}
                        {screen.errorType === "transactionValidationFailed" && (
                          <p>Transaction Validation Failed, Try again later.</p>
                        )}
                        {screen.errorType === "onlinePaymentSetupFailed" && (
                          <>
                            <p>
                              The online setup could not be completed as
                              expected. Click the button below to reload and
                              retry
                            </p>
                            <Button
                              className="bordered"
                              variant="secondary"
                              onClick={() => {
                                setScreen({
                                  ...screen,
                                  status: "fetching",
                                });
                                validateTransaction();
                              }}
                            >
                              Reload
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="payOnlineSection ">
          <div className="yellowBox"></div>
          <Row className="ms-xl-1">
            <Col xl={12} className="py-xl-5 py-md-5 py-lg-5 py-1">
              <Row className="uniPadding innerSection py-xl-3 py-lg-3 py-md-3 pb-5">
                <Col xl={12} lg={12} md={12} className="pt-4">
                  <div className="headContainer">
                    <h3 className="payOnlineText">
                      {" "}
                      {screen.status === "error" ? (
                        <>
                          {screen.errorType === "onlinePaymentSetupFailed"
                            ? "Setup Failed"
                            : "Pay Online"}
                        </>
                      ) : (
                        "Pay Online"
                      )}
                    </h3>
                    <hr className="yellowLine" />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="payOnlineContent">
                    {screen.status === "error" && (
                      <>
                        {screen.errorType === "apiFailed" && <>
                            <p>
                              Something went wrong. Click the button below to reload and
                              retry
                            </p>
                            <Button
                              className="bordered"
                              variant="secondary"
                              onClick={() => {
                                setScreen({
                                  ...screen,
                                  status: "fetching",
                                });
                                validateTransaction();
                              }}
                            >
                              Reload
                            </Button>
                          </>}
                        {screen.errorType === "transactionValidationFailed" && (
                          <>
                            <p>
                              The transaction validation could not be completed
                              as expected. Click the button below to reload and
                              retry
                            </p>
                            <Button
                              className="bordered"
                              variant="secondary"
                              onClick={() => {
                                setScreen({
                                  ...screen,
                                  status: "fetching",
                                });
                                validateTransaction();
                              }}
                            >
                              Reload
                            </Button>
                          </>
                        )}
                        {screen.errorType === "onlinePaymentSetupFailed" && (
                          <>
                            <p>
                              The online setup could not be completed as
                              expected. Click the button below to reload and
                              retry
                            </p>
                            <Button
                              className="bordered"
                              variant="secondary"
                              onClick={() => {
                                setScreen({
                                  ...screen,
                                  status: "fetching",
                                });
                                validateTransaction();
                              }}
                            >
                              Reload
                            </Button>
                          </>
                        )}
                      </>
                    )}

                    {screen.status === "fetched" && (
                      <>
                        <p>
                          After clicking "Pay Now", You will be redirected to
                          the secured payment page
                        </p>

                        {screen.invoice && screen.invoice.totalAmount && (
                          <p>
                            Amount:{" "}
                            <span className="amountDisplay">
                              {" "}
                              {screen.invoice.totalAmount} $
                            </span>
                          </p>
                        )}
                        <div>
                          <Button
                            className="bordered"
                            variant="secondary"
                            onClick={() => {
                              setScreen({
                                ...screen,
                                status: "fetching",
                              });
                              window.Checkout.showPaymentPage();
                            }}
                          >
                            Pay Now
                          </Button>
                        </div>
                        {/* <p class="text_contact"><span class="btn btn-theme btn-large" onclick="Checkout.showPaymentPage()">Pay Now</span></p> */}
                        {/* <button onClick={() => window.Checkout.showPaymentPage()}>
                        Pay Now
                      </button> */}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            {screen.status === "fetched" && (
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="securitySeal"
              >
                <div className="securitySealDiv">
                  <p>Protected by the “NetCommerce secure payment processor”</p>
                </div>
              </Col>
            )}
          </Row>
        </div>
      )}
    </BodyLayout>
  );
};

export default PayOnline;
