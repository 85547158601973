import React ,{useEffect} from "react";
import { Formik } from "formik";
import CMSFormCheckBoxes from "../../../../shared/formFields/CMSFormCheckBoxes";
import CMSFormRadioButtons from "../../../../shared/formFields/CMSFormRadioButtons";
import { Accordion } from "react-bootstrap";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
const PublicationFilter = ({
  screen,
  onSubmit,
  setParentScreen,
  sortArray,
  languages,
  years,
  sources,
  onClick,
  search,
  setSearch,
}) => {
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i], value: item[i] });
    }

    return array;
  };

  return (
    <Formik
      initialValues={{
        languages: screen.language ? screen.language : "",
        years: screen.year ? screen.year : "",
        sources: screen.sources ? screen.sources : "",
        relevant: screen.relevant ? screen.relevant : "",
        sort: screen.sortedValue ? screen.sortedValue : "",
      }}
      onSubmit={async (values) => {
        onSubmit(values);
      }}
      onReset={async (values) => {
        setParentScreen({
          ...screen,
          showModal: false,
          language: "",
          searchedValue: "",
          sortedValue: "",
          year: "",
          relevant: "",
          sources: "",
        });
        setSearch("");
        localStorage.setItem("publicationFiltersApplied", JSON.stringify(""));
        localStorage.setItem(
          "publicationScreenValues",
          JSON.stringify({
            ...screen,
            sortedValue: "",
            searchedValue: "",
            year: "",
            sources: "",
            relevant: "",
            language: "",
          })
        );
      }}
    >
      {(form) => (
        <>
          <Accordion defaultActiveKey="0">
            {languages.length > 0 && (
              <Accordion.Item eventKey="0">
                <Accordion.Header>Choose a Language</Accordion.Header>
                <Accordion.Body>
                  <div className="filterHead group mt-2">
                    <CMSFormCheckBoxes
                      name="languages"
                      form={form}
                      label=""
                      options={handleFormatArray(languages)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {console.log(handleFormatArray(years))}
            {years.length > 0 && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>Choose a Year</Accordion.Header>
                <Accordion.Body>
                  <div className="filterHead group mt-2">
                    <CMSFormCheckBoxes
                      name="years"
                      form={form}
                      label=""
                      options={handleFormatArray(years)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {sources.length > 0 && (
              <Accordion.Item eventKey="2">
                <Accordion.Header>Choose a Source</Accordion.Header>
                <Accordion.Body>
                  <div className="filterHead group mt-2">
                    <CMSFormCheckBoxes
                      name="sources"
                      form={form}
                      label=""
                      options={handleFormatArray(sources)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}

            <Accordion.Item eventKey="3">
              <Accordion.Header>More Filters</Accordion.Header>
              <Accordion.Body>
                <div className="filterHead group mt-2">
                  <CMSFormCheckBoxes
                    name="relevant"
                    form={form}
                    label=""
                    options={[{ value: "relevant", label: "Relevant" }]}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="showOnMobile">
              <Accordion.Header>Sort by</Accordion.Header>
              <Accordion.Body>
                <div className="filterHead sort">
                  <CMSFormRadioButtons
                    name="sort"
                    form={form}
                    label=""
                    options={sortArray}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="filterHeadBottom sort">
            <CustomTooltip title="Please click to submit" position="top">
              <a href="" className="filterApply" onClick={form.handleSubmit}>
                apply
              </a>
            </CustomTooltip>
          </div>
          {screen.language.length > 0 ||
          screen.searchedValue ||
          screen.relevant ||
          screen.year.length > 0 ||
          screen.sortedValue ? (
            <div className="filterHeadBottom sort">
              <CustomTooltip title="Please click to reset" position="bottom">
                <a href="" className="filterApply" onClick={form.handleReset}>
                  reset all filters
                </a>
              </CustomTooltip>
            </div>
          ) : null}
        </>
      )}
    </Formik>
  );
};

export default PublicationFilter;
