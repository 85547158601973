import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import BodyLayout from "../../layout/body/BodyLayout";
import LinkComponent from "./linkComponent/LinkComponent";
import { Loading } from "../../shared/loading/Loading";
import CustomTooltip from "../../shared/tooltip/CustomTooltip";
import {
  screenInitialState,
  screenReducer,
} from "../../shared/reducers/screenReducer";
import axiosInstance from "../../../config/axios";
import { FetchError } from "../../shared/displayAlerts/DisplayAlerts";

const UsefulLinks = () => {
  const [screen, setScreen] = useState({
    count: 3,
    loadMore: false,
    searchField: "",
    loading: true,
    filterShow: false,
    data: [],
  });

  const loadMoreItems = async () => {
    if (
      detailsState &&
      detailsState.data &&
      detailsState.data.pagination &&
      detailsState.data.pagination.currentPage <
        detailsState.data.pagination.pageCount
    ) {
      setScreen((prevState) => ({ ...prevState, loadMore: true }));
      try {
        const res = await axiosInstance.get(
          "useful-links" + "?page=" + detailsState.data.pagination.nextPage
        );
        setScreen((screen) => ({
          ...screen,
          loadMore:false,
          data: screen.data.concat(res.data.items),
        }));
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    } else {
    }
  };

  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );

  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`useful-links`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setScreen((screen) => ({
        ...screen,
        loadMore: false,
        data: res.data.items,
      }));
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    localStorage.removeItem("newsFiltersApplied");
    localStorage.removeItem("newsScreenValues");
    localStorage.removeItem("motFiltersApplied");
    localStorage.removeItem("motScreenValues");
    localStorage.removeItem("ownAFossilFiltersApplied");
    localStorage.removeItem("ownAFossilScreenValues");
    localStorage.removeItem("mediaFiltersApplied");
    localStorage.removeItem("mediaScreenValues");
    localStorage.removeItem("pressFiltersApplied");
    localStorage.removeItem("pressScreenValues");
    localStorage.removeItem("publicationFiltersApplied");
    localStorage.removeItem("publicationScreenValues");
    localStorage.removeItem("currentActiveIdNews");
    localStorage.removeItem("currentActiveIdTestimonial");
  }, []);
  useEffect(() => {
    loadItem();
  }, []);
  useEffect(() => {
   
    document.title = "Useful Links";
  }, []);
  return (
    <BodyLayout>
      <div
        id="top"
        className={`usefulLinksSection ${
          detailsState.hasError ? "center" : ""
        }  pb-2`}
      >
        {detailsState.isFetching === true && <Loading />}
        <div className="yellowBox"></div>
        {detailsState.hasError && <FetchError />}
        {detailsState && !detailsState.hasError && detailsState.data && (
          <Row className="ms-xl-1 position-relative pb-2">
            <Col xl={12} className="pt-xl-5 pb-2">
              <Row className="uniPadding innerSection pb-2 pt-xl-3">
                <Col
                  xl={12}
                  className="pt-xl-4 pt-lg-4 pt-xl-5 pt-lg-5 pt-md-5 pt-sm-5 pt-3"
                >
                  <div className="headContainer">
                    <h3 className="headingText">
                      {" "}
                      {detailsState.data.heading
                        ? detailsState.data.heading
                        : "Useful Links"}
                    </h3>
                  </div>
                </Col>
              </Row>
              <Row className="uniPadding innerSection2 pt-xl-2 pt-lg-2 pb-0">
                <Col
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="pt-2 px-3"
                >
                  <Row>
                    {screen.data &&
                      screen.data.map((item, index) => {
                        return (
                          <Col
                            key={index}
                            xl={4}
                            lg={4}
                            md={6}
                            sm={6}
                            xs={12}
                            className="linkHolder"
                          >
                            <LinkComponent name={item.title} link={item.link} />
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
                <Col xl={12}>
                  {parseInt(
                    detailsState &&
                      detailsState.data &&
                      detailsState.data.pagination &&
                      detailsState.data.pagination.currentPage
                  ) <
                    parseInt(
                      detailsState &&
                        detailsState.data &&
                        detailsState.data.pagination &&
                        detailsState.data.pagination.pageCount
                    ) && (
                    <div
                      className="loadMore pb-xl-5 pb-lg-5 pb-3"
                      onClick={loadMoreItems}
                    >
                      {screen.loadMore === true ? (
                        <div className="spinner-container center">
                          <div className="loading-spinner"></div>
                        </div>
                      ) : (
                        <span>
                          show more
                        </span>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </BodyLayout>
  );
};

export default UsefulLinks;
