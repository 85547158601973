import React ,{useEffect} from "react";
import { Formik } from "formik";
import CMSFormCheckBoxes from "../../../../shared/formFields/CMSFormCheckBoxes";
import CMSFormRadioButtons from "../../../../shared/formFields/CMSFormRadioButtons";
import { Accordion } from "react-bootstrap";
const PressReleasesFilter = ({
  screen,
  onSubmit,
  parentState,
  sortArray,
  languages,
  years,
  sources,
  onClick,
  search,
  setSearch,
}) => {
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i], value: item[i] });
    }

    return array;
  };

  return (
    <Formik
      initialValues={{
        languages: screen.language ? screen.language : "",
        years: screen.year ? screen.year : "",
        sources: screen.sources ? screen.sources : "",
        relevant: screen.relevant ? screen.relevant : "",
        sort: screen.sortedValue ? screen.sortedValue : "",
      }}
      onSubmit={async (values) => {
        onSubmit(values);
      }}
      onReset={async () => {
        parentState({
          ...screen,
          sortedValue: "",
          searchedValue: "",
          year: "",
          sources: "",
          relevant: "",
          language: "",
        });
        setSearch("");
        localStorage.setItem("pressFiltersApplied", JSON.stringify(""));
        localStorage.setItem(
          "pressScreenValues",
          JSON.stringify({
            ...screen,
            sortedValue: "",
            searchedValue: "",
            year: "",
            sources: "",
            relevant: "",
            language: "",
          })
        );
      }}
    >
      {(form) => (
        <>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Choose a Language</Accordion.Header>
              <Accordion.Body>
                <div className="filterHead group mt-2">
                  <CMSFormCheckBoxes
                    name="languages"
                    form={form}
                    label=""
                    options={handleFormatArray(languages)}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Choose a Year</Accordion.Header>
              <Accordion.Body>
                <div className="filterHead group mt-2">
                  <CMSFormCheckBoxes
                    name="years"
                    form={form}
                    label=""
                    options={handleFormatArray(years)}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Choose a Source</Accordion.Header>
              <Accordion.Body>
                <div className="filterHead group mt-2">
                  <CMSFormCheckBoxes
                    name="sources"
                    form={form}
                    label=""
                    options={handleFormatArray(sources)}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>More Filters</Accordion.Header>
              <Accordion.Body>
                <div className="filterHead group mt-2">
                  <CMSFormCheckBoxes
                    name="relevant"
                    form={form}
                    label=""
                    options={[{ value: "relevant", label: "Relevant" }]}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="showOnMobile">
              <Accordion.Header>Sort by</Accordion.Header>
              <Accordion.Body>
                <div className="filterHead sort">
                  <CMSFormRadioButtons
                    name="sort"
                    form={form}
                    label=""
                    options={sortArray}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="filterHeadBottom sort">
            <a href="" className="filterApply" onClick={form.handleSubmit}>
              apply
            </a>
          </div>
          {screen.language.length > 0 ||screen.searchedValue ||
          screen.year.length > 0 ||
          screen.sources.length > 0 ||
          screen.sortedValue ||
          screen.relevant ? (
            <div className="filterHeadBottom sort">
              <a href="" className="filterApply" onClick={form.handleReset}>
                reset all filters
              </a>
            </div>
          ) : null}
        </>
      )}
    </Formik>
  );
};

export default PressReleasesFilter;
