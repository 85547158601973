import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
const CMSFormRadioButtonsSubmit = (props) => {
  const { label, name, options, onChange, form, required, ...rest } = props;

  return (
    <div>
      {label !== "" && (
        <>
          {required === true ? (
            <OverlayTrigger
              key={`tooltip-${name}`}
              placement="right"
              overlay={<Tooltip id={`tooltip-right`}>Required field.</Tooltip>}
            >
              <label
                htmlFor={name}
                className={`required ' ${
                  form.touched[name] && form.errors[name]
                    ? "isInvalidText label"
                    : " label"
                }`}
              >
                {" "}
                {label}
              </label>
            </OverlayTrigger>
          ) : (
            <label
              htmlFor={name}
              className={` ${
                form.touched[name] && form.errors[name]
                  ? "isInvalidText label"
                  : " label"
              }`}
            >
              {" "}
              {label}
            </label>
          )}
        </>
      )}
      {/* {(label !== "") && <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "isInvalidTextuired" : "required"}`}> {label}</label>} */}
      <div role="group" aria-labelledby="my-radio-group" className={` ${
          form.touched[name] && form.errors[name]
            ? "is-invalid  "
            : " "
        }`}>
            <div className="checkBoxContainer">
        {options.map((option) => {
          return (
       

        
            <label
              className={` ${
                form.touched[name] && form.errors[name]
                  ? "isInvalidText radioText mb-2 radio" 
                  : "radioText radio"
              }`}
            >
              <Field
                name={name}
                type="radio"
                onChange={onChange}
                value={option.value}
              />

             <span className="ms-2"> {option.label}</span>
            </label>
       
          );
        })}
         </div>
      </div>
      {/* <div
        className={` ${
          form.touched[name] && form.errors[name]
            ? "is-invalid payment "
            : "payment "
        }`}
      >
        {options.map((option) => {
          return (
            <div key={option.label} className="checkBoxContainer ">
              <Field
                name={name}
                type="radio"
                id={option.value}
                value={option.value}
                onChange={onChange}
                checked={form.values[name] === option.value}
              />
              <label
                htmlFor={option.value}
                className={` ${
                  form.touched[name] && form.errors[name]
                    ? "isInvalidText ms-2 radioText"
                    : "ms-2 radioText"
                }`}
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div> */}
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="error invalid-feedback">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

export default CMSFormRadioButtonsSubmit;
