import React from "react";
import { Route, Routes } from "react-router-dom";
import FossilLab from "../components/pages/discoverUs/fossilLab/FossilLab";
import FossilMuseum from "../components/pages/discoverUs/fossilMuseum/FossilMuseum";
import FossilSites from "../components/pages/discoverUs/fossilSites/FossilSites";
import InternationalRelations from "../components/pages/discoverUs/internationalRelations/InternationalRelations";
import MIM from "../components/pages/discoverUs/mim/MIM";
import OurHistory from "../components/pages/discoverUs/ourHistory/OurHistory";
import TheShop from "../components/pages/discoverUs/theShop/TheShop";
import HomePage from "../components/pages/home/HomePage";
import OwnAFossil from "../components/pages/ownAFossil/OwnAFossil";
import PageNotFound from "../components/pages/PageNotFound";
import ContactPage from "../components/pages/ContactPage";
import HowToReach from "../components/pages/HowToReach";
import UsefulLinks from "../components/pages/usefulLinks/UsefulLinks";
import PressReleases from "../components/pages/memories/pressReleases/PressReleases";
import NewsEvents from "../components/pages/memories/newsEvents/NewsEvents";
import MediaGallery from "../components/pages/memories/mediaGallery/MediaGallery";
import FossilScience from "../components/pages/fossils/fossilScence/FossilScience";
import MemoryCollectionListing from "../components/pages/fossils/memoryOfTimeCollection/listing/MemoryCollectionListing";
import CollectionDetails from "../components/pages/fossils/memoryOfTimeCollection/details/CollectionDetails";
import OwnAFossilDetails from "../components/pages/ownAFossil/details/OwnAFossilDetails";
import Publications from "../components/pages/memories/publications/Publications";
import CartDisplay from "../components/pages/cart/CartDisplay";
import Testimonial from "../components/pages/testimonial/Testimonial";
import Unsubscribe from "../components/pages/Unsubscribe";
import PayOnline from "../components/pages/payOnline/PayOnline";
import PaymentStatus from "../components/pages/paymentStatus/PaymentStatus";
import AddTestimonialMobile from "../components/pages/testimonial/AddTestimonialMobile";
import NewsEventsMobile from "../components/pages/memories/newsEvents/NewsEventsMobile";
import Checkout from "../components/pages/checkout/Checkout";
import TermsAndConditions from "../components/pages/terms/TermsAndConditions";
import PaymentRequestPaymentStatus from "../components/pages/paymentRequestPaymentStatus/PaymentRequestPaymentStatus";


const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      {/* discover us */}
      <Route path="discover-us">
        <Route path=":our-history" element={<OurHistory />} />
        <Route path=":fossil-lab" element={<FossilLab />} />
        <Route path=":the-shop" element={<TheShop />} />
        <Route path=":fossil-sites" element={<FossilSites />} />
        <Route path=":fossil-museum" element={<FossilMuseum />} />
        <Route path=":mim" element={<MIM />} />
        <Route
          path=":international-relations"
          element={<InternationalRelations />}
        />
      </Route>
      {/* memories */}
      <Route path="memories">
        <Route path=":news-events/" element={<NewsEvents />} />
        <Route path=":news-events-mobile/" element={<NewsEventsMobile />} />
        <Route path=":media-gallery" element={<MediaGallery />} />
        <Route path=":press-releases" element={<PressReleases />} />
        <Route path=":publications" element={<Publications />} />
      </Route>
      <Route path="fossils">
        <Route
          path=":memory-of-time-collection"
          element={<MemoryCollectionListing />}
        />
        <Route
          path=":memory-of-time-collection/details/:slug"
          element={<CollectionDetails />}
        />
        <Route path=":fossils-science" element={<FossilScience />} />
      </Route>
      {/* own a fossil */}
      <Route path="/own-a-fossil" element={<OwnAFossil />} />
      <Route path="/own-a-fossil/:slug" element={<OwnAFossilDetails />} />
      {/* cart */}
      <Route path="/testimonial" element={<Testimonial />} />
      <Route path="/add-testimonial" element={<AddTestimonialMobile />} />
      {/* cart */}
      <Route path="/cart" element={<CartDisplay />} />
      {/* checkout */}
      <Route path="/checkout/:transactionId" element={<Checkout />} />
      {/* Pay Online */}
      <Route path="/pay-online/:transactionId" element={<PayOnline />} />

      {/* Payment Status */}
      <Route
        path="/payment-status/:transactionId/:indicator"
        element={<PaymentStatus />}
      />

      {/* Payment Request Payment Status */}
      <Route
        path="/payment-request-status"
        element={<PaymentRequestPaymentStatus />}
      />





      {/* others */}
      <Route path="terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="contact-us" element={<ContactPage />} />
      <Route path="unsubscribe" element={<Unsubscribe />} />
      <Route path="how-to-reach-us" element={<HowToReach />} />
      <Route path="useful-links" element={<UsefulLinks />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
