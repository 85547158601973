import React from "react";
import { Row, Col } from "react-bootstrap";
import { BlueButtons } from "../shared/buttons/Buttons";
import BodyLayout from "../layout/body/BodyLayout";

const PageNotFound = () => {
  return (
    <BodyLayout>
        
      <div className="errorPage center">
      <div className="yellowBox"></div>
      <Row >
        <Col
          xl={4}
          lg={4}
          className="p-4  center flex-column errorContainer mx-auto"
        >
          <h2>Page not found.</h2>
          <p>
            The page you are looking for does not exist.
            Click the button below to go back to the homepage.
          </p>
          <BlueButtons />
        </Col>
      </Row>
      </div>
    
    </BodyLayout>
  );
};

export default PageNotFound;
