import React from "react";
import "./expandedComponent.scss";
import parse from "html-react-parser";
import dummy from "../../../../../assets/images/dummyBg.png"
const ExpandedComponent = ({ item }) => {
  const handleOnError = (e) => {
    e.target.src = dummy;
  };
  return (
    <div className="expandedComponentHolder">
      <div className="topSection">
        {item.image && (
          <img onError={handleOnError}
            src={process.env.REACT_APP_BACKEND_URL + "public/" + item.image}
            className="topImage"
          />
        )}
        <div className="yellowSection px-4 end">
          {item.date && item.date.formattedDate}
        </div>
      </div>

      {item.description && (
        <div className="bottomSection">
          <h5 className="detailsTitle">{item.title}</h5>
          {item.description && (
            <p className="details mb-0"> {parse(item.description)}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ExpandedComponent;
