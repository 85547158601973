import React from "react";
import "../../pressReleases/listingComponent/listingComponent.scss";
import { FiDownload, FiExternalLink } from "react-icons/fi";
import { Row, Col } from "react-bootstrap";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
const ListingComponent = ({ item }) => {
  return (
    <div className="pressListingComponentHolder">
      <div className="leftSection ">
        {item.day && item.month && item.year && (
          <>
            {item.day && <p className="date">{item.day}</p>}
            {item.month && <p className="month">{item.month}</p>}
            {item.year && <p className="year">{item.year}</p>}
          </>
        )}
        {!item.day && item.month && item.year && (
          <>
            {item.month && <p className="monthLg">{item.month}</p>}
            {item.year && <p className="year">{item.year}</p>}
          </>
        )}
        {!item.day && !item.month && item.year && (
          <>{item.year && <p className="yearXl">{item.year}</p>}</>
        )}
      </div>
      <div className="middleSection">
        <Row className="middleRow">
          <Col>
            {item.title && <p className="title">{item.title}</p>}
            {item.topic && <p className="subtitle"> {item.topic}</p>}
            {item.author && <p className="subtitleItalic">by {item.author}</p>}
          </Col>
          <Col xl={2} md={2} lg={2} xs={12}>
            {item.externalLink && (
              <a href={item.externalLink} target="_blank">
                <div className="linkIcon">
                  <CustomTooltip
                    title={
                      item.externalLinkTitle
                        ? item.externalLinkTitle
                        : "Click to read more"
                    }
                  >
                    <FiExternalLink />{" "}
                  </CustomTooltip>
                </div>
              </a>
            )}
          </Col>
        </Row>
        {item.source && <p className="detail">{item.source}</p>}
      </div>
      {item.file === "" ? (
        <div xl={2} className="rightSection ">
          <p className="language">{item.language}</p>
        </div>
      ) : (
        <div xl={2} className="rightSection pointer">
          <CustomTooltip title="Click to download">
            <a  href={process.env.REACT_APP_BACKEND_URL + "public/" + item.file}
              target="_blank"
              download={
                process.env.REACT_APP_BACKEND_URL + "public/" + item.file
              }>
              <FiDownload
                className="download"
                
              />
              <p className="language">
                {item.language && item.language}
                {item.filePagesCount && ","}
              </p>
              {item.filePagesCount && (
                <p className="language">{item.filePagesCount} Page(s)</p>
              )}
            </a>
          </CustomTooltip>
        </div>
      )}
    </div>
  );
};

export default ListingComponent;
