import React, { useEffect, useReducer } from "react";
import DiscoverUsSubPage from "../../../layout/subPage/DiscoverUsSubpage";
import { Loading } from "../../../shared/loading/Loading";
import "../discoverUs.scss";
import axiosInstance from "../../../../config/axios";
import TextSwiper from "../textSwiper/TextSwiper";
import {
  screenInitialState,
  screenReducer,
} from "../../../shared/reducers/screenReducer";

const TheShop = () => {
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const sleep = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    await sleep(2000);
    try {
      const res = await axiosInstance.get(`page-content/the-shop`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();

    document.title = "The Shop";
  }, []);

  return (
    <>
      {detailsState.isFetching === true ? (
        <Loading />
      ) : detailsState.hasError ? (
        <DiscoverUsSubPage error={true} />
      ) : (
        <DiscoverUsSubPage
          heading={detailsState.data.heading}
          leftContent={
            <TextSwiper layout="ourHistory" slide1={detailsState.data.slide1} />
          }
          images={detailsState.data.banner}
          title={
            detailsState.data.bannerCaption
              ? detailsState.data.bannerCaption
              : detailsState.data.heading
          }
          buttonText={detailsState.data.buttonText}
          buttonLink={detailsState.data.buttonLink}
          galleryCount={detailsState.data.galleryCount}
          slug="page-gallery/the-shop"
        />
      )}
    </>
  );
};

export default TheShop;
