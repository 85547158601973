import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import BodyLayout from "../../../layout/body/BodyLayout";
import { Loading, OverLayLoading } from "../../../shared/loading/Loading";
import "../memories.scss";
import { AiOutlineSearch } from "react-icons/ai";
import ListingComponent from "./listingComponent/ListingComponent";
import { Formik } from "formik";
import {
  screenInitialState,
  screenReducer,
} from "../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../config/axios";
import ExpandedComponent from "./expandedComponent/ExpandedComponent";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import NewsEventsFilters from "./filters/NewsEventsFilters";
import {
  EmptyResponse,
  FetchError,
  NoItemsFound,
  ShowingSearchResultsDialog,
} from "../../../shared/displayAlerts/DisplayAlerts";
import CMSFormSelect from "../../../shared/formFields/CMSFormSelect";
import { MdClose } from "react-icons/md";
import ModalFilter from "../../../shared/modal/ModalFilter";
import { AppliedFilterSort } from "../../../shared/filters/AppliedFilter";
import { Link, useLocation } from "react-router-dom";
import CustomIcon from "../../../shared/customIcon/CustomIcon";

const NewsEventsMobile = () => {
  const [screen, setScreen] = useState({
    loadMore: false,
    data: null,
    selectedItem: "",
    selectedHomeItem: "",
    filterShow: false,
    showModal: false,
    selectedValue: "",
    searchedValue: "",
    sortedValue: "",
    overlay: false,
    sortArray: "",
  });
  const [search, setSearch] = useState("");
  const getFilterString = () => {
    let filterString = "";

    if (screen.searchedValue) {
      filterString = "q=" + screen.searchedValue;
    }
    if (screen.sortedValue) {
      filterString = filterString + "&year=" + screen.sortedValue;
    }

    return filterString;
  };
  const loadMoreItems = async () => {
    if (
      detailsState &&
      detailsState.data &&
      detailsState.data.pagination &&
      detailsState.data.pagination.currentPage <
        detailsState.data.pagination.pageCount
    ) {
      setScreen((prevState) => ({ ...prevState, loadMore: true }));
      try {
        const res = await axiosInstance.get(
          "news-events" + "?page=" + detailsState.data.pagination.nextPage
        );
        setScreen((screen) => ({
          ...screen,
          loadMore: false,
          data: screen.data.concat(res.data.items),
        }));
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    } else {
    }
  };
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    const filterAppliedSaved = localStorage.getItem("newsFiltersApplied");
    const filterApplied = JSON.parse(filterAppliedSaved);
    const newsScreenSaved = localStorage.getItem("newsScreenValues");
    const newsScreen = JSON.parse(newsScreenSaved);
    let filterString = getFilterString();
    if (
      newsScreen !== null &&
      filterString === "" &&
      (filterApplied !== "" || filterApplied !== null)
    ) {
      filterString = filterApplied !== "" ? filterApplied : "";

      setScreen({
        ...screen,
        sortedValue: newsScreen.sortedValue,
        searchedValue: newsScreen.searchedValue,
      });
      setSearch(newsScreen.searchedValue);
    }

    console.log(filterApplied, newsScreen, filterString, "inside item");

    try {
      const res = await axiosInstance.get(
        "news-events" + (filterString ? "?" + filterString : "")
      );
      localStorage.setItem("newsFiltersApplied", JSON.stringify(filterString));
      localStorage.setItem("newsScreenValues", JSON.stringify(screen));
      setScreen((screen) => ({
        ...screen,
        loadMore: false,
        selectedItem: "",
        sortArray: res.data.availableYears,
        data: res.data.items,
      }));
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
    document.title = "News & Events";
  }, [screen.searchedValue, screen.sortedValue]);
  useEffect(() => {
    document.title = "News & Events";
  }, []);
  const containsObject = (activeId) => {
    const result = "";
    for (let i = 0; i < screen.data.length; i++) {
      console.log(i, screen.data[i]._id, activeId);
      if (screen.data[i]._id === activeId) {
        console.log("true");
        //localStorage.removeItem("currentActiveIdTestimonial");

        return true;
      } else {
      }
    }
  };
  useEffect(() => {
    const activeIdSaved = localStorage.getItem("currentActiveIdNewsHome");
    const activeId = JSON.parse(activeIdSaved);
    if (activeId !== null && !screen.sortedValue) {
      if (screen.data !== null) {
        setScreen({
          ...screen,
          selectedHomeItem: activeId,
        });
        if (containsObject(activeId) === true) {
          console.log("founded");
        } else {
          loadMoreItems();
        }
      }
    }
  }, [screen.data]);

  useEffect(() => {
    const activeIdSaved = localStorage.getItem("currentActiveIdNews");
    const activeId = JSON.parse(activeIdSaved);
    console.log(activeId);
    if (activeId !== null && !screen.sortedValue) {
      if (screen.data !== null) {
        setScreen({
          ...screen,
          selectedItem: activeId,
          selectedHomeItem: "",
        });
        if (containsObject(activeId) === true) {
          console.log("founded");
        } else {
          loadMoreItems();
        }
      }
    }
  }, [screen.data]);

  const handleSearchSubmit = (values) => {
    localStorage.setItem("newsFiltersApplied", JSON.stringify(""));
    localStorage.setItem(
      "newsScreenValues",
      JSON.stringify({ ...screen, searchedValue: search })
    );
    setScreen({
      ...screen,
      loading: true,
      searchedValue: search,
    });
  };
  const handleClear = (values) => {
    localStorage.setItem("newsFiltersApplied", JSON.stringify(""));
    localStorage.setItem(
      "newsScreenValues",
      JSON.stringify({ ...screen, searchedValue: "" })
    );
    setScreen({
      ...screen,
      searchedValue: "",
    });
    setSearch("");
  };
  const handleFormSubmit = (values) => {
    setScreen({
      ...screen,
      filterSubmit: true,
      filterShow: false,
      showModal: false,
      sortedValue: values.sort,
    });
  };
  const handleItem = (title) => {
    setScreen((screen) => ({
      ...screen,
      selectedItem: title,
    }));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    localStorage.setItem("currentActiveIdNews", JSON.stringify(title));
    localStorage.removeItem("currentActiveIdNewsHome");
  };
  const handleBack = () => {
    setScreen((screen) => ({
      ...screen,
      selectedItem: "",
    }));
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit(search);
    }
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleRemoveItem = () => {
    setScreen({
      ...screen,
      overlay: true,
    });

    setTimeout(() => {
      setScreen({
        ...screen,
        sortedValue: "",
        overlay: false,
      });
      localStorage.setItem("newsFiltersApplied", JSON.stringify(""));
      localStorage.setItem(
        "newsScreenValues",
        JSON.stringify({ ...screen, sortedValue: "" })
      );
    }, 100);
  };
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i], value: item[i] });
    }

    return array;
  };
  useEffect(() => {
    localStorage.removeItem("motFiltersApplied");
    localStorage.removeItem("motScreenValues");
    localStorage.removeItem("ownAFossilFiltersApplied");
    localStorage.removeItem("ownAFossilScreenValues");
    localStorage.removeItem("mediaFiltersApplied");
    localStorage.removeItem("mediaScreenValues");
    localStorage.removeItem("pressFiltersApplied");
    localStorage.removeItem("pressScreenValues");
    localStorage.removeItem("publicationFiltersApplied");
    localStorage.removeItem("publicationScreenValues");
  }, []);
  return (
    <BodyLayout>
      <div
        className={`memoriesSection  ${
          detailsState.hasError ? "center" : ""
        } position-relative pb-3`}
      >
        {detailsState.isFetching === true && screen.data === null && (
          <Loading />
        )}
        {detailsState.isFetching === true &&
          screen.data !== null &&
          screen.overlay !== true && <OverLayLoading />}
        {screen.overlay === true && <OverLayLoading />}

        <div className="yellowBox"></div>
        {detailsState &&
          detailsState.isFetching === false &&
          detailsState.hasError !== true && (
            <Row className="ms-xl-1  pb-0 mb-xl-0">
              <Col xl={12} className="pt-xl-5">
                <Row className=" uniPadding pt-xl-3 mt-xl-4 pt-md-5 pb-md-3">
                  <Col className="ps-xl-4 ps-md-4 newsEventsMobile">
                  {screen.selectedItem !== "" && (
                      <div className="backIconTop smallDevice">
                        <CustomTooltip title="Please click to go back">
                          <CustomIcon
                            icon="leftArrow"
                            size={40}
                            className="goBack"
                            onClick={() => handleBack()}
                          /> 
                        </CustomTooltip>
                      </div>
                    )}
                    <div className="headContainer">
                      <h3 className="headingText ">News & Events</h3>
                    </div>
                 
                  </Col>
                </Row>

                {detailsState &&
                  detailsState.isFetching === false &&
                  detailsState.hasError !== true &&
                  screen.selectedItem === "" && (
                    <>
                      <Row className=" uniPadding pt-0">
                        <Col className="ps-4">
                          <Row
                            className={`uniPaddingOwnAFossil hidOnMobile filterRow  pt-0  `}
                          >
                            <Col
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="pt-1 ms-2 pe-xl-0 filters "
                            >
                              <div className="searchBox me-xl-4 me-md-4">
                                <input
                                  id="search"
                                  name="search"
                                  placeholder="search"
                                  type="text"
                                  onKeyDown={handleKeyPress}
                                  onChange={handleChange}
                                  value={search}
                                />
                                <div
                                  className="icon center"
                                  onClick={() => handleSearchSubmit(search)}
                                >
                                  <AiOutlineSearch className="searchIcon" />
                                </div>
                              </div>

                              <>
                                <Formik
                                  initialValues={{
                                    sort: screen.sortedValue
                                      ? screen.sortedValue
                                      : "",
                                  }}
                                  onSubmit={async (values) => {
                                    setScreen({
                                      ...screen,
                                      sortedValue: values.sort,
                                    });
                                    handleFormSubmit(values);
                                  }}
                                >
                                  {(form) => (
                                    <>
                                      <CustomTooltip
                                        title="Please click to filter"
                                        position="top"
                                      >
                                        <div
                                          className={`filterBox sort ${
                                            screen.sortShow === true
                                              ? " viewAll "
                                              : ""
                                          } center`}
                                          onClick={() =>
                                            setScreen((screen) => ({
                                              ...screen,
                                              sortShow: !screen.sortShow,
                                            }))
                                          }
                                        >
                                          <CMSFormSelect
                                            name="sort"
                                            placeholder={{
                                              value: " ",
                                              label: "choose year",
                                            }}
                                            form={form}
                                            selected={screen.sortedValue}
                                            onChange={(e) => {
                                              form.handleChange(e);
                                              form.handleSubmit(e);
                                            }}
                                            filter={true}
                                            options={handleFormatArray(
                                              screen.sortArray
                                            )}
                                          />
                                        </div>
                                      </CustomTooltip>
                                    </>
                                  )}
                                </Formik>
                              </>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="mobileFilters pb-3 pt-3 uniPadding center showOnMobile">
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="pt-1  filters "
                        >
                          <div className="mobileSearchComponent">
                            <div
                              className={`searchBox ${
                                screen.expandSearchField ? "expand" : ""
                              }`}
                            >
                              <input
                                id="search"
                                name="search"
                                placeholder="search"
                                type="text"
                                onChange={handleChange}
                                value={search}
                              />
                              <div
                                className="icon center"
                                onClick={
                                  screen.expandSearchField
                                    ? () => handleSearchSubmit(search)
                                    : () =>
                                        setScreen((screen) => ({
                                          ...screen,
                                          expandSearchField:
                                            !screen.expandSearchField,
                                        }))
                                }
                                // onClick={() => handleSearchSubmit(search)}
                              >
                                <AiOutlineSearch className="searchIcon" />
                              </div>
                              {screen.expandSearchField && (
                                <div
                                  className="icon center"
                                  onClick={() =>
                                    setScreen((screen) => ({
                                      ...screen,
                                      expandSearchField:
                                        !screen.expandSearchField,
                                    }))
                                  }
                                  // onClick={() => handleSearchSubmit(search)}
                                >
                                  <MdClose className="searchIcon" />
                                </div>
                              )}
                            </div>
                          </div>

                          <>
                            {screen.showModal === true && (
                              <ModalFilter
                                show={screen.showModal}
                                onHide={() =>
                                  setScreen({ ...screen, showModal: false })
                                }
                              >
                                <div className="filterExpandedMobile pt-3">
                                  <div className="filterContentMobile">
                                    <div className="mobileFilterTop">
                                      <div
                                        title={
                                          screen.filterShow === false
                                            ? "Please click to view filters"
                                            : "Please click to close filters"
                                        }
                                        className={`filterBox ${
                                          screen.expandSearchField === true
                                            ? "expand"
                                            : ""
                                        } ${
                                          screen.showModal === true
                                            ? "viewAllMobile "
                                            : ""
                                        } center`}
                                        onClick={() =>
                                          setScreen((screen) => ({
                                            ...screen,
                                            showModal: !screen.showModal,
                                          }))
                                        }
                                      >
                                        <div className="arrow-down"></div>
                                        view all filters
                                      </div>
                                      <div
                                        className="icon center"
                                        onClick={() =>
                                          setScreen({
                                            ...screen,
                                            showModal: false,
                                          })
                                        }
                                        // onClick={() => handleSearchSubmit(search)}
                                      >
                                        <MdClose className="filterCloseIconMobile" />
                                      </div>
                                    </div>
                                    <NewsEventsFilters
                                      onSubmit={handleFormSubmit}
                                      screen={screen}
                                      parentState={setScreen}
                                      sortArray={handleFormatArray(
                                        screen.sortArray
                                      )}
                                    />
                                  </div>
                                </div>
                              </ModalFilter>
                            )}

                            <div
                              title={
                                screen.filterShow === false
                                  ? "Please click to view filters"
                                  : "Please click to close filters"
                              }
                              className={`filterBox ${
                                screen.expandSearchField === true
                                  ? "expand"
                                  : ""
                              } ${
                                screen.filterMobileShow === true
                                  ? "viewAll  "
                                  : ""
                              } center`}
                              onClick={() =>
                                setScreen((screen) => ({
                                  ...screen,
                                  showModal: !screen.showModal,
                                }))
                              }
                            >
                              <div className="arrow-down"></div>
                              view all filters
                            </div>
                          </>
                        </Col>
                      </Row>
                    </>
                  )}

                <Row className="uniPadding innerSection2 pt-0">
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="ps-xl-4 ps-lg-4 ps-md-4"
                  >
                    {/* applied filters display start */}
                    {screen.sortedValue.length > 2 ? (
                      <Row className="pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-3 pb-0">
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          className="start  filtersTopDisplay"
                        >
                          Applied filter(s) :
                          {screen.sortedValue && (
                            <AppliedFilterSort
                              value={screen.sortedValue}
                              onClick={handleRemoveItem}
                            />
                          )}
                        </Col>
                      </Row>
                    ) : null}
                    {/* applied filters display end */}
                    {detailsState &&
                      detailsState.isFetching === false &&
                      detailsState.data && (
                        <>
                          {screen.searchedValue !== "" && (
                            <ShowingSearchResultsDialog
                              value={screen.searchedValue}
                              onClick={handleClear}
                              count={
                                detailsState &&
                                detailsState.data &&
                                detailsState.data.pagination &&
                                detailsState.data.pagination.totalFilteredItems
                              }
                            />
                          )}
                          {detailsState &&
                            screen.searchedValue !== "" &&
                            detailsState.data &&
                            detailsState.data.items &&
                            detailsState.data.items.length <= 0 && (
                              <NoItemsFound />
                            )}
                          {/* {detailsState &&
                            screen.searchedValue === "" &&
                            detailsState.data &&
                            detailsState.data.items &&
                            detailsState.data.items.length <= 0 && (
                              <EmptyResponse />
                            )} */}
                        </>
                      )}
                  </Col>
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="px-3 "
                  >
                    {screen.searchedValue === "" &&
                      detailsState &&
                      detailsState.data &&
                      detailsState.data.items &&
                      detailsState.data.items.length <= 0 && <EmptyResponse />}
                  </Col>
                </Row>
                <Row className="uniPadding innerSection3 pt-0">
                  {screen.selectedItem === "" ? (
                    <Col
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      className="pt-4 ps-xl-4 ps-md-4 pb-5  pe-xl-3 pe-md-3"
                    >
                      {detailsState &&
                        !detailsState.hasError &&
                        detailsState.data && (
                          <>
                            {screen.data &&
                              screen.data.map((item, index) => {
                                return (
                                  <>
                                    <ListingComponent
                                      onClick={() => handleItem(item._id)}
                                      item={item}
                                      initialItem=""
                                      activeItem={
                                        screen.selectedItem !== "" &&
                                        screen.selectedItem
                                      }
                                    />
                                  </>
                                );
                              })}

                            {parseInt(
                              detailsState &&
                                detailsState.data &&
                                detailsState.data.pagination &&
                                detailsState.data.pagination.currentPage
                            ) <
                              parseInt(
                                detailsState &&
                                  detailsState.data &&
                                  detailsState.data.pagination &&
                                  detailsState.data.pagination.pageCount
                              ) && (
                              <div
                                className="loadMore mt-3 pt-4"
                                onClick={loadMoreItems}
                              >
                                {screen.loadMore === true ? (
                                  <div className="spinner-container center">
                                    <div className="loading-spinner"></div>
                                  </div>
                                ) : (
                                  <CustomTooltip title="Click to view more">
                                    show more
                                  </CustomTooltip>
                                )}
                              </div>
                            )}
                          </>
                        )}
                    </Col>
                  ) : (
                    <Col
                      xl={6}
                      lg={6}
                      md={12}
                      sm={12}
                      xs={12}
                      className="pt-xl-4 pt-lg-4 pt-md-4 pt-4 ps-md-4 pe-md-3 pb-xl-5 pb-lg-5 pb-md-4 pb-sm-4 pb-4 mb-md-3 "
                    >
                      {detailsState && detailsState.data && (
                        <>
                          {screen.selectedItem !== "" && (
                            <>
                              {screen.data.map((item, index) => {
                                if (screen.selectedItem === item._id)
                                  return <ExpandedComponent item={item} />;
                              })}
                            </>
                          )}
                        </>
                      )}
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          )}
        <Row>
          {detailsState && detailsState.hasError === true && (
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="pt-4 center"
            >
              <FetchError />
            </Col>
          )}
        </Row>
      </div>
    </BodyLayout>
  );
};

export default NewsEventsMobile;
