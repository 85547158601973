import React, { useState, useEffect, useReducer } from "react";
import BodyLayout from "../layout/body/BodyLayout";
import { Row, Col } from "react-bootstrap";
import CustomIcon from "../shared/customIcon/CustomIcon";
import location from "../../assets/images/location.png";
import { Loading } from "../shared/loading/Loading";
import {
  screenInitialState,
  screenReducer,
} from "../shared/reducers/screenReducer";
import ContactInfo from "../shared/contactInfo/ContactInfo";
import axiosInstance from "../../config/axios";
import CustomTooltip from "../shared/tooltip/CustomTooltip";
import { FetchErrorLight } from "../shared/displayAlerts/DisplayAlerts";
const HowToReach = () => {
  const [screen, setScreen] = useState({
    submitting: false,
    status: "fetched",
    loading: true,
  });
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const [isLoading, setIsLoading] = useState(true);

  const handleVideoLoaded = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    localStorage.removeItem("newsFiltersApplied");
    localStorage.removeItem("newsScreenValues");
    localStorage.removeItem("motFiltersApplied");
    localStorage.removeItem("motScreenValues");
    localStorage.removeItem("ownAFossilFiltersApplied");
    localStorage.removeItem("ownAFossilScreenValues");
    localStorage.removeItem("mediaFiltersApplied");
    localStorage.removeItem("mediaScreenValues");
    localStorage.removeItem("pressFiltersApplied");
    localStorage.removeItem("pressScreenValues");
    localStorage.removeItem("publicationFiltersApplied");
    localStorage.removeItem("publicationScreenValues");
    localStorage.removeItem("currentActiveIdNews");
    localStorage.removeItem("currentActiveIdTestimonial");
  }, []);
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`page-content/how-to-reach-us`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  useEffect(() => {
    loadItem();
  }, []);
  useEffect(() => {
    document.title = "How to Reach Us";
  }, []);
  return (
    <BodyLayout>
      <div
        className={`ourHistorySection ${
          detailsState && detailsState.hasError ? "center" : ""
        }  pb-xl-5 pb-lg-5 pb-md-4`}
      >
        {detailsState.isFetching === true && <Loading />}
        <div className="yellowBox"></div>
        {detailsState && detailsState.hasError && (
          <Row className="center">
            <Col xl={12} className="pt-4">
              <FetchErrorLight />
            </Col>
          </Row>
        )}
        {detailsState && !detailsState.hasError && detailsState.data && (
          <Row className="ms-xl-1 position-relative pb-4">
            <Col xl={12} className="py-xl-5">
              <Row className="uniPadding innerSection pt-3">
                <Col xl={12} className="pt-4">
                  <div className="headContainer">
                    <h3 className="ourHistoryText">
                      {" "}
                      {detailsState.data.heading}
                    </h3>
                    <hr className="yellowLine" />
                  </div>
                </Col>
              </Row>

              <Row className="uniPadding innerSection2 pt-0 pb-2">
                <Col xl={4} className="pt-0 ">
                  <ContactInfo item={detailsState.data} />
                </Col>
                <Col xl={7} className="pt-xl-5  ">
                  <Row>
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="pt-md-3"
                    >
                      {detailsState.data.addresses && (
                        <div className="locationIframeContainer ">
                          {/* <div className="redLine one"></div>
                        <div className="redLine two"></div>
                        <div className="redLine three"></div> */}
                          {detailsState.data.addresses.map((address, index) => {
                            if (address.name === "google_map_iframe")
                              return (
                                <>
                                  {isLoading && (
                                    <div className="loading-holder">
                                      <div className="loading-spinner "></div>
                                    </div>
                                  )}
                                  <iframe
                                    key={index}
                                    width="100%"
                                    height="425"
                                    src={address.value}
                                    onLoad={handleVideoLoaded}
                                    className="locationImage mb-xl-3 mb-lg-3 mb-md-4 mb-4"
                                  ></iframe>
                                </>
                              );
                          })}
                          {detailsState.data.addresses.map((address, index) => {
                            if (address.name === "google_map_link")
                              return (
                                <a
                                  key={index}
                                  target="_blank"
                                  href={address.value}
                                  className="viewOnGoogle"
                                >
                                  view on google map
                                </a>
                              );
                          })}
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </BodyLayout>
  );
};

export default HowToReach;
