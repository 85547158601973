import React, { useReducer, useEffect,useState } from "react";
import { MdDelete } from "react-icons/md";
// temporary
import dummy from "../../../../assets/images/dummyBg.png";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import {
  updateInitialState,
  updateReducer,
} from "../../../shared/reducers/updateReducer";
import axiosInstance from "../../../../config/axios";
import { ContainerLoading } from "../../../shared/loading/Loading";
import {
  screenInitialState,
  screenReducer,
} from "../../../shared/reducers/screenReducer";
import CartItemDetails from "./CartItemDetails";
const CartListItem = (props) => {
  const { item, parent, parentState, deleteIcon } = props;
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [screen, setScreen] = useState({
    show: false,
  });

  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const saveIdInLocalStorage = (value) => {
    localStorage.setItem("BuyerId", JSON.stringify(value));
  };
  const handleOnError = (e) => {
    e.target.src = dummy;
  };
  const cartCountUpdate = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    const saved = localStorage.getItem("BuyerId");
    const buyerId = JSON.parse(saved);
    try {
      const res = await axiosInstance.post(`cart/`, {
        buyerId: buyerId,
      });
console.log(res.data)
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      localStorage.setItem(
        "cartItemsCount",
        JSON.stringify(res.data.items.length)
      );
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const handleSubmit = async () => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    const localBuyerIdFetch = localStorage.getItem("BuyerId");
    const buyerId =
      localBuyerIdFetch === "undefined" ||
      localBuyerIdFetch === "null" ||
      localBuyerIdFetch === null
        ? ""
        : JSON.parse(localBuyerIdFetch);
    try {
      const res = await axiosInstance.post(`cart/remove-from-cart`, {
        buyerId: buyerId === undefined ? "" : buyerId,
        productId: item._id,
      });

      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      parentState({
        ...parent,
        deleted: true,
      });
      cartCountUpdate();
      // saveIdInLocalStorage(res.data.buyerId);
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    }
  };
  return (
    <div className="cartListItem position-relative">
      {updateState.isSubmitting && <ContainerLoading />}
      <div className="cartItemImageHolder" onClick={() => setScreen(() => ({ ...screen, show: true }))}>
        <img
          src={process.env.REACT_APP_BACKEND_URL + "public/" + item.image}
          className="cartItemImage"  
          onError={handleOnError}
          alt="fossilImage"
        />
        <div className="hover"></div>
      </div>
      <div
        className={`cartItemDetailsHolder ${
          deleteIcon !== false ? "" : "fullWidth"
        }`}
      >
        <p className="fossilName">{item.genus}</p>
        <p className="fossilPrice">
          {item.price}$
          <sup className="priceOffer">{item.oldPrice > 0 && item.oldPrice + "$"}</sup>
        </p>
      </div>
      {deleteIcon !== false && (
        <div className="cartItemDeleteIconHolder">
          <CustomTooltip
            title="Click to remove"
            position="top"
          >
            <MdDelete className="deleteIcon" onClick={() => handleSubmit()} />
          </CustomTooltip>
        </div>
      )}
       <CartItemDetails
          show={screen.show}
          item={item}
          onHide={() => setScreen(() => ({ ...screen, show: false }))}
          
        />
    </div>
  );
};

export default CartListItem;
