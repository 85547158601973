import React, { useState, useEffect, useReducer } from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import BodyLayout from "../../layout/body/BodyLayout";
import ProductCard from "../../shared/product/ProductCard";
import CMSFormSelect from "../../shared/formFields/CMSFormSelect";
import { Formik } from "formik";
import "./ownAFossil.scss";
import { Loading, OverLayLoading } from "../../shared/loading/Loading";
import CustomTooltip from "../../shared/tooltip/CustomTooltip";
import OwnAFossilFilters from "./filters/OwnAFossilFilters";
import { MdClose } from "react-icons/md";
import {
  EmptyResponse,
  FetchError,
  NoItemsFound,
  ShowingSearchResultsDialog,
} from "../../shared/displayAlerts/DisplayAlerts";
import ModalFilter from "../../shared/modal/ModalFilter";
import {
  screenInitialState,
  screenReducer,
} from "../../shared/reducers/screenReducer";
import axiosInstance from "../../../config/axios";
import AppliedFiltersOwnAFossil from "./filters/AppliedFiltersOwnAFossil";
import { useParams } from "react-router-dom";
const OwnAFossil = () => {
  const [screen, setScreen] = useState({
    loadMore: false,
    data: null,
    reload: false,
    status: "",
    showModal: false,
    filterShow: false,
    sortShow: false,
    sortedValue: "",
    searchedValue: "",
    category: "",
    subCategory: "",
    site: "",
    tag: "",
    overlay: false,
    heading: "",
    description: "",
    price: "",
  });
  const [search, setSearch] = useState("");
  const sortArray = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
    { value: "low-price", label: "price low to high" },
    { value: "high-price", label: "Price high to low" },
  ];
  useEffect(() => {
    localStorage.removeItem("newsFiltersApplied");
    localStorage.removeItem("newsScreenValues");
    localStorage.removeItem("motFiltersApplied");
    localStorage.removeItem("motScreenValues");
    localStorage.removeItem("mediaFiltersApplied");
    localStorage.removeItem("mediaScreenValues");
    localStorage.removeItem("pressFiltersApplied");
    localStorage.removeItem("pressScreenValues");
    localStorage.removeItem("publicationFiltersApplied");
    localStorage.removeItem("publicationScreenValues");
    localStorage.removeItem("currentActiveIdNews");
    localStorage.removeItem("currentActiveIdTestimonial");
    document.title = "Own a Fossil";
  }, []);
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );

  const getFilterString = () => {
    let filterString = "";

    if (screen.searchedValue) {
      filterString = "&q=" + screen.searchedValue;
    }
    // if (
    //   buyerId !== "" ||
    //   buyerId !== undefined ||
    //   buyerId !== null ||
    //   buyerId !== "undefined" ||
    //   buyerId !== "null"
    // ) {
    //   filterString = filterString + "&buyerId=" + buyerId;
    // }
    if (screen.sortedValue) {
      filterString = filterString + "&sort=" + screen.sortedValue;
    }

    if (screen.category) {
      filterString = filterString + "&category=" + screen.category;
    }
    if (screen.subCategory.length > 0) {
      for (let index = 0; index < screen.subCategory.length; index++) {
        filterString =
          filterString + "&subCategories[]=" + screen.subCategory[index];
      }
    }
    if (screen.site.length > 0) {
      for (let index = 0; index < screen.site.length; index++) {
        filterString = filterString + "&sites[]=" + screen.site[index];
      }
    }
    if (screen.tag.length > 0) {
      for (let index = 0; index < screen.tag.length; index++) {
        filterString = filterString + `&${screen.tag[index]}=` + 1;
      }
    }
    if (screen.price.length > 0) {
      filterString = filterString + "&minPrice=" + screen.price[0];
      filterString = filterString + "&maxPrice=" + screen.price[1];
    }

    return filterString;
  };
  useEffect(() => {
    if (screen.showModal) {
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.showWidget();
        }
      };
    }
  }, [screen.showModal]);
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    const filterAppliedSaved = localStorage.getItem("ownAFossilFiltersApplied");
    const filterApplied = JSON.parse(filterAppliedSaved);
    const ownAFossilScreenSaved = localStorage.getItem(
      "ownAFossilScreenValues"
    );
    const saved = localStorage.getItem("BuyerId");
    const buyerId = saved === null ? "" : JSON.parse(saved);
    console.log(buyerId, saved, "saved");
    const ownAFossilScreen = JSON.parse(ownAFossilScreenSaved);
    let filterString = getFilterString();
    console.log(filterString);
    if (filterString === "" && filterApplied) {
      filterString = filterApplied ? filterApplied : filterString;
      setScreen({
        ...screen,
        sortedValue: ownAFossilScreen.sortedValue,
        category: ownAFossilScreen.category,
        subCategory: ownAFossilScreen.subCategory,
        site: ownAFossilScreen.site,
        tag: ownAFossilScreen.tag,
        searchedValue: ownAFossilScreen.searchedValue,
        price: ownAFossilScreen.price,
        filterShow: false,
      });
      setSearch(ownAFossilScreen.searchedValue);
    }
    setScreen((screen) => ({
      ...screen,
      filterShow: screen.reload === true ? true : false,
    }));
    try {
      const res = await axiosInstance.get(
        "fossil-saleable" +
          (buyerId ? "?buyerId=" + buyerId : "?") +
          (filterString ? "" + filterString : "")
      );

      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data);
      localStorage.setItem(
        "ownAFossilFiltersApplied",
        JSON.stringify(filterString)
      );
      localStorage.setItem("ownAFossilScreenValues", JSON.stringify(screen));
      setScreen((screen) => ({
        ...screen,
        loadMore: false,
        filterShow: true,
        showModal: false,
        reload: false,
        loadingEffect: true,
        data: res.data.items,
      }));
      console.log(res.data);
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      console.log(error);
    }
  };

  const loadMoreItems = async () => {
    if (
      detailsState &&
      detailsState.data &&
      detailsState.data.pagination &&
      detailsState.data.pagination.currentPage <
        detailsState.data.pagination.pageCount
    ) {
      let filterString = getFilterString();
      const saved = localStorage.getItem("BuyerId");
      const buyerId = saved === null ? "" : JSON.parse(saved);
      console.log(buyerId, saved, "saved");

      setScreen((prevState) => ({ ...prevState, loadMore: true }));
      try {
        const res = await axiosInstance.get(
          "fossil-saleable" +
            "?page=" +
            detailsState.data.pagination.nextPage +
            (buyerId ? "&buyerId=" + buyerId : "") +
            (filterString ? "&" + filterString : "")
        );

        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
        setScreen((screen) => ({
          ...screen,
          loadMore: false,
          data: screen.data.concat(res.data.items),
        }));
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    } else {
    }
  };
  const handleClear = (values) => {
    localStorage.setItem("ownAFossilFiltersApplied", JSON.stringify(""));
    localStorage.setItem(
      "ownAFossilScreenValues",
      JSON.stringify({ ...screen, searchedValue: "" })
    );
    setScreen((screen) => ({
      ...screen,
      searchedValue: "",
    }));

    setSearch("");
  };
  const handleSearchSubmit = (values) => {
    localStorage.setItem("ownAFossilFiltersApplied", JSON.stringify(""));
    localStorage.setItem(
      "ownAFossilScreenValues",
      JSON.stringify({ ...screen, searchedValue: search })
    );
    setScreen((screen) => ({
      ...screen,
      searchedValue: search,
    }));
  };

  const handleFormSubmit = (values) => {
    setScreen((screen) => ({
      ...screen,
      showModal: false,
      sortedValue: values.sort ? values.sort : screen.sortedValue,
      category: values.category ? values.category : screen.category,
      subCategory:
        values.subCategory && values.category === screen.category
          ? values.subCategory
          : "",
      site: values.site ? values.site : screen.site,
      tag: values.tag ? values.tag : screen.tag,
      price: values.price ? values.price : screen.price,
    }));

    console.log(values);
    window.scrollTo(0, 0);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit(search);
    }
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const saveFilterInLocalStorage = () => {
    let filterString = getFilterString();
    localStorage.setItem(
      "fossilSaleableFilterApplied",
      JSON.stringify(filterString)
    );
  };

  useEffect(() => {
    loadItem();
    const screenLocalSaved = localStorage.getItem("fossilSaleableScreen");
    const screenLocal = JSON.parse(screenLocalSaved);
    const saveScreenInLocalStorage = () => {
      localStorage.setItem("fossilSaleableScreen", JSON.stringify(screen));
    };
    saveScreenInLocalStorage();
  }, [
    screen.sortedValue,
    screen.searchedValue,
    screen.category,
    screen.subCategory,
    screen.site,
    screen.tag,
    screen.price,
    screen.reload,
  ]);

  return (
    <BodyLayout scrollButton="show">
      <div
        className={`ownAFossilSection ${
          detailsState.hasError === true ? "center" : ""
        } pt-xl-5 position-relative`}
      >
        {detailsState.isFetching === true && screen.data === null && (
          <Loading />
        )}
        {detailsState.isFetching === true &&
          screen.data !== null &&
          screen.reload !== true && <OverLayLoading />}
        {screen.overlay === true && <OverLayLoading />}
        <div className="yellowBox"></div>
        {detailsState.hasError === true && screen.data === null && (
          <Row>
            <Col>
              <FetchError />
            </Col>
          </Row>
        )}

        {detailsState.hasError !== true && (
          <>
            <Row className=" uniPadding pt-xl-5 pt-md-5">
              <Col className="ps-xl-4 ps-md-4">
                <Row className="pb-0 pt-xl-2 ">
                  <Col xl={3} xs={12} md={4}>
                    <div className="title">Own a Fossil </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {detailsState && detailsState.data && (
              <>
                <Row className=" uniPadding pt-0">
                  <Col className="ps-4">
                    <Row
                      className={`uniPaddingOwnAFossil mt-md-2  hidOnMobile filterRow innerSection2 pt-0  `}
                    >
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="pt-1 pe-xl-0 filters"
                      >
                        <div className="searchBox me-xl-4 me-md-4">
                          <input
                            id="search"
                            name="search"
                            placeholder="search for a fossil"
                            type="text"
                            onKeyDown={handleKeyPress}
                            onChange={handleChange}
                            value={search}
                          />
                          <div
                            className="icon center"
                            onClick={() => handleSearchSubmit(search)}
                          >
                            <AiOutlineSearch className="searchIcon" />
                          </div>
                        </div>

                        <>
                          <Formik
                            initialValues={{
                              category: screen.category ? screen.category : "",
                              subCategory: screen.subCategory
                                ? screen.subCategory
                                : "",
                              site: screen.site ? screen.site : "",
                              tag: screen.tag ? screen.tag : "",
                              price: screen.price ? screen.price : "",
                              sort: screen.sortedValue
                                ? screen.sortedValue
                                : "",
                            }}
                            onSubmit={async (values) => {
                              setScreen((screen) => ({
                                ...screen,
                                sortedValue: values.sort,
                              }));
                              // handleFormSubmit(values);
                            }}
                          >
                            {(form) => (
                              <>
                                <div
                                  className={`filterBox sort me-xl-4 me-md-4${
                                    screen.sortShow === true ? " viewAll " : ""
                                  } center`}
                                  onClick={() =>
                                    setScreen((screen) => ({
                                      ...screen,
                                      sortShow: !screen.sortShow,
                                    }))
                                  }
                                >
                                  <CMSFormSelect
                                    name="sort"
                                    placeholder={{
                                      value: " ",
                                      label: "sort by",
                                    }}
                                    selected={screen.sortedValue}
                                    form={form}
                                    onChange={(e) => {
                                      form.handleChange(e);
                                      form.handleSubmit(e);
                                    }}
                                    filter={true}
                                    options={sortArray}
                                  />
                                </div>
                              </>
                            )}
                          </Formik>

                          <div
                            className={`filterBox ${
                              screen.filterShow === true ? "viewAll  " : ""
                            }  center`}
                            onClick={() =>
                              setScreen((screen) => ({
                                ...screen,
                                filterShow: !screen.filterShow,
                              }))
                            }
                          >
                            <div className="arrow-down"></div>
                            view all filters
                          </div>
                        </>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mobileFilters pb-3 pt-3 uniPadding center showOnMobile">
                  <Col
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="pt-1  filters "
                  >
                    <div className="mobileSearchComponent">
                      <div
                        className={`searchBox ${
                          screen.expandSearchField ? "expand" : ""
                        }`}
                      >
                        <input
                          id="search"
                          name="search"
                          placeholder="search for a fossil"
                          type="text"
                          onChange={handleChange}
                          value={search}
                        />
                        <div
                          className="icon center"
                          onClick={
                            screen.expandSearchField
                              ? () => handleSearchSubmit(search)
                              : () =>
                                  setScreen((screen) => ({
                                    ...screen,
                                    expandSearchField:
                                      !screen.expandSearchField,
                                  }))
                          }
                          // onClick={() => handleSearchSubmit(search)}
                        >
                          <AiOutlineSearch className="searchIcon" />
                        </div>
                        {screen.expandSearchField && (
                          <div
                            className="icon center"
                            onClick={() =>
                              setScreen((screen) => ({
                                ...screen,
                                expandSearchField: !screen.expandSearchField,
                              }))
                            }
                            // onClick={() => handleSearchSubmit(search)}
                          >
                            <MdClose className="searchIcon" />
                          </div>
                        )}
                      </div>
                    </div>

                    <>
                      {screen.showModal === true && (
                        <ModalFilter
                          show={screen.showModal}
                          onHide={() =>
                            setScreen((screen) => ({
                              ...screen,
                              showModal: false,
                            }))
                          }
                        >
                          <div className="filterExpandedMobile pt-3">
                            <div className="filterContentMobile">
                              <div className="mobileFilterTop">
                                <div
                                  title={
                                    screen.filterShow === false
                                      ? "Please click to view filters"
                                      : "Please click to close filters"
                                  }
                                  className={`filterBox ${
                                    screen.expandSearchField === true
                                      ? "expand"
                                      : ""
                                  } ${
                                    screen.showModal === true
                                      ? "viewAllMobile "
                                      : ""
                                  } center`}
                                  onClick={() =>
                                    setScreen((screen) => ({
                                      ...screen,
                                      showModal: !screen.showModal,
                                    }))
                                  }
                                >
                                  <div className="arrow-down"></div>
                                  view all filters
                                </div>
                                <div
                                  className="icon center"
                                  onClick={() =>
                                    setScreen((screen) => ({
                                      ...screen,
                                      showModal: false,
                                    }))
                                  }
                                  // onClick={() => handleSearchSubmit(search)}
                                >
                                  <MdClose className="filterCloseIconMobile" />
                                </div>
                              </div>

                              {detailsState &&
                                detailsState.data &&
                                detailsState.data.categories &&
                                detailsState.data.subCategories &&
                                detailsState.data.fossilSites && (
                                  <OwnAFossilFilters
                                    onSubmit={handleFormSubmit}
                                    parentState={setScreen}
                                    screen={screen}
                                    sortArray={sortArray}
                                    search={search}
                                    setSearch={setSearch}
                                    onClick={handleClear}
                                    categories={
                                      detailsState &&
                                      detailsState.data &&
                                      detailsState.data.categories
                                    }
                                    subCategories={
                                      detailsState &&
                                      detailsState.data &&
                                      detailsState.data.subCategories
                                    }
                                    fossilSites={
                                      detailsState &&
                                      detailsState.data &&
                                      detailsState.data.fossilSites
                                    }
                                    priceRange={
                                      detailsState &&
                                      detailsState.data &&
                                      detailsState.data.priceRange
                                    }
                                    moreFilters={
                                      detailsState &&
                                      detailsState.data &&
                                      detailsState.data.moreFilters
                                    }
                                  />
                                )}
                            </div>
                          </div>
                        </ModalFilter>
                      )}

                      <div
                        title={
                          screen.filterShow === false
                            ? "Please click to view filters"
                            : "Please click to close filters"
                        }
                        className={`filterBox ${
                          screen.expandSearchField === true ? "expand" : ""
                        } ${
                          screen.filterMobileShow === true ? "viewAll  " : ""
                        } center`}
                        onClick={() =>
                          setScreen((screen) => ({
                            ...screen,
                            showModal: !screen.showModal,
                          }))
                        }
                      >
                        <div className="arrow-down"></div>
                        view all filters
                      </div>
                    </>
                  </Col>
                </Row>
              </>
            )}

            {detailsState && detailsState.data && (
              <Row className=" uniPadding pt-xl-4 pt-lg-4 pt-sm-3 mt-2 pt-md-4 pb-xl-3 pb-lg-3 pb-sm-3 pb-md-3 pb-4 pt-2">
                <Col
                  xl={screen.filterShow ? 9 : 12}
                  lg={screen.filterShow ? 8 : 12}
                  md={screen.filterShow ? 7 : 12}
                  sm={12}
                  xs={12}
                  className="ps-xl-4 ps-md-4"
                >
                  {/* applied filters display start */}
                  {screen.category ||
                  screen.subCategory.length > 0 ||
                  screen.site.length > 0 ||
                  screen.tag.length > 0 ||
                  screen.price ? (
                    <AppliedFiltersOwnAFossil
                      screen={screen}
                      setParentScreen={setScreen}
                    />
                  ) : null}
                  {/* applied filters display end */}

                  {detailsState &&
                    detailsState.isFetching === false &&
                    detailsState.data && (
                      <>
                        {screen.searchedValue !== "" && (
                          <ShowingSearchResultsDialog
                            value={screen.searchedValue}
                            onClick={handleClear}
                            count={
                              detailsState &&
                              detailsState.data &&
                              detailsState.data.pagination &&
                              detailsState.data.pagination.totalFilteredItems
                            }
                          />
                        )}
                      </>
                    )}
                  {detailsState &&
                    detailsState.isFetching === false &&
                    detailsState.data &&
                    detailsState.data.items &&
                    detailsState.data.items.length <= 0 && <NoItemsFound />}
                  <Row className={`pt-2 ${screen.filterShow ? "" : "pe-2"}`}>
                    {screen.data &&
                      screen.data.map((item) => {
                        return (
                          <>
                            <Col
                              xl={screen.filterShow ? 4 : 3}
                              lg={screen.filterShow ? 6 : 4}
                              md={screen.filterShow ? 10 : 6}
                              sm={12}
                              xs={12}
                              className="mb-5 mt-1 slideAnimation"
                              key={item.id}
                            >
                              <ProductCard
                                product={item}
                                parent={screen}
                                parentState={setScreen}
                                onClick={saveFilterInLocalStorage}
                                page=""
                              />
                            </Col>
                          </>
                        );
                      })}
                  </Row>

                  {parseInt(
                    detailsState &&
                      detailsState.data &&
                      detailsState.data.pagination &&
                      detailsState.data.pagination.currentPage
                  ) <
                    parseInt(
                      detailsState &&
                        detailsState.data &&
                        detailsState.data.pagination &&
                        detailsState.data.pagination.pageCount
                    ) && (
                    <div
                      className="loadMore pt-0 pb-4 mb-1"
                      onClick={loadMoreItems}
                    >
                      {screen.loadMore === true ? (
                        <div className="spinner-container center">
                          <div className="loading-spinner"></div>
                        </div>
                      ) : (
                        <span>show more fossils</span>
                      )}
                    </div>
                  )}
                </Col>
                <Col className="rightFilters ">
                  {screen.filterShow === true && (
                    <div className={`filterContent ownAFossil`}>
                      <OwnAFossilFilters
                        onSubmit={handleFormSubmit}
                        parentState={setScreen}
                        screen={screen}
                        sortArray={[]}
                        search={search}
                        setSearch={setSearch}
                        onClick={handleClear}
                        categories={
                          window.innerWidth > 767
                            ? detailsState &&
                              detailsState.data &&
                              detailsState.data.categories
                            : []
                        }
                        subCategories={
                          window.innerWidth > 767
                            ? detailsState &&
                              detailsState.data &&
                              detailsState.data.subCategories
                            : []
                        }
                        fossilSites={
                          window.innerWidth > 767
                            ? detailsState &&
                              detailsState.data &&
                              detailsState.data.fossilSites
                            : []
                        }
                        moreFilters={
                          window.innerWidth > 767
                            ? detailsState &&
                              detailsState.data &&
                              detailsState.data.moreFilters
                            : []
                        }
                        priceRange={
                          window.innerWidth > 767
                            ? detailsState &&
                              detailsState.data &&
                              detailsState.data.priceRange
                            : []
                        }
                      />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </BodyLayout>
  );
};

export default OwnAFossil;
