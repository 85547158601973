import React, { useState, useReducer, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiFillCloseCircle } from "react-icons/ai";
import "./cartItemDetails.scss";
import parse from "html-react-parser";
import { Row, Col } from "react-bootstrap";
import CustomIcon from "../../../shared/customIcon/CustomIcon";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import { BsFillBagCheckFill, BsDownload } from "react-icons/bs";
const CartItemDetails = (props) => {
  const [screen, setScreen] = useState({
    data: null,
    swiperIndex: 0,
    showMobileSwiper: false,
  });

  return (
    <Modal
      show={props.show}
      fullscreen={true}
      className="center"
      aria-labelledby="center swiper"
      centered
    >
      <Modal.Body>
        <div
          className={`closeContainer ${
            screen.showMobileSwiper ? "two" : ""
          }  my-xl-3  my-lg-5 my-md-5  mb-3 `}
        >
          {screen.showMobileSwiper && (
            <CustomIcon
              icon="leftArrow"
              size={48}
              className="backIconWhite "
              onClick={() => {
                setScreen({ ...screen, showMobileSwiper: false });
              }}
            />
          )}
          <div className="closeContainerInside">
            <p>Close</p>
            <CustomTooltip title="Click to close" position="top">
              <CustomIcon
                icon="plus"
                size={48}
                className="closeIconWhite "
                onClick={props.onHide}
              />
            </CustomTooltip>
          </div>
        </div>

        <div
          className={`expandedDiv ${
            screen.showMobileSwiper ? "mobileView" : ""
          } `}
        >
          <Row className="">
            <Col xl={12} lg={12} md={12} className="">
           
              <div className="detailsHolder">
                <div className="tagDisplay d-flex">
                  {props.item.discounted > 0 && (
                    <div className={`tag two`}>Discounted</div>
                  )}
                  {props.item.exclusiveItem === "1" && (
                    <div className={`tag one`}>Exclusive Item</div>
                  )}
                </div>

                <div className="detailsDisplay ">
                  <div className="leftSection">
                    <Col>
                    {props.item.discounted > 0 && (
                              <div className="offDisplay">
                                <span className="number">
                                  {Math.round(
                                    props.item.discounted
                                  )}
                                  % off
                                </span>
                              </div>
                            )}
                      <p className="fossilName">{props.item.genus}</p>
                      {/* <p className="fossilCode">ABC1X5</p> */}
                      {props.item.oldPrice && props.item.oldPrice !== undefined ? (
                        <p className="fossilPrice">
                          {props.item.price}${" "}
                          <sup className="priceOffer">{props.item.oldPrice}$</sup>
                        </p>
                      ) : (
                        <p className="fossilPrice">{props.item.price}$</p>
                      )}
                      <hr className="redLine " />
                    </Col>

                    <Row className="detailsRow">
                    <Col xl={5} lg={5} md={6} sm={12} xs={12}>
              
                                <div className="mt-2">
                                  {props.item.code && (
                                    <>
                                      <p className="fossilSubHead">Item Code</p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {props.item.code}
                                      </p>
                                    </>
                                  )}
                                  {props.item.category && (
                                    <>
                                      <p className="fossilSubHead">Category</p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {props.item.category}
                                      </p>
                                    </>
                                  )}
                                  {props.item.subCategory && (
                                    <>
                                      <p className="fossilSubHead">Subcategory</p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {props.item.subCategory}
                                      </p>
                                    </>
                                  )}
                                  {props.item.site && (
                                    <>
                                      <p className="fossilSubHead">Site</p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {props.item.site}
                                      </p>
                                    </>
                                  )}
                                  {props.item.ageOrOriginDate && (
                                    <>
                                      <p className="fossilSubHead">
                                        Age / Origin date
                                      </p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {props.item.ageOrOriginDate}
                                      </p>
                                    </>
                                  )}
                                 
                                  {props.item.dimension && (
                                    <>
                                      <p className="fossilSubHead">
                                        Dimension{" "}
                                      </p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {props.item.dimension}
                                      </p>
                                    </>
                                  )}
                                    
                                   {props.item.matrix && (
                                    <>
                                      <p className="fossilSubHead">
                                        Matrix{" "}
                                      </p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {props.item.matrix}
                                      </p>
                                    </>
                                  )}
                                </div>
                              </Col>

                      <Col xl={7} lg={7} md={6} sm={12} xs={12}>
                        {props.item.description && 
                        <p className="fossilDetails mt-0 mb-1">
                          {parse(props.item.description)}
                        </p>
                        }
                        {props.item.relatedMaterial && (
                          <>
                            <a className="fossilSubHead orange mt-2">
                              Related Material
                              <span title="Click to Download">
                                <BsDownload className="ms-2 orange icon" />
                              </span>
                            </a>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>

                  <div className="rightSection position-relative">
                    <img
                     src={
                      process.env.REACT_APP_BACKEND_URL +
                      "public/" +
                      props.item.image
                    }
                      className="rightImage"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CartItemDetails;
