import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CustomTooltip = (props) => {
  return (
    <OverlayTrigger className="toolTipOverlay"
      placement={props.position ? props.position : "right"}
      delay={{ show: 250, hide: 150 }}
      overlay={<Tooltip className="toolTip">{props.title}</Tooltip>}
    >
      <span>{props.children}</span>
    </OverlayTrigger>
  );
};

export default CustomTooltip;
