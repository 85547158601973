import React, { useState, useReducer, useEffect } from "react";
import BodyLayout from "../../layout/body/BodyLayout";
import "./checkout.scss";
import { Formik } from "formik";
import { Row, Col } from "react-bootstrap";
import CMSFormRadioButtons from "../../shared/formFields/CMSFormRadioButtons";
import ShippingForm from "./partials/ShippingForm";
import StoreAddress from "./partials/StoreAddress";
import OrderDisplay from "./partials/orderDetails/OrderDisplay";
import { Link, useParams } from "react-router-dom";
import { CiEdit } from "react-icons/ci";
import CustomTooltip from "../../shared/tooltip/CustomTooltip";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdError } from "react-icons/md";
import {
  updateInitialState,
  updateReducer,
} from "../../shared/reducers/updateReducer";
import axiosInstance from "../../../config/axios";
import { Loading, OverLayLoading } from "../../shared/loading/Loading";
import {
  screenInitialState,
  screenReducer,
} from "../../shared/reducers/screenReducer";
import CMSFormRadioButtonsSubmit from "../../shared/formFields/CMSFormRadioButtonsSubmit";
const Checkout = () => {
  const [screen, setScreen] = useState({
    payment: "Online",
    reload: false,
    orderDisplayReload: false,
    delivery: "Delivery",
    shippingAddress: null,
    cart: null,
    orderStatus: "",
    orderId: "1112223333",
  });
  const { transactionId } = useParams();
  const saved = localStorage.getItem("BuyerId");
  const buyerId = JSON.parse(saved);
  console.log(buyerId);
  const [paymentState, paymentDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [deliveryState, deliveryDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  useEffect(() => {
    document.title = "Checkout";
  }, []);

  const handleSubmitDeliveryType = async (data) => {
    deliveryDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axiosInstance.post(
        `cart/change-delivery-method `,
        data
      );
      // window.scrollTo(0, 0);
      deliveryDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      deliveryDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
    }
  };
  const handleSubmit = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    const saved = localStorage.getItem("BuyerId");
    const buyerId = JSON.parse(saved);

    try {
      const res = await axiosInstance.post(`cart/`, {
        buyerId: buyerId,
      });

      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data.deliveryMethod, "cart function");
    
      setScreen((prevState) => ({
        ...prevState,
        cart: res.data,
        delivery: res.data.deliveryMethod?res.data.deliveryMethod:"Delivery",
      }));
      if(!res.data.deliveryMethod){
        handleSubmitDeliveryType({ buyerId: buyerId,
          transactionId: transactionId,
          deliveryMethod:"Delivery",});
      }
     
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    handleSubmit();
    if (screen.reload === true) {
      setTimeout(() => {
        setScreen((prevState) => ({
          ...prevState,
          reload: false,
        }));
      }, 500);
    }
  }, [screen.payment, screen.delivery]);
useEffect(() => {

}, [])

  return (
    <BodyLayout>
      <div
        className={`checkoutSection ${
          screen.orderStatus !== "" ? "center" : ""
        } `}
      >
        <div className="yellowBox"></div>
        {detailsState.isFetching === true && <OverLayLoading />}
        {deliveryState.isSubmitting === true && <OverLayLoading />}
        {screen.orderStatus === "success" && (
          <div className="center flex-column msgBody">
            <AiFillCheckCircle className="submitSuccessIcon theme2" />
            <h2 className="submitSuccessHead theme2">Thank you!</h2>
            <p className="submitSuccessPara theme2">
              Your order has been received and will be processed soon.
            </p>

            <p className="submitSuccessPara theme2">
              Your order number is: <strong>{screen.orderId}</strong>
            </p>
            <Link to="/own-a-fossil" className="link">
              CONTINUE SHOPPING
            </Link>
            <div className="additionalMsgAfterSubmission">
              <div>
                <p>
                  We have sent an invoice with your order's details on your
                  registered email address.
                </p>
                <p>
                  If you do not receive the message within a few minutes of your
                  order, please check your Bulk Mail / Spam / Junk folders just
                  in case the email got delivered there instead of your inbox.{" "}
                </p>
              </div>
              <p>
                if you have any query, Kindly{" "}
                <Link to="/contact" className="link">
                  Contact us{" "}
                </Link>
              </p>
            </div>
          </div>
        )}
        {screen.orderStatus === "error" && (
          <div className="center flex-column msgBody">
            <MdError className="submitSuccessIcon theme2" />
            <h2 className="submitSuccessHead theme2">Error!</h2>
            <p className="submitSuccessPara theme2">Something went wrong ...</p>

            <p
              className="submitSuccessPara theme2"
              onClick={() => setScreen({ ...screen, orderStatus: "" })}
            >
              Try again
            </p>
          </div>
        )}
        {screen.orderStatus === "" && (
          <Row className="mx-auto position-relative w-100 py-4">
            <Col xl={12} className="py-xl-5 py-lg-4 py-md-3 py-sm-3 py-3">
              <Row className="uniPadding  pt-0">
                <Col xl={12} className="pt-xl-3">
                  <div className="headContainer">
                    <h3 className="checkoutText" id="title">
                      Checkout
                    </h3>
                    <hr className="yellowLine" />
                  </div>
                </Col>
              </Row>
              <Row className="uniPadding innerSection2 pt-0">
                <Col xl={11} lg={12} md={12} className="pt-xl-0 ">
                  {/* <div className="paymentType p-4 mb-3">
                    <p className="sectionHeading">
                      Please select your preferred payment method.
                    </p>
                    {screen.cart && (
                      <Formik
                        initialValues={{
                          buyerId: buyerId,
                          paymentMethod: screen.cart.paymentMethod
                            ? screen.cart.paymentMethod
                            : "",
                        }}
                        onSubmit={async (values) => {
                          handleSubmitPaymentType(values);
                          setScreen({
                            ...screen,
                            payment: values.paymentMethod,
                          });
                        }}
                      >
                        {(form) => (
                          <>
                            <CMSFormRadioButtonsSubmit
                              name="paymentMethod"
                              form={form}
                              label=""
                           
                              options={[
                                { label: "Online Payment", value: "Online" },
                                { label: "Cash on Delivery", value: "COD" },
                                { label: "Cash in Store", value: "CIS" },
                              ]}
                            />
                          </>
                        )}
                      </Formik>
                    )}
                  </div> */}
                  {screen.payment === "Online" && (
                    <div className="paymentType p-4 mb-3">
                      <p className="sectionHeading">
                        Please select your preference.
                      </p>
                      <div class="ps-xl-3 ms-xl-1">
                      {screen.cart && (
                        <Formik
                          initialValues={{
                            buyerId: buyerId,
                            transactionId: transactionId,
                            deliveryMethod: screen.cart.deliveryMethod
                              ? screen.cart.deliveryMethod
                              : "Delivery",
                          }}
                          onSubmit={async (values) => {
                            handleSubmitDeliveryType(values);
                            setScreen({
                              ...screen,
                              delivery: values.deliveryMethod,
                            });
                          }}
                        >
                          {(form) => (
                            <>
                              <CMSFormRadioButtonsSubmit
                                name="deliveryMethod"
                                form={form}
                                label=""
                                onChange={(e) => {
                                  form.handleChange(e);
                                  form.handleSubmit(e);
                                }}
                                options={[
                                  {
                                    label:
                                      "Receive the product at delivery address",
                                    value: "Delivery",
                                  },
                                  {
                                    label: "Pick the product from our shop",
                                    value: "Pickup",
                                  },
                                ]}
                              />
                            </>
                          )}
                        </Formik>
                      )}
                      </div>
                      

                    
                    </div>
                  )}

                  <div className="paymentType p-4 mb-3">
                    {screen.delivery === "Delivery" || screen.delivery === "delivery"  ? (
                      <>
                        <p className="sectionHeading pt-xl-2">
                          Shipping Address
                        </p>

                        <ShippingForm
                          parent={screen}
                          parentState={setScreen}
                          transactionId={transactionId}
                        />
                      </>
                    ) : (
                      <>
                        <p className="sectionHeading">Store Address</p>

                        <StoreAddress />
                      </>
                    )}
                  </div>
                  
                  <div className="paymentType order p-4">
                    <p className="sectionHeading mb-0">Order details</p>
                    <Link
                      to="/cart"
                      className="end mb-xl-3 mb-lg-3 mb-md-3 mb-sm-3  mb-3 me-1 mt-xl-0 mt-lg-0 mt-sm-0 mt-md-0 mt-3"
                    >
                      <span>
                        <CiEdit className="me-2 icon" />
                        Edit your cart
                      </span>
                    </Link>
                    <OrderDisplay
                      paymentMethod={screen.payment}
                      deliveryMethod={screen.delivery}
                      parent={screen}
                      parentState={setScreen}
                      transactionId={transactionId}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </BodyLayout>
  );
};

export default Checkout;
