import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import image from "../../../../../assets/images/testimonialImage.png";
import dummy from "../../../../../assets/images/dummyIcon.png";
// import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Autoplay } from 'swiper';
// import "swiper/scss";
// import "swiper/scss/pagination";
// import 'swiper/scss/autoplay';
// import { Pagination } from "swiper";
import "./testimonial.scss";
import EmailSubmit from "./EmailSubmit";
import AddTestimonial from "./AddTestimonial";
import CustomIcon from "../../../../shared/customIcon/CustomIcon";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
import {
  screenInitialState,
  screenReducer,
} from "../../../../shared/reducers/screenReducer";
import { FetchError } from "../../../../shared/displayAlerts/DisplayAlerts";
import axiosInstance from "../../../../../config/axios";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/scss";
import "swiper/scss/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";

// testimonial swiper section
function Testimonial() {
  const handleOnError = (e) => {
    e.target.src = dummy;
  };
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );

  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(
        `testimonials/home-page-testimonials`
      );
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
  }, []);

  return (
    <>
      {/* for mobile and tablet */}
      <div className="hideOnDesktop uniPadding">
        <Row className="px-4 spaceBetween w-100 ">
          <Col xs={12} sm={12} md={6} xl={6} className=" h-100">
            <Swiper
              autoplay={{
                delay: 7500,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              loop
              pagination={{ clickable: true }}
              modules={[Pagination, Autoplay]}
              className="testimonialSwiper"
            >
              {detailsState.isFetching && (
                <SwiperSlide>
                  <div className="testimonial">
                    <div className="testimonialImageHolder skeleton"></div>
                    <h1 className="name start skeleton"></h1>
                    <h4 className="company skeleton mb-5"></h4>
                    <div className="testimonialMessage mb-1  skeleton"></div>
                    <div className="testimonialMessage mb-1 skeleton"></div>
                    <div className="testimonialMessage mb-1 skeleton"></div>
                    <div className="testimonialMessage mb-1 skeleton"></div>
                  </div>
                </SwiperSlide>
              )}
              {detailsState && detailsState.data && detailsState.data.items && (
                <>
                  {detailsState.data.items.map((item) => {
                    return (
                      <SwiperSlide key={item.id}>
                        <div className="testimonial">
                          <div className="testimonialImageHolder">
                            <img
                              src={
                                process.env.REACT_APP_BACKEND_URL +
                                "public/" +
                                item.image
                              }
                              className="testimonialImage"
                              onError={handleOnError}
                              alt="Image"
                            />
                          </div>
                          <h1 className="name start">
                            {" "}
                            {item.title} {item.firstName} {item.lastName}
                          </h1>
                          <h4 className="company">
                            {item.designation}{" "}
                            {item.designation &&
                              item.company &&
                              "," + item.company}
                          </h4>
                          {item.message.length > 85 ? (
                            <div className="testimonialMessage">
                              <p className="mb-0 showOnTablet">
                                "{item.message.slice(0, 85)}..."{" "}
                                <CustomTooltip title="Click to read more">
                                  <Link
                                    className="addTestimonialText mb-0"
                                    to="/testimonial"
                                    onClick={() =>
                                      localStorage.setItem(
                                        "currentActiveIdTestimonial",
                                        JSON.stringify(item._id)
                                      )
                                    }
                                  >
                                    <CustomIcon
                                      icon="plus"
                                      size={20}
                                      className="readMore"
                                    />
                                  </Link>
                                </CustomTooltip>
                              </p>
                              <p className="mb-0 showOnMobile">
                                "{item.message.slice(0, 72)}..."{" "}
                                <CustomTooltip title="Click to read more">
                                  <Link
                                    className="addTestimonialText mb-0"
                                    to="/testimonial"
                                    onClick={() =>
                                      localStorage.setItem(
                                        "currentActiveIdTestimonial",
                                        JSON.stringify(item._id)
                                      )
                                    }
                                  >
                                    <CustomIcon
                                      icon="plus"
                                      size={20}
                                      className="readMore"
                                    />
                                  </Link>
                                </CustomTooltip>
                              </p>
                            </div>
                          ) : (
                            <div className="testimonialMessage">
                              <p className="mb-0">"{item.message}"</p>
                            </div>
                          )}
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </>
              )}
            </Swiper>
            <div className="showOnMobile iconArea mb-4 pb-3">
              <div className="d-flex flex-column ">
                <Link className="addTestimonialText mb-0" to="/add-testimonial">
                  add testimonial
                </Link>

                <Link
                  className="addTestimonialText mt-4  mb-0 me-xl-1"
                  to="/testimonial"
                >
                  View all
                </Link>
              </div>

              <CustomIcon
                icon="comment"
                size={90}
                className="messageIcon hideOnDesktop"
              />
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            xl={3}
            className=" newsLetterSection mb-5 p-0 h-100"
          >
            <Row>
              <Col>
                <div className="blueLine"></div>
              </Col>
              <Col md={9} className="textArea flex-column">
                <div className="textSection">
                  <p className="newsletterLargeText mb-0">Keep up to date</p>
                  <p className="newsletterSmallText">Join our newsletter</p>

                  <div className="w-100">
                    <EmailSubmit />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* for desktop */}
      <div className="showOnDesktop">
        <Row className="uniPadding   w-100 center testimonialRow ">
          <Col xs={12} sm={12} md={6} xl={9} lg={9} className="testimonialCol">
            <Row>
              <Col
                xs={12}
                sm={12}
                md={6}
                xl={4}
                lg={0}
                className="threeIconSection"
              >
                <CustomIcon
                  icon="comment"
                  className="largeRedBorder"
                  size={106}
                />

                <CustomIcon
                  icon="commentLeft"
                  className="mediumCreamBorder"
                  size={107}
                />
                <CustomIcon
                  icon="commentFill"
                  className="smallCreamBackground"
                  size={77}
                />
              </Col>
              <Col xs={12} sm={12} md={6} xl={6} lg={10} className="p-0 h-100">
                {/* <Swiper
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              loop
              
              pagination={{ clickable: true }}
              modules={[Pagination,Autoplay]}
              className="testimonialSwiper"
            >
         
            </Swiper> */}
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 5500,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination]}
                  className="testimonialSwiper"
                >
                  {detailsState.isFetching && (
                    <SwiperSlide>
                      <div className="testimonial">
                        <div className="nameAndImage">
                          <div className="w-75">
                            <h1 className="name skeleton"></h1>
                            <h4 className="company skeleton"></h4>
                          </div>

                          <div className="testimonialImageHolder skeleton"></div>
                        </div>

                        <div className="testimonialMessage mb-1 skeleton"></div>
                        <div className="testimonialMessage mb-1 skeleton"></div>
                        <div className="testimonialMessage mb-1 skeleton"></div>
                        <div className="testimonialMessage mb-1 skeleton"></div>
                      </div>
                    </SwiperSlide>
                  )}
                  {detailsState &&
                    detailsState.data &&
                    detailsState.data.items && (
                      <>
                        {detailsState.data.items.map((item, index) => {
                          return (
                            <SwiperSlide key={item._id}>
                              <div className="testimonial">
                                <div className="nameAndImage">
                                  <div className="nameHolder">
                                    <h1 className="name">
                                      {item.title} {item.firstName}{" "}
                                      {item.lastName}
                                    </h1>
                                    <h4 className="company">
                                      {item.designation}{" "}
                                      {item.designation &&
                                        item.company &&
                                        "," + item.company}
                                    </h4>
                                  </div>

                                  <div className="testimonialImageHolder">
                                    <img
                                      src={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "public/" +
                                        item.image
                                      }
                                      className="testimonialImage"
                                      onError={handleOnError}
                                      alt="Image"
                                    />
                                  </div>
                                </div>

                                {item.message.length > 110 ? (
                                  <div className="testimonialMessage">
                                    <p className="mb-0">
                                      "{item.message.slice(0, 110)}... "{" "}
                                      <CustomTooltip title="Click to read more">
                                        <Link
                                          className="addTestimonialText mb-0"
                                          to="/testimonial"
                                          onClick={() =>
                                            localStorage.setItem(
                                              "currentActiveIdTestimonial",
                                              JSON.stringify(item._id)
                                            )
                                          }
                                        >
                                          <CustomIcon
                                            icon="plus"
                                            size={20}
                                            className="readMore"
                                          />
                                        </Link>
                                      </CustomTooltip>
                                    </p>
                                  </div>
                                ) : (
                                  <div className="testimonialMessage">
                                    <p className="mb-0">"{item.message}"</p>
                                  </div>
                                )}
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </>
                    )}
                  {detailsState.hasError && (
                    <SwiperSlide>
                      <FetchError />
                    </SwiperSlide>
                  )}
                </Swiper>

                <div className="iconArea">
                  <Link className="addTestimonialText mb-0" to="/testimonial">
                    add testimonial
                  </Link>

                  <Link className="addTestimonialText mb-0" to="/testimonial">
                    View all
                  </Link>
                </div>
              </Col>
              <Col lg={2} className="blueLineCol">
                <div className="blueLine"></div>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            xl={3}
            lg={3}
            className=" newsLetterSection p-0  h-100"
          >
            <div className="textSection">
              <p className="newsletterLargeText mb-0">Keep up to date</p>
              <p className="newsletterSmallText">Join our newsletter</p>

              <div className="w-100">
                <EmailSubmit />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Testimonial;
