import React, { useState, useEffect, useReducer } from "react";
import BodyLayout from "../../layout/body/BodyLayout";
import "./cart.scss";
import { Row, Col } from "react-bootstrap";
import CartListItem from "./cartListItem/CartListItem";
import CheckoutType from "./checkoutType/CheckoutType";
import { Link } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import CustomTooltip from "../../shared/tooltip/CustomTooltip";
import {
  CartEmpty,
  FetchErrorLight,
  FormSubmitSuccess,
} from "../../shared/displayAlerts/DisplayAlerts";
import axiosInstance from "../../../config/axios";
import {
  screenInitialState,
  screenReducer,
} from "../../shared/reducers/screenReducer";
import { Loading, OverLayLoading } from "../../shared/loading/Loading";
import OrderSuccessMessage from "../../shared/orderMessage/OrderSuccessMessage";
const CartDisplay = () => {
  const [screen, setScreen] = useState({
    orderByWhatsapp: false,
    payOnline: false,
    noItems: false,
    deleted: false,
    orderId: null,
    status: "",
  });

  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  useEffect(() => {
    localStorage.removeItem("newsFiltersApplied");
    localStorage.removeItem("newsScreenValues");
    localStorage.removeItem("motFiltersApplied");
    localStorage.removeItem("motScreenValues");
    localStorage.removeItem("ownAFossilFiltersApplied");
    localStorage.removeItem("ownAFossilScreenValues");
    localStorage.removeItem("mediaFiltersApplied");
    localStorage.removeItem("mediaScreenValues");
    localStorage.removeItem("pressFiltersApplied");
    localStorage.removeItem("pressScreenValues");
    localStorage.removeItem("publicationFiltersApplied");
    localStorage.removeItem("publicationScreenValues");
    localStorage.removeItem("currentActiveIdNews");
    localStorage.removeItem("currentActiveIdTestimonial");
  }, []);
  // useEffect(() => {
  //   if (screen.orderByWhatsapp === true) {
  //     setTimeout(() => {
  //       setScreen({
  //         ...screen,
  //         orderByWhatsapp: true,
  //       });
  //     }, 30000);
  //   }
  // }, [screen.orderByWhatsapp]);
  const handleSubmit = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    const saved = localStorage.getItem("BuyerId");
    const buyerId = JSON.parse(saved);
    try {
      const res = await axiosInstance.post(`cart/`, {
        buyerId: buyerId,
      });

      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      localStorage.setItem(
        "cartItemsCount",
        JSON.stringify(res.data.items.length)
      );
      console.log(res.data);
      setScreen((prevState) => ({
        ...prevState,
        data: res.data,
      }));
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    handleSubmit();
    if (screen.deleted === true) {
      setTimeout(() => {
        setScreen({
          ...screen,
          deleted: false,
        });
      }, 100);
    }
  }, [screen.deleted, screen.orderId]);
  useEffect(() => {
    document.title = "Cart";
  }, []);

  return (
    <BodyLayout>
      {detailsState.isFetching === true && screen.data === null && <Loading />}
      {detailsState.isFetching === true && screen.data !== null && (
        <OverLayLoading />
      )}
      <div
        className={`cartSection ${
          detailsState &&
          !detailsState.hasError &&
          detailsState.data &&
          detailsState.data.items &&
          detailsState.data.items.length <= 0
            ? "center"
            : ""
        } ${
          screen.orderByWhatsapp === true ||
          screen.payOnline === true ||
          detailsState.hasError === true
            ? "center"
            : ""
        }  `}
      >
        <div className="yellowBox"></div>
        {screen.orderByWhatsapp === true ? (
          <OrderSuccessMessage orderId={screen.orderId} />
        ) : null}

        {detailsState &&
          !detailsState.hasError &&
          screen.orderByWhatsapp === false &&
          screen.payOnline === false &&
          detailsState.data &&
          detailsState.data.items &&
          detailsState.data.items.length > 0 && (
            <Row className="mx-auto position-relative w-100 py-4">
              <Col xl={12} className="py-xl-5 py-lg-4 py-md-3 py-sm-3 py-3">
                <Row className="uniPadding  pt-0">
                  <Col xl={12} className="pt-xl-3">
                    <div className="headContainer">
                      <h3 className="cartText" id="title">
                        Cart
                      </h3>
                      <hr className="yellowLine" />
                    </div>
                  </Col>
                </Row>
                <Row className="uniPadding innerSection2 pt-0">
                  <Col xl={5} lg={6} md={12} className="pt-xl-0 ">
                    {detailsState &&
                      detailsState.data &&
                      detailsState.data.items &&
                      detailsState.data.items.map((item, index) => {
                        return (
                          <CartListItem
                            key={index}
                            item={item}
                            parent={screen}
                            parentState={setScreen}
                          />
                        );
                      })}
                    <div className="rightSide">
                      {detailsState &&
                        detailsState.data &&
                        detailsState.data.invoice && (
                          <div className="orderAmountDisplay  ">
                            <div className="price total">
                              <p>Total</p>
                              <strong>
                                {detailsState.data.invoice.totalAmount} $
                              </strong>
                            </div>
                          </div>
                        )}
                    </div>
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={12}
                    className="pt-xl-0 pt-lg-0 pt-4 pt-md-4 ps-0 "
                  >
                    <CheckoutType
                      parentState={screen}
                      setParentState={setScreen}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

        <Row>
          {detailsState.hasError &&
            screen.orderByWhatsapp === false &&
            screen.payOnline === false && (
              <Col className="py-5">
                <FetchErrorLight />
              </Col>
            )}
          {detailsState &&
            !detailsState.isFetching &&
            !detailsState.hasError &&
            detailsState.data &&
            detailsState.data.items &&
            detailsState.data.items.length <= 0 && (
              <Col className="py-5">
                <CartEmpty />
              </Col>
            )}
        </Row>
      </div>
    </BodyLayout>
  );
};

export default CartDisplay;
