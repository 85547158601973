import React, { useReducer, useEffect } from "react";
import "./terms.scss";
import BodyLayout from "../../layout/body/BodyLayout";
import {
  screenInitialState,
  screenReducer,
} from "../../shared/reducers/screenReducer";
import { Col, Row } from "react-bootstrap";
import { Loading } from "../../shared/loading/Loading";
import axiosInstance from "../../../config/axios";
import parse from "html-react-parser";
const TermsAndConditions = () => {
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`page-content/terms-conditions`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "111");
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
  }, []);
  useEffect(() => {
    document.title = "Terms & Conditions";
  }, []);
  return (
    <BodyLayout>
      <div className={`termsSection `}>
        {" "}
        {detailsState.isFetching === true && <Loading />}
        <div className="yellowBox"></div>
        <Row className="ms-xl-1 position-relative">
          <Col xl={12} className="py-5">
            <Row className="uniPadding innerSection pt-3">
              <Col xl={12} className="pt-xl-4">
                <div className="headContainer">
                  <h3 className="ourHistoryText" id="title">
                    {detailsState.data.heading
                      ? detailsState.data.heading
                      : "Terms & Conditions"}
                  </h3>
                  <hr className="yellowLine" />
                </div>
              </Col>
            </Row>
            <Row className="uniPadding innerSection2 pt-0 pb-xl-5 pb-lg-5 pb-md-0 pb-2">
              <Col xl={12} className="pt-xl-0 pe-xl-4 termsText">
                {detailsState.data.content && parse(detailsState.data.content)}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </BodyLayout>
  );
};

export default TermsAndConditions;
