import { Formik } from "formik";
import React, { useState, useReducer,useEffect } from "react";
import "./testimonial.scss";
import { BsCheckCircleFill } from "react-icons/bs";
import { Button } from "react-bootstrap";
import { emailSchema } from "../../../../../utils/yupValidation";
import CustomIcon from "../../../../shared/customIcon/CustomIcon";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
import {
  updateInitialState,
  updateReducer,
} from "../../../../shared/reducers/updateReducer";
import axiosInstance from "../../../../../config/axios";

// email submit field in newsletter section

const EmailSubmit = () => {
  const [screen, setScreen] = useState();
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const handleSubmit = async (data, resetForm) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    if (screen === "emailAlreadySubmitted") {
      setScreen("");
    }

    try {
      const res = await axiosInstance.post(`subscribe`, data);

      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      if (res.status === 200) {
        setTimeout(() => {
          updateDispatch({
            type: "FORM_INITIAL_STATE",
          });
          //setScreen("");
          //resetForm();
        }, 2000);
        setScreen("emailAlreadySubmitted");
      
      }
      if (res.status === 201) {
        setTimeout(() => {
          updateDispatch({
            type: "FORM_INITIAL_STATE",
          });
          resetForm();
        }, 5000);
      }
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
        //resetForm();
      }, 5000);
    }
  };
  // useEffect(() => {
  //  if(screen==="emailAlreadySubmitted"){
  //   setTimeout(() => {
  //     setScreen("")
  //   }, 8000);
  //  }
  // }, [screen])
  

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={emailSchema}
      onSubmit={(values, { resetForm, actions }) => {
        handleSubmit(values, resetForm);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit} className="form">
          <div
            className={
              (errors.email && touched.email && errors.email) 
                ? "inputFieldBlue  error"
                : "inputFieldBlue "
            }
          >
            <input
              type="email"
              name="email"
              disabled={
                (updateState.hasSubmissionError !== true &&
                  updateState.isSubmitted) ||
                updateState.isSubmitting
              }
              placeholder="Enter your email"
              className="input"
              onChange={(e)=>{handleChange(e);setScreen("")}}
              onBlur={handleBlur}
              value={values.email}
            />
            {updateState.isSubmitted && screen !== "emailAlreadySubmitted" ? (
              <Button
                variant="primary"
                className="center"
                type="submit"
                disabled={true}
              >
                <BsCheckCircleFill className="arrowIcon" />
              </Button>
            ) : (
              <Button
                variant="primary"
                className="center"
                type="submit"
                disabled={updateState.isSubmitting}
              >
                <CustomTooltip
                  title="Click to submit"
                  position="right"
                >
                  <CustomIcon
                    icon="arrowRight"
                    size={25}
                    className="arrowIcon"
                  />
                </CustomTooltip>
              </Button>
            )}
          </div>
          <p className="errorText">
            {errors.email && touched.email && errors.email}
          </p>
          {updateState.isSubmitted === true && screen === "" && (
            <p className="successText">
              Thankyou for joining our newsletter you will receive updates from
              us soon.{" "}
            </p>
          )}
          {screen === "emailAlreadySubmitted" && !errors.email && (
            <p className="errorText">
              This email is already used, please use any other email.
            </p>
          )}
          {updateState.hasSubmissionError && (
            <p className="errorText">
              Something went wrong !!! please try again later.
            </p>
          )}
        </form>
      )}
    </Formik>
  );
};

export default EmailSubmit;
