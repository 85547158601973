import React from "react";
import "./appliedFilter.scss";
import { MdClose } from "react-icons/md";
import CustomTooltip from "../tooltip/CustomTooltip";
const formatSub = (str) => {
  str = str.replaceAll("-", " ");

  const words = str.split(" ");

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  if (words.join(" ").includes(" ")) {
    return words.join(" ");
  } else {
    const string = words.join("").replace(/([a-z])([A-Z])/g, "$1 $2");
    return string;
  }
};
export const AppliedFilter = ({ value, onClick, array, type }) => {
  return (
    <div className="appliedFilter center">
      {type === "price" ? value : formatSub(value)}
      <div
        className="close center "
        onClick={() => onClick(array, value, type)}
      >
        <CustomTooltip title="Remove filter" position="top">
          <MdClose className="filterClose" />
        </CustomTooltip>
      </div>
    </div>
  );
};

export const AppliedFilterSort = ({ value, onClick, theme, price }) => {
  return (
    <div className="appliedFilter center">
      {value === "-1" && price === true && "Price high to low"}
      {value === "1" && price === true && "Price low to high"}
      {value === "-1" && !price === true && "Newest"}
      {value === "1" && !price === true && "Oldest"}
      {value !== "1" && value !== "-1" && value}
      <div
        className={`close center ${theme === "light" ? "light" : ""} `}
        onClick={onClick}
      >
        <CustomTooltip title="Remove filter" position="top">
          <MdClose onClick={onClick} className="filterClose" />
        </CustomTooltip>
      </div>
    </div>
  );
};
