// banner showing in home screen with video and images
import React, { useState, useEffect, useReducer, useRef } from "react";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import fossil from "../../../../../assets/images/bannerDummy.png";
import overlay from "../../../../../assets/images/overlay.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import { Pagination } from "swiper";
import "./banner.scss";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
import {
  screenInitialState,
  screenReducer,
} from "../../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../../config/axios";
import { Link } from "react-router-dom";
import dummyBanner from "../../../../../assets/images/bannerImage.png";
import CustomIcon from "../../../../shared/customIcon/CustomIcon";
function Banner() {
  const [screen, setScreen] = useState({
    status: 0,
    activeIndex: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleVideoLoaded = () => {
    setIsLoading(false);
  };
  // reducer
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  // image error handling
  const handleOnError = (e) => {
    e.target.src = dummyBanner;
  };
  const videoRef = useRef(null);

  useEffect(() => {
    // Check if the video element is loaded
    if (videoRef.current) {
      // Play the video once it's loaded
      videoRef.current.play().catch((error) => {
        // Autoplay was prevented, handle the error
        console.error("Autoplay prevented:", error);
      });
    }
  }, []);

  // function api fetch
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`home-page-banners`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data);
      localStorage.removeItem("closePopOver");
      const saved = localStorage.getItem("closePopOver");
      const close = JSON.parse(saved);
      if (close === null) {
        setTimeout(() => {
          setShow(true);
        }, 4000);
        setTimeout(() => {
          setScreen({
            ...screen,
            activeIndex: 1,
          });
        }, 7000);
        setTimeout(() => {
          setScreen({
            ...screen,
            activeIndex: 2,
          });
        }, 10000);
        setTimeout(() => {
          setShow(false);
          // localStorage.setItem(
          //   "closePopOver",
          //   JSON.stringify(1)
          // );
        }, 13000);
      }
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
  }, []);

  return (
    <>
      {/* for desktop and tablet */}

      <div className="bannerDesktopTablet ">
        <Row className="bannerRow px-0 pb-0 m-0">
          <Col className={`p-0 m-0 bannerCol `}>
            {detailsState.hasError ||
            (detailsState &&
              !detailsState.hasError &&
              detailsState.data &&
              detailsState.data.items &&
              detailsState.data.items.length <= 0) ? (
              <>
                <div className="yellowBox"></div>
                <div
                  className={`whiteLineTab redColor
                  `}
                >
                  <div
                    className={`tabPosition0 
                      redColor`}
                  >
                    <span className="insideContent"></span>
                  </div>
                </div>
                <div
                  className={`firstHolder0 ${
                    screen.status === 0 ? "active" : "notActive"
                  }`}
                >
                  <div className="bannerImageHolder">
                    <div className="centerTextContainer ">
                      <h1 className={`title`}>
                        Welcome to the new website of “Memory of Time”
                      </h1>
                      <hr className="redLine" />
                      <Link to="/own-a-fossil">
                        <div className="link">Know more about us</div>
                      </Link>
                    </div>

                    <>
                      <img
                        src={dummyBanner}
                        onError={handleOnError}
                        alt="bannerImage1"
                        className=" overlay"
                      />
                      <div className=" overlay"></div>
                    </>
                  </div>
                </div>
              </>
            ) : null}

            {detailsState &&
              !detailsState.hasError &&
              detailsState.data &&
              detailsState.data.items &&
              detailsState.data.items.length > 0 &&
              detailsState.data.items.slice(0, 4).map((item, index) => {
                return (
                  <>
                    <div className="yellowBox"></div>
                    <div
                      className={`whiteLineTab ${
                        screen.status === index ? "redColor" : ""
                      }`}
                    >
                      <div
                        className={`tabPosition${index} ${
                          detailsState.data.items.length > 3 ? "change" : ""
                        } ${screen.status === index ? "redColor" : ""}`}
                      >
                        {show &&
                          index !== 0 &&
                          index > screen.status &&
                          screen.activeIndex === index && (
                            <>
                              <div className="customPopover">
                                <div class="arrow-up"></div>
                                {/* <CustomIcon
                                  icon="plus"
                                  size={18}
                                  className="closeIconWhite "
                                  onClick={() => {
                                    setShow(false);
                                    localStorage.setItem(
                                      "closePopOver",
                                      JSON.stringify(1)
                                    );
                                  }}
                                /> */}
                                <i>Click here for another view</i>
                                {/* <i>Close these instructions </i> */}
                              </div>
                            </>
                          )}
                      </div>

                      <div
                        className={`tabPosition${index} ${
                          detailsState.data.items.length > 3 ? "change" : ""
                        } ${screen.status === index ? "redColor" : ""}`}
                        onClick={() => setScreen({ ...screen, status: index })}
                      >
                        {/* <CustomTooltip
                            title={`${
                              screen.status === index
                                ? "Active slide"
                                : "Click to view slide"
                            } `}
                          > */}
                        <span className="insideContent"></span>
                        {/* </CustomTooltip> */}
                      </div>
                    </div>
                    <div
                      className={`firstHolder${index} ${
                        screen.status === index ? "active" : "notActive"
                      }`}
                    >
                      <div className="bannerImageHolder">
                        {isLoading ? (
                          <div className="loading-spinner"></div>
                        ) : (
                          <div className="centerTextContainer ">
                            <h1
                              className={`title ${
                                item.title.length > 15 ? "small" : ""
                              }`}
                            >
                              {item.title}
                            </h1>
                            <hr className="redLine" />
                            {item.link && item.linkTitle && (
                              <a
                                href={item.link}
                                target="_blank"
                                tabIndex="0"
                                className="link"
                              >
                                {item.linkTitle}
                              </a>
                            )}
                          </div>
                        )}
                        {item.type === "video" && (
                          <>
                            {isLoading && (
                              <div className="loading-spinner"></div>
                            )}
                            <video
                              width="100%"
                              height="100%"
                              controls={false}
                              autoPlay="autoplay"
                              preload="auto"
                              loop
                              muted
                              playsInline
                              onLoadedData={handleVideoLoaded}
                            >
                              <source
                                src={
                                  process.env.REACT_APP_BACKEND_URL +
                                  "public/" +
                                  item.video
                                }
                                type="video/mp4"
                              />
                            </video>
                            {isLoading ? (
                              <div className="loading-spinner"></div>
                            ) : (
                              <div className=" overlay"></div>
                            )}
                          </>
                        )}
                        {item.type === "image" && (
                          <>
                            <img
                              src={
                                process.env.REACT_APP_BACKEND_URL +
                                "public/" +
                                item.image
                              }
                              onError={handleOnError}
                              alt="bannerImage1"
                              className=" overlay"
                            />
                            <div className=" overlay"></div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
            {!detailsState.hasError && <div className="yellowBox"></div>}
          </Col>
        </Row>
      </div>
      {/* swiper for mobile */}
      <div className="bannerMobile">
        <Swiper
          autoplay={true}
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="bannerSwiper h-100"
        >
          {detailsState.hasError && (
            <SwiperSlide>
              <div className="bannerImageHolder">
                <div className="centerTextContainer ">
                  <h1 className={`title `}>
                    Welcome to the new website of “Memory of Time”
                  </h1>
                  <hr className="redLine" />

                  <CustomTooltip title="Please click to view">
                    <Link to="/own-a-fossil" className={`link`}>
                      Know more about us
                    </Link>
                  </CustomTooltip>
                </div>

                <img
                  src={dummyBanner}
                  onError={handleOnError}
                  alt="bannerImage1"
                />
                <div className=" overlay"></div>
              </div>
            </SwiperSlide>
          )}
          {detailsState &&
            !detailsState.hasError &&
            detailsState.data &&
            detailsState.data.items &&
            detailsState.data.items.length <= 0 && (
              <SwiperSlide>
                <div className="bannerImageHolder">
                  <div className="centerTextContainer ">
                    <h1 className={`title `}>
                      Welcome to the new website of “Memory of Time”
                    </h1>
                    <hr className="redLine" />

                    <CustomTooltip title="Please click to view">
                      <Link to="/own-a-fossil" className={`link`}>
                        Know more about us
                      </Link>
                    </CustomTooltip>
                  </div>

                  <img
                    src={dummyBanner}
                    onError={handleOnError}
                    alt="bannerImage1"
                  />
                  <div className=" overlay"></div>
                </div>
              </SwiperSlide>
            )}
          {detailsState &&
            !detailsState.hasError &&
            detailsState.data &&
            detailsState.data.items &&
            detailsState.data.items.slice(0, 4).map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div className="bannerImageHolder">
                    {isLoading ? (
                      <div className="loading-spinner"></div>
                    ) : (
                      <div className="centerTextContainer ">
                        {item.mobileTitle ? (
                          <h1
                            className={`title ${
                              item.mobileTitle.length > 15 ? "small" : ""
                            }`}
                          >
                            {item.mobileTitle}
                          </h1>
                        ) : (
                          <h1
                            className={`title ${
                              item.title.length > 15 ? "small" : ""
                            }`}
                          >
                            {item.title}
                          </h1>
                        )}
                        <hr className="redLine" />
                        {item.link && item.linkTitle && (
                          <CustomTooltip
                            title={`Please click to get more info`}
                          >
                            <a
                              href={item.link}
                              className={`link ${
                                item.title.length > 15 ? "small" : ""
                              }`}
                            >
                              {item.linkTitle}
                            </a>
                          </CustomTooltip>
                        )}
                      </div>
                    )}
                    {item.type === "video" && (
                      <>
                        {isLoading && <div className="loading-spinner"></div>}
                        <video
                          ref={videoRef}
                          controls={false}
                          autoPlay="autoplay"
                          preload="auto"
                          loop
                          muted
                          playsInline
                          onLoadedData={handleVideoLoaded}
                        >
                          <source
                            src={
                              process.env.REACT_APP_BACKEND_URL +
                              "public/" +
                              item.video
                            }
                            type="video/mp4"
                          />
                        </video>
                        {isLoading ? (
                          <div className="loading-spinner"></div>
                        ) : (
                          <div className=" overlay"></div>
                        )}
                      </>
                    )}
                    {item.type === "image" && (
                      <>
                        <img
                          src={
                            process.env.REACT_APP_BACKEND_URL +
                            "public/" +
                            item.image
                          }
                          onError={handleOnError}
                          alt="bannerImage1"
                        />
                        <div className=" overlay"></div>
                      </>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </>
  );
}

export default Banner;
