import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";

import CMSFormInput from "../../../shared/formFields/CMSFormInput";
import CMSFormSelect from "../../../shared/formFields/CMSFormSelect";
import CMSFormTelephone from "../../../shared/formFields/CMSFormTelephone";
import CMSFormTextArea from "../../../shared/formFields/CMSFormTextArea";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import { FormSubmitButton } from "../../../shared/buttons/Buttons";
import { shippingAddressSchema } from "../../../../utils/yupValidation";
import { CiEdit } from "react-icons/ci";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { FaAddressBook } from "react-icons/fa";
import { GiMailbox } from "react-icons/gi";
import {
  updateInitialState,
  updateReducer,
} from "../../../shared/reducers/updateReducer";
import axiosInstance from "../../../../config/axios";
import {
  screenInitialState,
  screenReducer,
} from "../../../shared/reducers/screenReducer";
import { OverLayLoading } from "../../../shared/loading/Loading";

const ShippingForm = ({ parent, parentState, transactionId }) => {
  const [screen, setScreen] = useState({
    state: "",
    update: false,
    shippingAddress: null,
  });
  const [error, setError] = useState(false);
  const buyerSaved = localStorage.getItem("BuyerDetails");
  const buyerDetails = JSON.parse(buyerSaved);
  const saved = localStorage.getItem("BuyerId");
  const buyerId = JSON.parse(saved);
  const shippingAddressSaved = "";
  const shippingAddress = "";
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const [countryState, countryDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i].name, value: item[i].name });
    }

    return array;
  };
  const loadCountries = async () => {
    countryDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`countries`);
      countryDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      countryDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadCountries();
  }, []);
  const cartDetails = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    const saved = localStorage.getItem("BuyerId");
    const buyerId = JSON.parse(saved);
    try {
      const res = await axiosInstance.post(`cart/`, {
        buyerId: buyerId,
      });

      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "shipping form cart reload");
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const handleSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axiosInstance.post(`cart/add-shipping-address`, data);
      // window.scrollTo(0, 0);
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "after submission");
      localStorage.setItem("shippingDetails", JSON.stringify(data));
      getBuyerDetails();
      cartDetails();
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    }
  };

  const getBuyerDetails = () => {
    const shippingAddressSaved = localStorage.getItem("shippingDetails");
    const shippingAddress = JSON.parse(shippingAddressSaved);
    console.log(shippingAddress, "shippingAddress");
    setScreen({
      ...screen,
      shippingAddress: shippingAddress,
      state: "submitted",
    });
    parentState({
      ...parent,
      shippingAddress: shippingAddress,
      orderDisplayReload: true,
    });
  };
  useEffect(() => {
    cartDetails();
  }, []);

  return (
    <>
      {updateState.isSubmitting === true && <OverLayLoading />}
      {detailsState &&
        detailsState.data &&
        detailsState.data.shippingAddress &&
        detailsState.data.shippingAddress.city &&
        screen.state !== "form" && (
          <div className="addressAdded">
            <p
              className="edit"
              onClick={() =>
                setScreen({
                  ...screen,
                  state: "form",
                })
              }
            >
              {" "}
              <CiEdit className="me-2 icon" />
              Edit address
            </p>

            <>
              <p className="name">
                {detailsState.data.shippingAddress.firstName}{" "}
                {detailsState.data.shippingAddress.lastName}
              </p>
              <p>
                {" "}
                <BsTelephone className="shipIcon" />
                {detailsState.data.shippingAddress.phone}
              </p>
              <p>
                <AiOutlineMail className="shipIcon" />{" "}
                {detailsState.data.shippingAddress.email}
              </p>
              <p>
                {detailsState.data.shippingAddress.country}
                {detailsState.data.shippingAddress.state &&
                  "," + detailsState.data.shippingAddress.state}

                {detailsState.data.shippingAddress.city &&
                  "," + detailsState.data.shippingAddress.city}
              </p>
              <p> {detailsState.data.shippingAddress.postCode}</p>
              <p>
                {" "}
                <FaAddressBook className="shipIcon" />
                {detailsState.data.shippingAddress.addressLine1}
              </p>
              {detailsState.data.shippingAddress.addressLine2 && (
                <p>
                  {/* <FaAddressBook className="shipIcon" /> */}
                  {detailsState.data.shippingAddress.addressLine2}
                </p>
              )}
            </>
          </div>
        )}
      {screen.state === "form" ||
      (detailsState &&
        detailsState.data &&
        detailsState.data.shippingAddress &&
        !detailsState.data.shippingAddress.city) ? (
        <Formik
          initialValues={
            detailsState.data.shippingAddress &&
            detailsState.data.shippingAddress.city
              ? {
                  buyerId: buyerId,
                  transactionId: transactionId,
                  ...detailsState.data.shippingAddress,
                }
              : {
                  buyerId: buyerId,
                  transactionId: transactionId,
                  firstName: buyerDetails.firstName
                    ? buyerDetails.firstName
                    : "",
                  lastName: buyerDetails.lastName ? buyerDetails.lastName : "",
                  email: buyerDetails.email ? buyerDetails.email : "",
                  country: buyerDetails.country ? buyerDetails.country : "",
                  phone: buyerDetails.phone ? buyerDetails.phone : "",
                  city: "",
                  state: "",
                  postCode: "",
                  addressLine1: "",
                  addressLine2: "",
                }
          }
          validationSchema={shippingAddressSchema}
          onSubmit={(values, { resetForm }) => {
            console.log(values, "inside shipping form");
            handleSubmit(values);
          }}
        >
          {(form) => (
            <>
              <Row className="px-1 position-relative ">
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-4"
                    id="firstName"
                  >
                    <CMSFormInput
                      name="firstName"
                      placeholder="First Name"
                      form={form}
                      required={true}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-4"
                    id="lastName"
                  >
                    <CMSFormInput
                      name="lastName"
                      placeholder="Last Name"
                      form={form}
                      required={true}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-4"
                    id="email"
                  >
                    <CMSFormInput
                      name="email"
                      placeholder="Email Address"
                      form={form}
                      required={true}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-4"
                    id="phone"
                  >
                    <CMSFormTelephone
                      name="phone"
                      placeholder="Phone"
                      form={form}
                      required={true}
                      errorState={error}
                      setErrorState={setError}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-4"
                    id="city"
                  >
                    <CMSFormInput
                      name="city"
                      placeholder="City"
                      form={form}
                      required={true}
                    />
                  </Col>
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-4"
                    id="state"
                  >
                    <CMSFormInput
                      name="state"
                      placeholder="State"
                      form={form}
                    />
                  </Col>
                  {countryState &&
                    countryState.data &&
                    countryState.data.items && (
                      <Col
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="mb-4"
                        id="country"
                      >
                        <CMSFormSelect
                          name="country"
                          placeholder={{ label: "Country", value: "" }}
                          form={form}
                          selected={form.values.country}
                          onChange={form.handleChange}
                          required={true}
                          options={
                            countryState &&
                            countryState.data &&
                            countryState.data.items &&
                            handleFormatArray(countryState.data.items)
                          }
                        />
                      </Col>
                    )}
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-4"
                    id="postCode"
                  >
                    <CMSFormInput
                      name="postCode"
                      placeholder="Postcode"
                      form={form}
                      required={true}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-xl-4 mb-lg-4 mb-md-0"
                    id="address1"
                  >
                    <CMSFormInput
                      name="addressLine1"
                      placeholder="Address Line 1"
                      form={form}
                      maxLength={300}
                      required={true}
                    />
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className="mb-xl-4 mb-lg-4 mb-md-0"
                    id="address1"
                  >
                    <CMSFormInput
                      name="addressLine2"
                      placeholder="Address Line 2"
                      form={form}
                      maxLength={300}
                      required={true}
                    />
                  </Col>
                </Col>

                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="end mt-xl-2"
                >
                  <CustomTooltip
                    title="Please click to submit"
                    position="bottom"
                  >
                    <FormSubmitButton
                      text="Submit"
                      theme="light"
                      onClick={form.handleSubmit}
                    />
                  </CustomTooltip>
                </Col>
              </Row>
            </>
          )}
        </Formik>
      ) : null}
    </>
  );
};

export default ShippingForm;
