import React from "react";
import CustomIcon from "../customIcon/CustomIcon";
import parse from "html-react-parser";
import { FetchErrorLight } from "../displayAlerts/DisplayAlerts";
const ContactInfo = ({ item, error }) => {
  return (
    <React.Fragment>
      {error ? (
        <div className="fossilSitesText">
          Thank you for visiting our website. Do you have any inquiry? You can
          fill the form here and send it or you can visit us, call us or email
          us below.
        </div>
      ) : (
        <>
          <div className="fossilSitesText">
            {item && item.addresses && <>{parse(item.content)}</>}
            {/* Thank you for visiting our website. Do you have any inquiry? You can
        fill the form here and send it or you can visit us, call us or email us
        below. */}
          </div>
          {item && item.addresses && (
            <div className="contactLocation pt-4 mt-4">
              <CustomIcon
                icon="map"
                size={45}
                className="contactLocationIcon "
              />
              <div className="locationTextArea">
                {/* <p className="headText">Memory of Time,</p> */}

                {item.addresses.map((address, index) => {
                  if (address.name === "title")
                    return (
                      <p key={index} className="headText">
                        {address.value}
                      </p>
                    );
                })}
                {item.addresses.map((address, index) => {
                  if (address.name === "address_line1")
                    return (
                      <p key={index} className="subText">
                        {address.value}
                      </p>
                    );
                })}
                {item.addresses.map((address, index) => {
                  if (address.name === "address_line2")
                    return (
                      <p key={index} className="subText">
                        {address.value}
                      </p>
                    );
                })}
              </div>
            </div>
          )}
          {item && item.phones && (
            <div className="contactLocation mt-4">
              <CustomIcon
                icon="circlePhone"
                size={45}
                className="contactLocationIcon "
              />
              <div className="locationTextArea">
                <p className="headText">Phone</p>
                {item.phones.map((phone, index) => {
                  return (
                    <p key={index} className="subText">
                      {phone.value}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
          {item && item.emails && (
            <div className="contactLocation  mt-4">
              <CustomIcon
                icon="circleMail"
                size={45}
                className="contactLocationIcon "
              />
              <div className="locationTextArea">
                <p className="headText">Email</p>
                {item.emails.map((email, index) => {
                  return (
                    <p key={index} className="subText">
                      {email.value}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default ContactInfo;
