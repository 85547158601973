export const screenInitialState = {
  data: [],
  isFetching: false,
  hasError: false,
  error: "",
  initialState: true,
  hasFetchingError: false,
  isSubmitting: false,
  isSubmitted: false,
  hasSubmissionError: false,
};

export const screenReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        hasError: true,
        isFetching: false,
        error: action.payload,
      };
    case "FORM_SUBMISSION_REQUEST":
      return {
        ...state,
        isSubmitting: true,
        hasFetchingError: false,
        error: "",
      };
    case "FORM_SUBMISSION_SUCCESS":
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };
    case "FORM_SUBMISSION_FAILURE":
      return {
        ...state,
        isSubmitting: false,
        hasSubmissionError: true,
        error: action.payload,
      };
    case "FORM_INITIAL_STATE":
      return {
        ...state,
        initialState: true,
        isSubmitting: false,
        isSubmitted: false,
        hasSubmissionError: false,
        error: "",
      };
    default:
      return state;
  }
};
