import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import "../productCard.scss";
import { emailSchema } from "../../../../utils/yupValidation";
import { FormSubmitButton } from "../../buttons/Buttons";
import CustomTooltip from "../../tooltip/CustomTooltip";
import CMSFormInput from "../../formFields/CMSFormInput";
import {
  FormSubmitError,
  FormSubmitSuccess,
} from "../../displayAlerts/DisplayAlerts";
const InitialEmailSubmission = ({
  show,
  onHide,
  parent,
  parentScreen,
  addToCart,
}) => {
  const [screen, setScreen] = useState({
    state: "initial",
    hide: false,
  });
  const saveEmailInLocalStorage = (value) => {
    localStorage.setItem("BuyerEmail", JSON.stringify(value));
    setScreen({
      ...screen,
      state: "submitted",
    });

    parentScreen({
      ...parent,
      reload: true,
    });
  };

  useEffect(() => {
    if (screen.state === "submitted") {
      setTimeout(() => {
        setScreen({
          ...screen,
          hide: true,
        });
        
      }, 1000);
    }
  }, [screen.state]);

  return (
    <Modal
      show={screen.hide === true ? false : show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="addEmail"
      onHide={onHide}
      onBackdropClick={onHide}
    >
      <Modal.Body>
        {screen.state === "submitted" && <FormSubmitSuccess msg2="hide" />}
        {/* <FormSubmitError /> */}
        {screen.state === "initial" && (
          <>
            <p className="description">
              Kindly provide us your active email id to continue.
            </p>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={emailSchema}
              onSubmit={(values, { resetForm }) => {
                saveEmailInLocalStorage(values.email);
              }}
            >
              {(form) => (
                <>
                  <Row className="">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} id="name">
                      <CMSFormInput
                        name="email"
                        placeholder="Email"
                        form={form}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="end mt-xl-4 mt-lg-4 mt-sm-3 mt-md-0 mt-4"
                    >
                      <CustomTooltip
                        title="Please click to submit"
                        position="bottom"
                      >
                        <FormSubmitButton
                          text="submit"
                          onClick={form.handleSubmit}
                        />
                      </CustomTooltip>
                    </Col>
                  </Row>
                </>
              )}
            </Formik>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default InitialEmailSubmission;
