import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import BodyLayout from "../../../../layout/body/BodyLayout";
import { Loading, OverLayLoading } from "../../../../shared/loading/Loading";
import { AiOutlineSearch } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { Formik } from "formik";
import "../../fossils.scss";
import axiosInstance from "../../../../../config/axios";
import {
  screenInitialState,
  screenReducer,
} from "../../../../shared/reducers/screenReducer";
import CollectionComponent from "./collectionComponent/CollectionComponent";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
import MemoryOfTimeFilters from "../memoryOfTimeFilters/MemoryOfTimeFilters";
import CMSFormSelect from "../../../../shared/formFields/CMSFormSelect";
import ModalFilter from "../../../../shared/modal/ModalFilter";
import {
  AppliedFilter,
  AppliedFilterSort,
} from "../../../../shared/filters/AppliedFilter";
import {
  FetchError,
  NoItemsFound,
  ShowingSearchResultsDialog,
} from "../../../../shared/displayAlerts/DisplayAlerts";
import parse from "html-react-parser";
const MemoryCollectionListing = () => {
  const [screen, setScreen] = useState({
    loadMore: false,
    data: null,
    status: "",
    filterShow: false,
    sortShow: false,
    sortedValue: "",
    searchedValue: "",
    category: "",
    subCategory: "",
    site: "",
    tag: "",
    overlay: false,
    heading: "",
    description: "",
  });
  const [search, setSearch] = useState("");
  const sortArray = [
    { value: "newest", label: "Newest" },
    { value: "oldest", label: "Oldest" },
  ];

  useEffect(() => {
    localStorage.removeItem("newsFiltersApplied");
    localStorage.removeItem("newsScreenValues");
    localStorage.removeItem("ownAFossilFiltersApplied");
    localStorage.removeItem("ownAFossilScreenValues");
    localStorage.removeItem("mediaFiltersApplied");
    localStorage.removeItem("mediaScreenValues");
    localStorage.removeItem("pressFiltersApplied");
    localStorage.removeItem("pressScreenValues");
    localStorage.removeItem("publicationFiltersApplied");
    localStorage.removeItem("publicationScreenValues");
    localStorage.removeItem("currentActiveIdNews");
    localStorage.removeItem("currentActiveIdTestimonial");
    document.title = "Memory of Time Collection";
  }, []);
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const getFilterString = () => {
    let filterString = "";
    if (screen.sortedValue) {
      filterString = filterString + "sort=" + screen.sortedValue;
    }
    if (screen.searchedValue) {
      filterString = filterString + "&q=" + screen.searchedValue;
    }
    if (screen.category) {
      filterString = filterString + "&category=" + screen.category;
    }
    if (screen.subCategory.length > 0) {
      for (let index = 0; index < screen.subCategory.length; index++) {
        filterString =
          filterString + "&subCategories[]=" + screen.subCategory[index];
      }
    }
    if (screen.site.length > 0) {
      for (let index = 0; index < screen.site.length; index++) {
        filterString = filterString + "&sites[]=" + screen.site[index];
      }
    }
    if (screen.tag.length > 0) {
      for (let index = 0; index < screen.tag.length; index++) {
        filterString = filterString + `&${screen.tag[index]}=` + 1;
      }
    }
    return filterString;
  };
  useEffect(() => {
    if (screen.showModal) {
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.showWidget();
        }
      };
    }
  }, [screen.showModal]);
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    const filterAppliedSaved = localStorage.getItem("motFiltersApplied");
    const filterApplied = JSON.parse(filterAppliedSaved);
    const motScreenSaved = localStorage.getItem("motScreenValues");
    const motScreen = JSON.parse(motScreenSaved);
    let filterString = getFilterString();
    if (filterString === "" && filterApplied) {
      filterString = filterApplied ? filterApplied : filterString;
      setScreen({
        ...screen,
        sortedValue: motScreen.sortedValue,
        category: motScreen.category,
        subCategory: motScreen.subCategory,
        site: motScreen.site,
        tag: motScreen.tag,
        searchedValue: motScreen.searchedValue,
      });
      setSearch(motScreen.searchedValue);

      setScreen((screen) => ({
        ...screen,
        filterShow: false,
      }));
    }
    setScreen((screen) => ({
      ...screen,
      filterShow: false,
    }));
    console.log(filterString);
    try {
      const res = await axiosInstance.get(
        "fossil-collections" + (filterString ? "?" + filterString : "")
      );
      localStorage.setItem("motFiltersApplied", JSON.stringify(filterString));
      localStorage.setItem("motScreenValues", JSON.stringify(screen));
      setScreen((screen) => ({
        ...screen,
        loadMore: false,
        filterShow: true,
        data: res.data.items,
      }));
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const loadPageContent = async () => {
    try {
      const res = await axiosInstance.get(
        "page-content/memory-of-time-collection"
      );
      setScreen((screen) => ({
        ...screen,
        status: "fetched",
        heading: res.data.heading,
        description: res.data.content,
      }));
    } catch (error) {
      setScreen((screen) => ({
        ...screen,
        status: "",
        heading: "",
        description: "",
      }));
    }
  };
  const loadMoreItems = async () => {
    if (
      detailsState &&
      detailsState.data &&
      detailsState.data.pagination &&
      detailsState.data.pagination.currentPage <
        detailsState.data.pagination.pageCount
    ) {
      setScreen((prevState) => ({ ...prevState, loadMore: true }));
      let filterString = getFilterString();
      try {
        const res = await axiosInstance.get(
          "fossil-collections" +
            "?page=" +
            detailsState.data.pagination.nextPage +
            (filterString ? "&" + filterString : "")
        );
        setScreen((screen) => ({
          ...screen,
          loadMore: false,
          data: screen.data.concat(res.data.items),
        }));
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    } else {
    }
  };
  const handleClear = (values) => {
    localStorage.setItem("motFiltersApplied", JSON.stringify(""));
    localStorage.setItem(
      "motScreenValues",
      JSON.stringify({ ...screen, searchedValue: "" })
    );
    setTimeout(() => {
      setScreen({
        ...screen,
        searchedValue: "",
      });
      setSearch("");
    }, 100);
  };
  const handleSearchSubmit = (values) => {
    localStorage.setItem("motFiltersApplied", JSON.stringify(""));
    localStorage.setItem(
      "motScreenValues",
      JSON.stringify({ ...screen, searchedValue: search })
    );
    setScreen({
      ...screen,
      searchedValue: search,
    });
  };

  const handleFormSubmit = (values) => {
    setScreen({
      ...screen,
      sortedValue: values.sort ? values.sort : screen.sortedValue,
      category: values.category ? values.category : screen.category,
      subCategory:
        values.subCategory && values.category === screen.category
          ? values.subCategory
          : "",
      site: values.site ? values.site : screen.site,
      tag: values.tag ? values.tag : screen.tag,
      showModal: false,
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit(search);
    }
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleRemoveItem = (array, value, type) => {
    setScreen({
      ...screen,
      overlay: true,
    });
    setTimeout(() => {
      const newArr = array.filter((e) => e !== value);

      if (type === "category") {
        setScreen({
          ...screen,
          category: "",
          overlay: false,
        });
      }
      if (type === "subCategory") {
        setScreen({
          ...screen,
          subCategory: newArr,
          overlay: false,
        });
      }
      if (type === "site") {
        setScreen({
          ...screen,
          site: newArr,
          overlay: false,
        });
      }
      if (type === "tag") {
        setScreen({
          ...screen,
          tag: newArr,
          overlay: false,
        });
      }
      localStorage.setItem("motFiltersApplied", JSON.stringify(""));
      localStorage.setItem("motScreenValues", JSON.stringify(screen));
    }, 100);
  };
  const handleRemoveItemSort = () => {
    setScreen({
      ...screen,
      overlay: true,
    });
    setTimeout(() => {
      setScreen({
        ...screen,
        sortedValue: "",
        overlay: false,
      });
    }, 2000);
  };
  const saveFilterInLocalStorage = () => {
    let filterString = getFilterString();
    localStorage.setItem(
      "fossilCollectionFilterApplied",
      JSON.stringify(filterString)
    );
  };
  useEffect(() => {
    loadPageContent();
  }, []);
  useEffect(() => {
    loadItem();
    if (screen.searchedValue === "") {
      setSearch("");
    }
  }, [
    screen.sortedValue,
    screen.searchedValue,
    screen.category,
    screen.subCategory,
    screen.site,
    screen.tag,
  ]);

  return (
    <BodyLayout scrollButton="show">
      <div
        className={`fossilsSection ${
          detailsState.hasError === true ? "center" : ""
        } position-relative pb-xl-4 pb-lg-4 pb-md-3 pb-3`}
      >
        {detailsState.isFetching === true && screen.data === null && (
          <Loading />
        )}
        {detailsState.isFetching === true && screen.data !== null && (
          <OverLayLoading />
        )}
        {screen.overlay === true && <OverLayLoading />}

        <div className="yellowBox"></div>
        {detailsState.hasError === true && screen.data === null && (
          <Row>
            <Col>
              <FetchError />
            </Col>
          </Row>
        )}

        {detailsState.hasError !== true && (
          <Row className="ms-xl-1  pb-xl-4 pb-md-0">
            <Col xl={12} className="pt-xl-5 pt-md-5">
              <Row className="uniPadding innerSection2 pt-xl-3 pt-md-3">
                <Col xl={12} lg={12} md={12} className="pt-xl-4 ">
                  <div className="headContainer">
                    <h3 className="headingText">{screen.heading}</h3>
                    <hr className="redLine" />
                  </div>
                </Col>
              </Row>
              <Row className="uniPadding detailsContainer innerSection2 pt-3">
                <Col xl={12} lg={12} md={12} className="pt-4 pb-md-2">
                  <p className="headDetails">{parse(screen.description)}</p>
                </Col>
              </Row>

              {detailsState && detailsState.data && (
                <>
                  <Row className=" uniPadding position-relative hidOnMobile mx-auto pb-lg-1 pb-xl-5 pb-md-0 pb-3">
                    <Col className="ps-4 pe-3">
                      <Row
                        className={`uniPaddingOwnAFossil   filterRow position innerSection2 pt-0  `}
                      >
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="pt-1 pe-xl-0 filters"
                        >
                          <div className="searchBox me-xl-4 me-md-4">
                            <input
                              id="search"
                              name="search"
                              placeholder="search for a fossil"
                              type="text"
                              onKeyDown={handleKeyPress}
                              onChange={handleChange}
                              value={search}
                            />
                            <div
                              className="icon center"
                              onClick={() => handleSearchSubmit(search)}
                            >
                              <AiOutlineSearch className="searchIcon" />
                            </div>
                          </div>

                          <>
                            <Formik
                              initialValues={{
                                category: screen.category ? screen.category : "",
                                subCategory: screen.subCategory ? screen.subCategory : "",
                                site: screen.site ? screen.site : "",
                                tag: screen.tag ? screen.tag : "",
                                sort: screen.sortedValue
                                  ? screen.sortedValue
                                  : "",
                              }}
                              onSubmit={async (values) => {
                                setScreen({
                                  ...screen,
                                  sortedValue: values.sort,
                                });
                              }}
                            >
                              {(form) => (
                                <>
                                  <div
                                    className={`filterBox sort me-xl-4 me-md-4${
                                      screen.sortShow === true
                                        ? " viewAll "
                                        : ""
                                    } center`}
                                    onClick={() =>
                                      setScreen((screen) => ({
                                        ...screen,
                                        sortShow: !screen.sortShow,
                                      }))
                                    }
                                  >
                                    <CMSFormSelect
                                      name="sort"
                                      placeholder={{
                                        value: " ",
                                        label: "sort by",
                                      }}
                                      selected={screen.sortedValue}
                                      form={form}
                                      onChange={(e) => {
                                        form.handleChange(e);
                                        form.handleSubmit(e);
                                      }}
                                      filter={true}
                                      options={sortArray}
                                    />
                                  </div>
                                </>
                              )}
                            </Formik>

                            <div
                              className={`filterBox ${
                                screen.filterShow === true ? "viewAll  " : ""
                              }  center`}
                              onClick={() =>
                                setScreen((screen) => ({
                                  ...screen,
                                  filterShow: !screen.filterShow,
                                }))
                              }
                            >
                              <div className="arrow-down"></div>
                              view all filters
                            </div>
                          </>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mobileFilters pb-3 pt-3 uniPadding center showOnMobile">
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="pt-1  filters "
                    >
                      <div className="mobileSearchComponent">
                        <div
                          className={`searchBox ${
                            screen.expandSearchField ? "expand" : ""
                          }`}
                        >
                          <input
                            id="search"
                            name="search"
                            placeholder="search for a fossil"
                            type="text"
                            onChange={handleChange}
                            value={search}
                          />
                          <div
                            className="icon center"
                            onClick={
                              screen.expandSearchField
                                ? () => handleSearchSubmit(search)
                                : () =>
                                    setScreen((screen) => ({
                                      ...screen,
                                      expandSearchField:
                                        !screen.expandSearchField,
                                    }))
                            }
                            // onClick={() => handleSearchSubmit(search)}
                          >
                            <AiOutlineSearch className="searchIcon" />
                          </div>
                          {screen.expandSearchField && (
                            <div
                              className="icon center"
                              onClick={() =>
                                setScreen((screen) => ({
                                  ...screen,
                                  expandSearchField: !screen.expandSearchField,
                                }))
                              }
                              // onClick={() => handleSearchSubmit(search)}
                            >
                              <MdClose className="searchIcon" />
                            </div>
                          )}
                        </div>
                      </div>

                      <>
                        {screen.showModal === true && (
                          <ModalFilter
                            show={screen.showModal}
                            onHide={() =>
                              setScreen({ ...screen, showModal: false })
                            }
                          >
                            <div className="filterExpandedMobile pt-3">
                              <div className="filterContentMobile">
                                <div className="mobileFilterTop">
                                  <div
                                    title={
                                      screen.filterShow === false
                                        ? "Please click to view filters"
                                        : "Please click to close filters"
                                    }
                                    className={`filterBox ${
                                      screen.expandSearchField === true
                                        ? "expand"
                                        : ""
                                    } ${
                                      screen.showModal === true
                                        ? "viewAllMobile "
                                        : ""
                                    } center`}
                                    onClick={() =>
                                      setScreen((screen) => ({
                                        ...screen,
                                        showModal: !screen.showModal,
                                      }))
                                    }
                                  >
                                    <div className="arrow-down"></div>
                                    view all filters
                                  </div>
                                  <div
                                    className="icon center"
                                    onClick={() =>
                                      setScreen({ ...screen, showModal: false })
                                    }
                                    // onClick={() => handleSearchSubmit(search)}
                                  >
                                    <MdClose className="filterCloseIconMobile" />
                                  </div>
                                </div>
                                <MemoryOfTimeFilters
                                  sortArray={sortArray}
                                  onSubmit={handleFormSubmit}
                                  parentState={setScreen}
                                  screen={screen}
                                  search={search}
                                  setSearch={setSearch}
                                  onClick={handleClear}
                                  categories={
                                    detailsState &&
                                    detailsState.data &&
                                    detailsState.data.categories
                                  }
                                  subCategories={
                                    detailsState &&
                                    detailsState.data &&
                                    detailsState.data.subCategories
                                  }
                                  fossilSites={
                                    detailsState &&
                                    detailsState.data &&
                                    detailsState.data.fossilSites
                                  }
                                  moreFilters={
                                    detailsState &&
                                    detailsState.data &&
                                    detailsState.data.moreFilters
                                  }
                                />
                              </div>
                            </div>
                          </ModalFilter>
                        )}

                        <div
                          title={
                            screen.filterShow === false
                              ? "Please click to view filters"
                              : "Please click to close filters"
                          }
                          className={`filterBox ${
                            screen.expandSearchField === true ? "expand" : ""
                          } ${
                            screen.filterMobileShow === true ? "viewAll  " : ""
                          } center`}
                          onClick={() =>
                            setScreen((screen) => ({
                              ...screen,
                              showModal: !screen.showModal,
                            }))
                          }
                        >
                          <div className="arrow-down"></div>
                          view all filters
                        </div>
                      </>
                    </Col>
                  </Row>
                </>
              )}

              {detailsState && detailsState.data && (
                <Row className="uniPadding   pt-xl-4 pt-lg-2 pt-md-3 mt-3">
                  <Col
                    xl={screen.filterShow ? 9 : 12}
                    lg={screen.filterShow ? 8 : 12}
                    md={screen.filterShow ? 7 : 12}
                    sm={12}
                    xs={12}
                  >
                    {/* applied filters display start */}
                    {screen.category ||
                    screen.subCategory.length > 0 ||
                    screen.site.length > 0 ||
                    screen.tag.length > 0 ? (
                      <div className="start filtersTopDisplay pb-xl-0 pb-lg-0 pb-md-0 pb-sm-0 pb-1">
                        Applied filter(s) :
                        {/* {screen.sortedValue && (
                          <AppliedFilterSort
                            value={screen.sortedValue}
                            onClick={handleRemoveItemSort}
                          />
                        )} */}
                        {screen.category && (
                          <AppliedFilter
                            value={screen.category}
                            onClick={handleRemoveItem}
                            array={[]}
                            type="category"
                          />
                        )}
                        {screen.subCategory &&
                          screen.subCategory.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <AppliedFilter
                                  value={item}
                                  onClick={handleRemoveItem}
                                  array={screen.subCategory}
                                  type="subCategory"
                                />
                              </React.Fragment>
                            );
                          })}
                        {screen.site &&
                          screen.site.map((item) => {
                            return (
                              <AppliedFilter
                                value={item}
                                onClick={handleRemoveItem}
                                array={screen.site}
                                type="site"
                              />
                            );
                          })}
                        {screen.tag &&
                          screen.tag.map((item) => {
                            return (
                              <AppliedFilter
                                value={item}
                                onClick={handleRemoveItem}
                                array={screen.tag}
                                type="tag"
                              />
                            );
                          })}
                      </div>
                    ) : null}
                    {/* applied filters display end */}

                    {detailsState &&
                      detailsState.isFetching === false &&
                      detailsState.data && (
                        <div>
                          {screen.searchedValue !== "" && (
                            <ShowingSearchResultsDialog
                              value={screen.searchedValue}
                              onClick={handleClear}
                              count={
                                detailsState &&
                                detailsState.data &&
                                detailsState.data.pagination &&
                                detailsState.data.pagination.totalFilteredItems
                              }
                            />
                          )}
                        </div>
                      )}
                    {detailsState &&
                      detailsState.isFetching === false &&
                      detailsState.data &&
                      detailsState.data.items &&
                      detailsState.data.items.length <= 0 && (
                        <div className="mt-2">
                          <NoItemsFound />
                        </div>
                      )}
                    <Row className="componentRow">
                      {screen.data &&
                        screen.data.map((item, index) => {
                          return (
                            <Col
                              xl={screen.filterShow ? 4 : 3}
                              lg={screen.filterShow ? 6 : 4}
                              md={screen.filterShow ? 8 : 5}
                              sm={12}
                              xs={12}
                              className="components  pt-2  pb-1"
                            >
                              <CollectionComponent
                                item={item}
                                onClick={saveFilterInLocalStorage}
                              />
                            </Col>
                          );
                        })}
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="pt-2 pb-0 px-3 "
                      >
                        {parseInt(
                          detailsState &&
                            detailsState.data &&
                            detailsState.data.pagination &&
                            detailsState.data.pagination.currentPage
                        ) <
                          parseInt(
                            detailsState &&
                              detailsState.data &&
                              detailsState.data.pagination &&
                              detailsState.data.pagination.pageCount
                          ) && (
                          <div
                            className="loadMore pt-2 pb-0"
                            onClick={loadMoreItems}
                          >
                            {screen.loadMore === true ? (
                              <div className="spinner-container center">
                                <div className="loading-spinner"></div>
                              </div>
                            ) : (
                              <span>show more fossils</span>
                            )}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="rightFilters ">
                    {screen.filterShow === true && (
                      <div className={`filterContent ownAFossil`}>
                        <MemoryOfTimeFilters
                          onSubmit={handleFormSubmit}
                          parentState={setScreen}
                          screen={screen}
                          sortArray={[]}
                          search={search}
                          setSearch={setSearch}
                          onClick={handleClear}
                          categories={
                            window.innerWidth > 767
                              ? detailsState &&
                                detailsState.data &&
                                detailsState.data.categories
                              : []
                          }
                          subCategories={
                            window.innerWidth > 767
                              ? detailsState &&
                                detailsState.data &&
                                detailsState.data.subCategories
                              : []
                          }
                          fossilSites={
                            window.innerWidth > 767
                              ? detailsState &&
                                detailsState.data &&
                                detailsState.data.fossilSites
                              : []
                          }
                          moreFilters={
                            window.innerWidth > 767
                              ? detailsState &&
                                detailsState.data &&
                                detailsState.data.moreFilters
                              : []
                          }
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        )}
      </div>
    </BodyLayout>
  );
};

export default MemoryCollectionListing;
