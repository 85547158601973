import React, { useState, useReducer, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import image from "../../../../../assets/images/newsMobiles.png";
import "./newsSection.scss";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
import {
  screenInitialState,
  screenReducer,
} from "../../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../../config/axios";
import { Link } from "react-router-dom";
import { OnlyLoadingLight } from "../../../../shared/loading/Loading";
import dummy from "../../../../../assets/images/dummy558.png";
// news section in homepage
function NewsSection() {
  const [screen, setScreen] = useState({
    loading: false,
    data: {
      title: "What's new?",
      subTitle: "News, Events, Activities...",
      linkText: "view all",
      link: "memories/news-events",
    },
  });
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );

  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`home-page-news`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
  }, []);
  const handleOnError = (e) => {
    e.target.src = dummy;
  };
  return (
    <div className="center">
      {/* for mobile */}
      {detailsState &&
      !detailsState.hasError &&
      detailsState.data &&
      detailsState.data.item ? (
        <Row className="newsRowHot uniPadding mobile center w-100">
          <Col xs={12} className="newsImageCol">
            {detailsState.data.item.image !== null ? (
              <div className="imageRelative">
                <img
                  src={
                    process.env.REACT_APP_BACKEND_URL +
                    "public/" +
                    detailsState.data.item.image
                  }
                  onError={handleOnError}
                  alt="News Image"
                  className="newsImage"
                />
                <div className="redCircle"></div>
              </div>
            ) : (
              <div className="imageRelative">
                <img
                  src={image}
                  onError={handleOnError}
                  alt="News Image"
                  className="newsImage"
                />
                <div className="redCircle"></div>
              </div>
            )}
          </Col>
          <Col xs={12} className="newsHotCol">
            <div className="newsContainer">
              <p className="newsHead">{detailsState.data.item.title}</p>
              <p className="newsDate">{detailsState.data.item.date}</p>
              <p className="newsDetails">
                {detailsState.data.item.shortDescription}
              </p>
              <Link
                to="memories/news-events-mobile"
                onClick={() => {
                  localStorage.setItem(
                    "currentActiveIdNews",
                    JSON.stringify(detailsState.data.item._id)
                  );
                  localStorage.setItem(
                    "currentActiveIdNewsHome",
                    JSON.stringify(detailsState.data.item._id)
                  );
                }}
                className="newsLink"
              >
                <CustomTooltip title="Please click to read more">
                  read more
                </CustomTooltip>
              </Link>
            </div>
          </Col>
          <Col>
            <div className="textSection">
              <h1 className="title">{screen.data.title}</h1>
              <p className="subTitle">{screen.data.subTitle} </p>
              <hr className="whiteLine" />

              <p className="link">
                <Link
                  to="memories/news-events"
                  className="text-decoration-none"
                >
                  <CustomTooltip title="Please click to view more">
                    {screen.data.linkText}
                  </CustomTooltip>
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="newsRow mobile center w-100">
          <Col xs={12} className="newsImageCol">
            <div className="imageRelative">
              <img
                src={image}
                onError={handleOnError}
                alt="News Image"
                className="newsImage"
              />
              <div className="redCircle"></div>
            </div>
          </Col>
          <Col>
            <div className="textSection">
              <h1 className="title">{screen.data.title}</h1>
              <p className="subTitle">{screen.data.subTitle} </p>
              <hr className="whiteLine" />

              <p className="link">
                <Link
                  to="memories/news-events"
                  className="text-decoration-none"
                >
                  <CustomTooltip title="Please click to view more">
                    {screen.data.linkText}
                  </CustomTooltip>
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      )}
      {/* {/* for desktop and tablet */}

      {detailsState &&
      !detailsState.hasError &&
      detailsState.data &&
      detailsState.data.item ? (
        <Row className="newsRowHot uniPadding  desktop">
          <Col xl={3} lg={4} md={4} className="position-relative   parallax">
            {detailsState.data.item.image !== null ? (
              <div
                className="parallaxCol"
                style={{
                  backgroundImage: `url(${
                    process.env.REACT_APP_BACKEND_URL +
                    "public/" +
                    detailsState.data.item.image
                  })`,
                }}
              ></div>
            ) : (
              <div className="parallaxCol"></div>
            )}
          </Col>
          <Col md={5} xl={6} lg={4} className="position-relative  circleCol">
            <div className="yellowRoundSmallHot"></div>
            <div className="yellowRoundHot"></div>
            <div className="newsContainer">
              <p className="newsHead">{detailsState.data.item.title}</p>
              <p className="newsDate">{detailsState.data.item.date}</p>
              <p className="newsDetails">
                {detailsState.data.item.shortDescription}
              </p>
              <Link
                to={`memories/news-events/`}
                onClick={() => {
                  localStorage.setItem(
                    "currentActiveIdNews",
                    JSON.stringify(detailsState.data.item._id)
                  );
                  localStorage.setItem(
                    "currentActiveIdNewsHome",
                    JSON.stringify(detailsState.data.item._id)
                  );
                }}
                className="newsLink"
              >
               
                  read more
               
              </Link>
            </div>
          </Col>
          <Col
            xl={3}
            md={3}
            lg={4}
            className={detailsState.isFetching ? "center" : "start"}
          >
            {detailsState.isFetching && <OnlyLoadingLight />}

            <div className="textSection">
              <h1 className="title">{screen.data.title}</h1>
              <p className="subTitle">{screen.data.subTitle} </p>
              <hr className="whiteLine" />

              <p className="link">
                <Link
                  to="memories/news-events"
                  className="text-decoration-none"
                >
                  
                    {screen.data.linkText}
                
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="newsRow uniPadding  desktop">
          <Col xl={4} lg={4} md={7} className="position-relative  parallax">
            <div
              className="parallaxCol"
              style={{
                backgroundImage: `${require("../../../../../assets/images/parallaxImage.png")}`,
              }}
            ></div>
            <div className="redCircle"></div>
          </Col>
          <Col md={1} xl={4} lg={3} className="position-relative circleCol">
            <div className="yellowRoundSmall"></div>
            <div className="yellowRound"></div>
          </Col>
          <Col
            xl={4}
            md={5}
            lg={5}
            className={detailsState.isFetching ? "center" : "start"}
          >
            {detailsState.isFetching && <OnlyLoadingLight />}

            <div className="textSection">
              <h1 className="title">{screen.data.title}</h1>
              <p className="subTitle">{screen.data.subTitle} </p>
              <hr className="whiteLine" />

              <p className="link">
                <Link
                  to="memories/news-events"
                  className="text-decoration-none"
                >
                
                    {screen.data.linkText}
                
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default NewsSection;
