import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import Footer from "../../layout/footer/Footer";
import UpdatedMenu from "../menu/UpdatedMenu";
import ScrollToTopButton from "../../shared/scrollToTop/ScrollToTopButton";
import CustomIcon from "../../shared/customIcon/CustomIcon";
import CustomTooltip from "../../shared/tooltip/CustomTooltip";
// This is main body layout with header,footer components
const BodyLayout = (props) => {
  // useEffect(() => {
  //   localStorage.clear();
  // }, []);

  return (
    <Container id="mainContainer" className="p-0 mainContainer" fluid="xxl">
      <UpdatedMenu />
      <div className="paddingTop">{props.children}</div>
      {/* <div className="fixedChatContainer">
      <Button variant="primary" className="p-0 m-0 fixedChatButton">
        <div className="chatIconHolder center">
          <CustomTooltip title="Click to chat">
            <CustomIcon
              icon="commentsSolid"
              size={64}
              className="menuChatIcon"
            />
          </CustomTooltip>
        </div>
      </Button>
      </div> */}
     
      {props.footer === "hide" ? null : <Footer />}
      {props.scrollButton === "show" && <ScrollToTopButton />}
    </Container>
  );
};
export default BodyLayout;
