import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../config/axios";
import BodyLayout from "../../layout/body/BodyLayout";
import { Loading, OverLayLoading } from "../../shared/loading/Loading";
import { AiFillCheckCircle } from "react-icons/ai";
import { Button, Col, Row } from "react-bootstrap";
import "./paymentStatus.scss";
import OrderSuccessMessage from "../../shared/orderMessage/OrderSuccessMessage";
import {
  updateInitialState,
  updateReducer,
} from "../../shared/reducers/updateReducer";
import {
  screenInitialState,
  screenReducer,
} from "../../shared/reducers/screenReducer";
const PaymentStatus = () => {
  const { transactionId, indicator } = useParams();
  console.log("transactionId => ", transactionId);
  console.log("indicator => ", indicator);
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const navigate = useNavigate();
  let buyerId = localStorage.getItem("BuyerId");
  buyerId = JSON.parse(buyerId);
  console.log("buyerId", buyerId);

  const [screen, setScreen] = useState({
    status: "fetching", //fetching,processing
    errorType: null, //apiFailed,transactionValidationFailed
  });
  useEffect(() => {
    document.title = "Memory of Time";
  }, []);

  const updateTransaction = async () => {
    try {
      const res = await axiosInstance.post(
        `cart/update-online-payment-status`,
        {
          buyerId,
          transactionId,
          indicator,
        }
      );

      if (res.status === 201 && res.data.status === "confirmed") {
        setScreen({
          ...screen,
          status: "paymentDone",
          orderId: res.data.orderId,
        });
        console.log(res.data);
        localStorage.setItem("cartItemsCount", JSON.stringify(0));
        localStorage.removeItem("checkOutType");
        localStorage.removeItem("cartShowForm");
      } else {
        setScreen({
          ...screen,
          status: "error",
          errorType: "paymentFailed",
        });
      }
    } catch (error) {
      setScreen({
        ...screen,
        status: "error",
        errorType: "apiFailed",
      });
    }
  };

  useEffect(() => {
    if (transactionId && indicator) {
      updateTransaction();
    }
  }, []);

  return (
    <BodyLayout>
      <div className="paymentStatusSection center">
        {screen.status === "fetching" && <Loading />}
        <div className="yellowBox"></div>
        <Row className="ms-xl-1">
          {screen.status === "error" && screen.errorType === "apiFailed" && (
            <Col xl={12} className="pb-5 pt-3">
              <Row className=" py-3">
                <Col xl={12}>
                  <div className="payOnlineContent">
                    {screen.status === "error" && (
                      <>
                        {screen.errorType === "apiFailed" && (
                          <>
                            <div className="center flex-column msgBody">
                              <h2 className="submitSuccessHead theme2">
                                Page Not Found.
                              </h2>
                              {/* <p className="submitSuccessPara theme2">
                              Page Not Found.
                            </p> */}
                              <p className="submitSuccessPara theme2">
                                The link you followed is
                                no longer in use. We're sorry for any
                                inconvenience.
                              </p>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          {screen.status === "error" &&
            screen.errorType === "paymentFailed" && (
              <Col xl={12} className="pb-5 pt-3">
                <Row className=" py-3">
                  <Col xl={12}>
                    <div className="payOnlineContent">
                      {screen.status === "error" && (
                        <>
                          {screen.errorType === "paymentFailed" && (
                            <>
                              <div className="center flex-column msgBody">
                                <h2 className="submitSuccessHead theme2">
                                  Payment Failed!
                                </h2>
                                <p className="submitSuccessPara theme2">
                                  Your payment attempt did not go through;
                                </p>
                                <p className="submitSuccessPara theme2">
                                  we apologize for any inconvenience caused.
                                </p>

                                <Button
                                  className="bordered "
                                  variant="secondary"
                                  onClick={() =>
                                    navigate("/pay-online/" + transactionId)
                                  }
                                >
                                  Retry Payment
                                </Button>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          {screen.status === "paymentDone" && screen.orderId && (
            <Col xl={12} className="pb-5 pt-5">
              <Row className=" py-3">
                <Col xl={12}>
                  <div className="payOnlineContent">
                    {screen.status === "paymentDone" && screen.orderId && (
                      <>
                        <OrderSuccessMessage orderId={screen.orderId} />
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </BodyLayout>
  );
};

export default PaymentStatus;
