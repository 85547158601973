import React ,{useEffect} from "react";
import CMSFormRadioButtons from "../../../../shared/formFields/CMSFormRadioButtons";
import { Formik } from "formik";
import { Accordion } from "react-bootstrap";
const NewsEventsFilters = ({ screen, onSubmit, sortArray, parentState }) => {
  useEffect(() => {
    if (window.Tawk_API) {
      window.Tawk_API.hideWidget();
    }
    return () => {
      if (window.Tawk_API) {
        window.Tawk_API.showWidget();
      }
    };
  }, []);
  return (
    <Formik
      initialValues={{
        sort: screen.sortedValue ? screen.sortedValue : "",
      }}
      onSubmit={async (values) => {
        onSubmit(values);
      }}
      onReset={async (values) => {
        parentState({
          ...screen,
          sortedValue: "",
          showModal: false,
          selectedItem:"",
        });
      }}
    >
      {(form) => (
        <>
          <Accordion defaultActiveKey="3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Choose a Year</Accordion.Header>
              <Accordion.Body>
                <div className="filterHead sort">
                  <CMSFormRadioButtons
                    name="sort"
                    form={form}
                    label=""
                    options={sortArray}
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="filterHeadBottom sort">
            <a href="" className="filterApply" onClick={form.handleSubmit}>
              apply
            </a>
          </div>
          {screen.sortedValue ? (
            <div className="filterHeadBottom last sort">
              <a href="" className="filterApply" onClick={form.handleReset}>
                reset all filter
              </a>
            </div>
          ) : null}
        </>
      )}
    </Formik>
  );
};

export default NewsEventsFilters;
