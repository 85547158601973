import React, { useState, useEffect } from "react";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import CustomTooltip from "../tooltip/CustomTooltip";

const ScrollToTopButton = () => {
  const handleScroll = () => {
    const offsetTop = window.scrollY;
    if (offsetTop > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo(0, 0); 
  }, 100);
  };
  return (
    <>
      {scrolled && (
        <div className="scrollToTopContainer">
          <div className="scrollToTopButton center">
            
            <CustomTooltip title="Click to move top" position="top">
              <BsFillArrowUpCircleFill
                className="scrollIcon"
                onClick={() => handleClick()}
              />
            </CustomTooltip>
          </div>
        </div>
      )}
    </>
  );
};

export default ScrollToTopButton;
