import React, { useState } from "react";
import CustomIcon from "../customIcon/CustomIcon";
import dummy from "../../../assets/images/dummy500.png";
import "./imageCircle.scss";
import ExpandedModal from "./expandedModal/ExpandedModal";
import CustomTooltip from "../tooltip/CustomTooltip";

const ImageCircle = ({ images, live,title, galleryCount, slug }) => {
  const [screen, setScreen] = useState({
    show: false,
  });

  const handleOnError = (e) => {
    e.target.src = dummy;
  };
  return (
    <>
      <div className="circle">
        {live === true ? (
          <img src={images} onError={handleOnError} />
        ) : (
          <img
            src={process.env.REACT_APP_BACKEND_URL + "public/" + images}
            onError={handleOnError}
          />
        )}

        <div className="plus">
          <div className="plusIconHolder">
            <CustomTooltip title="Click to view pictures">
              <CustomIcon
                icon="plus"
                size={32}
                className="plusIcon"
                onClick={() => setScreen(() => ({ ...screen, show: true }))}
              />
            </CustomTooltip>
          </div>
        </div>
        <div className="viewSection center">
         
            <p
              className="mb-0 clickableText"
              onClick={() => setScreen(() => ({ ...screen, show: true }))}
            >
              {galleryCount < 1 ? "click to enlarge" : "view all photos"}
            </p>
         
        </div>
      </div>

      {galleryCount && galleryCount > 0 ? (
        <ExpandedModal
          show={screen.show}
          onHide={() => setScreen(() => ({ ...screen, show: false }))}
          array={[images]}
          title={title}
          url={slug}
        />
      ) : (
        <ExpandedModal
          show={screen.show}
          onHide={() => setScreen(() => ({ ...screen, show: false }))}
          array={[images]}
          url={slug}
          title={title}
          count="1"
        />
      )}
    </>
  );
};

export default ImageCircle;
