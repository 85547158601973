import React, { useReducer, useState, useEffect } from "react";
import { Formik } from "formik";
import { Row, Col } from "react-bootstrap";
import CMSFormInput from "../../../shared/formFields/CMSFormInput";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import { FormSubmitButton } from "../../../shared/buttons/Buttons";
import CMSFormTelephone from "../../../shared/formFields/CMSFormTelephone";
import CMSFormSelect from "../../../shared/formFields/CMSFormSelect";
import { onlineCheckoutSchema } from "../../../../utils/yupValidation";
import {
  updateInitialState,
  updateReducer,
} from "../../../shared/reducers/updateReducer";
import axiosInstance from "../../../../config/axios";
import {
  screenInitialState,
  screenReducer,
} from "../../../shared/reducers/screenReducer";
import { OverLayLoading } from "../../../shared/loading/Loading";
import { useNavigate } from "react-router-dom";

const GuestForm = ({ setScreen, screen }) => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
   const [error, setError] = useState(false);
  const navigate = useNavigate();
  const localEmailValueFetch = localStorage.getItem("BuyerEmail");
  const localEmailValue = JSON.parse(localEmailValueFetch);
  const saved = localStorage.getItem("BuyerId");
  const buyerId = JSON.parse(saved);
  const buyerSaved = localStorage.getItem("BuyerDetails");
  const buyerDetail = JSON.parse(buyerSaved);
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );

  const loadCountries = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`countries`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i].name, value: item[i].name });
    }

    return array;
  };
  useEffect(() => {
    loadCountries();
  }, []);
  const handleSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axiosInstance.post(`cart/online-payment-checkout`, data);
      localStorage.setItem("BuyerDetails", JSON.stringify(data));
      localStorage.setItem("cartShowForm", JSON.stringify(false));
      //console.log("online-payment-checkout res", res)
      if (res && res.data && res.data.transactionId) {
        navigate('/checkout/' + res.data.transactionId)
       // window.location.href = '/pay-online/' + res.data.transactionId
      }


      //window.scrollTo(0, 0);
      // updateDispatch({
      //   type: "FORM_SUBMISSION_SUCCESS",
      //   payload: res.data,
      // });

      //

      //window.location.href = 'http://localhost:4000/'
      //window.location.href = '/'
      //return navigate("/")
      // setScreen({
      //   ...screen,
      //   showForm: false,
      //   status: "success",
      //   orderByWhatsapp: false,
      //   payOnline: true,
      // });
      // console.log(res.data);
      //setScreen({ ...screen, showForm: false });
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      localStorage.setItem("BuyerDetails", JSON.stringify(data));
      setScreen({
        ...screen,
        showForm: false,
        status: "error",
        payOnline: false,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    }
  };

  return (
    <>
      {updateState.isSubmitting === true && <OverLayLoading />}
      <Formik
        initialValues={
          buyerDetail !== null
            ? {
              buyerId: buyerId,
              firstName: buyerDetail.firstName ? buyerDetail.firstName : "",
              lastName: buyerDetail.lastName ? buyerDetail.lastName : "",
              email: buyerDetail.email ? buyerDetail.email : "",
              phone: buyerDetail.phone ? buyerDetail.phone : "",
              country: buyerDetail.country ? buyerDetail.country : "",
            }
            : {
              buyerId: buyerId,
              firstName: "",
              lastName: "",
              email: localEmailValue ? localEmailValue : "",
              phone: "",
              country: "",
            }
        }
        validationSchema={onlineCheckoutSchema}
        onSubmit={(values, { resetForm }) => {
        // handleSubmit(values);
        !error ? handleSubmit(values) : console.log("error");
        console.log(values);
      }}
      >
        {(form) => (
          <>
            <Row className="mx-0">
              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mb-3">
                <div className=" end">
                  <p className="mandatoryContainer light mb-0">All fields are mandatory</p>
                </div>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                id="name"
                className="mb-3"
              >
                <CMSFormInput
                  name="firstName"
                  placeholder="First Name"
                  form={form}
                  required={true}
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                id="name"
                className="mb-3"
              >
                <CMSFormInput
                  name="lastName"
                  placeholder="Last Name"
                  form={form}
                  required={true}
                />
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                id="email"
                className="mb-3"
              >
                <CMSFormInput
                  name="email"
                  placeholder="Email"
                  form={form}
                  required={true}
                />
              </Col>
              {detailsState && detailsState.data && detailsState.data.items && (
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="mb-3"
                  id="country"
                >
                  <CMSFormSelect
                    name="country"
                    placeholder={{ label: "Country", value: "" }}
                    form={form}
                    selected={form.values.country}
                    onChange={form.handleChange}
                    required={true}
                    options={handleFormatArray(detailsState.data.items)}
                  />
                </Col>
              )}
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                id="phone"
                className="mb-3"
              >
                <CMSFormTelephone
                  name="phone"
                  placeholder="Phone"
                  form={form}
                  errorState={error}
                  setErrorState={setError}
                  required={true}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="end mt-xl-3 px-4"
              >
              
                  <FormSubmitButton
                    text="Pay online"
                    onClick={form.handleSubmit}
                  />
              
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </>
  );
};

export default GuestForm;
