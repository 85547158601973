import React, { useState, useReducer, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiFillCloseCircle } from "react-icons/ai";
import "./certificate.scss";
import { MdError } from "react-icons/md";
import { Row, Col } from "react-bootstrap";
import dummy from "../../../../../assets/images/dummy500.png";
import fff from "../../../../../assets/images/fff.png";
import certificate from "../../../../../assets/images/certificate.jpg";
import CustomIcon from "../../../../shared/customIcon/CustomIcon";
import {
  screenInitialState,
  screenReducer,
} from "../../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../../config/axios";
import { OverLayLoading } from "../../../../shared/loading/Loading";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
const Certificate = (props) => {
  const [screen, setScreen] = useState({
    data: null,
    swiperIndex: 0,
    showMobileSwiper: false,
  });

  const handleOnError = (e) => {
    e.target.src = dummy;
  };

  return (
    <Modal
      show={props.show}
      fullscreen={true}
      className="center"
      aria-labelledby="center swiper"
      centered
    >
      <Modal.Body>
        <div
          className={` ms-auto closeContainer ${
            screen.showMobileSwiper ? "two" : ""
          }  my-xl-3  my-lg-5 my-md-5  mb-3 `}
        >
          {screen.showMobileSwiper && (
            <CustomIcon
              icon="leftArrow"
              size={48}
              className="backIconWhite "
              onClick={() => {
                setScreen({ ...screen, showMobileSwiper: false });
              }}
            />
          )}
          <div className="closeContainerInside">
            <p>Close</p>
            <CustomTooltip title="Click to close" position="top">
              <CustomIcon
                icon="plus"
                size={48}
                className="closeIconWhite "
                onClick={props.onHide}
              />
            </CustomTooltip>
          </div>
        </div>

        <div
          className={`imageOne ${screen.showMobileSwiper ? "mobileView" : ""} `}
        >
          <img 
            onError={handleOnError}
            src={
              process.env.REACT_APP_BACKEND_URL + "public/" + props.image
            }
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Certificate;
