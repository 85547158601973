import React, { useState, useRef, useEffect, useReducer } from "react";
import { Col, Row, Button, Nav, Navbar } from "react-bootstrap";
import { GiHamburgerMenu } from "react-icons/gi";
import desktopLogo from "../../../assets/images/desktopLogo.png";
import "./updatedMenu.scss";
import CartOverlay from "./cartOverlay/CartOverlay";
import { Link, NavLink } from "react-router-dom";
import CustomIcon from "../../shared/customIcon/CustomIcon";
import logo from "../../../assets/images/mobileLogo.png";
import {
  ImFacebook,
  ImTwitter,
  ImYoutube,
  ImLinkedin,
  ImInstagram,
} from "react-icons/im";
import { AiFillInstagram } from "react-icons/ai";
import { BiChevronRightCircle, BiChevronLeftCircle } from "react-icons/bi";
import CustomTooltip from "../../shared/tooltip/CustomTooltip";
import {
  screenInitialState,
  screenReducer,
} from "../../shared/reducers/screenReducer";
import axiosInstance from "../../../config/axios";
const NAV_ITEMS = [
  ["Home"],
  [
    "discover-us",
    [
      "our-history",
      "fossil-sites",
      "fossil-lab",
      "the-shop",
      "fossil-museum",
      "mim-museum",
      "international-relations",
    ],
  ],
  ["fossils", ["memory-of-time-collection", "fossils-science"]],
  ["own-a-fossil"],
  [
    "memories",
    ["news-events", "media-gallery", "press-releases", "publications"],
  ],
  ["contact-us"],
];
const UpdatedMenu = () => {
  const [screen, setScreen] = useState({
    isDropdown: null,
    dropdownName: "",
    subMenu: "",
  });
  const [scrolled, setScrolled] = useState(false);
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const cartCountSaved = localStorage.getItem("cartItemsCount");
  const cartCount = JSON.parse(cartCountSaved);
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`home-page-settings`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
  }, []);
  const navItems = useRef();
  const handleNavHover = ({ target }) => {
    if (navItems && !navItems.current.contains(target)) {
      handleHoverOut();
    }
  };
  const handleHover = (name) => {
    setScreen({
      ...screen,
      isDropdown: true,
      dropdownName: name,
    });
  };
  const handleHoverOut = () => {
    setScreen({
      ...screen,
      isDropdown: false,
      dropdownName: "",
    });
  };
  const handleScroll = () => {
    const offsetTop = window.scrollY;
    if (offsetTop > 120) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  const format = (str) => {
    const str1 = str;
    const str2 = str1.charAt(0).toUpperCase() + str.replace("-", " ").slice(1);
    return str2.replace("-", " ");
  };
  const formatSub = (str) => {
    if (str === "mim") {
      str = str.toUpperCase();
    }
    str = str.replaceAll("-", " ");

    const words = str.split(" ");

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
  };
  useEffect(() => {
    setTimeout(() => {
      const elmnt = document.getElementById("root");
      if(elmnt.getBoundingClientRect().height-window.innerHeight > 250){
        window.addEventListener("scroll", handleScroll);
      }
    }, 1000);
 
      
   
  });
  useEffect(() => {
    document.addEventListener("mouseover", handleNavHover);
    return () => document.removeEventListener("mouseover", handleNavHover);
  });
  useEffect(() => { 
    const nav = document.querySelector(".Discover");
    if (window.location.href.indexOf("Discover%20us") > -1) {
      nav.classList.add("active");
    }
    const element = document.querySelector("#mainMenu");
   
    setTimeout(() => {
      let scrollHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight
      );
      const elmnt = document.getElementById("root");
      console.log(
        elmnt.getBoundingClientRect().height,
        "------",window.innerHeight,elmnt.getBoundingClientRect().height-window.innerHeight
      );
  
      window.addEventListener("scroll", (event) => {
        if (window.innerWidth > 1025 ) {
          if (elmnt.getBoundingClientRect().height-window.innerHeight>250 && window.scrollY > 120) {
            element.classList.add("menuDesktopSmall");
            element.classList.remove("menuDesktop");
            setScreen({
              ...screen,
              isScrolled: true,
            });
            setScrolled(true);
          } else {
            element.classList.remove("menuDesktopSmall");
            element.classList.add("menuDesktop");
  
            setScreen({
              ...screen,
              isScrolled: false,
            });
            setScrolled(false);
          }
        }
      });
    }, 1000);
  }, []);
  let activeStyle = {
    border: "1px solid #ECC57E",
    fontFamily: "gothamBold",
    marginBottom: "3px",
    // fontWeight: "600",
    letterSpacing: "0px",
  };
  let activeStyleSub = {
    color: "#29b4b8",
  };
  let navbarClasses = ["navbar"];
  if (scrolled) {
    navbarClasses.push("stickHeader");
  }
  return (
    <React.Fragment>
      {/* menu */}
      <Navbar className={"appHeader py-0 " + navbarClasses.join(" ")}>
        <div
          id="mainMenu"
          className={`uniPaddingHeader ${
            scrolled ? "menuDesktopSmall" : "menuDesktop"
          }  topMenu`}
        >
          <Row className="h-100 m-0 p-0 w-100 mainRow">
            <Col md={3} lg={2} xl={2} xxl={2} className="logoContainer">
              {scrolled ? (
                <CustomTooltip
                  title="Click to view home page"
                  position="bottom-start"
                >
                  <Link to="/">
                    <img
                      src={logo}
                      className="appDesktopLogoSmall"
                      alt="Memory of Time"
                    />
                  </Link>
                </CustomTooltip>
              ) : (
                <CustomTooltip
                  title="Click to view home page"
                  position="bottom-start"
                >
                  <Link to="/">
                    <img
                      src={desktopLogo}
                      className="appDesktopLogo"
                      alt="Memory of Time"
                    />
                  </Link>
                </CustomTooltip>
              )}
            </Col>
            <Col xl={9} lg={9} xxl={9} className="">
              <header className="header">
                <nav>
                  <ul ref={navItems}>
                    {NAV_ITEMS.map(([navName, navDropdown = []], index) => (
                      <div className="navLinkHolder" key={index}>
                        <NavLink
                          as="li"
                          to={navName === "Home" ? "/" : `/${navName}`}
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                          onClick={
                            navDropdown.length && navDropdown.length > 0
                              ? (e) => e.preventDefault()
                              : (event) => null
                          }
                          className={`navLink ${navName}`}
                          onMouseEnter={
                            window.innerWidth > 1025
                              ? () =>
                                  navDropdown.length
                                    ? handleHover(navName)
                                    : handleHoverOut()
                              : null
                          }
                        >
                          {format(navName)}
                          {navDropdown.length > 0 ? (
                            <span className="hideOnDesktop ms-2">
                              <BiChevronRightCircle />
                            </span>
                          ) : null}
                        </NavLink>
                        {navDropdown.length &&
                        screen.dropdownName === navName ? (
                          <ul className="dropdown">
                            <div className="redLine"></div>
                            {navDropdown.map((nav, index) => (
                              <NavLink
                                end
                                style={({ isActive }) =>
                                  isActive ? activeStyleSub : undefined
                                }
                                to={
                                  nav === "mim-museum"
                                    ? `/${navName}/mim`
                                    : `/${navName}/${nav}`
                                }
                                className="navLinkSub"
                                key={index}
                              >
                                {formatSub(nav) === "News Events"
                                  ? "News & Events"
                                  : formatSub(nav) === "Fossils Science"
                                  ? "Fossil's Science"
                                  : formatSub(nav) === "Mim Museum"
                                  ? "MIM Museum"
                                  : formatSub(nav)}
                              </NavLink>
                            ))}
                          </ul>
                        ) : null}
                      </div>
                    ))}
                    {screen.isDropdown && <div className="dropdown-bg" />}
                  </ul>
                </nav>
              </header>
            </Col>
            <Col xl={1} lg={1} xxl={1} className="center cartColumn ">
              <div className="p-0 ps-2 m-0 cartIconOffset ">
                <div
                  className={
                    screen.showModal === true
                      ? "cartIconHolder center active"
                      : "cartIconHolder center"
                  }
                  onClick={() =>
                    setScreen({ ...screen, showModal: !screen.showModal })
                  }
                >
                  <CustomTooltip
                    title="Take a look at your cart"
                    position="bottom"
                  >
                    <CustomIcon
                      icon="cart"
                      size={28}
                      className="menuCartIcon"
                    />
                  </CustomTooltip>

                  <CartOverlay value={screen.showModal} style="position" />
                  <div
                    className={`${cartCount <= 0 ? "hide" : ""} itemsCount`}
                    id="itemsCount"
                  >
                    {cartCount}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Navbar>

      {/* mobile menu holder */}
      <div className="menuHolderMobile uniPadding">
        <Row className="h-100 mainRowMobileHolder">
          <Col xs={4} sm={3} md={2} className="logoContainer">
            <CustomTooltip title="Click to view home page">
              <Link to="/">
                <img
                  src={logo}
                  className="appMobileLogo"
                  alt="Memory of Time"
                />
              </Link>
            </CustomTooltip>
          </Col>
          <Col xs={6} sm={4} md={3} className="iconsContainer">
            <Row className=" p-0 m-0  h-100 iconsRow">
              <Col className="end ps-2">
                <CustomTooltip title="Click to view menu">
                  <Button
                    variant="primary"
                    className="p-0 m-0 "
                    onClick={() =>
                      setScreen({
                        ...screen,
                        showMobileMenu: !screen.showMobileMenu,
                      })
                    }
                  >
                    <GiHamburgerMenu className="menuIcon" />
                  </Button>
                </CustomTooltip>
              </Col>
              <Col className="end pe-0">
                <CustomTooltip title="Take a look at your cart">
                  <div variant="primary" className="p-0 m-0 ">
                    <div
                      className={
                        screen.showModal === true
                          ? "cartIconHolder center active"
                          : "cartIconHolder center"
                      }
                      onClick={() =>
                        setScreen({
                          ...screen,
                          showModal: !screen.showModal,
                          showMobileMenu: false,
                        })
                      }
                    >
                      <CustomIcon
                        icon="cart"
                        size={28}
                        className="menuCartIcon"
                      />
                      <CartOverlay value={screen.showModal} />
                      <div
                        className={`${cartCount <= 0 ? "hide" : ""} itemsCount`}
                      >
                        {cartCount}
                      </div>
                    </div>
                  </div>
                </CustomTooltip>
              </Col>
              {/* <Col className="center pe-0 ps-1">
                <Button variant="primary" className="p-0 m-0 ">
                  <div className="chatIconHolder center">
                    <CustomTooltip title="Click to chat">
                      <CustomIcon
                        icon="commentsSolid"
                        size={64}
                        className="menuChatIcon"
                      />
                    </CustomTooltip>
                  </div>
                </Button>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </div>
      <div
        className={`mobileMenu subContentDisplay p-2 ${
          screen.showMobileMenu === true ? "show" : "hide"
        }`}
      >
        <Row className="w-100 m-0">
          <Col className="p-0">
            {screen.subMenu === "" && (
              <Nav className="navbarContainer center flex-column">
                <NavLink
                  to="/"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  className="navLink"
                >
                  Home
                </NavLink>

                <NavLink
                  // to="/discoverUs/ourHistory"
                  className="navLink"
                  onClick={() =>
                    setScreen({ ...screen, subMenu: "discoverUs" })
                  }
                  // style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Discover us{" "}
                  <span>
                    <BiChevronRightCircle />
                  </span>
                </NavLink>
                <NavLink
                  className="navLink"
                  onClick={() => setScreen({ ...screen, subMenu: "fossils" })}
                >
                  Fossils{" "}
                  <span>
                    <BiChevronRightCircle />
                  </span>
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/own-a-fossil"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Own a fossil
                </NavLink>
                <NavLink
                  className="navLink"
                  onClick={() => setScreen({ ...screen, subMenu: "memories" })}
                >
                  Memories{" "}
                  <span>
                    <BiChevronRightCircle />
                  </span>
                </NavLink>
                <NavLink
                  className="navLink"
                  onClick={() => setScreen({ ...screen, subMenu: "contactUs" })}
                >
                  Contact us{" "}
                  <span>
                    <BiChevronRightCircle />
                  </span>
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/testimonial"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Testimonials
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/terms-and-conditions"
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Terms & Conditions
                </NavLink>
              </Nav>
            )}
            {screen.subMenu === "discoverUs" && (
              <Nav className="subNavbarDiscover  center flex-column pt-4">
                <div className="center addIconBefore">
                  <BiChevronLeftCircle
                    className="leftMenuIcon"
                    onClick={() => setScreen({ ...screen, subMenu: "" })}
                  />

                  <NavLink
                    className="navLinkBold"
                    onClick={() => setScreen({ ...screen, subMenu: "" })}
                  >
                    {" "}
                    Discover us
                  </NavLink>
                </div>
                <NavLink
                  className="navLink mt-3"
                  to="/discover-us/our-history"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  Our History
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/discover-us/fossil-sites"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  Fossil Sites
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/discover-us/fossil-lab"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  Fossil Lab
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/discover-us/the-shop"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  The Shop
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/discover-us/fossil-museum"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  Fossil Museum{" "}
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/discover-us/mim"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  MIM Museum
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/discover-us/international-relations"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  International Relations
                </NavLink>
              </Nav>
            )}
            {screen.subMenu === "fossils" && (
              <Nav className="subNavbarDiscover  center flex-column pt-4">
                <div className="center addIconBefore">
                  <BiChevronLeftCircle
                    className="leftMenuIcon"
                    onClick={() => setScreen({ ...screen, subMenu: "" })}
                  />

                  <NavLink
                    className="navLinkBold"
                    onClick={() => setScreen({ ...screen, subMenu: "" })}
                  >
                    Fossils
                  </NavLink>
                </div>

                <NavLink
                  className="navLink mt-3"
                  to="/fossils/memory-of-time-collection"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  Memory of Time Collection
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/fossils/fossil's-science"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  Fossil's Science
                </NavLink>
              </Nav>
            )}
            {screen.subMenu === "memories" && (
              <Nav className="subNavbarDiscover  center flex-column pt-4">
                <div className="center addIconBefore">
                  <BiChevronLeftCircle
                    className="leftMenuIcon"
                    onClick={() => setScreen({ ...screen, subMenu: "" })}
                  />

                  <NavLink
                    className="navLinkBold"
                    onClick={() => setScreen({ ...screen, subMenu: "" })}
                  >
                    Memories
                  </NavLink>
                </div>

                <NavLink
                  className="navLink mt-3"
                  to="/memories/news-events-mobile"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  News & Events
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/memories/media-gallery"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  Media Gallery
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/memories/press-releases"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  Press Releases
                </NavLink>
                <NavLink className="navLink">
                  {" "}
                  <NavLink
                    className="navLink"
                    to="/memories/publications"
                    style={({ isActive }) =>
                      isActive ? activeStyleSub : undefined
                    }
                  >
                    Publications
                  </NavLink>
                </NavLink>
              </Nav>
            )}
            {screen.subMenu === "contactUs" && (
              <Nav className="subNavbarDiscover  center flex-column pt-4">
                <div className="center addIconBefore">
                  <BiChevronLeftCircle
                    className="leftMenuIcon"
                    onClick={() => setScreen({ ...screen, subMenu: "" })}
                  />

                  <NavLink
                    className="navLinkBold"
                    onClick={() => setScreen({ ...screen, subMenu: "" })}
                  >
                    Contact us
                  </NavLink>
                </div>
                <NavLink
                  className="navLink mt-3"
                  to="/contact-us"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  Contact us
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/how-to-reach-us"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  How to reach us
                </NavLink>
                <NavLink
                  className="navLink"
                  to="/useful-links"
                  style={({ isActive }) =>
                    isActive ? activeStyleSub : undefined
                  }
                >
                  Useful Links
                </NavLink>
              </Nav>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="socialMedia mt-5">
              <div className="center">
                {detailsState &&
                  detailsState.data &&
                  detailsState.data.socialChannels &&
                  detailsState.data.socialChannels.map((item, index) => {
                    if (item.name === "facebook" && item.value) {
                      return (
                        <CustomTooltip
                          key={index}
                          title="Visit our facebook page"
                        >
                          <a
                            href={item.value}
                            target="_blank"
                            className="socialItem center"
                          >
                            <ImFacebook className="socialIcon" />
                          </a>
                        </CustomTooltip>
                      );
                    }
                    if (item.name === "youtube" && item.value) {
                      return (
                        <CustomTooltip
                          key={index}
                          title="Visit our youtube channel"
                        >
                          <a
                            href={item.value}
                            target="_blank"
                            className="socialItem center"
                          >
                            <ImYoutube className="socialIcon" />
                          </a>
                        </CustomTooltip>
                      );
                    }
                    if (item.name === "twitter" && item.value) {
                      return (
                        <CustomTooltip
                          key={index}
                          title="Visit our twitter page"
                        >
                          <a
                            href={item.value}
                            target="_blank"
                            className="socialItem center"
                            title="Visit our twitter page"
                          >
                            <ImTwitter className="socialIcon" />
                          </a>
                        </CustomTooltip>
                      );
                    }
                    if (item.name === "instagram" && item.value) {
                      return (
                        <CustomTooltip
                          key={index}
                          title="Visit our instagram page"
                        >
                          <a
                            href={item.value}
                            target="_blank"
                            className="socialItem center"
                            title="Visit our twitter page"
                          >
                            <AiFillInstagram className="socialIcon" />
                          </a>
                        </CustomTooltip>
                      );
                    }
                    if (item.name === "linkedin" && item.value) {
                      return (
                        <CustomTooltip
                          key={index}
                          title="Visit our linkedin page"
                        >
                          <a
                            href={item.value}
                            target="_blank"
                            className="socialItem center"
                            title="Visit our twitter page"
                          >
                            <ImLinkedin className="socialIcon" />
                          </a>
                        </CustomTooltip>
                      );
                    }
                  })}

                {/* <a
                  href="#"
                  className="socialItem center"
                  title="Visit our instagram page"
                >
                  <AiFillInstagram className="socialIcon" />
                </a> */}
              </div>
              <CustomTooltip title="Click to tweet with this hashtag">
                {detailsState &&
                  detailsState.data &&
                  detailsState.data.hashtag && (
                    <a
                      href={`http://twitter.com/intent/tweet?text=%23${detailsState.data.hashtag.slice(
                        1
                      )}`}
                      target="_blank"
                      className="hashtag center mt-3 mb-0 ps-1"
                    >
                      {detailsState.data.hashtag}{" "}
                    </a>
                  )}
              </CustomTooltip>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default UpdatedMenu;
