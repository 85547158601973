import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import BodyLayout from "../../../layout/body/BodyLayout";
import { Formik } from "formik";
import { AiOutlineSearch } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { Loading, OverLayLoading } from "../../../shared/loading/Loading";
import "../memories.scss";
import ListingComponent from "./listingComponent/ListingComponent";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import {
  screenInitialState,
  screenReducer,
} from "../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../config/axios";
import {
  FetchError,
  NoItemsFound,
  ShowingSearchResultsDialog,
} from "../../../shared/displayAlerts/DisplayAlerts";
import CMSFormSelect from "../../../shared/formFields/CMSFormSelect";
import ModalFilter from "../../../shared/modal/ModalFilter";
import PublicationFilter from "./filter/PublicationFilter";
import AppliedPublicationFilters from "./filter/AppliedPublicationFilters";
const Publications = () => {
  const [screen, setScreen] = useState({
    loadMore: false,
    data: null,
    showModal: false,
    filterShow: false,
    sortShow: false,
    sortedValue: "",
    searchedValue: "",
    language: [],
    year: [],
    sources: [],
    relevant: "",
  });
  const [search, setSearch] = useState("");
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  useEffect(() => {
    if(screen.showModal){
      if (window.Tawk_API) {
        window.Tawk_API.hideWidget();
      }
      return () => {
        if (window.Tawk_API) {
          window.Tawk_API.showWidget();
        }
      };
    }
  
}, [screen.showModal]);
  const sortArray = [
    {
      value: "-1",
      label: "Newest",
    },
    {
      value: "1",
      label: "Oldest",
    },
  ];
  const getFilterString = () => {
    let filterString = "";
    if (screen.sortedValue) {
      filterString = filterString + "sortYear=" + screen.sortedValue;
    }
    if (screen.relevant !== "") {
      filterString = filterString + "&relevant=" + 1;
    }
    if (screen.searchedValue) {
      filterString = "&q=" + screen.searchedValue;
    }

    if (screen.language.length > 0) {
      for (let index = 0; index < screen.language.length; index++) {
        filterString = filterString + "&languages[]=" + screen.language[index];
      }
    }
    if (screen.sources.length > 0) {
      for (let index = 0; index < screen.sources.length; index++) {
        filterString = filterString + "&sources[]=" + screen.sources[index];
      }
    }
    if (screen.year.length > 0) {
      for (let index = 0; index < screen.year.length; index++) {
        filterString = filterString + "&years[]=" + screen.year[index];
      }
    }

    return filterString;
  };
  // API fetch
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    const filterAppliedSaved = localStorage.getItem(
      "publicationFiltersApplied"
    );
    const filterApplied = JSON.parse(filterAppliedSaved);
    const publicationScreenSaved = localStorage.getItem(
      "publicationScreenValues"
    );
    const publicationScreen = JSON.parse(publicationScreenSaved);
    let filterString = getFilterString();

    if (
      (filterString === "sortYear=" || filterString === "") &&
      filterApplied
    ) {
      filterString = filterApplied ? filterApplied : filterString;
      setScreen({
        ...screen,
        sortedValue: publicationScreen.sortedValue,
        searchedValue: publicationScreen.searchedValue,
        language: publicationScreen.language,
        year: publicationScreen.year,
        relevant: publicationScreen.relevant,
        sources: publicationScreen.sources,
      });
      setSearch(publicationScreen.searchedValue);
    }

    console.log(filterApplied, publicationScreen, filterString, "inside item");
    try {
      const res = await axiosInstance.get(
        "publications" + (filterString ? "?" + filterString : "")
      );
      localStorage.setItem(
        "publicationFiltersApplied",
        JSON.stringify(filterString)
      );
      localStorage.setItem("publicationScreenValues", JSON.stringify(screen));
      setScreen((screen) => ({
        ...screen,
        loadMore: false,
        filterShow: true,
        data: res.data.items,
      }));
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    localStorage.removeItem("newsFiltersApplied");
    localStorage.removeItem("newsScreenValues");
    localStorage.removeItem("motFiltersApplied");
    localStorage.removeItem("motScreenValues");
    localStorage.removeItem("ownAFossilFiltersApplied");
    localStorage.removeItem("ownAFossilScreenValues");
    localStorage.removeItem("mediaFiltersApplied");
    localStorage.removeItem("mediaScreenValues");
    localStorage.removeItem("pressFiltersApplied");
    localStorage.removeItem("pressScreenValues");
  }, []);
  // function to load more items
  const loadMoreItems = async () => {
    if (
      detailsState &&
      detailsState.data &&
      detailsState.data.pagination &&
      detailsState.data.pagination.currentPage <
        detailsState.data.pagination.pageCount
    ) {
      setScreen((prevState) => ({ ...prevState, loadMore: true }));
      let filterString = getFilterString();
      try {
        const res = await axiosInstance.get(
          "publications" +
            "?page=" +
            detailsState.data.pagination.nextPage +
            (filterString ? "&" + filterString : "")
        );

        setScreen((screen) => ({
          ...screen,
          loadMore: false,
          data: screen.data.concat(res.data.items),
        }));
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    } else {
    }
  };

  // function to clear search
  const handleClear = (values) => {
    localStorage.setItem("publicationFiltersApplied", JSON.stringify(""));
    localStorage.setItem(
      "publicationScreenValues",
      JSON.stringify({ ...screen, searchedValue: "" })
    );
    setScreen({
      ...screen,
      searchedValue: "",
    });
    setSearch("");
  };
  // function for  filter form submission
  const handleFormSubmit = (values) => {
    setScreen({
      ...screen,
      showModal: false,
      sortedValue: values.sort,
      language: values.languages,
      year: values.years,
      relevant: values.relevant,
      sources: values.sources,
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit(search);
      window.scrollTo(0, 0);
    }
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleSearchSubmit = () => {
    localStorage.setItem("publicationFiltersApplied", JSON.stringify(""));
    localStorage.setItem(
      "publicationScreenValues",
      JSON.stringify({ ...screen, searchedValue: search })
    );
    setScreen({
      ...screen,
      searchedValue: search,
    });
    window.scrollTo(0, 0);
  };

  // calling load function
  useEffect(() => {
    loadItem();
  }, [
    screen.searchedValue,
    screen.sortedValue,
    screen.language,
    screen.year,
    screen.relevant,
    screen.sources,
  ]);
  useEffect(() => {
    document.title = "Publications";
  }, []);
  return (
    <BodyLayout>
      <div
        className={`memoriesSection ${
          detailsState.hasError ? "center" : ""
        }  position-relative pb-0`}
      >
        <div className="yellowBox"></div>
        {detailsState.isFetching === true && screen.data === null && (
          <Loading />
        )}
        {detailsState.isFetching === true && screen.data !== null && (
          <OverLayLoading />
        )}

        {detailsState &&
          detailsState.isFetching === false &&
          detailsState.hasError === true && (
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
                <FetchError />
              </Col>
            </Row>
          )}

        {detailsState &&
          detailsState.isFetching === false &&
          detailsState.hasError !== true && (
            <Row className="ms-xl-1 pb-xl-3 pb-lg-4 pb-md-5 pb-sm-4 pb-4">
              <Col xl={12} className="py-xl-5 pb-lg-3 pb-md-4">
                {detailsState && detailsState.hasError !== true && (
                  <Row className=" uniPadding pt-xl-4 mt-xl-3 pt-md-5 pb-md-3">
                    <Col className="ps-xl-3 ps-md-4">
                      <div className="headContainer">
                        <h3 className="headingText">Publications</h3>
                      </div>
                    </Col>
                  </Row>
                )}
                {detailsState && detailsState.hasError !== true && (
                  <>
                    <Row className=" uniPadding pt-0">
                      <Col className="ps-4">
                        <Row
                          className={`uniPaddingOwnAFossil  hidOnMobile filterRow innerSection2 pt-0  `}
                        >
                          <Col
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="pt-1 pe-xl-0 filters"
                          >
                            <div className="searchBox me-xl-4 me-md-4">
                              <input
                                id="search"
                                name="search"
                                placeholder="search"
                                type="text"
                                onKeyDown={handleKeyPress}
                                onChange={handleChange}
                                value={search}
                              />
                              <div
                                className="icon center"
                                onClick={() => handleSearchSubmit(search)}
                              >
                                <AiOutlineSearch className="searchIcon" />
                              </div>
                            </div>

                            <>
                              <Formik
                                initialValues={{
                                  sort: screen.sortedValue
                                    ? screen.sortedValue
                                    : "",
                                }}
                                onSubmit={async (values) => {
                                  setScreen({
                                    ...screen,
                                    sortedValue: values.sort,
                                  });
                                }}
                              >
                                {(form) => (
                                  <>
                                 
                                      <div
                                        className={`filterBox sort me-xl-4 me-md-4${
                                          screen.sortShow === true
                                            ? " viewAll "
                                            : ""
                                        } center`}
                                        onClick={() =>
                                          setScreen((screen) => ({
                                            ...screen,
                                            sortShow: !screen.sortShow,
                                          }))
                                        }
                                      >
                                        <CMSFormSelect
                                          name="sort"
                                          placeholder={{
                                            value: " ",
                                            label: "sort by",
                                          }}
                                          selected={screen.sortedValue}
                                          form={form}
                                          onChange={(e) => {
                                            form.handleChange(e);
                                            form.handleSubmit(e);
                                          }}
                                          filter={true}
                                          options={sortArray}
                                        />
                                      </div>
                                 
                                  </>
                                )}
                              </Formik>

                              <div
                                className={`filterBox ${
                                  screen.filterShow === true ? "viewAll  " : ""
                                }  center`}
                                onClick={() =>
                                  setScreen((screen) => ({
                                    ...screen,
                                    filterShow: !screen.filterShow,
                                  }))
                                }
                              >
                               
                                  <div className="arrow-down"></div>
                                  view all filters
                                
                              </div>
                            </>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mobileFilters pb-3 pt-3 uniPadding center showOnMobile">
                      <Col
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className="pt-1  filters "
                      >
                        <div className="mobileSearchComponent">
                          <div
                            className={`searchBox ${
                              screen.expandSearchField ? "expand" : ""
                            }`}
                          >
                            <input
                              id="search"
                              name="search"
                              placeholder="search"
                              type="text"
                              onChange={handleChange}
                              value={search}
                            />
                            <div
                              className="icon center"
                              onClick={
                                screen.expandSearchField
                                  ? () => handleSearchSubmit(search)
                                  : () =>
                                      setScreen((screen) => ({
                                        ...screen,
                                        expandSearchField:
                                          !screen.expandSearchField,
                                      }))
                              }
                              // onClick={() => handleSearchSubmit(search)}
                            >
                              <AiOutlineSearch className="searchIcon" />
                            </div>
                            {screen.expandSearchField && (
                              <div
                                className="icon center"
                                onClick={() =>
                                  setScreen((screen) => ({
                                    ...screen,
                                    expandSearchField:
                                      !screen.expandSearchField,
                                  }))
                                }
                                // onClick={() => handleSearchSubmit(search)}
                              >
                                <MdClose className="searchIcon" />
                              </div>
                            )}
                          </div>
                        </div>

                        <>
                          {screen.showModal === true && (
                            <ModalFilter
                              show={screen.showModal}
                              onHide={() =>
                                setScreen({ ...screen, showModal: false })
                              }
                            >
                              <div className="filterExpandedMobile pt-3">
                                <div className="filterContentMobile">
                                  <div className="mobileFilterTop">
                                    <div
                                      
                                      className={`filterBox ${
                                        screen.expandSearchField === true
                                          ? "expand"
                                          : ""
                                      } ${
                                        screen.showModal === true
                                          ? "viewAllMobile "
                                          : ""
                                      } center`}
                                      onClick={() =>
                                        setScreen((screen) => ({
                                          ...screen,
                                          showModal: !screen.showModal,
                                        }))
                                      }
                                    >
                                      <div className="arrow-down"></div>
                                      view all filters
                                    </div>
                                    <div
                                      className="icon center"
                                      onClick={() =>
                                        setScreen({
                                          ...screen,
                                          showModal: false,
                                        })
                                      }
                                      // onClick={() => handleSearchSubmit(search)}
                                    >
                                      <MdClose className="filterCloseIconMobile" />
                                    </div>
                                  </div>
                                  <PublicationFilter
                                    onSubmit={handleFormSubmit}
                                    screen={screen}
                                    setParentScreen={setScreen}
                                    search={search}
                                    setSearch={setSearch}
                                    languages={
                                      detailsState &&
                                      detailsState.data &&
                                      detailsState.data.availableFilters
                                        .languages
                                    }
                                    sources={
                                      detailsState &&
                                      detailsState.data &&
                                      detailsState.data.availableFilters.sources
                                        ? detailsState.data.availableFilters
                                            .sources
                                        : []
                                    }
                                    years={
                                      window.innerWidth <= 767
                                        ? detailsState &&
                                          detailsState.data &&
                                          detailsState.data.availableFilters
                                            .years
                                        : []
                                    }
                                    sortArray={sortArray}
                                  />
                                </div>
                              </div>
                            </ModalFilter>
                          )}

                          <div
                           
                            className={`filterBox ${
                              screen.expandSearchField === true ? "expand" : ""
                            } ${
                              screen.filterMobileShow === true
                                ? "viewAll  "
                                : ""
                            } center`}
                            onClick={() =>
                              setScreen((screen) => ({
                                ...screen,
                                showModal: !screen.showModal,
                              }))
                            }
                          >
                            <div className="arrow-down"></div>
                            view all filters
                          </div>
                        </>
                      </Col>
                    </Row>
                  </>
                )}

                {detailsState && detailsState.data && (
                  <Row className="uniPadding innerSection3 pt-2">
                    <Col
                      xl={
                        screen.filterShow ||
                        (detailsState.data &&
                          detailsState.data.items &&
                          detailsState.data.items.length > 0)
                          ? 7
                          : 12
                      }
                      lg={
                        screen.filterShow ||
                        (detailsState.data &&
                          detailsState.data.items &&
                          detailsState.data.items.length > 0)
                          ? 8
                          : 12
                      }
                      md={
                        screen.filterShow ||
                        (detailsState.data &&
                          detailsState.data.items &&
                          detailsState.data.items.length > 0)
                          ? 8
                          : 12
                      }
                      sm={12}
                      xs={12}
                      className="pt-xl-3 pt-lg-3 pt-md-3 pt-sm-3 pt-2 mt-1 px-3 "
                    >
                      {/* applied filters display start */}
                      {screen.language.length > 0 ||
                      screen.year.length > 0 ||
                      screen.sources.length > 0 ||
                      screen.relevant ? (
                        <AppliedPublicationFilters
                          screen={screen}
                          setParentScreen={setScreen}
                        />
                      ) : null}
                      {/* applied filters display end */}

                      {detailsState &&
                        detailsState.isFetching === false &&
                        detailsState.data &&
                        screen.searchedValue !== "" && (
                          <ShowingSearchResultsDialog
                            value={screen.searchedValue}
                            onClick={handleClear}
                            count={
                              detailsState &&
                              detailsState.data &&
                              detailsState.data.pagination &&
                              detailsState.data.pagination.totalFilteredItems
                            }
                          />
                        )}
                      {detailsState &&
                        detailsState.isFetching === false &&
                        detailsState.data &&
                        detailsState.data.items &&
                        detailsState.data.items.length <= 0 && <NoItemsFound />}
                      {detailsState && detailsState.data && (
                        <>
                          {screen.data &&
                            screen.data.map((item, index) => {
                              return (
                                <>
                                  <ListingComponent item={item} />
                                </>
                              );
                            })}

                          {parseInt(
                            detailsState &&
                              detailsState.data &&
                              detailsState.data.pagination &&
                              detailsState.data.pagination.currentPage
                          ) <
                            parseInt(
                              detailsState &&
                                detailsState.data &&
                                detailsState.data.pagination &&
                                detailsState.data.pagination.pageCount
                            ) && (
                            <div
                              className="loadMore pt-4 mt-3 pb-0"
                              onClick={loadMoreItems}
                            >
                              {screen.loadMore === true ? (
                                <div className="spinner-container center">
                                  <div className="loading-spinner"></div>
                                </div>
                              ) : (
                                <span>
                                  show more
                                </span>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </Col>
                    <Col className="rightFilters mt-1">
                      {console.log(detailsState && detailsState.data)}
                      {screen.filterShow === true && (
                        <div className={`filterContent hideOnMobile `}>
                          <PublicationFilter
                            onSubmit={handleFormSubmit}
                            screen={screen}
                            setParentScreen={setScreen}
                            search={search}
                            setSearch={setSearch}
                            languages={
                              window.innerWidth > 767
                                ? detailsState &&
                                  detailsState.data &&
                                  detailsState.data.availableFilters.languages
                                : []
                            }
                            sources={
                              window.innerWidth > 767
                                ? detailsState &&
                                  detailsState.data &&
                                  detailsState.data.availableFilters.sources
                                  ? detailsState.data.availableFilters.sources
                                  : []
                                : []
                            }
                            years={
                              window.innerWidth > 767
                                ? detailsState &&
                                  detailsState.data &&
                                  detailsState.data.availableFilters.years
                                : []
                            }
                            sortArray={[]}
                          />
                        </div>
                      )}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          )}
      </div>
    </BodyLayout>
  );
};

export default Publications;
