import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import "./orderSuccessMessage.scss";
import { Button } from "react-bootstrap";
const OrderSuccessMessage = ({ orderId }) => {
  const navigate = useNavigate();
  return (
    <div className="center flex-column msgBodyOrder">
      <AiFillCheckCircle className="submitSuccessIcon theme2" />
      <h2 className="submitSuccessHead theme2">Thank you!</h2>
      <p className="submitSuccessPara theme2">
        Your order has been received and will be processed soon.
      </p>

      <p className="submitSuccessPara theme2">
        Your order number is: <strong>{orderId}</strong>
      </p>

      <div className="additionalMsgAfterSubmission">
        <div>
          <p>
            We have sent an invoice with your order's details on your registered
            email address.
          </p>
          <p>
            If you do not receive the message within a few minutes of your
            order, please check your Bulk Mail / Spam / Junk folders just in
            case the email got delivered there instead of your inbox.{" "}
          </p>
        </div>
        <p>
          if you have any query, Kindly{" "}
          <Link to="/contact-us" className="link pink">
            Contact us{" "}
          </Link>
        </p>
      </div>
      {/* <Link to="/own-a-fossil" className="link">
        Continue Shopping
      </Link> */}
      <Button
        className="bordered "
        variant="secondary"
        onClick={() => navigate("/own-a-fossil")}
      >
        Continue Shopping
      </Button>
    </div>
  );
};

export default OrderSuccessMessage;
