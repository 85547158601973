import React from "react";
import "sanitize.css";
import "./sliderStyles.scss";
import InputRange from "react-input-range";
import Slider from "@material-ui/core/Slider";
import { makeStyles } from "@material-ui/core/styles";

// docs:
// - https://material-ui.com/api/slider/#css
// - https://material-ui.com/styles/advanced/#makestyles-withstyles-styled
const useStyles = makeStyles(
  {
    track: {
      color: "#29b4b8",
      height: "5px",
      borderRadius: "3px",
    },
    rail: {
      opacity: 1,
      backgroundColor: "#fff",
      border: "1px solid #29b4b8",
      height: "5px",
      borderRadius: "0px",
    },
    thumb: {
      color: "white",
      width: "17px",
      height: "17px",
      marginTop: "-6px",
      border: "2px solid #d9983e",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.09)",
      "&$focusVisible,&:hover": {
        boxShadow: "0 0 0 8px rgba(41, 180, 184, 0.2)",
      },
      "&$active": {
        boxShadow: "0 0 0 14px rgba(41, 180, 184, 0.2)",
      },
    },
    // don't remove the following lines
    focusVisible: {},
    active: {},
  },
  { name: "MuiSlider" }
);

export default function CMSSlider({ name, form, priceRange }) {
  const [val2, setVal2] = React.useState([
    priceRange.minValue,
    priceRange.maxValue,
  ]);

  const classes = useStyles();

  return (
    <div>
      <div>
        <div>
          <Slider
            //disabled
            classes={classes}
            step={5}
            min={priceRange.minValue}
            max={priceRange.maxValue}
            value={val2}
            onChange={(ev, v) => setVal2(v)}
            onChangeCommitted={(ev, v) => form.setFieldValue(name, v)}
            valueLabelDisplay="off"
            aria-labelledby="range-slider"
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "5px 0",
            fontWeight: 500,
            color: "#29b4b8",
            maxWidth: "180px",
          }}
        >
          <div>{`$${val2[0]}`}</div>
          <div>{`$${val2[1]}`}</div>
        </div>
      </div>
    </div>
  );
}
