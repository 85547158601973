import React, { useReducer } from "react";
import BodyLayout from "../layout/body/BodyLayout";
import { Button, Col, Row } from "react-bootstrap";
import CustomTooltip from "../shared/tooltip/CustomTooltip";
import { BorderButton, FormSubmitButton } from "../shared/buttons/Buttons";
import {
  updateInitialState,
  updateReducer,
} from "../shared/reducers/updateReducer";
import axiosInstance from "../../config/axios";
import CMSFormInput from "../shared/formFields/CMSFormInput";
import { Formik } from "formik";
import { unSubscribeSchema } from "../../utils/yupValidation";
import "./unsubscribe.scss";
import { OverLayLoading } from "../shared/loading/Loading";
const Unsubscribe = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const handleSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axiosInstance.post(`unsubscribe`, data);
      window.scrollTo(0, 0);
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(error.response);
      if (error.response.status === 404) {
        updateDispatch({
          type: "FORM_SUBMISSION_FAILURE",
          payload: "404",
        });
      } else {
        updateDispatch({
          type: "FORM_SUBMISSION_FAILURE",
          payload: errorMessage,
        });
      }
      window.scrollTo(0, 0);

      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 12000);
    }
  };
  return (
    <BodyLayout>
      {updateState.isSubmitting === true && <OverLayLoading />}
      <div className={`unSubscribeSection `}>
        <div className="yellowBox"></div>
        {!updateState.hasSubmissionError &&
          !updateState.isSubmitted &&
          updateState.initialState && (
            <Row className=" uniPadding py-5">
              <Col className="ps-xl-4 ps-md-4">
                <Row className="pb-0 pt-xl-2 ">
                  <Col>
                    <h1 className="title mb-2">Unsubscribe from the list</h1>
                    <p className="subText mb-1">We are sorry to see you go!</p>
                    <p className="subText">
                      If you unsubscribe from our list you will no longer
                      receive emails.
                    </p>

                    <Row className="ps-1 position-relative">
                      <Col xl={12} className="py-0 ms-md-1">
                        <Formik
                          initialValues={{
                            email: "",
                          }}
                          validationSchema={unSubscribeSchema}
                          onSubmit={(values, { resetForm }) => {
                            handleSubmit(values);
                          }}
                        >
                          {(form) => (
                            <>
                              <Row className=" position-relative ">
                                <Col xs={12} sm={12} md={10} lg={10} xl={8}>
                                  <CMSFormInput
                                    name="email"
                                    placeholder="Email Address"
                                    form={form}
                                    required={true}
                                  />
                                </Col>

                                <Col
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={4}
                                  className="marginTop "
                                >
                                  <Button
                                    className={`unSubscribeButton`}
                                    variant="secondary"
                                    onClick={(e) => form.handleSubmit(e)}
                                  >
                                    Unsubscribe
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Formik>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        {updateState.isSubmitted === true && (
          <Row className="py-5 center messageRow">
            <Col xl={4} xs={11} md={6} className="ps-1">
              <h1 className="title mb-2 center">You're Off the List!</h1>
              <p className="subText mb-1 text-center center">
                You have successfully unsubscribed from our list and won't
                receive any further emails from us.
              </p>
            </Col>
          </Row>
        )}
        {updateState.hasSubmissionError === true && (
          <Row className="py-5 center messageRow">
            <Col xl={5} xs={11} md={7} className="">
              {updateState.error === "404" ? (
                <>
                  <h1 className="title mb-2 center">Sorry about that!</h1>
                  <p className="subText mb-1 text-center center">
                    It appears that the email you provided is not subscribed to
                    our mailing list. Please verify the email address or
                    consider subscribing to receive our emails.
                  </p>
                </>
              ) : (
                <>
                  <h1 className="title mb-2 center">Sorry about that!</h1>
                  <p className="subText mb-1 text-center center">
                    Something went wrong, Please try again.
                  </p>
                </>
              )}
            </Col>
          </Row>
        )}
      </div>
    </BodyLayout>
  );
};

export default Unsubscribe;
