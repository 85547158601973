import React, { useState, useEffect, useReducer } from "react";
import "./productCard.scss";
// import fossilOne from "../../../assets/images/fossilOne.png";
import dummy from "../../../assets/images/dummyBg.png";
import { BsFillBagCheckFill } from "react-icons/bs";
import { MdOutlineError } from "react-icons/md";
import CustomIcon from "../customIcon/CustomIcon";
import $ from "jquery";
import { Link } from "react-router-dom";
import CustomTooltip from "../tooltip/CustomTooltip";
import InitialEmailSubmission from "./initialEmailSubmission/InitialEmailSubmission";
import { updateInitialState, updateReducer } from "../reducers/updateReducer";
import axiosInstance from "../../../config/axios";
import { screenInitialState, screenReducer } from "../reducers/screenReducer";
// product card  with details
function ProductCard({ product, onClick, parent, parentState, page }) {
  const [screen, setScreen] = useState({
    display: "image",
    addedToCart: true,
    sendToCart: false,
    buyerId: null,
    shake: false,
    show: false,
    reload: false,
    addedToCartError: false,
  });
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const localEmailValueFetch = localStorage.getItem("BuyerEmail");
  const localEmailValue = JSON.parse(localEmailValueFetch);
  const productId = product._id;
  const showBasketIconAnimation = (itemClass) => {
    setTimeout(() => {
      setScreen((prevState) => ({
        ...prevState,
        addedToCart: !screen.addedToCart,
      }));
    }, 1500);

    var emlToDrag = $("." + itemClass);
    var cart = $(".cartIconHolder");
    var basket = $(".fullOverlay");
    var width = window.innerWidth;
    var cartOverlayContent = $(".cartOverlayContainer");
    var cartOverlayContentPosition = $(".cartOverlayContainerPosition");
    var itemsCount = $(".itemsCount");
    if (width >= 1025) {
      $(emlToDrag).addClass("rotate");
      setTimeout(function () {
        if (emlToDrag) {
          var emlClone = emlToDrag
            .clone()
            .offset({
              top: emlToDrag.offset().top,
              left: emlToDrag.offset().left,
            })
            .css({
              position: "absolute",
              "z-index": "205",
              color: "brown",
            })
            .appendTo($("body"))
            .animate(
              {
                top: cart.offset().top,
                left: $(".cartIconOffset").offset().left,

                width: 60,
                height: 60,
              },
              700,
              "swing",
              function () {
                $(emlClone).hide();
                setTimeout(function () {
                  $(cart).addClass("shake").css({ backgroundColor: "#F2D69C" });
                  $(basket).addClass("show").removeClass("hide");
                  $(cartOverlayContent).addClass("show").removeClass("hide");
                  $(cartOverlayContentPosition)
                    .addClass("show")
                    .removeClass("hide");
                  $(itemsCount).addClass("show").removeClass("hide");

                  setTimeout(function () {
                    $(cart).css({ backgroundColor: "transparent" });
                    $(basket).addClass("hide").removeClass("show");
                    $(cartOverlayContent).addClass("hide").removeClass("show");
                    $(cartOverlayContentPosition)
                      .addClass("hide")
                      .removeClass("show");
                  }, 3000);
                }, 500);
              }
            );
          emlClone.animate(
            {
              top: basket.offset().top,
              left: basket.offset().left,
              width: 19,
              height: 19,
              "font-size": "8px",
              padding: "0",
            },
            function () {
              cart.removeClass("shake");
              setTimeout(function () {
                $(emlToDrag).removeClass("shrink");
                emlClone.css({
                  opacity: "0",
                  display: "none",
                });
              }, 500);
              $(emlToDrag).removeClass("add-to-cart").addClass("view-cart");
            }
          );
        }
      }, 1000);
    } else {
      $(emlToDrag).addClass("rotate");
      setTimeout(function () {
        $(cart).addClass("shake").css({ backgroundColor: "#F2D69C" });
        setTimeout(() => {
          $(basket).addClass("show").removeClass("hide");
          $(cartOverlayContent).addClass("show").removeClass("hide");
          $(cartOverlayContentPosition).addClass("show").removeClass("hide");
        }, 700);

        $(itemsCount).addClass("show").removeClass("hide").html("1");

        setTimeout(function () {
          cart.removeClass("shake");
          $(cart).css({ backgroundColor: "transparent" });
          $(basket).addClass("hide").removeClass("show");
          $(cartOverlayContent).addClass("hide").removeClass("show");
          $(cartOverlayContentPosition).addClass("hide").removeClass("show");
        }, 3000);
      }, 500);
    }
  };
  const showErrorAnimation = (itemClass) => {
    var emlToDrag = $("." + itemClass);
    var error = $(".addedToCartError");
    $(emlToDrag).addClass("rotate");
    setTimeout(function () {
      $(emlToDrag).removeClass("rotate");
      setScreen((prevState) => ({
        ...prevState,
        addedToCartError: true,
      }));
    }, 1000);
    setTimeout(() => {
      setScreen((prevState) => ({
        ...prevState,
        addedToCartError: false,
      }));
    }, 1500);
  };
  const saveIdInLocalStorage = (value) => {
    localStorage.setItem("BuyerId", JSON.stringify(value));
  };
  const cartCountUpdate = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    console.log("inside cart");
    const saved = localStorage.getItem("BuyerId");
    console.log(saved, "saved");
    const buyerId = saved === null ? "" : JSON.parse(saved);
    console.log(buyerId, "buyerId in cart function");
    try {
      const res = await axiosInstance.post(`cart/`, {
        buyerId: buyerId,
      });
      console.log(res.data, "cart count");
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      localStorage.setItem(
        "cartItemsCount",
        JSON.stringify(res.data.items.length)
      );
    } catch (error) {
      console.log(error, "errorrrrrrrrr");
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const updateProduct = () => {
    const newState = parent.data.map((obj) => {
      // 👇️ if id equals 2, update country property
      if (obj._id === product._id) {
        return { ...obj, addedToCart: 1 };
      }

      // 👇️ otherwise return the object as is
      return obj;
    });

    parentState({ ...parent, data: newState });
  };
  const handleSubmit = async () => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    const localBuyerIdFetch = localStorage.getItem("BuyerId");

    const buyerId =
      localBuyerIdFetch === "undefined" ||
      localBuyerIdFetch === "null" ||
      localBuyerIdFetch === null
        ? ""
        : JSON.parse(localBuyerIdFetch);
    if (buyerId !== screen.buyerId) {
      setScreen({
        ...screen,
        buyerId: buyerId,
      });
    }
    console.log(localEmailValue, buyerId, "before submission");
    try {
      const res = await axiosInstance.post(`cart/add-to-cart`, {
        buyerEmail: localEmailValue,
        buyerId: buyerId,
        productId: productId,
      });

      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "after submission");
      showBasketIconAnimation(`addToCartHolder${product._id} `);
      saveIdInLocalStorage(res.data.buyerId);

      setTimeout(() => {
        updateProduct();
      }, 1001);

      cartCountUpdate();
      console.log("after cart");
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(error, errorMessage);
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      showErrorAnimation(`addToCartHolder${product._id} `);
    }
  };

  const handleOnError = (e) => {
    e.target.src = dummy;
  };
  const className = () => {
    let count = 0;
    if (product.exclusiveItem === "1") {
      count += 1;
    }
    if (product.discounted) {
      count += 1;
    }

    if (count === 1) {
      return false;
    } else if (count > 1) return true;
  };
  useEffect(() => {
    const localEmailValueFetch = localStorage.getItem("BuyerEmail");
    const localEmailValue = JSON.parse(localEmailValueFetch);
    const localBuyerIdFetch = localStorage.getItem("BuyerId");
    const buyerId =
      localBuyerIdFetch === "undefined" ||
      localBuyerIdFetch === "null" ||
      localBuyerIdFetch === null
        ? ""
        : JSON.parse(localBuyerIdFetch);

    if (screen.reload === true) {
      setTimeout(() => {
        setScreen({
          ...screen,
          reload: false,
        });
        parentState((parent) => ({
          ...parent,
          //reload: true,
        }));
      }, 100);
    }
    setTimeout(() => {
      if (
        screen.reload === false &&
        localEmailValue != null &&
        localBuyerIdFetch === null
      ) {
        console.log(buyerId, "buyerId");
        handleSubmit();
      }
    }, 1000);
  }, [screen.reload]);

  const handleBack = () => {
    localStorage.setItem("goBackTo", JSON.stringify(page));
  };
  return (
    <>
      <div className="productCard" onClick={onClick}>
        {product.discounted > 0 && (
          <div className="offDisplay">
            <span className="number">
              {Math.round(product.discounted)}% off
            </span>
          </div>
        )}

        <Link to={`/own-a-fossil/${product._id}`} onClick={() => handleBack()}>
          <CustomTooltip title="Click to get more details" position="right">
            <div className="fossilImageContainer">
            <img
              src={
                process.env.REACT_APP_BACKEND_URL + "public/" + product.image
              }
              className="fossilImage"
              onError={handleOnError}
              alt=""
            />
             <div className="fossilImage hover"></div>
            </div>
            
           
          </CustomTooltip>
        </Link>
        <Link to={`/own-a-fossil/${product._id}`} onClick={() => handleBack()}>
          <div className="plusIcon">
            <CustomTooltip title="Click to get more details">
              <CustomIcon
                icon="plus"
                size={32}

                // onClick={() =>
                //   setScreen((prevState) => ({ ...prevState, display: "details" }))
                // }
              />
            </CustomTooltip>
          </div>
        </Link>

        {/* 
      <div
        className={`fossilOverlay ${
          screen.display === "details" ? "display" : ""
        }`}
      >
        <RiCloseCircleFill
          className="closeIcon"
          title="Click to view image"
          onClick={() =>
            setScreen((prevState) => ({ ...prevState, display: "image" }))
          }
        />
        <div className="expandedDetails">
          <p className="headProperty mb-0">Age</p>
          <p className="detailProperty">{product.age}</p>
          <p className="headProperty mb-0">Description</p>
          <p className="detailProperty">{product.description}</p>
          <p className="headProperty mb-0">Group</p>
          <p className="detailProperty">{product.group}</p>
          <p className="headProperty mb-0">Type</p>
          <p className="detailProperty">{product.type}</p>
        </div>
      </div> */}
        <div className="detailsSection">
          <div className={`tagDisplay ${className()}`}>
            {product.exclusiveItem === "1" && (
              <div className={`tag one`}>Exclusive Item </div>
            )}
            {product.discounted > 0 && (
              <div className={`tag three`}>Discounted</div>
            )}
          </div>

          <hr className="redLine" />
          <div className="bottomSection">
            <p className="fossilName mt-3 mb-0 ps-0">{product.genus}</p>
            <div className="rowDisplay w-100">
              <div className="textArea">
                {/* <p className="fossilCode">Item Code: {product.code}</p> */}
                {product.oldPrice && product.oldPrice !== undefined ? (
                  <p className="fossilPrice">
                    {product.price}${" "}
                    <sup className="priceOffer">{product.oldPrice}$</sup>
                  </p>
                ) : (
                  <p className="fossilPrice">{product.price}$</p>
                )}
              </div>
              {!product.addedToCart || product.addedToCart === 0 ? (
                <div
                  id="addToCartHolder"
                  className={`addToCartHolder addToCartHolder${product._id}  center  `}
                  onClick={() => {
                    !localEmailValue
                      ? setScreen({
                          ...screen,
                          show: true,
                        })
                      : handleSubmit();
                  }}
                >
                  {screen.addedToCartError === true ? (
                    <MdOutlineError className="addedToCartError" />
                  ) : (
                    <CustomTooltip title="Buy it now">
                      <CustomIcon
                        icon="cartPlus"
                        size={45}
                        className={`addToCart
                   `}
                      />
                    </CustomTooltip>
                  )}
                </div>
              ) : null}
              {product.addedToCart && product.addedToCart === 1 ? (
                <div id="addToCartHolder" className={`addToCartHolder center`}>
                  {/* <CustomTooltip title="Item added to your cart">
                    <BsFillBagCheckFill className="addedToCart" />
                  </CustomTooltip> */}
                  <BsFillBagCheckFill className="addedToCart" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <InitialEmailSubmission
        show={screen.show}
        parentScreen={setScreen}
        parent={screen}
        addToCart={handleSubmit}
        onHide={() => setScreen(() => ({ ...screen, show: false }))}
      />
    </>
  );
}

export default ProductCard;
