import React, { useEffect } from "react";
import { Field, Formik } from "formik";
import CMSFormCheckBoxes from "../../../shared/formFields/CMSFormCheckBoxes";
import CMSSlider from "../../../shared/formFields/CMSSlider";
import CMSFormRadioButtons from "../../../shared/formFields/CMSFormRadioButtons";
import { Accordion } from "react-bootstrap";
import CMSFormRadioButtonsSubmit from "../../../shared/formFields/CMSFormRadioButtonsSubmit";
const OwnAFossilFilters = ({
  screen,
  onSubmit,
  sortArray,
  parentState,
  categories,
  subCategories,
  fossilSites,
  moreFilters,
  priceRange,
  search,
  setSearch,
  onClick,
}) => {
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i].value, value: item[i].normalizedValue });
    }

    return array;
  };

  return (
    <>
      <Formik
        initialValues={{
          category: screen.category ? screen.category : "",
          subCategory: screen.subCategory ? screen.subCategory : "",
          site: screen.site ? screen.site : "",
          tag: screen.tag ? screen.tag : "",
          price: screen.price ? screen.price : "",
          sort: screen.sortedValue ? screen.sortedValue : "",
        }}
        onSubmit={async (values) => {
          onSubmit(values);
        }}
        onReset={async (values) => {
          parentState({
            ...screen,
            sortedValue: "",
            searchedValue: "",
            category: "",
            subCategory: "",
            site: "",
            price: "",
            tag: "",
          });
          setSearch("");
          //onClick();
          localStorage.setItem("ownAFossilFiltersApplied", JSON.stringify(""));
          localStorage.setItem(
            "ownAFossilScreenValues",
            JSON.stringify(screen)
          );
        }}
      >
        {(form) => (
          <>
            <Accordion defaultActiveKey={["0", "3"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Choose a Category</Accordion.Header>
                <Accordion.Body>
                  <div className="filterHead type">
                    <CMSFormRadioButtonsSubmit
                      name="category"
                      form={form}
                      label=""
                      onChange={(e) => {
                        form.handleChange(e);
                        form.values.subCategory = "";
                        form.handleSubmit(e);
                      }}
                      options={handleFormatArray(categories)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {subCategories.length > 0 ? (
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Choose a subcategory</Accordion.Header>
                  <Accordion.Body>
                    <div className="filterHead type">
                      <CMSFormCheckBoxes
                        name="subCategory"
                        form={form}
                        label=""
                        options={handleFormatArray(subCategories)}
                      />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ) : null}

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  {" "}
                  Price <span className="lightText">(between)</span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="filterHead price">
                    <CMSSlider
                      name="price"
                      form={form}
                      label=""
                      priceRange={priceRange}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>Choose a Site</Accordion.Header>
                <Accordion.Body>
                  <div className="filterHead type">
                    <CMSFormCheckBoxes
                      name="site"
                      form={form}
                      label=""
                      options={handleFormatArray(fossilSites)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>More Filters</Accordion.Header>
                <Accordion.Body>
                  <div className="filterHead sort">
                    <CMSFormCheckBoxes
                      name="tag"
                      form={form}
                      label=""
                      options={handleFormatArray(moreFilters)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="showOnMobile">
                <Accordion.Header>Sort by</Accordion.Header>
                <Accordion.Body>
                  <div className="filterHead sort">
                    <CMSFormRadioButtons
                      name="sort"
                      form={form}
                      label=""
                      options={sortArray}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="filterHeadBottom sort">
              <a
                href=""
                className="filterApply first"
                onClick={form.handleSubmit}
              >
                apply
              </a>
            </div>

            {screen.category ||
            screen.searchedValue ||
            screen.subCategory.length > 0 ||
            screen.site.length > 0 ||
            screen.tag ||
            screen.price ||
            screen.sortedValue ? (
              <div className="filterHeadBottom sort">
                <a href="" className="filterApply" onClick={form.handleReset}>
                  reset all filters
                </a>
              </div>
            ) : null}
          </>
        )}
      </Formik>
    </>
  );
};

export default OwnAFossilFilters;
