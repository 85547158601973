import React, { useReducer, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import CartListItem from "../../../cart/cartListItem/CartListItem";
import { FormSubmitButton } from "../../../../shared/buttons/Buttons";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
import {
  screenInitialState,
  screenReducer,
} from "../../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../../config/axios";
import { useNavigate } from "react-router-dom";
const OrderDisplay = ({
  paymentMethod,
  parent,
  parentState,
  transactionId,
  deliveryMethod,
}) => {
  const [screen, setScreen] = useState({
    deleted: false,
    orderStatus: "",
  });
  const navigate = useNavigate();
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const [paymentState, paymentDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const [checkoutState, checkoutDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const handleSubmit = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    const saved = localStorage.getItem("BuyerId");
    const buyerId = JSON.parse(saved);
    try {
      const res = await axiosInstance.post(`cart/`, {
        buyerId: buyerId,
      });

      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const handlePayment = async () => {
    navigate("/pay-online/" + transactionId);
  };
  const handleCheckout = async () => {
    checkoutDispatch({
      type: "FETCH_REQUEST",
    });

    const saved = localStorage.getItem("BuyerId");
    const buyerId = JSON.parse(saved);
    try {
      const res = await axiosInstance.post(`cart/guest-checkout`, {
        buyerId: buyerId,
      });

      checkoutDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      parentState({
        ...parent,
        orderStatus: "success",
        orderId: res.data.orderId,
      });

      localStorage.removeItem("BuyerDetails");
      localStorage.removeItem("shippingDetails");
      console.log(res.data, "checkout result");
    } catch (error) {
      checkoutDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
      parentState({
        ...parent,
        orderStatus: "error",
      });
    }
  };
  useEffect(() => {
    handleSubmit();
    if (screen.deleted === true) {
      setTimeout(() => {
        setScreen({
          ...screen,
          deleted: false,
        });
      }, 100);
    }
  }, [screen.deleted]);
  useEffect(() => {
    handleSubmit();
    if (parent.orderDisplayReload === true) {
      setTimeout(() => {
        parentState({
          ...parent,
          orderDisplayReload: false,
        });
      }, 500);
    }
  }, [parent.orderDisplayReload]);
  return (
    <Row className="orderRow  mx-xl-1">
      <Col xl={6} lg={6} md={7} className=" ps-xl-0">
        {detailsState &&
          detailsState.data &&
          detailsState.data.items &&
          detailsState.data.items.map((item, index) => {
            return (
              <CartListItem
                key={index}
                item={item}
                deleteIcon={false}
                parent={screen}
                parentState={setScreen}
              />
            );
          })}
      </Col>

      <Col xl={6} lg={6} md={5} className="rightSide pe-xl-2">
        {detailsState && detailsState.data && detailsState.data.invoice && (
          <Row className="orderAmountDisplay  ">
            {/* <Col className="price ">
              <p>Subtotal</p>
              <strong>{detailsState.data.invoice.subTotal} $</strong>
            </Col> */}
            {/* <Col className="price ">
              <p>Shipment charges</p>
              <strong> {detailsState.data.invoice.shippingCharges} $</strong>
              
            </Col> */}
            {/* <Col className="price ">
              <i>Shipment charges are already included in the fossil price</i>
            </Col> */}
            <Col className="price total">
              <p>Grand Total</p>
              <strong>{detailsState.data.invoice.totalAmount} $</strong>
            </Col>

            <Col
              xl={12}
              className=" py-0 ps-0 pe-0 end mt-xl-4 mt-lg-4 mt-md-0 me-3"
            >
              {deliveryMethod === "Delivery" &&
                detailsState &&
                detailsState.data &&
                detailsState.data.shippingAddress &&
                detailsState.data.shippingAddress.city && (
                  <FormSubmitButton
                    theme="light"
                    text="Move to payment"
                    onClick={() => handlePayment()}
                  />
                )}
              {deliveryMethod === "Pickup" && (
                <FormSubmitButton
                  theme="light"
                  text="Move to payment"
                  onClick={() => handlePayment()}
                />
              )}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default OrderDisplay;
