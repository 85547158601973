import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ImageCircle from "../../shared/subpageImageCircle/ImageCircle";
import BodyLayout from "../body/BodyLayout";
import { BorderButton } from "../../shared/buttons/Buttons";
import CustomTooltip from "../../shared/tooltip/CustomTooltip";
import { FetchErrorLight } from "../../shared/displayAlerts/DisplayAlerts";

const DiscoverUsSubPage = ({
  error,
  heading,
  leftContent,
  images,
  title,
  buttonLink,
  buttonText,
  galleryCount,
  slug,
}) => {
  const [screen, setScreen] = useState({
    loading: true,
  });
  useEffect(() => {
    setTimeout(() => {
      setScreen({
        ...screen,
        loading: false,
      });
    }, 100);
  }, []);
  useEffect(() => {
    localStorage.removeItem("newsFiltersApplied");
    localStorage.removeItem("newsScreenValues");
    localStorage.removeItem("motFiltersApplied");
    localStorage.removeItem("motScreenValues");
    localStorage.removeItem("ownAFossilFiltersApplied");
    localStorage.removeItem("ownAFossilScreenValues");
    localStorage.removeItem("mediaFiltersApplied");
    localStorage.removeItem("mediaScreenValues");
    localStorage.removeItem("pressFiltersApplied");
    localStorage.removeItem("pressScreenValues");
    localStorage.removeItem("publicationFiltersApplied");
    localStorage.removeItem("publicationScreenValues");
    localStorage.removeItem("currentActiveIdNews");
    localStorage.removeItem("currentActiveIdTestimonial");
  }, []);

  return (
    <BodyLayout>
      {/* {screen.loading === true && <Loading />} */}
      {error ? (
        <div className="ourHistorySection center">
          <>
            <div className="yellowBox"></div>
            <Row className="w-100 mx-auto  p-0">
              <Col>
                <FetchErrorLight />
              </Col>
            </Row>
          </>
        </div>
      ) : (
        <div className="ourHistorySection">
          {screen.loading === false && (
            <>
              <div className="yellowBox"></div>
              <Row className="ms-xl-1">
                <Col xl={12} className="py-5">
                  <Row className="uniPadding innerSection py-3">
                    <Col xl={7} lg={12} md={12} className="pt-4">
                      <div className="headContainer">
                        <h3 className="ourHistoryText">{heading}</h3>
                        <hr className="yellowLine" />
                      </div>
                      <div className="leftContent">{leftContent}</div>
                      {buttonText && buttonLink && (
                        
                        <div>
                          <CustomTooltip
                            title={
                              buttonText === "Own a Fossil"
                                ? "Click to purchase"
                                : "Click to know more"
                            }
                          >
                            <BorderButton text={buttonText} link={buttonLink} />
                          </CustomTooltip>
                        </div>
                      )}
                    </Col>
                    <Col
                      xl={5}
                      lg={12}
                      md={12}
                      xs={12}
                      className="pe-3 circleContainer"
                    >
                      <ImageCircle
                        images={images}
                        galleryCount={galleryCount}
                        slug={slug}
                        title={title}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </BodyLayout>
  );
};

export default DiscoverUsSubPage;
