export function initNetCommerceCheckout(transactionId) {
  //console.log("transactionId", transactionId)



  var scriptFunctions = document.createElement('script')
  scriptFunctions.setAttribute('type', 'text/javascript');
  document.head.appendChild(scriptFunctions);

  let s = "async function completeCallback(result){ \n";
  s += " console.log('result', result) \n";
  s += " if(result && result.resultIndicator){ \n";
  s += " window.location.href = 'https://www.memoryoftime.com/payment-status/" + transactionId + "/' + result.resultIndicator  \n";
  //s += " window.location.href = 'http://localhost:4000/payment-status/" + transactionId + "/' + result.resultIndicator  \n";
  s += " }else{ \n";
  s += " window.location.href = 'https://www.memoryoftime.com/payment-status/" + transactionId + "/error' \n";
  //s += " window.location.href = 'http://localhost:4000/payment-status/" + transactionId + "/error' \n";
  s += " \n }";
  s += " \n }";


  scriptFunctions.innerHTML = s;




  const script = document.createElement('script');
  script.src = 'https://creditlibanais-netcommerce.gateway.mastercard.com/static/checkout/checkout.min.js';

  script.setAttribute('data-complete', 'completeCallback');

  //script.dataError = "errorCallback"
  //script.dataCancel = "cancelCallback"
  script.async = true;
  document.head.appendChild(script);
}
