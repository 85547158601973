import React, { useState, useEffect, useReducer, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import CustomIcon from "../../../shared/customIcon/CustomIcon";
import {
  screenInitialState,
  screenReducer,
} from "../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../config/axios";

const StoreAddress = () => {
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`page-content/contact-us`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data);
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
  }, []);
  const [isLoading, setIsLoading] = useState(true);

  const handleVideoLoaded = () => {
    setIsLoading(false);
  };
  return (
    <Row className="p-1">
      {detailsState.data && (
        <>
          <Col xl={12}>
            <div className="contactLocation">
              <CustomIcon icon="map" size={28} className="locationIcon map" />
              <div className="locationTextArea">
                <p className="subText">
                  {detailsState.data && detailsState.data.addresses && (
                    <>
                      {detailsState.data.addresses.map((address, index) => {
                        if (address.name === "address_line1")
                          return (
                            <p key={index} className="subText">
                              {address.value}
                            </p>
                          );
                      })}
                      {detailsState.data.addresses.map((address, index) => {
                        if (address.name === "address_line2")
                          return (
                            <p key={index} className="subText">
                              {address.value}
                            </p>
                          );
                      })}
                    </>
                  )}
                </p>
              </div>
            </div>
          </Col>
          <Col xl={12}>
            <div className="contactLocation">
              <CustomIcon
                icon="circlePhone"
                size={28}
                className="locationIcon "
              />
              <div className="locationTextArea">
                {detailsState.data.phones &&
                  detailsState.data.phones.map((phone, index) => {
                    return (
                      <p key={index} className="subText">
                        {phone.value}
                      </p>
                    );
                  })}
              </div>
            </div>{" "}
          </Col>
          <Col xl={12}>
            <div className="contactLocation">
              <CustomIcon
                icon="circleMail"
                size={28}
                className="locationIcon "
              />
              <div className="locationTextArea">
                {detailsState.data.emails &&
                  detailsState.data.emails.map((email, index) => {
                    return (
                      <p key={index} className="subText">
                        {email.value}
                      </p>
                    );
                  })}
              </div>
            </div>
          </Col>
          <Col xl={12}>
            {detailsState.data.addresses &&
              detailsState.data.addresses.map((address, index) => {
                if (address.name === "google_map_iframe")
                  return (
                    <>
                      {/* {isLoading && (
                        <div className="loading-holder">
                          <div className="loading-spinner "></div>
                        </div>
                      )} */}
                      <iframe
                        width="100%"
                        height="225"
                        src={address.value}
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                      
                        className="locationImage"
                      ></iframe>
                    </>
                  );
              })}{" "}
          </Col>
        </>
      )}
    </Row>
  );
};

export default StoreAddress;
