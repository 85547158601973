import React, { useState, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import BodyLayout from "../../../layout/body/BodyLayout";
import { Loading, OverLayLoading } from "../../../shared/loading/Loading";
import "../memories.scss";
import {
  screenInitialState,
  screenReducer,
} from "../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../config/axios";
import { Formik } from "formik";
import ListingComponent from "./listingComponent/ListingComponent";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import MediaGalleryFilters from "./filters/MediaGalleryFilters";
import {
  EmptyResponseLight,
  FetchErrorLight,
  NoItemsFoundLight,
  ShowingSearchResultsDialogLight,
} from "../../../shared/displayAlerts/DisplayAlerts";
import { AiOutlineSearch } from "react-icons/ai";
import CMSFormSelect from "../../../shared/formFields/CMSFormSelect";
import { MdClose } from "react-icons/md";
import ModalFilter from "../../../shared/modal/ModalFilter";
import { AppliedFilterSort } from "../../../shared/filters/AppliedFilter";
const MediaGallery = () => {
  const [screen, setScreen] = useState({
    loadMore: false,
    data: null,
    sortShow: false,
    showModal: false,
    sortSubmit: false,
    filterShow: false,
    overlay: false,
    year: "",
    sortedValue: "",
    searchedValue: "",
  });
  const [search, setSearch] = useState("");
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const sortArray = [
    { value: "-1", label: "Newest" },
    { value: "1", label: "Oldest" },
  ];
  // function to get filters
  const getFilterString = () => {
    let filterString = "";
    if (screen.sortedValue) {
      filterString = filterString + "sort=" + screen.sortedValue;
    }
    if (screen.searchedValue) {
      filterString = filterString + "&q=" + screen.searchedValue;
    }
    if (screen.year) {
      filterString = filterString + "&year=" + screen.year;
    }

    return filterString;
  };
  // API fetch
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    const filterAppliedSaved = localStorage.getItem("mediaFiltersApplied");
    const filterApplied = JSON.parse(filterAppliedSaved);
    const mediaScreenSaved = localStorage.getItem("mediaScreenValues");
    const mediaScreen = JSON.parse(mediaScreenSaved);
    let filterString = getFilterString();
    if (
      mediaScreen !== null &&
      filterString === "" &&
      (filterApplied !== "" ||
        filterApplied !== null ||
        filterApplied !== "null")
    ) {
      filterString = filterApplied !== "" ? filterApplied : filterString;

      setScreen({
        ...screen,
        sortedValue: mediaScreen.sortedValue,
        year: mediaScreen.year,
        searchedValue: mediaScreen.searchedValue,
      });
      setSearch(mediaScreen.searchedValue);
    }
    console.log(filterApplied, mediaScreen, filterString, "inside item");
    try {
      const res = await axiosInstance.get(
        "media-gallery" + (filterString ? "?" + filterString : "")
      );
      localStorage.setItem("mediaFiltersApplied", JSON.stringify(filterString));
      localStorage.setItem("mediaScreenValues", JSON.stringify(screen));
      setScreen((screen) => ({
        ...screen,
        filterShow: true,
        loadMore: false,
        data: res.data.items,
      }));
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
   
    document.title = "Media Gallery";
  }, []);
  useEffect(() => {
    localStorage.removeItem("newsFiltersApplied");
    localStorage.removeItem("newsScreenValues");
    localStorage.removeItem("motFiltersApplied");
    localStorage.removeItem("motScreenValues");
    localStorage.removeItem("ownAFossilFiltersApplied");
    localStorage.removeItem("ownAFossilScreenValues");
    localStorage.removeItem("pressFiltersApplied");
    localStorage.removeItem("pressScreenValues");
    localStorage.removeItem("publicationFiltersApplied");
    localStorage.removeItem("publicationScreenValues");
    localStorage.removeItem("currentActiveIdNews");
    localStorage.removeItem("currentActiveIdTestimonial");
  }, []);
    useEffect(() => {
      if(screen.showModal){
        if (window.Tawk_API) {
          window.Tawk_API.hideWidget();
        }
        return () => {
          if (window.Tawk_API) {
            window.Tawk_API.showWidget();
          }
        };
      }
    
  }, [screen.showModal]);
  // function to load more items
  const loadMoreItems = async () => {
    if (
      detailsState &&
      detailsState.data &&
      detailsState.data.pagination &&
      detailsState.data.pagination.currentPage <
        detailsState.data.pagination.pageCount
    ) {
      setScreen((prevState) => ({ ...prevState, loadMore: true }));
      let filterString = getFilterString();
      try {
        const res = await axiosInstance.get(
          "media-gallery" +
            "?page=" +
            detailsState.data.pagination.nextPage +
            (filterString ? "&" + filterString : "")
        );
        setScreen((screen) => ({
          ...screen,
          loadMore:false,
          data: screen.data.concat(res.data.items),
        }));
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    } else {
    }
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleSearchSubmit = () => {
    localStorage.setItem("mediaFiltersApplied", JSON.stringify(""));
    localStorage.setItem(
      "mediaScreenValues",
      JSON.stringify({ ...screen, searchedValue: search })
    );
    setScreen({
      ...screen,
      searchedValue: search,
    });
    window.scrollTo(0, 0);
  };
  // function to clear search
  const handleClear = (values) => {
    localStorage.setItem("mediaFiltersApplied", JSON.stringify(""));
    localStorage.setItem(
      "mediaScreenValues",
      JSON.stringify({ ...screen, searchedValue: "" })
    );
    setScreen({
      ...screen,
      searchedValue: "",
    });
    setSearch("");
  };
  // function for  filter form submission
  const handleFormSubmit = (values) => {
    setScreen({
      ...screen,
      sortSubmit: true,
      sortShow: false,
      showModal: false,
      sortedValue: values.sort,
      year: values.year,
    });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit(search);
      window.scrollTo(0, 0);
    }
  };
  const handleRemoveItemYear = () => {
    setScreen({
      ...screen,
      overlay: true,
    });
    setTimeout(() => {
      setScreen({
        ...screen,
        year: "",
        overlay: false,
      });
      localStorage.setItem("mediaFiltersApplied", JSON.stringify(""));
      localStorage.setItem(
        "mediaScreenValues",
        JSON.stringify({
          ...screen,
          year: "",
        })
      );
    }, 100);
  };
  // calling load function
  useEffect(() => {
    loadItem();
  }, [screen.searchedValue, screen.sortedValue, screen.year]);

  return (
    <BodyLayout>
      <div
        className={`memoriesSection  redBackground ${
          detailsState.hasError ? "center" : ""
        } position-relative pb-4`}
      >
        {detailsState.isFetching === true && screen.data === null && (
          <Loading />
        )}
        {detailsState.isFetching === true &&
          screen.data !== null &&
          screen.overlay !== true && <OverLayLoading />}
        {screen.overlay === true && <OverLayLoading />}
        <div className="yellowBox"></div>
        {detailsState &&
          detailsState.isFetching === false &&
          detailsState.hasError !== true && (
            <Row className="ms-xl-1  pb-0 mb-xl-0">
              <Col xl={12} className="pt-xl-5">
                <Row className=" uniPadding pt-xl-4 mt-xl-3 pt-md-5 pb-md-3">
                  <Col className="ps-xl-4 ps-md-4">
                    <div className="headContainer">
                      <h3 className="headingText cream">Media Gallery </h3>
                    </div>
                  </Col>
                </Row>

                {detailsState &&
                  detailsState.isFetching === false &&
                  detailsState.hasError !== true && (
                    <>
                      <Row className=" uniPadding pt-0">
                        <Col className="ps-4">
                          <Row
                            className={`uniPaddingOwnAFossil  hidOnMobile filterRow innerSection2 pt-0  `}
                          >
                            <Col
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="pt-1 pe-xl-0 filters"
                            >
                              <div className="searchBox me-xl-4 me-md-4">
                                <input
                                  id="search"
                                  name="search"
                                  placeholder="search"
                                  type="text"
                                  onKeyDown={handleKeyPress}
                                  onChange={handleChange}
                                  value={search}
                                />
                                <div
                                  className="icon center"
                                  onClick={() => handleSearchSubmit(search)}
                                >
                                  <AiOutlineSearch className="searchIcon" />
                                </div>
                              </div>

                              <>
                                <Formik
                                  initialValues={{
                                    sort: screen.sortedValue
                                      ? screen.sortedValue
                                      : "",
                                  }}
                                  onSubmit={async (values) => {
                                    setScreen({
                                      ...screen,
                                      sortedValue: values.sort,
                                    });
                                  }}
                                >
                                  {(form) => (
                                    <>
                                    
                                      <div
                                        
                                        className={`filterBox sort me-xl-4 me-md-4${
                                          screen.sortShow === true
                                            ? " viewAll "
                                            : ""
                                        } center`}
                                        onClick={() =>
                                          setScreen((screen) => ({
                                            ...screen,
                                            sortShow: !screen.sortShow,
                                          }))
                                        }
                                      >
                                        <CMSFormSelect
                                          name="sort"
                                          placeholder={{
                                            value: " ",
                                            label: "sort by",
                                          }}
                                          form={form}
                                          selected={screen.sortedValue}
                                          onChange={(e) => {
                                            form.handleChange(e);
                                            form.handleSubmit(e);
                                          }}
                                          filter={true}
                                          options={sortArray}
                                        />
                                      </div>
                                    
                                    </>
                                  )}
                                </Formik>
                                <div
                                  className={`filterBox ${
                                    screen.filterShow === true
                                      ? "viewAll light"
                                      : ""
                                  }  center`}
                                  onClick={() =>
                                    setScreen((screen) => ({
                                      ...screen,
                                      filterShow: !screen.filterShow,
                                    }))
                                  }
                                >
                                
                                    <div className="arrow-down"></div>
                                    view all filters
                                  
                                </div>
                              </>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="mobileFilters pb-3 pt-3 uniPadding center showOnMobile">
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="pt-1  filters "
                        >
                          <div className="mobileSearchComponent">
                            <div
                              className={`searchBox ${
                                screen.expandSearchField ? "expand" : ""
                              }`}
                            >
                              <input
                                id="search"
                                name="search"
                              placeholder="search"
                                type="text"
                                onChange={handleChange}
                                value={search}
                              />
                              <div
                                className="icon center"
                                onClick={
                                  screen.expandSearchField
                                    ? () => handleSearchSubmit(search)
                                    : () =>
                                        setScreen((screen) => ({
                                          ...screen,
                                          expandSearchField:
                                            !screen.expandSearchField,
                                        }))
                                }
                                // onClick={() => handleSearchSubmit(search)}
                              >
                                <AiOutlineSearch className="searchIcon" />
                              </div>
                              {screen.expandSearchField && (
                                <div
                                  className="icon center"
                                  onClick={() =>
                                    setScreen((screen) => ({
                                      ...screen,
                                      expandSearchField:
                                        !screen.expandSearchField,
                                    }))
                                  }
                                  // onClick={() => handleSearchSubmit(search)}
                                >
                                  <MdClose className="searchIcon" />
                                </div>
                              )}
                            </div>
                          </div>

                          <>
                            {screen.showModal === true && (
                              <ModalFilter
                                show={screen.showModal}
                                onHide={() =>
                                  setScreen({ ...screen, showModal: false })
                                }
                              >
                                <div className="filterExpandedMobile pt-3">
                                  <div className="filterContentMobile">
                                    <div className="mobileFilterTop">
                                      <div
                                      
                                        className={`filterBox ${
                                          screen.expandSearchField === true
                                            ? "expand"
                                            : ""
                                        } ${
                                          screen.showModal === true
                                            ? "viewAllMobile "
                                            : ""
                                        } center`}
                                        onClick={() =>
                                          setScreen((screen) => ({
                                            ...screen,
                                            showModal: !screen.showModal,
                                          }))
                                        }
                                      >
                                        <div className="arrow-down"></div>
                                        view all filters
                                      </div>
                                      <div
                                        className="icon center"
                                        onClick={() =>
                                          setScreen({
                                            ...screen,
                                            showModal: false,
                                          })
                                        }
                                        // onClick={() => handleSearchSubmit(search)}
                                      >
                                        <MdClose className="filterCloseIconMobile" />
                                      </div>
                                    </div>

                                    <MediaGalleryFilters
                                      onSubmit={handleFormSubmit}
                                      parentState={setScreen}
                                      onClick={handleClear}
                                      search={search}
                                      setSearch={setSearch}
                                      availableYears={
                                        detailsState &&
                                        detailsState.data &&
                                        detailsState.data.availableYears
                                      }
                                      sortArray={sortArray}
                                      screen={screen}
                                    />
                                  </div>
                                </div>
                              </ModalFilter>
                            )}

                            <div
                             
                              className={`filterBox ${
                                screen.expandSearchField === true
                                  ? "expand"
                                  : ""
                              } ${
                                screen.filterMobileShow === true
                                  ? "viewAll  "
                                  : ""
                              } center`}
                              onClick={() =>
                                setScreen((screen) => ({
                                  ...screen,
                                  showModal: !screen.showModal,
                                }))
                              }
                            >
                              <div className="arrow-down"></div>
                              view all filters
                            </div>
                          </>
                        </Col>
                      </Row>
                    </>
                  )}

                {/* applied filters display end */}

                <Row className="uniPadding pt-3 ">
                  <Col
                    xl={screen.filterShow ? 9 : 12}
                    lg={screen.filterShow ? 8 : 12}
                    md={screen.filterShow ? 7 : 12}
                    sm={12}
                    xs={12}
                    className="pt-xl-2 pt-lg-2 pt-md-2 pt-sm-2 pt-0 px-3 ps-xl-4 ps-md-4 "
                  >
                    {/* applied filters display start */}
                    {screen.year ? (
                      <div className="start filtersTopDisplay light pb-3">
                        Applied filter(s) :
                        {/* {screen.sortedValue && (
                          <AppliedFilterSort
                            value={screen.sortedValue}
                            onClick={handleRemoveItem}
                            theme="light"
                          />
                        )} */}
                        {screen.year && (
                          <AppliedFilterSort
                            value={screen.year}
                            onClick={handleRemoveItemYear}
                            theme="light"
                          />
                        )}
                      </div>
                    ) : null}
                    {detailsState &&
                      detailsState.isFetching === false &&
                      detailsState.data && (
                        <>
                          {screen.searchedValue !== "" && (
                            <ShowingSearchResultsDialogLight
                              value={screen.searchedValue}
                              onClick={handleClear}
                              count={
                                detailsState &&
                                detailsState.data &&
                                detailsState.data.pagination &&
                                detailsState.data.pagination.totalFilteredItems
                              }
                            />
                          )}
                          {detailsState &&
                            detailsState.hasError === false &&
                            detailsState.data &&
                            detailsState.data.items &&
                            detailsState.data.items.length <= 0 && (
                              <div className="mb-xl-4">
                                <NoItemsFoundLight />
                              </div>
                            )}
                        </>
                      )}
                    <Row>
                      <>
                        {detailsState && detailsState.data && (
                          <>
                            {screen.data &&
                              screen.data.map((item, index) => {
                                return (
                                  <>
                                    <Col
                                      xl={screen.filterShow ? 4 : 3}
                                      lg={screen.filterShow ? 6 : 4}
                                      md={screen.filterShow ? 8 : 6}
                                      key={index}
                                      className="component mt-1"
                                    >
                                      <ListingComponent
                                        //   onClick={() => handleItem(item._id)}
                                        item={item}
                                        initialItem={screen.data[0]}
                                        activeItem={
                                          screen.selectedItem
                                            ? screen.selectedItem
                                            : screen.data[0]._id
                                        }
                                      />
                                    </Col>
                                  </>
                                );
                              })}

                            {parseInt(
                              detailsState &&
                                detailsState.data &&
                                detailsState.data.pagination &&
                                detailsState.data.pagination.currentPage
                            ) <
                              parseInt(
                                detailsState &&
                                  detailsState.data &&
                                  detailsState.data.pagination &&
                                  detailsState.data.pagination.pageCount
                              ) && (
                              <div
                                className="loadMore light pt-0 pb-4 mb-3"
                                onClick={loadMoreItems}
                              >
                                {screen.loadMore === true ? (
                                  <div className="spinner-container center">
                                    <div className="loading-spinner"></div>
                                  </div>
                                ) : (
                                  <span>
                                    show more
                                  </span>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    </Row>
                  </Col>
                  <Col className="rightFilters ">
                    {screen.filterShow === true && (
                      <div className={`filterContent `}>
                        <MediaGalleryFilters
                          onSubmit={handleFormSubmit}
                          parentState={setScreen}
                          screen={screen}
                          search={search}
                          setSearch={setSearch}
                          onClick={handleClear}
                          availableYears={
                            window.innerWidth > 767
                              ? detailsState &&
                                detailsState.data &&
                                detailsState.data.availableYears
                              : []
                          }
                          sortArray={[]}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        <Row>
          {detailsState && detailsState.hasError === true && (
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="pt-4 center"
            >
              <FetchErrorLight />
            </Col>
          )}
        </Row>
      </div>
    </BodyLayout>
  );
};

export default MediaGallery;
