import React from "react";
import CustomIcon from "../../../../shared/customIcon/CustomIcon";
import "./siteCard.scss";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
import dummy from "../../../../../assets/images/dummy500.png";
const SiteCard = ({ item, onClick }) => {
  const handleOnError = (e) => {
    e.target.src = dummy;
  };
  return (
    <div className="siteCard start" >
      <div className="siteImageContainer">
        
        <img
          className="siteImage"
          onError={handleOnError}
          src={process.env.REACT_APP_BACKEND_URL + "public/" + item.image}
        />
      </div>
      <div className="ms-3 siteName">{item.title}</div>
      <div className="plusIcon" onClick={onClick}>
        <CustomTooltip title="Click to view more details">
          <CustomIcon icon="plus" size={32} className="icon" />
        </CustomTooltip>
      </div>
    </div>
  );
};

export default SiteCard;
