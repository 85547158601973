import React from "react";
import "./listingComponent.scss";
import { useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CustomIcon from "../../../../shared/customIcon/CustomIcon";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
const ListingComponent = ({ item, onClick, activeItem, initialItem }) => {
  const ref = useRef(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };
  useEffect(() => {
    const activeIdSaved = localStorage.getItem("currentActiveIdNewsHome");
    const activeId = JSON.parse(activeIdSaved);
    if (activeId && activeItem === item._id) {
      setTimeout(() => {
        handleClick();
      }, 100);
    }
  }, [activeItem]);
  return (
    <div className="listingComponentHolder" ref={ref} onClick={onClick}>
      <div className="leftSection ">
        {item.date && item.date.day && <p className="date">{item.date.day}</p>}
        {item.date && item.date.month && (
          <p className="month">{item.date.month}</p>
        )}
        {item.date && item.date.year && (
          <p className="year">{item.date.year}</p>
        )}
      </div>

      <div
        className={`middleSection  ${activeItem === item._id ? "active" : ""} ${
          activeItem == " " && initialItem ? "active" : ""
        }`}
      >
        <div className="hover"></div>

        <div className="PlusIcon">
          {" "}
          <CustomTooltip title="Click to view details" position="top">
            <CustomIcon icon="plus" size={30} />
          </CustomTooltip>
        </div>

        <CustomTooltip title="Click to view details" position="top">
          <Row className="middleRow ">
            <Col>
              <Row className="topTitle ">
                <Col xl={11}>
                  {item.title && <p className="title">{item.title}</p>}
                </Col>
              </Row>
            </Col>
            <Col>
              {item.shortDescription && (
                <p className="subtitle">{item.shortDescription}</p>
              )}
            </Col>
          </Row>
        </CustomTooltip>
        <div className="activeDiv"></div>
      </div>
    </div>
  );
};

export default ListingComponent;
