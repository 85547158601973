import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { BsChevronDown } from "react-icons/bs";
const CMSFormSelect = (props) => {
  const {
    label,
    name,
    form,
    options,
    selected,
    autoFocus,
    placeholder,
    required,
    onChange,
    filter,
    ...rest
  } = props;
  return (
    <div className="form-group position-relative">
      {/* {label !== "" && (
        <>
          {required === true ? (
            <OverlayTrigger
              key={`tooltip-${name}`}
              placement="right"
              overlay={<Tooltip id={`tooltip-right`}>Required field.</Tooltip>}
            >
              <label
                htmlFor={name}
                className={`required ' ${
                  form.touched[name] && form.errors[name] ? "isInvalidText" : ""
                }`}
              >
                {" "}
                {label}
              </label>
            </OverlayTrigger>
          ) : (
            <label
              htmlFor={name}
              className={` ${
                form.touched[name] && form.errors[name] ? "isInvalidText" : ""
              }`}
            >
              {" "}
              {label}
            </label>
          )}
        </>
      )} */}
      {required && (
        <label
          htmlFor={name}
          className={` ${
            form.touched[name] && form.errors[name]
              ? "isInvalidText required"
              : "required"
          }`}
        >
          {label}
        </label>
      )}
      <div>
        <BsChevronDown className="dropIcon" />
      </div>
      <Field
        component="select"
        autoFocus={autoFocus}
        id={name}
        value={selected}
        name={name}
        onChange={onChange}
        className={`form-control ${
          filter ? "filterPartial" : "customInput"
        }   select  ${
          form.touched[name] && form.errors[name] ? "is-invalid" : ""
        }`}
        {...rest}
      >
        <option value={placeholder.value}>{placeholder.label} </option>

        {options.map((option) => {
          return (
            <option
              key={option.value}
              className="selectOption"
              value={option.value}
            >
              {option.label}
            </option>
          );
        })}
      </Field>
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="errorMessage">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

export default CMSFormSelect;
