import React, { useState, useEffect, useReducer, useRef } from "react";
import BodyLayout from "../layout/body/BodyLayout";
import { Row, Col } from "react-bootstrap";
import CMSFormInput from "../shared/formFields/CMSFormInput";
import { ErrorMessage, Formik } from "formik";
import { contactUsSchema } from "../../utils/yupValidation";
import CMSFormTextArea from "../shared/formFields/CMSFormTextArea";
import { FormSubmitButton } from "../shared/buttons/Buttons";
import CMSFormSelect from "../shared/formFields/CMSFormSelect";
import CMSFormTelephone from "../shared/formFields/CMSFormTelephone";
import { ContainerLoading, Loading } from "../shared/loading/Loading";
import ReCAPTCHA from "react-google-recaptcha";
import CustomTooltip from "../shared/tooltip/CustomTooltip";
import ContactInfo from "../shared/contactInfo/ContactInfo";

import {
  screenInitialState,
  screenReducer,
} from "../shared/reducers/screenReducer";
import axiosInstance from "../../config/axios";
import {
  updateInitialState,
  updateReducer,
} from "../shared/reducers/updateReducer";
import {
  FormSubmitError,
  FormSubmitSuccess,
} from "../shared/displayAlerts/DisplayAlerts";

const ContactPage = () => {
  const [screen, setScreen] = useState({
    submitting: false,
    status: "fetched",
    loading: true,
  });
  const [error, setError] = useState(false);
  useEffect(() => {
    localStorage.removeItem("newsFiltersApplied");
    localStorage.removeItem("newsScreenValues");
    localStorage.removeItem("motFiltersApplied");
    localStorage.removeItem("motScreenValues");
    localStorage.removeItem("ownAFossilFiltersApplied");
    localStorage.removeItem("ownAFossilScreenValues");
    localStorage.removeItem("mediaFiltersApplied");
    localStorage.removeItem("mediaScreenValues");
    localStorage.removeItem("pressFiltersApplied");
    localStorage.removeItem("pressScreenValues");
    localStorage.removeItem("publicationFiltersApplied");
    localStorage.removeItem("publicationScreenValues");
    localStorage.removeItem("currentActiveIdNews");
    localStorage.removeItem("currentActiveIdTestimonial");
  }, []);
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [countryState, countryDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );

  const loadCountries = async () => {
    countryDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`countries`);
      countryDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      countryDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadCountries();
  }, []);
  useEffect(() => {
    document.title = "Contact Us";
  }, []);

  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`page-content/contact-us`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const handleSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axiosInstance.post(`contact-us`, data);
      window.scrollTo(0, 0);
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;

      window.scrollTo(0, 0);
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    }
  };
  const handleFormatArray = (item) => {
    const array = [];
    for (let i = 0; i < item.length; i++) {
      array.push({ label: item[i].name, value: item[i].name });
    }

    return array;
  };
  useEffect(() => {
    loadItem();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setScreen({
        ...screen,
        loading: false,
      });
    }, 1500);
  }, []);
  const handleScroll = (error) => {
    if (Object.keys(error)[0] == "firstName") {
      setTimeout(() => {
        const element = document.getElementById("firstName");
        let position = element.getBoundingClientRect();
        // scrolls to 20px above element
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    } else if (Object.keys(error)[0] == "lastName") {
      setTimeout(() => {
        const element = document.getElementById("lastName");
        let position = element.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    } else if (Object.keys(error)[0] == "email") {
      setTimeout(() => {
        const element = document.getElementById("email");
        let position = element.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    } else if (Object.keys(error)[0] == "country") {
      setTimeout(() => {
        const element = document.getElementById("country");
        let position = element.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    } else if (Object.keys(error)[0] == "phone") {
      setTimeout(() => {
        const element = document.getElementById("phone");
        let position = element.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    } else if (Object.keys(error)[0] == "message") {
      setTimeout(() => {
        const element = document.getElementById("message");
        let position = element.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    } else {
      setTimeout(() => {
        const element = document.getElementById("firstName");
        let position = element.getBoundingClientRect();
        // scrolls to 20px above element
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    }
  };
  return (
    <BodyLayout>
      <div
        className={`ourHistorySection ${
          updateState.isSubmitted || updateState.hasSubmissionError
            ? "center"
            : ""
        } `}
      >
        {detailsState.isFetching === true && <Loading />}
        {updateState.isSubmitting === true && <ContainerLoading />}

        <div className="yellowBox"></div>
        {updateState.isSubmitted && <FormSubmitSuccess />}
        {updateState.hasSubmissionError && <FormSubmitError />}
        {!updateState.hasSubmissionError &&
          !updateState.isSubmitted &&
          updateState.initialState &&
          detailsState &&
          detailsState.data && (
            <Row className="ms-xl-1 position-relative">
              <Col xl={12} className="py-5">
                <Row className="uniPadding innerSection pt-3">
                  <Col xl={12} className="pt-xl-4">
                    <div className="headContainer">
                      <h3 className="ourHistoryText" id="title">
                        {detailsState.data.heading
                          ? detailsState.data.heading
                          : "Contact us"}
                      </h3>
                      <hr className="yellowLine" />
                    </div>
                  </Col>
                </Row>
                <Row className="uniPadding innerSection2 pt-0 pb-xl-5 pb-lg-5 pb-md-0 pb-2">
                  <Col xl={4} className="pt-xl-0 ">
                    <ContactInfo
                      item={detailsState.data}
                      error={detailsState.hasError}
                    />
                  </Col>
                  <Col xl={7} className="pt-0 ">
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                        country: "",
                        phone: "",
                        message: "",
                        // recaptcha: "",
                      }}
                      validationSchema={contactUsSchema}
                      onSubmit={(values, { resetForm }) => {
                        // handleSubmit(values);
                        !error ? handleSubmit(values) : console.log("error");
                        console.log(values);
                      }}
                    >
                      {(form) => (
                        <>
                          <Row className="px-3 position-relative ">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <div className="end mb-2">
                                <p className="mandatoryContainer mb-0">
                                 All fields are mandatory
                                </p>
                              </div>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className="mb-3"
                              id="firstName"
                            >
                              <CMSFormInput
                                name="firstName"
                                placeholder="First Name"
                                form={form}
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className="mb-3"
                              id="lastName"
                            >
                              <CMSFormInput
                                name="lastName"
                                placeholder="Last Name"
                                form={form}
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-3"
                              id="email"
                            >
                              <CMSFormInput
                                name="email"
                                placeholder="Email Address"
                                form={form}
                                required={true}
                              />
                            </Col>
                            {countryState &&
                              countryState.data &&
                              countryState.data.items && (
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  xl={6}
                                  className="mb-3"
                                  id="country"
                                >
                                  <CMSFormSelect
                                    name="country"
                                    placeholder={{
                                      label: "Country",
                                      value: "",
                                    }}
                                    form={form}
                                    onChange={form.handleChange}
                                      required={true}
                                    options={
                                      countryState &&
                                      countryState.data &&
                                      countryState.data.items &&
                                      handleFormatArray(countryState.data.items)
                                    }
                                  />
                                </Col>
                              )}
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              xl={6}
                              className="mb-3"
                              id="phone"
                            >
                              <CMSFormTelephone
                                name="phone"
                                placeholder="Phone"
                                form={form}
                                required={true}
                                errorState={error}
                                setErrorState={setError}
                              />
                            </Col>

                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="mb-3"
                              id="message"
                            >
                              <CMSFormTextArea
                                name="message"
                                placeholder="Message"
                                form={form}
                                required={true}
                              />
                            </Col>

                            {/* <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={6}
                              id="recaptcha"
                              className="position-relative"
                            >
                              <div
                                className="captcha"
                                style={{
                                  transform: "scale(0.85)",
                                  transformOrigin: "0 0",
                                }}
                              >
                                <ReCAPTCHA
                                  sitekey="6Lf2rHknAAAAAB5E0V-47tbRC39ob1A2j9uQSkb2"
                                  onChange={(value) =>
                                    form.setFieldValue("recaptcha", value)
                                  }
                                />
                                {form.touched["recaptcha"] &&
                                  form.errors["recaptcha"] && (
                                    <span
                                      id={`recaptcha-error"`}
                                      className="errorMessage"
                                    >
                                      <ErrorMessage name="recaptcha" />
                                    </span>
                                  )}
                              </div>

                             
                            </Col> */}
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="end"
                            >
                              <FormSubmitButton
                                text="send inquiry"
                                onClick={(e) => {
                                  form.handleSubmit(e);
                                  setTimeout(() => {
                                    handleScroll(form.errors);
                                  }, 10);
                                }}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
      </div>
    </BodyLayout>
  );
};

export default ContactPage;
