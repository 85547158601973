import React, { useState, useReducer, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CustomIcon from "../../../shared/customIcon/CustomIcon";
import "./ownAFossilDetails.scss";
import BodyLayout from "../../../layout/body/BodyLayout";
import $ from "jquery";
import parse from "html-react-parser";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import {
  screenInitialState,
  screenReducer,
} from "../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../config/axios";
import { Loading, OverLayLoading } from "../../../shared/loading/Loading";
import { FetchError } from "../../../shared/displayAlerts/DisplayAlerts";
import { BsFillBagCheckFill, BsDownload } from "react-icons/bs";
import { GrCertificate } from "react-icons/gr";
import ExpandedModal from "../../../shared/subpageImageCircle/expandedModal/ExpandedModal";
import DetailsPageSwiperWithZoom from "../../../shared/detailsPageSwiperWithZoom/DetailsPageSwiperWithZoom";
import {
  updateInitialState,
  updateReducer,
} from "../../../shared/reducers/updateReducer";
import InitialEmailSubmission from "../../../shared/product/initialEmailSubmission/InitialEmailSubmission";
import ProductSwiper from "../../home/homePagePartials/productSwiper/ProductSwiper";
import YouMayAlsoLike from "./youMayAlsoLike/YouMayAlsoLike";
import Certificate from "./certificate/Certificate";
const OwnAFossilDetails = (props) => {
  const { slug } = useParams();
  const goBackSaved = localStorage.getItem("goBackTo");
  const goBack = JSON.parse(goBackSaved);
  const [screen, setScreen] = useState({
    display: "image",
    addedToCart: true,
    sendToCart: false,
    shake: false,
    animate: false,
    data: [],
    show: false,
    showCertificate: false,
    showEmail: false,
    id: slug,
    reload: false,
    certificate: null,
    fossilLoading: true,
    filterApplied: "",
  });
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const [certificateState, certificateDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [addState, addDispatch] = useReducer(screenReducer, screenInitialState);
  const showCertificateModal = () => {
    setScreen({ ...screen, showCertificate: true });
  };
  const showModal = () => {
    setScreen({ ...screen, show: true });
  };
  const saved = localStorage.getItem("BuyerId");
  const buyerId = saved === null ? "" : JSON.parse(saved);

  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    console.log("called");

    setScreen((prevState) => ({
      ...prevState,
      fossilLoading: true,
    }));
    const saved = localStorage.getItem("fossilSaleableFilterApplied");
    const initialValue = JSON.parse(saved);
    const activeIdSaved = localStorage.getItem("currentActiveIdOwnAFossil");
    const activeId = JSON.parse(activeIdSaved);
    console.log(activeId, initialValue, "active id");
    try {
      const res = await axiosInstance.get(
        `fossil-saleable/` +
          activeId +
          (buyerId ? "?buyerId=" + buyerId : "?") +
          (initialValue ? "&" + initialValue : "")
      );
      console.log(res.data);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setScreen((prevState) => ({
        ...prevState,
        animate: false,
        fossilLoading: false,
        data: res.data.item.images,
      }));
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const loadCertificateContent = async () => {
    certificateDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axiosInstance.get(`page-content/own-a-fossil-details`);
      console.log(res.data, "certificate");
      certificateDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setScreen((prevState) => ({
        ...prevState,
        certificate: res.data,
      }));
    } catch (error) {
      certificateDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  const showBasketIconAnimation = (itemClass) => {
    setTimeout(() => {
      setScreen((prevState) => ({
        ...prevState,
        addedToCart: !screen.addedToCart,
      }));
    }, 1500);

    var emlToDrag = $("." + itemClass);
    var cart = $(".cartIconHolder");
    var basket = $(".fullOverlay");
    var width = window.innerWidth;
    var cartOverlayContent = $(".cartOverlayContainer");
    var cartOverlayContentPosition = $(".cartOverlayContainerPosition");
    var itemsCount = $(".itemsCount");
    if (width >= 1025) {
      $(emlToDrag).addClass("rotate");
      setTimeout(function () {
        if (emlToDrag) {
          var emlClone = emlToDrag
            .clone()
            .offset({
              top: emlToDrag.offset().top,
              left: emlToDrag.offset().left,
            })
            .css({
              position: "absolute",
              "z-index": "205",
              color: "brown",
            })
            .appendTo($("body"))
            .animate(
              {
                top: cart.offset().top,
                left: $(".cartIconOffset").offset().left,

                width: 60,
                height: 60,
              },
              700,
              "swing",
              function () {
                $(emlClone).hide();
                setTimeout(function () {
                  $(cart).addClass("shake").css({ backgroundColor: "#F2D69C" });
                  $(basket).addClass("show").removeClass("hide");
                  $(cartOverlayContent).addClass("show").removeClass("hide");
                  $(cartOverlayContentPosition)
                    .addClass("show")
                    .removeClass("hide");
                  $(itemsCount).addClass("show").removeClass("hide");

                  setTimeout(function () {
                    $(cart).css({ backgroundColor: "transparent" });
                    $(basket).addClass("hide").removeClass("show");
                    $(cartOverlayContent).addClass("hide").removeClass("show");
                    $(cartOverlayContentPosition)
                      .addClass("hide")
                      .removeClass("show");
                  }, 3000);
                }, 500);
              }
            );
          emlClone.animate(
            {
              top: basket.offset().top,
              left: basket.offset().left,
              width: 19,
              height: 19,
              "font-size": "8px",
              padding: "0",
            },
            function () {
              cart.removeClass("shake");
              setTimeout(function () {
                $(emlToDrag).removeClass("shrink");
                emlClone.css({
                  opacity: "0",
                  display: "none",
                });
              }, 500);
              $(emlToDrag).removeClass("add-to-cart").addClass("view-cart");
            }
          );
        }
      }, 1000);
    } else {
      $(emlToDrag).addClass("rotate");
      setTimeout(function () {
        $(cart).addClass("shake").css({ backgroundColor: "#F2D69C" });
        setTimeout(() => {
          $(basket).addClass("show").removeClass("hide");
          $(cartOverlayContent).addClass("show").removeClass("hide");
          $(cartOverlayContentPosition).addClass("show").removeClass("hide");
        }, 700);

        $(itemsCount).addClass("show").removeClass("hide").html("1");

        setTimeout(function () {
          cart.removeClass("shake");
          $(cart).css({ backgroundColor: "transparent" });
          $(basket).addClass("hide").removeClass("show");
          $(cartOverlayContent).addClass("hide").removeClass("show");
          $(cartOverlayContentPosition).addClass("hide").removeClass("show");
        }, 3000);
      }, 500);
    }
  };
  const showErrorAnimation = (itemClass) => {
    var emlToDrag = $("." + itemClass);
    var error = $(".addedToCartError");
    $(emlToDrag).addClass("rotate");
    setTimeout(function () {
      $(emlToDrag).removeClass("rotate");
      setScreen((prevState) => ({
        ...prevState,
        addedToCartError: true,
      }));
    }, 1000);
    setTimeout(() => {
      setScreen((prevState) => ({
        ...prevState,
        addedToCartError: false,
      }));
    }, 1500);
  };
  const saveIdInLocalStorage = (value) => {
    localStorage.setItem("BuyerId", JSON.stringify(value));
  };
  const cartCountUpdate = async () => {
    addDispatch({
      type: "FETCH_REQUEST",
    });
    console.log("inside cart");
    const saved = localStorage.getItem("BuyerId");
    console.log(saved, "saved");
    const buyerId = saved === null ? "" : JSON.parse(saved);
    console.log(buyerId, "buyerId in cart function");
    try {
      const res = await axiosInstance.post(`cart/`, {
        buyerId: buyerId,
      });
      console.log(res.data, "cart count");
      addDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      localStorage.setItem(
        "cartItemsCount",
        JSON.stringify(res.data.items.length)
      );
    } catch (error) {
      console.log(error, "errorrrrrrrrr");
      addDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  const localEmailValueFetch = localStorage.getItem("BuyerEmail");
  const localEmailValue = JSON.parse(localEmailValueFetch);
  const handleSubmit = async (id) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    const localBuyerIdFetch = localStorage.getItem("BuyerId");

    const buyerId =
      localBuyerIdFetch === "undefined" ||
      localBuyerIdFetch === "null" ||
      localBuyerIdFetch === null
        ? ""
        : JSON.parse(localBuyerIdFetch);
    if (buyerId !== screen.buyerId) {
      setScreen((prevState) => ({
        ...prevState,
        buyerId: buyerId,
      }));
    }
    console.log(localEmailValue, buyerId, "before submission");
    try {
      const res = await axiosInstance.post(`cart/add-to-cart`, {
        buyerEmail: localEmailValue,
        buyerId: buyerId,
        productId: id,
      });

      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "after submission");
      showBasketIconAnimation(`addToCartHolder${id} `);
      saveIdInLocalStorage(res.data.buyerId);

      setScreen((prevState) => ({
        ...prevState,
        animate: true,
        fossilLoading: false,
      }));
      setTimeout(() => {
        loadItem();
      }, 1010);

      cartCountUpdate();
      console.log("after cart");
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(error, errorMessage);
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      showErrorAnimation(`addToCartHolder${id} `);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      const activeIdSaved = localStorage.getItem("currentActiveIdOwnAFossil");
      const activeId = JSON.parse(activeIdSaved);
      // if (activeId === null) {
      //   localStorage.setItem(
      //     "currentActiveIdOwnAFossil",
      //     JSON.stringify(screen.id)
      //   );
      // }
      localStorage.setItem(
        "currentActiveIdOwnAFossil",
        JSON.stringify(screen.id)
      );

      loadItem();
    }, 100);

    navigate(`/own-a-fossil/${screen.id}`);
  }, [screen.id, slug]);
  useEffect(() => {
    setTimeout(() => {
      const activeIdSaved = localStorage.getItem("currentActiveIdOwnAFossil");
      const activeId = JSON.parse(activeIdSaved);
      // if (activeId === null) {
      //   localStorage.setItem(
      //     "currentActiveIdOwnAFossil",
      //     JSON.stringify(screen.id)
      //   );
      // }
      localStorage.setItem("currentActiveIdOwnAFossil", JSON.stringify(slug));

      loadItem();
    }, 100);

    navigate(`/own-a-fossil/${slug}`);
  }, [slug]);
  useEffect(() => {
    if (screen.animate === false) {
      setTimeout(() => {
        const activeIdSaved = localStorage.getItem("currentActiveIdOwnAFossil");
        const activeId = JSON.parse(activeIdSaved);
        // if (activeId === null) {
        //   localStorage.setItem(
        //     "currentActiveIdOwnAFossil",
        //     JSON.stringify(screen.id)
        //   );
        // }
        localStorage.setItem(
          "currentActiveIdOwnAFossil",
          JSON.stringify(screen.id)
        );

        loadItem();
      }, 100);
    }

    //navigate(`/own-a-fossil/${screen.id}`);
  }, [screen.animate]);
  useEffect(() => {
    loadCertificateContent();
  }, [screen.id, slug]);
  useEffect(() => {
    const localEmailValueFetch = localStorage.getItem("BuyerEmail");
    const localEmailValue = JSON.parse(localEmailValueFetch);
    const localBuyerIdFetch = localStorage.getItem("BuyerId");
    const buyerId =
      localBuyerIdFetch === "undefined" ||
      localBuyerIdFetch === "null" ||
      localBuyerIdFetch === null
        ? ""
        : JSON.parse(localBuyerIdFetch);

    if (screen.reload === true) {
      setTimeout(() => {
        setScreen((prevState) => ({
          ...prevState,
          reload: false,
        }));
      }, 100);
    }
    setTimeout(() => {
      if (
        screen.reload === false &&
        localEmailValue != null &&
        localBuyerIdFetch === null
      ) {
        console.log(buyerId, "buyerId");
        handleSubmit(detailsState.data.item._id);
      }
    }, 1000);
  }, [screen.reload]);
  return (
    <BodyLayout>
      {detailsState.isFetching === true && !screen.data && <Loading />}
      {screen.fossilLoading === true && screen.animate === false && (
        <OverLayLoading />
      )}
      {console.log(screen.certificate, "certificate")}
      {screen.reload === true && <OverLayLoading />}

      <div className="OwnAFossilsSection position-relative pb-3">
        <div className="yellowBox"></div>

        <Row className="ms-xl-1 ms-md-1 mb-xl-4 pb-4">
          <Col xl={12} className="pt-xl-5 pt-md-5">
            <Row className="uniPadding innerSection2 pt-2">
              <Col xl={12} lg={12} md={12} className="pt-5">
                <div className="headContainer">
                  <div className="backIconTop smallDevice">
                    <Link
                      to={goBack === "home" ? "/" : "/own-a-fossil"}
                      className="goBack"
                    >
                      <CustomTooltip title="Click to go back">
                        <CustomIcon
                          icon="leftArrow"
                          size={40}
                          className="imagePlus "
                          // onClick={() => handleBack()}
                        />
                      </CustomTooltip>
                    </Link>
                  </div>
                  <div className="backIcon">
                    <Link
                      to={goBack === "home" ? "/" : "/own-a-fossil"}
                      className="goBack"
                    >
                      <CustomTooltip title="Click to go back">
                        <CustomIcon
                          icon="leftArrow"
                          size={40}
                          className="imagePlus "
                          // onClick={() => handleBack()}
                        />
                      </CustomTooltip>
                    </Link>
                  </div>
                  <h3 className="title mb-0">Own a Fossil</h3>
                </div>
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                className="pt-0 pe-xl-5 prevNextIcons"
              >
                <div className="nextPrevIcons">
                  <div className="start me-2 nextContainer ">
                    {detailsState &&
                      detailsState.data &&
                      detailsState.data.pagination &&
                      detailsState.data.pagination.prevId && (
                        <>
                          <CustomTooltip
                            title="Click to view previous fossil"
                            position="bottom-end"
                          >
                            <div className="hideOnMobile">
                              <CustomIcon
                                onClick={() =>
                                  setScreen({
                                    ...screen,
                                    id: detailsState.data.pagination.prevId,
                                    fossilLoading: true,
                                  })
                                }
                                icon="leftArrow"
                                size={40}
                                className="leftRotate me-xl-4 me-md-0  me-4 hideOnMobile"
                                // onClick={() => handleBack()}
                              />
                            </div>
                            <div className="showOnMobile">
                              <CustomIcon
                                onClick={() =>
                                  setScreen({
                                    ...screen,
                                    id: detailsState.data.pagination.prevId,
                                    fossilLoading: true,
                                  })
                                }
                                icon="leftArrow"
                                size={25}
                                className="leftRotate me-xl-4  me-2 showOnMobile"
                                // onClick={() => handleBack()}
                              />
                            </div>
                          </CustomTooltip>
                          <p className="pe-3 ps-2 mb-0 viewNextFossil hideOnMobile">
                            Previous fossil
                          </p>
                          <p className="pe-3 ps-1 mb-0 viewNextFossil showOnMobile">
                            Previous
                          </p>
                        </>
                      )}
                  </div>

                  <div className="end  nextContainer">
                    {detailsState &&
                      detailsState.data &&
                      detailsState.data.pagination &&
                      detailsState.data.pagination.nextId && (
                        <>
                          <p className="pe-2 mb-0 viewNextFossil hideOnMobile">
                            Next fossil
                          </p>
                          <p className="pe-1 mb-0 viewNextFossil showOnMobile">
                            Next
                          </p>
                          <CustomTooltip
                            title="Click to view next fossil"
                            position="bottom-end"
                          >
                            <div className="hideOnMobile">
                              <CustomIcon
                                onClick={() =>
                                  setScreen({
                                    ...screen,
                                    id: detailsState.data.pagination.nextId,
                                    fossilLoading: true,
                                  })
                                }
                                icon="leftArrow"
                                size={40}
                                className="rightRotate me-xl-4 me-md-4"
                                // onClick={() => handleBack()}
                              />
                            </div>
                            <div className="showOnMobile">
                              <CustomIcon
                                onClick={() =>
                                  setScreen({
                                    ...screen,
                                    id: detailsState.data.pagination.nextId,
                                    fossilLoading: true,
                                  })
                                }
                                icon="leftArrow"
                                size={25}
                                className="rightRotate me-2"
                                // onClick={() => handleBack()}
                              />
                            </div>
                          </CustomTooltip>
                        </>
                      )}
                  </div>
                </div>
              </Col>
            </Row>
            {detailsState && detailsState.hasError && (
              <>
                <Row className="uniPadding innerSection2 pt-1 ">
                  <Col xl={12} lg={12} md={12} className="pt-4 pe-xl-5">
                    <FetchError />
                  </Col>
                </Row>
              </>
            )}
            {detailsState &&
              !detailsState.hasError &&
              detailsState.data &&
              detailsState.data.item && (
                <>
                  <Row className="uniPadding innerSection2 pt-1 ">
                    <Col xl={12} lg={12} md={12} className="pt-1 pe-xl-5">
                      <div className="detailsHolder ms-xl-2 ms-lg-2 ">
                        <div className="tagDisplay d-flex">
                          {detailsState.data.item.discounted > 0 && (
                            <div className={`tag two`}>Discounted</div>
                          )}
                          {detailsState.data.item.exclusiveItem === "1" && (
                            <div className={`tag one`}>Exclusive Item</div>
                          )}
                        </div>

                        <Row className="detailsDisplay">
                          <Col className="leftSection">
                            {detailsState.data.item.discounted > 0 && (
                              <div className="offDisplay">
                                <span className="number">
                                  {Math.round(
                                    detailsState.data.item.discounted
                                  )}
                                  % off
                                </span>
                              </div>
                            )}

                            <Row className="nameAndPrice">
                              <Col>
                                <p
                                  className={`${
                                    detailsState.data.item.genus.length > 30
                                      ? "fossilNameSmall"
                                      : "fossilName"
                                  }`}
                                >
                                  {detailsState.data.item.genus}
                                </p>
                                {/* <p className="fossilCode">ABC1X5</p> */}
                                {detailsState.data.item.oldPrice &&
                                detailsState.data.item.oldPrice !==
                                  undefined ? (
                                  <p className="fossilPrice">
                                    {detailsState.data.item.price}${" "}
                                    <sup className="priceOffer">
                                      {detailsState.data.item.oldPrice}$
                                    </sup>
                                  </p>
                                ) : (
                                  <p className="fossilPrice">
                                    {detailsState.data.item.price}$
                                  </p>
                                )}
                                {console.log(detailsState.data.item)}
                                <hr className="redLine " />
                              </Col>
                              <Col>
                                <div
                                  id="addToCartHolder "
                                  className={`addToCartSectionHolder  end  `}
                                >
                                  {" "}
                                  <span>
                                    {" "}
                                    {(detailsState.data.item.addedToCart &&
                                      detailsState.data.item.addedToCart ===
                                        1) ||
                                    detailsState.data.item.addedToCart === "1"
                                      ? "Added to basket"
                                      : "Add to basket"}
                                  </span>
                                  {(detailsState.data.item.addedToCart &&
                                    detailsState.data.item.addedToCart === 1) ||
                                  detailsState.data.item.addedToCart === "1" ? (
                                    <div
                                      id="addToCartHolder"
                                      className={`addToCartHolder center`}
                                    >
                                      <CustomTooltip title="Item added to your cart">
                                        <BsFillBagCheckFill className="addedToCart" />
                                      </CustomTooltip>
                                    </div>
                                  ) : (
                                    <div
                                      id="addToCartHolder"
                                      className={`addToCartHolder addToCartHolder${detailsState.data.item._id}  center  `}
                                      onClick={() => {
                                        !localEmailValue
                                          ? setScreen({
                                              ...screen,
                                              showEmail: true,
                                            })
                                          : handleSubmit(
                                              detailsState.data.item._id
                                            );
                                      }}
                                    >
                                      <CustomTooltip title="buy it now">
                                        <CustomIcon
                                          icon="cartPlus"
                                          size={45}
                                          className={`addToCart
                 `}
                                        />
                                      </CustomTooltip>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={5} lg={5} md={6} sm={12} xs={12}>
                                <div className="mt-2">
                                  {detailsState.data.item.code && (
                                    <>
                                      <p className="fossilSubHead">Item Code</p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.code}
                                      </p>
                                    </>
                                  )}
                                  {detailsState.data.item.category && (
                                    <>
                                      <p className="fossilSubHead">Category</p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.category}
                                      </p>
                                    </>
                                  )}

                                  {detailsState.data.item.subCategory && (
                                    <>
                                      <p className="fossilSubHead">
                                        Subcategory
                                      </p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.subCategory}
                                      </p>
                                    </>
                                  )}
                                  {detailsState.data.item.site && (
                                    <>
                                      <p className="fossilSubHead">Site</p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.site}
                                      </p>
                                    </>
                                  )}
                                  {detailsState.data.item.ageOrOriginDate && (
                                    <>
                                      <p className="fossilSubHead">
                                        Age / Origin date
                                      </p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.ageOrOriginDate}
                                      </p>
                                    </>
                                  )}

                                  {detailsState.data.item.dimension && (
                                    <>
                                      <p className="fossilSubHead">
                                        Dimension{" "}
                                      </p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.dimension}
                                      </p>
                                    </>
                                  )}
                                  {detailsState.data.item.matrix && (
                                    <>
                                      <p className="fossilSubHead">Matrix </p>
                                      <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.matrix}
                                      </p>
                                    </>
                                  )}
                                </div>
                              </Col>

                              <Col xl={7} lg={7} md={6} sm={12} xs={12}>
                                <p className="fossilDetails mt-0 mb-1">
                                  {parse(detailsState.data.item.description)}
                                </p>
                                {detailsState.data.item.relatedMaterial && (
                                  <>
                                    <a
                                      className="fossilSubHead orange mt-2"
                                      download={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "public/" +
                                        detailsState.data.item.relatedMaterial
                                      }
                                      href={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "public/" +
                                        detailsState.data.item.relatedMaterial
                                      }
                                    >
                                      <CustomTooltip title="Please click to download">
                                        Related Material <BsDownload />
                                      </CustomTooltip>
                                    </a>
                                    {/* <p className="fossilSubDetail">
                                        {" "}
                                        {detailsState.data.item.relatedMaterial}
                                      </p> */}
                                  </>
                                )}
                              </Col>
                            </Row>
                            {console.log(screen.certificate)}

                            {screen.certificate !== null &&
                              screen.certificate.content && (
                                <Row>
                                  <Col>
                                    <div className="certificateAlert">
                                      <GrCertificate className="certificateIcon" />
                                      <span>
                                        {parse(screen.certificate.content)}{" "}
                                        {screen.certificate.certificate && (
                                          <span>
                                            <a
                                              class="viewCertificate"
                                              onClick={showCertificateModal}
                                            >
                                              view certificate sample
                                            </a>
                                          </span>
                                        )}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                          </Col>

                          <Col
                            md={12}
                            className="rightSection position-relative ps-xl-0 ps-lg-0"
                            // onClick={() =>
                            //   setScreen(() => ({ ...screen, show: true }))
                            // }
                          >
                            <DetailsPageSwiperWithZoom
                              images={screen.data}
                              image={detailsState.data.item.image}
                              onClick={showModal}
                              id={detailsState.data.item._id}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row className="uniPadding innerSection2 pt-1 ">
                    <Col xl={12} lg={12} md={12} className="pt-1 pe-xl-5">
                      <YouMayAlsoLike
                        activeId={screen.id}
                        category={
                          detailsState.data.item.category &&
                          detailsState.data.item.category
                        }
                        excludeId={
                          detailsState.data.item._id &&
                          detailsState.data.item._id
                        }
                        setParentScreen={setScreen}
                        setParent={screen}
                      />
                    </Col>
                  </Row>

                  <ExpandedModal
                    show={screen.show}
                    onHide={() => setScreen(() => ({ ...screen, show: false }))}
                    array={[
                      detailsState.data.item.image &&
                        detailsState.data.item.image,
                    ]}
                    url={`fossils/gallery/${slug}`}
                  />
                  {screen.certificate.certificate && (
                    <Certificate
                      show={screen.showCertificate}
                      image={
                        screen.certificate.certificate &&
                        screen.certificate.certificate
                      }
                      onHide={() =>
                        setScreen(() => ({ ...screen, showCertificate: false }))
                      }
                    />
                  )}
                  <InitialEmailSubmission
                    show={screen.showEmail}
                    parentScreen={setScreen}
                    parent={screen}
                    addToCart={handleSubmit}
                    onHide={() =>
                      setScreen(() => ({ ...screen, showEmail: false }))
                    }
                  />
                </>
              )}
          </Col>
        </Row>
      </div>
    </BodyLayout>
  );
};

export default OwnAFossilDetails;
