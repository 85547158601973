import React, { useState, useReducer, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiFillCloseCircle } from "react-icons/ai";
import "../imageCircle.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdError } from "react-icons/md";
// Import Swiper styles
import "swiper/scss";
import "swiper/scss/free-mode";
import "swiper/scss/navigation";
import "swiper/scss/thumbs";
import "swiper/scss/scrollbar";
import { FreeMode, Navigation, Thumbs, Scrollbar } from "swiper";
import { Row, Col } from "react-bootstrap";
import CustomIcon from "../../customIcon/CustomIcon";
import {
  screenInitialState,
  screenReducer,
} from "../../reducers/screenReducer";
import axiosInstance from "../../../../config/axios";
import { OverLayLoading } from "../../loading/Loading";
import CustomTooltip from "../../tooltip/CustomTooltip";
import dummy from "../../../../assets/images/dummy500.png";
const ExpandedModal = (props) => {
  const [screen, setScreen] = useState({
    data: null,
    swiperIndex: 0,
    showMobileSwiper: false,
  });
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const x = [];
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const handleOnError = (e) => {
    e.target.src = dummy;
  };
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axiosInstance.get(props.url);

      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "555555");
      setScreen({
        ...screen,
        data: [...props.array, ...res.data.pictures, ...res.data.videos],
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  useEffect(() => {
    if (props.url) {
      loadItem();
    }
  }, [props.url]);
  return (
    <Modal
      show={props.show}
      fullscreen={true}
      className="center"
      aria-labelledby="center swiper"
      centered
    >
      <Modal.Body>
        {detailsState && detailsState.isFetching === true && (
          <OverLayLoading color="light" />
        )}

        <div
          className={` ms-auto closeContainer ${
            screen.showMobileSwiper ? "two" : ""
          }  my-xl-3  my-lg-5 my-md-5  mb-3 `}
        >
          {screen.showMobileSwiper && (
            <CustomIcon
              icon="leftArrow"
              size={48}
              className="backIconWhite "
              onClick={() => {
                setScreen({ ...screen, showMobileSwiper: false });
              }}
            />
          )}
          <div className="closeContainerInside">
            <p>Close</p>
            <CustomTooltip title="Click to close" position="top">
              <CustomIcon
                icon="plus"
                size={48}
                className="closeIconWhite "
                onClick={props.onHide}
              />
            </CustomTooltip>
          </div>
        </div>

        {detailsState && detailsState.hasError === true ? (
          <Row>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="pt-4 center"
            >
              <div className="center flex-column p-0">
                <MdError size={120} className="text-white mb-3" />
                <h3 className="errorHeading ">Something went wrong!</h3>
                <h4 className="errorDescription ">Please try again later.</h4>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            {(detailsState &&
              detailsState.data &&
              detailsState.data.pictures &&
              detailsState.data.pictures.length > 0) ||
            (detailsState &&
              detailsState.data &&
              detailsState.data.videos &&
              detailsState.data.videos.length > 0) ? (
              <div
                className={`expandedDiv ${
                  screen.showMobileSwiper ? "mobileView" : ""
                } `}
              >
                <Row className="w-100 mx-auto hideOnMobile imageRow bg-white">
                  <Col xl={3} lg={3} md={12} className="thumbsImageCol">
                    <div className="pt-4">
                      <hr className="redLine" />
                      <p className="heading mb-2">
                        {detailsState.data.name && detailsState.data.name}
                        {detailsState.data.title && detailsState.data.title}
                        {detailsState.data.genus && detailsState.data.genus}
                      </p>
                      {detailsState.data.date && (
                        <p className="createdDate mb-2">
                          {detailsState.data.date}
                        </p>
                      )}
                      <p className="createdDate d-none  mb-2">{props.id}</p>
                      <p className="count mb-4">
                        {props.url ? (
                          <>
                            {(detailsState.data.pictures &&
                              detailsState.data.pictures.length) +
                              (props.array && props.array.length)}{" "}
                            photo(s){" "}
                            {detailsState.data.videos &&
                              detailsState.data.videos.length > 0 &&
                              "," +
                                detailsState.data.videos.length +
                                " video(s)"}{" "}
                          </>
                        ) : (
                          <>{props.array && props.array.length} photo(s)</>
                        )}
                      </p>
                    </div>

                    <Swiper
                      onSwiper={setThumbsSwiper}
                      spaceBetween={20}
                      freeMode={true}
                      breakpoints={{
                        0: {
                          direction: "vertical",
                          slidesPerView: 3,
                        },
                        700: {
                          direction: "horizontal",
                          slidesPerView: 3,
                        },
                        990: {
                          direction: "vertical",
                          slidesPerView:
                            (detailsState.data.name &&
                              detailsState.data.name.length > 120) ||
                            (detailsState.data.title &&
                              detailsState.data.title.length > 120) ||
                            (detailsState.data.genus &&
                              detailsState.data.genus.length > 120)
                              ? 2
                              : 3,
                          scrollbar: {
                            enabled: false,
                            hide: true,
                            draggable: false,
                          },
                        },
                        1100: {
                          direction: "vertical",
                          slidesPerView:
                            (detailsState.data.name &&
                              detailsState.data.name.length > 120) ||
                            (detailsState.data.title &&
                              detailsState.data.title.length > 120) ||
                            (detailsState.data.genus &&
                              detailsState.data.genus.length > 120)
                              ? 2
                              : 3,
                        },
                        1280: {
                          direction: "vertical",
                          slidesPerView:
                            (detailsState.data.name &&
                              detailsState.data.name.length > 120) ||
                            (detailsState.data.title &&
                              detailsState.data.title.length > 120) ||
                            (detailsState.data.genus &&
                              detailsState.data.genus.length > 120)
                              ? 2
                              : 3,
                        },
                      }}
                      scrollbar={{
                        draggable: true,
                      }}
                      watchSlidesProgress={true}
                      modules={[FreeMode, Navigation, Thumbs, Scrollbar]}
                      className="thumbsSwiper hideOnMobile"
                    >
                      {props.array &&
                        props.array.map((item, i) => {
                          return (
                            <SwiperSlide key={i}>
                              <div className="imageHolder">
                                <div className="redBox"></div>
                                <div className="imageContainer">
                                  <CustomIcon
                                    icon="plus"
                                    size={18}
                                    className="imagePlus "
                                    onClick={null}
                                    title="Click to close"
                                  />

                                  {item.image ? (
                                    <img
                                      onError={handleOnError}
                                      src={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "public/" +
                                        item.image
                                      }
                                    />
                                  ) : (
                                    <img
                                      onError={handleOnError}
                                      src={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "public/" +
                                        item
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="hover"></div>
                            </SwiperSlide>
                          );
                        })}
                      {detailsState.data.pictures &&
                        detailsState.data.pictures.map((item, i) => {
                          return (
                            <SwiperSlide key={i}>
                              <div className="imageHolder">
                                <div className="redBox"></div>
                                <div className="imageContainer">
                                  <CustomIcon
                                    icon="plus"
                                    size={18}
                                    className="imagePlus "
                                    onClick={null}
                                    title="Click to close"
                                  />

                                  <img
                                    onError={handleOnError}
                                    src={
                                      process.env.REACT_APP_BACKEND_URL +
                                      "public/" +
                                      item.image
                                    }
                                  />
                                </div>
                              </div>
                              <div className="hover"></div>
                            </SwiperSlide>
                          );
                        })}
                      {detailsState.data.videos &&
                        detailsState.data.videos.map((item, i) => {
                          return (
                            <SwiperSlide key={i}>
                              <div className="imageHolder">
                                <div className="redBox"></div>
                                <div className="imageContainer">
                                  <CustomIcon
                                    icon="plus"
                                    size={18}
                                    className="imagePlus "
                                    onClick={null}
                                    title="Click to close"
                                  />
                                  {console.log(item.video)}
                                  <div class="holdsTheIframe">
                                    <iframe
                                      width="100%"
                                      height="100%"
                                      src={item.video}
                                    ></iframe>
                                  </div>
                                </div>
                              </div>
                              <div className="hover"></div>
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  </Col>
                  <Col xl={9} lg={9} md={12} xs={12} className="p-0">
                    <div className="rightSide">
                      <Swiper
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{
                          swiper:
                            thumbsSwiper && !thumbsSwiper.destroyed
                              ? thumbsSwiper
                              : null,
                        }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                      >
                        {props.array &&
                          props.array.map((item, i) => {
                            return (
                              <SwiperSlide key={i}>
                                {item.image ? (
                                  <img
                                    onError={handleOnError}
                                    src={
                                      process.env.REACT_APP_BACKEND_URL +
                                      "public/" +
                                      item.image
                                    }
                                  />
                                ) : (
                                  <img
                                    onError={handleOnError}
                                    src={
                                      process.env.REACT_APP_BACKEND_URL +
                                      "public/" +
                                      item
                                    }
                                  />
                                )}

                                <div className="caption">{props.title
                            ? props.title
                            : detailsState.data.name && detailsState.data.name
                            ? detailsState.data.name
                            : detailsState.data.title && detailsState.data.title
                            ? detailsState.data.title
                            : detailsState.data.genus && detailsState.data.genus
                            ? detailsState.data.genus
                            : null}</div>
                              </SwiperSlide>
                            );
                          })}
                        {detailsState.data.pictures &&
                          detailsState.data.pictures.map((item, i) => {
                            return (
                              <SwiperSlide key={i}>
                                {/* <iframe
                        width="100%"
                        height="700"
                        src="https://www.youtube.com/embed/tgbNymZ7vqY"
                      ></iframe> */}
                                <img
                                  onError={handleOnError}
                                  src={
                                    process.env.REACT_APP_BACKEND_URL +
                                    "public/" +
                                    item.image
                                  }
                                />
                                {item.title && (
                                  <div className="caption">{item.title}</div>
                                )}
                              </SwiperSlide>
                            );
                          })}
                        {detailsState.data.videos &&
                          detailsState.data.videos.map((item, i) => {
                            return (
                              <>
                                <SwiperSlide key={i}>
                                  <div class="holdsTheIframe">
                                    <iframe
                                      width="100%"
                                      height="100%"
                                      src={item.video}
                                    ></iframe>
                                  </div>

                                  <div className="caption">{item.title}</div>
                                </SwiperSlide>
                              </>
                            );
                          })}
                      </Swiper>
                    </div>
                  </Col>
                </Row>

                {screen.showMobileSwiper ? (
                  <>
                    <Swiper
                      spaceBetween={10}
                      navigation={true}
                      initialSlide={screen.swiperIndex}
                      modules={[FreeMode, Navigation]}
                      className="mySwiper2"
                    >
                      {screen.data &&
                        screen.data.map((item, i) => {
                          return (
                            <SwiperSlide key={i}>
                              {!item._id && (
                                <>
                                  {console.log(item, "111")}
                                  <img
                                    onError={handleOnError}
                                    src={
                                      process.env.REACT_APP_BACKEND_URL +
                                      "public/" +
                                      item
                                    }
                                  />
                                </>
                              )}

                              {item.image && (
                                <img
                                  onError={handleOnError}
                                  src={
                                    process.env.REACT_APP_BACKEND_URL +
                                    "public/" +
                                    item.image
                                  }
                                />
                              )}

                              {item.video && (
                                <div class="holdsTheIframe">
                                  <iframe
                                    width="100%"
                                    height="100%"
                                    src={item.video}
                                  ></iframe>
                                </div>
                              )}

                              {item.title ? (
                                <div className="caption">{item.title}</div>
                              ) : (
                                <div className="caption">{props.title}</div>
                              )}
                            </SwiperSlide>
                          );
                        })}
                    </Swiper>
                  </>
                ) : (
                  <Row className="w-100 mx-auto showOnMobile imageRow bg-white">
                    <Col xl={3} lg={3} md={12} className="thumbsImageCol">
                      <div className="pt-4">
                        <hr className="redLine" />
                        <p className="heading mb-2">
                          {detailsState.data.name && detailsState.data.name}
                          {detailsState.data.title && detailsState.data.title}
                          {detailsState.data.genus && detailsState.data.genus}
                        </p>
                        {detailsState.data.date && (
                          <p className="createdDate mb-2">
                            {detailsState.data.date}
                          </p>
                        )}
                        <p className="createdDate d-none  mb-2">{props.id}</p>
                        <p className="count mb-4">
                          {props.url ? (
                            <>
                              {(detailsState.data.pictures &&
                                detailsState.data.pictures.length) +
                                (props.array && props.array.length)}{" "}
                              photo(s){" "}
                              {detailsState.data.videos &&
                                detailsState.data.videos.length > 0 &&
                                "," +
                                  detailsState.data.videos.length +
                                  " video(s)"}{" "}
                            </>
                          ) : (
                            <>{props.array && props.array.length} photo(s)</>
                          )}
                        </p>
                      </div>
                      <div className="showOnMobile mobile">
                        {console.log(screen.data)}
                        {screen.data &&
                          screen.data.map((item, i) => {
                            return (
                              <React.Fragment key={i}>
                                <div
                                  className="imageHolderMobile"
                                  onClick={() => {
                                    setScreen({
                                      ...screen,
                                      swiperIndex: i,
                                      showMobileSwiper: true,
                                    });
                                  }}
                                >
                                  <div className="imageContainerMobile">
                                    <CustomIcon
                                      icon="plus"
                                      size={25}
                                      className="imagePlusMobile"
                                      onClick={() => {
                                        setScreen({
                                          ...screen,
                                          swiperIndex: i,
                                          showMobileSwiper: true,
                                        });
                                      }}
                                      title="Click to close"
                                    />
                                    {!item._id && (
                                      <>
                                        <img
                                          onError={handleOnError}
                                          onClick={() => {
                                            setScreen({
                                              ...screen,
                                              swiperIndex: i,
                                              showMobileSwiper: true,
                                            });
                                          }}
                                          src={
                                            process.env.REACT_APP_BACKEND_URL +
                                            "public/" +
                                            item
                                          }
                                        />
                                      </>
                                    )}
                                    {console.log(item.image, "111")}
                                    {item.image && (
                                      <img
                                        onError={handleOnError}
                                        onClick={() => {
                                          setScreen({
                                            ...screen,
                                            swiperIndex: i,
                                            showMobileSwiper: true,
                                          });
                                        }}
                                        src={
                                          process.env.REACT_APP_BACKEND_URL +
                                          "public/" +
                                          item.image
                                        }
                                      />
                                    )}
                                    {item.video && (
                                      <div class="holdsTheIframe">
                                        <iframe
                                          width="100%"
                                          height="100%"
                                          src={item.video}
                                        ></iframe>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                      </div>
                    </Col>
                    <Col xl={9} lg={9} md={12} xs={12} className="p-0">
                      <div className="rightSide">
                        <Swiper
                          spaceBetween={10}
                          navigation={true}
                          thumbs={{
                            swiper:
                              thumbsSwiper && !thumbsSwiper.destroyed
                                ? thumbsSwiper
                                : null,
                          }}
                          modules={[FreeMode, Navigation, Thumbs]}
                          className="mySwiperMobile"
                        >
                          {props.array &&
                            props.array.map((item, i) => {
                              return (
                                <SwiperSlide key={i}>
                                  {item.image ? (
                                    <img
                                      onError={handleOnError}
                                      src={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "public/" +
                                        item.image
                                      }
                                    />
                                  ) : (
                                    <img
                                      onError={handleOnError}
                                      src={
                                        process.env.REACT_APP_BACKEND_URL +
                                        "public/" +
                                        item
                                      }
                                    />
                                  )}

                                  <div className="caption">{props.title
                            ? props.title
                            : detailsState.data.name && detailsState.data.name
                            ? detailsState.data.name
                            : detailsState.data.title && detailsState.data.title
                            ? detailsState.data.title
                            : detailsState.data.genus && detailsState.data.genus
                            ? detailsState.data.genus
                            : null}</div>
                                </SwiperSlide>
                              );
                            })}
                          {detailsState.data.pictures &&
                            detailsState.data.pictures.map((item, i) => {
                              return (
                                <SwiperSlide key={i}>
                                  <img
                                    onError={handleOnError}
                                    src={
                                      process.env.REACT_APP_BACKEND_URL +
                                      "public/" +
                                      item.image
                                    }
                                  />
                                  {item.title && (
                                    <div className="caption">{item.title}</div>
                                  )}
                                </SwiperSlide>
                              );
                            })}
                          {detailsState.data.videos &&
                            detailsState.data.videos.map((item, i) => {
                              return (
                                <>
                                  <SwiperSlide key={i}>
                                    <div class="holdsTheIframe">
                                      <iframe
                                        width="100%"
                                        height="100%"
                                        src={item.video}
                                      ></iframe>
                                    </div>

                                    <div className="caption">{item.title}</div>
                                  </SwiperSlide>
                                </>
                              );
                            })}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            ) : (
              <div
                className={`imageOne ${
                  screen.showMobileSwiper ? "mobileView" : ""
                } `}
              >
                {props.array &&
                  props.array.map((item, i) => {
                    return (
                      <>
                        {item.image ? (
                          <img
                            onError={handleOnError}
                            src={
                              process.env.REACT_APP_BACKEND_URL +
                              "public/" +
                              item.image
                            }
                          />
                        ) : (
                          <img
                            onError={handleOnError}
                            src={
                              process.env.REACT_APP_BACKEND_URL +
                              "public/" +
                              item
                            }
                          />
                        )}

                        <div className="caption">
                          {props.title
                            ? props.title
                            : detailsState.data.name && detailsState.data.name
                            ? detailsState.data.name
                            : detailsState.data.title && detailsState.data.title
                            ? detailsState.data.title
                            : detailsState.data.genus && detailsState.data.genus
                            ? detailsState.data.genus
                            : null}
                        </div>
                      </>
                    );
                  })}
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ExpandedModal;
