import React, { useState, useEffect, useReducer } from "react";
import { Buttons } from "../../../shared/buttons/Buttons";
import "./cartOverlay.scss";
import CustomTooltip from "../../../shared/tooltip/CustomTooltip";
import { Link } from "react-router-dom";
import {
  screenInitialState,
  screenReducer,
} from "../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../config/axios";
// overlay displays when clicked on cart icon in menu
function CartOverlay(props) {
  const [style, setStyle] = useState("");
  const cartCountSaved = localStorage.getItem("cartItemsCount");
  const cartCount = JSON.parse(cartCountSaved);
  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (window.scrollY > 80) {
        setStyle("position");
      } else {
        setStyle("");
      }
    });
  }, []);
  return (
    <div className={`fullOverlay  ${props.value === true ? "show" : "hide"} `}>
      {style === "" ? (
        <div
          className={`cartOverlayContainerPosition start ${
            props.value === true ? "show" : "hide"
          }`}
        >
          <p className="yourBasket">Your basket</p>
          <p className="totalItems">
            You have <span className="bold">{cartCount ? cartCount : 0}</span>{" "}
            item(s)
          </p>
          {cartCount && cartCount > 0 ? (
            <Link to="/cart">
              <Buttons text="to checkout" />
            </Link>
          ) : (
            <Link to="/own-a-fossil">
              <Buttons text="add fossils" />
            </Link>
          )}

          {/* <a className="login">Login / Logout</a> */}
        </div>
      ) : (
        <div
          className={`cartOverlayContainer start ${
            props.value === true ? "show" : "hide"
          } ${props.style} `}
        >
          <p className="yourBasket">Your basket</p>
          <p className="totalItems">
            You have <span className="bold">{cartCount ? cartCount : 0}</span>{" "}
            item(s)
          </p>
          {cartCount && cartCount > 0 ? (
            <Link to="/cart">
              <Buttons text="to checkout" />
            </Link>
          ) : (
            <Link to="/own-a-fossil">
              <Buttons text="add fossils" />
            </Link>
          )}

          {/* <a className="login">Login / Logout</a> */}
        </div>
      )}
    </div>
  );
}

export default CartOverlay;
