import React, { useState, useEffect, useReducer } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { HiPlusCircle } from "react-icons/hi";
import { BiError } from "react-icons/bi";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/scrollbar";
import { Pagination, Scrollbar,Autoplay } from "swiper";
import { Row, Col } from "react-bootstrap";
import "./productSwiper.scss";
import ProductCard from "../../../../shared/product/ProductCard";

// images
import fossilOne from "../../../../../assets/images/fossilOne.png";
import fossilTwo from "../../../../../assets/images/fossilTwo.png";
import fossilThree from "../../../../../assets/images/fossilThree.png";
import CustomIcon from "../../../../shared/customIcon/CustomIcon";
import CustomTooltip from "../../../../shared/tooltip/CustomTooltip";
import {
  screenInitialState,
  screenReducer,
} from "../../../../shared/reducers/screenReducer";
import axiosInstance from "../../../../../config/axios";
import {
  FetchError,
  NoItemsFound,
} from "../../../../shared/displayAlerts/DisplayAlerts";
import { Link } from "react-router-dom";

//  swiper slider with product cards
function ProductSwiper() {
  const [screen, setScreen] = useState({
    loading: true,
    status: "fetching",
    reload: false,
    data: null,
  });

  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );
  const getFilterString = () => {
    let filterString = "";

    const saved = localStorage.getItem("BuyerId");
    const buyerId = saved === null ? "" : JSON.parse(saved);
    console.log(buyerId, saved, "saved");

    if (buyerId !== "") {
      filterString = filterString + "buyerId=" + buyerId;
    }

    return filterString;
  };

  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    let filterString = getFilterString();
    try {
      const res = await axiosInstance.get(
        `fossil-saleable/home-page-items` +
          (filterString ? "?" + filterString : "")
      );
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setScreen((screen) => ({
        ...screen,
        reload: false,
        data: res.data.items,
      }));
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
  }, []);

  return (
    <div className="py-5 bg-secondaryCream  productSwiperSection">
      <Row className="uniPadding m-0 w-100">
        <Col xl={12}>
          <h2 className="sectionHeading">Own a Fossil</h2>
        </Col>
      </Row>
      <Row className="uniPadding m-0  w-100">
        <Col xl={12} xs={12} lg={12} md={12} className="swiperSection">
          {detailsState.hasError === true ? (
            <div className="errorContainer ">
              <FetchError />
            </div>
          ) : screen.data && screen.data.length <= 0 ? (
            ( <div className="errorContainer ">
            <NoItemsFound />
          </div>)
          ) : (
            <Swiper
              scrollbar={{
                draggable: true,
              }}
              autoplay={{
                delay: 7500,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              }}
              
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                600: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1025: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              modules={[Pagination, Scrollbar,Autoplay]}
              className="productSwiper"
            >
              {detailsState.isFetching === true && screen.data === null && (
                <>
                  <SwiperSlide className=" bg-transparent swiperSlide">
                    <div className="skeleton center"></div>
                  </SwiperSlide>
                  <SwiperSlide className=" bg-transparent swiperSlide">
                    <div className="skeleton center"></div>
                  </SwiperSlide>
                  <SwiperSlide className=" bg-transparent swiperSlide">
                    <div className="skeleton center"></div>
                  </SwiperSlide>
                </>
              )}

              {screen.data &&
                screen.data.length > 0 &&
                screen.data.map((item) => {
                  return (
                    <SwiperSlide
                      key={item._id}
                      className=" bg-transparent swiperSlide"
                    >
                      <ProductCard
                        product={item}
                        parent={screen}
                        parentState={setScreen}
                        page="home"
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          )}

          <div className="plusSection desktop  px-2">
           
              <Link to="/own-a-fossil">
                {" "}
                <CustomIcon icon="plus" size={103} className="bigPlusIcon " />
              </Link>
          
              <Link to="/own-a-fossil" className="viewAll mt-3">
                view all
              </Link>
         
          </div>
          <div className="plusSection mobile p-0">
           
              <Link to="/own-a-fossil">
                <CustomIcon
                  icon="plus"
                  size={40}
                  className="bigPlusIcon mobile "
                  title="Please click to view all"
                />
              </Link>
           
              <Link to="/own-a-fossil" className="viewAll mt-xl-1">
                view all
              </Link>
           
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ProductSwiper;
