import React, { useState, useReducer, useEffect, useRef } from "react";
import BodyLayout from "../../layout/body/BodyLayout";
import "./testimonial.scss";
import { Button, Col, Row } from "react-bootstrap";
import CMSFormSelect from "../../shared/formFields/CMSFormSelect";
import TestimonialListingItem from "./listingItem/TestimonialListingItem";
import CustomTooltip from "../../shared/tooltip/CustomTooltip";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdError } from "react-icons/md";
import {
  screenInitialState,
  screenReducer,
} from "../../shared/reducers/screenReducer";
import axiosInstance from "../../../config/axios";
import {
  ContainerLoading,
  Loading,
  OverLayLoading,
} from "../../shared/loading/Loading";
import {
  EmptyResponse,
  FetchError,
} from "../../shared/displayAlerts/DisplayAlerts";
import CMSFormInput from "../../shared/formFields/CMSFormInput";
import CMSFormImageWithCrop from "../../shared/formFields/CMSFormImageWithCrop";
import CMSFormTextArea from "../../shared/formFields/CMSFormTextArea";
import { addTestimonialSchema } from "../../../utils/yupValidation";
import {
  updateInitialState,
  updateReducer,
} from "../../shared/reducers/updateReducer";
import { FormSubmitButton } from "../../shared/buttons/Buttons";
import { Formik, ErrorMessage } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
const AddTestimonialMobile = () => {
  const [screen, setScreen] = useState({
    sortShow: false,
    sortedValue: "",
    selectedItem: "",
    loadMore: false,
    data: null,
  });
  // let imageRequirement = {
  //   dimension: {
  //     width: 70,
  //     height: 70,
  //   },
  //   allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
  //   size: 1024 * 1024 ,
  // };

  let imageRequirement = {
    dimension: {
      width: 500,
      height: 500,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 3,
  };
  const [detailsState, detailsDispatch] = useReducer(
    screenReducer,
    screenInitialState
  );

  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );


  useEffect(() => {
    document.title = "Testimonials";
  }, []);
  const handleScroll = (error) => {
    if (Object.keys(error)[0] == "firstName") {
      setTimeout(() => {
        const element = document.getElementById("firstName");
        let position = element.getBoundingClientRect();
        // scrolls to 20px above element
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    } else if (Object.keys(error)[0] == "lastName") {
      setTimeout(() => {
        const element = document.getElementById("lastName");
        let position = element.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    } else if (Object.keys(error)[0] == "email") {
      setTimeout(() => {
        const element = document.getElementById("email");
        let position = element.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    } else if (Object.keys(error)[0] == "message") {
      setTimeout(() => {
        const element = document.getElementById("message");
        let position = element.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    } else if (Object.keys(error)[0] == "recaptcha") {
      setTimeout(() => {
        const element = document.getElementById("test-recaptcha");
        let position = element.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    } else {
      setTimeout(() => {
        const element = document.getElementById("firstName");
        let position = element.getBoundingClientRect();
        // scrolls to 20px above element
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }, 20);
    }
  };
  const getFilterString = () => {
    let filterString = "";
    if (screen.sortedValue !== "") {
      filterString = filterString + "sort=" + screen.sortedValue;
    }
    return filterString;
  };
  const handleSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    localStorage.removeItem("currentActiveIdTestimonial");
    console.log(data, "before submission ---- testimonial");

    try {
      const res = await axiosInstance.post(`testimonials`, data);

      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "after submission ---- testimonial");
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    } catch (error) {
      const status = error.response ? error.response.status : 500;
      console.log(error, "after submission  errro ---- testimonial");
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      console.log(error);
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 8000);
    }
  };
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    let filterString = getFilterString();
    try {
      const res = await axiosInstance.get(
        `testimonials` + (filterString ? "?" + filterString : "")
      );
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      setScreen((screen) => ({
        ...screen,
        loadMore: false,
        data: res.data.items,
      }));
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  const loadMoreItems = async () => {
    if (
      detailsState &&
      detailsState.data &&
      detailsState.data.pagination &&
      detailsState.data.pagination.currentPage <
        detailsState.data.pagination.pageCount
    ) {
      setScreen((prevState) => ({ ...prevState, loadMore: true }));
      let filterString = getFilterString();
      try {
        const res = await axiosInstance.get(
          "testimonials" +
            "?page=" +
            detailsState.data.pagination.nextPage +
            (filterString ? "&" + filterString : "")
        );
        setScreen((screen) => ({
          ...screen,
          loadMore: false,
          data: screen.data.concat(res.data.items),
        }));
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    } else {
    }
  };
  useEffect(() => {
    loadItem();
  }, [screen.sortedValue]);
  const containsObject = (activeId) => {
    const result = "";
    for (let i = 0; i < screen.data.length; i++) {
      console.log(i, screen.data[i]._id, activeId);
      if (screen.data[i]._id === activeId) {
        console.log("true");
        localStorage.removeItem("currentActiveIdTestimonial");

        return true;
      } else {
      }
    }
  };
  useEffect(() => {
    const activeIdSaved = localStorage.getItem("currentActiveIdTestimonial");
    const activeId = JSON.parse(activeIdSaved);

    if (activeId !== null && screen.sortedValue.length <= 0) {
      if (screen.data !== null) {
        setScreen({
          ...screen,
          selectedItem: activeId,
        });
        if (containsObject(activeId) === true) {
          console.log("hi");
        } else {
          loadMoreItems();
        }
      }
    }
  }, [screen.data]);
  const ref = useRef(null);
  const doClick = () => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  };

  useEffect(() => {
    const activeIdSaved = localStorage.getItem("addTestimonial");
    const activeId = JSON.parse(activeIdSaved);
    console.log(activeId, "activeId: " + activeId);
    if (activeId !== null && window.innerWidth < 500) {
      setTimeout(() => {
        doClick();
        localStorage.removeItem("addTestimonial");
      }, 100);
    }
  }, []);

  return (
    <BodyLayout>
      <div className={`testimonialSection  position-relative`}>
        <div className="yellowBox"></div>
        {detailsState.isFetching === true && screen.data === null && (
          <Loading />
        )}
        {detailsState.isFetching === true && screen.data !== null && (
          <OverLayLoading />
        )}
        {detailsState.hasError && (
          <Row className="py-5">
            <Col xl={12} className="">
              <FetchError />
            </Col>
          </Row>
        )}
        {!detailsState.hasError && (
          <>
            <Row className=" uniPadding w-100 mx-auto    topRow">
              <Col xl={6} className="headColumn">
                <div className="headContainer">
                  <h3 className="headingText">Add testimonial</h3>
                </div>

              
              </Col>
            </Row>
            <Row className=" uniPadding w-100 mx-auto  listingRow">
             

              <Col xl={6} className="formColumn">
                <div className="formHolder ">
                  {updateState.isSubmitting && <ContainerLoading />}
                  {updateState.isSubmitted && (
                    <div className="center flex-column my-4">
                      <AiFillCheckCircle className="submitSuccessIcon" />
                      <h2 className="submitSuccessHead theme2">Thank you!</h2>
                      <p className="submitSuccessPara theme2">
                        Your testimonial have been submitted successfully.
                      </p>
                    </div>
                  )}
                  {updateState.hasSubmissionError && (
                    <div className="center flex-column my-4">
                      <MdError className="submitErrorIcon text-white" />
                      <h2 className="submitErrorHead theme2 ">Submission failed!</h2>
                      <p className="submitErrorPara theme2 text-center">
                        Looks like something went wrong. <br />
                        Please try checking back again in a few minutes.
                      </p>
                    </div>
                  )}
                  {!updateState.hasError &&
                    !updateState.isSubmitted &&
                    !updateState.hasSubmissionError && (
                      <>
                        <Formik
                          initialValues={{
      
                            firstName: "",
                            lastName: "",
                            company: "",
                            email: "",
                            designation: "",
                            message: "",
                            image: null,
                            recaptcha: "",
                          }}
                          validationSchema={addTestimonialSchema}
                          onSubmit={(values, { resetForm }) => {
                            const formData = new FormData();
                            delete values.recaptcha;
                            for (let name in values) {
                              if (Array.isArray(values[name])) {
                                formData.append(
                                  name,
                                  JSON.stringify(values[name])
                                );
                              } else {
                                formData.append(name, values[name]);
                              }
                            }
                            handleSubmit(formData);
                            console.log(formData);
                          }}
                        >
                          {(form) => (
                            <>
                              <Row className="px-3">
                               
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                  <div className=" end">
                                    <p className="mandatoryContainer mb-0">
                                      Mandatory
                                    </p>
                                  </div>
                                </Col>
                               

                               
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  id="firstName"
                                  className="mb-xl-0 mb-lg-0 mb-3"
                                >
                                  <CMSFormInput
                                    name="firstName"
                                    placeholder="First Name"
                                    form={form}
                                    required={true}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  id="lastName"
                                  className="mb-xl-0 mb-lg-0 mb-3"
                                >
                                  <CMSFormInput
                                    name="lastName"
                                    placeholder="Last Name"
                                    form={form}
                                    required={true}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  id="email"
                                  className="mb-xl-0 mb-lg-0 mb-3"
                                >
                                  <CMSFormInput
                                    name="email"
                                    placeholder="Email"
                                    form={form}
                                    required={true}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  id="company"
                                  className="mb-xl-0 mb-lg-0 mb-3"
                                >
                                  <CMSFormInput
                                    name="company"
                                    placeholder="Company"
                                    form={form}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  id="designation"
                                  className="mb-xl-0 mb-lg-0 mb-3"
                                >
                                  <CMSFormInput
                                    name="designation"
                                    placeholder="Designation"
                                    form={form}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  id="image"
                                  className="mb-xl-0 mb-lg-0 mb-3"
                                >
                                  <CMSFormImageWithCrop
                                    placeholder="Image"
                                    name="image"
                                    form={form}
                                    aspect={1 / 1}
                                    outputSize={imageRequirement.dimension}
                                  />
                                </Col>
                                <Col
                                  xs={12}
                                  sm={11}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  id="message"
                                  className="mb-xl-0 mb-lg-0 mb-3"
                                >
                                  <CMSFormTextArea
                                    name="message"
                                    placeholder="Testimonial max(300 characters)"
                                    form={form}
                                    theme="light"
                                    maxLength={300}
                                    required={true}
                                  />
                                </Col>
                                {/* <Col
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={6}
                                  id="test-recaptcha"
                                  className="position-relative mt-2 pt-1"
                                >
                                  <div
                                    className="captcha"
                                    style={{
                                      transform: "scale(0.85)",
                                      transformOrigin: "0 0",
                                    }}
                                  >
                                    <ReCAPTCHA
                                      sitekey="6Lf2rHknAAAAAB5E0V-47tbRC39ob1A2j9uQSkb2"
                                      onChange={(value) =>
                                        form.setFieldValue("recaptcha", value)
                                      }
                                    />
                                    {form.touched["recaptcha"] &&
                                      form.errors["recaptcha"] && (
                                        <span
                                          id={`recaptcha-error"`}
                                          className="errorMessage"
                                        >
                                          <ErrorMessage name="recaptcha" />
                                        </span>
                                      )}
                                  </div>
                                </Col> */}
                              </Row>
                              <Row>
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  className="end mt-xl-4 mt-lg-4 mt-md-4 px-4"
                                >
                                  <CustomTooltip
                                    title="Please click to submit"
                                    position="bottom"
                                  >
                                    <FormSubmitButton
                                      text="submit"
                                      onClick={(e) => {
                                        form.handleSubmit(e);
                                        setTimeout(() => {
                                          handleScroll(form.errors);
                                        }, 10);
                                      }}
                                    />
                                  </CustomTooltip>
                                </Col>
                              </Row>
                            </>
                          )}
                        </Formik>
                      </>
                    )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </BodyLayout>
  );
};

export default AddTestimonialMobile;
