import React, { useEffect, useState, useReducer } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import axiosInstance from "../../../config/axios";
import BodyLayout from "../../layout/body/BodyLayout";
import { Loading, OverLayLoading } from "../../shared/loading/Loading";
import { AiFillCheckCircle } from "react-icons/ai";
import { Button, Col, Row } from "react-bootstrap";
import "./paymentRequestPaymentStatus.scss";
import OrderSuccessMessage from "../../shared/orderMessage/OrderSuccessMessage";
import {
  updateInitialState,
  updateReducer,
} from "../../shared/reducers/updateReducer";
import {
  screenInitialState,
  screenReducer,
} from "../../shared/reducers/screenReducer";
import PaymentRequestSuccessMessage from "../../shared/paymentRequestMessage/PaymentRequestSuccessMessage";
import queryString from "query-string";
const PaymentRequestPaymentStatus = () => {
  //const { transactionId } = useParams();
  //console.log("transactionId =>",transactionId)
  const { search } = useLocation();
  console.log("search =>", search)
  let queryParams = queryString.parse(search)
  console.log("queryParams =>", queryParams)
  let transactionId = queryParams.transactionId;
  console.log("transactionId =>", transactionId)



  const navigate = useNavigate();
  const [screen, setScreen] = useState({
    status: "fetching", //fetching,processing
    errorType: null, //apiFailed,transactionValidationFailed
  });
  useEffect(() => {
    document.title = "Memory of Time";
  }, []);

  const validateTransaction = async () => {
    try {
      const res = await axiosInstance.post(
        `payment-requests/get-payment-status`,
        {
          transactionId
        }
      );
      console.log(res)
      if (res.status === 200 && res.data.status === true) {
        setScreen({
          ...screen,
          status: "paymentDone",
          transactionId: res.data.transactionId,
          amount: res.data.amount,
          currency: res.data.currency,
        });
      } else {
        setScreen({
          ...screen,
          status: "error",
          errorType: "paymentLinkExpired",
        });
      }
    } catch (error) {
      setScreen({
        ...screen,
        status: "error",
        errorType: "paymentLinkExpired",
      });
    }
  };

  useEffect(() => {
    if (transactionId) {
      validateTransaction();
    }
  }, [transactionId]);

  return (
    <BodyLayout>
      <div className="paymentStatusSection center">
        {screen.status === "fetching" && <Loading />}
        <div className="yellowBox"></div>
        <Row className="ms-xl-1">
          {screen.status === "error" && screen.errorType === "paymentLinkExpired" && (
            <Col xl={12} className="pb-5 pt-3">
              <Row className=" py-3">
                <Col xl={12}>
                  <div className="payOnlineContent">
                    <div className="center flex-column msgBody">
                      <h2 className="submitSuccessHead theme2">
                        Page Not Found.
                      </h2>
                      {/* <p className="submitSuccessPara theme2">
                        Page Not Found.
                      </p> */}
                      <p className="submitSuccessPara theme2">
                        The link you followed is
                        no longer in use. We're sorry for any
                        inconvenience.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          )}

          {screen.status === "paymentDone" && screen.transactionId && (
            <Col xl={12} className="pb-5 pt-5">
              <Row className=" py-3">
                <Col xl={12}>
                  <div className="payOnlineContent">
                    <PaymentRequestSuccessMessage transactionId={screen.transactionId} amount={screen.amount} currency={screen.currency} />
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </BodyLayout>
  );
};

export default PaymentRequestPaymentStatus;
